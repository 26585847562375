import {
  Lodging,
  HotelStarRatingEnum,
  ListingSearchResult,
  ListingCollectionEnum,
} from "redmond";
import { PremierCollectionAvailabilitySortOption } from "../index";

const priceSortComparer =
  (ascending: boolean) => (l1: Lodging, l2: Lodging) => {
    if (!l1.price || !l2.price) {
      if (!l1.price && !l2.price) {
        return 0;
      } else if (!l1.price) {
        return -1;
      }

      return 1;
    }
    if (ascending) {
      return (
        l1.price.nightlyPrice.fiat.value - l2.price.nightlyPrice.fiat.value
      );
    }
    return l2.price.nightlyPrice.fiat.value - l1.price.nightlyPrice.fiat.value;
  };

export const orderByPriceLowToHigh = (lodgings: Lodging[]) => {
  return lodgings.sort(priceSortComparer(true));
};

export const orderByPriceHighToLow = (lodgings: Lodging[]) => {
  return lodgings.sort(priceSortComparer(false));
};

const vrPriceSortComparer =
  (ascending: boolean) =>
  (l1: ListingSearchResult, l2: ListingSearchResult) => {
    if (ascending) {
      return (
        (l1.availability.rate?.price.nightly.fiat.value || 0) -
        (l2.availability.rate?.price.nightly.fiat.value || 0)
      );
    }
    return (
      (l2.availability.rate?.price.nightly.fiat.value || 0) -
      (l1.availability.rate?.price.nightly.fiat.value || 0)
    );
  };

export const vrOrderByPriceLowToHigh = (listings: ListingSearchResult[]) => {
  return listings.sort(vrPriceSortComparer(true));
};

export const vrOrderByPriceHighToLow = (listings: ListingSearchResult[]) => {
  return listings.sort(vrPriceSortComparer(false));
};

export const vrOrderByRecommended = (listings: ListingSearchResult[]) =>
  listings.sort((a, b) => {
    if (
      a.listing.listingCollection === ListingCollectionEnum.Lifestyle &&
      b.listing.listingCollection === ListingCollectionEnum.Premier
    )
      return 1;
    if (
      a.listing.listingCollection === ListingCollectionEnum.Premier &&
      b.listing.listingCollection === ListingCollectionEnum.Lifestyle
    )
      return -1;
    return 0;
  });

export const getVRListingsSort = (
  sortOption: PremierCollectionAvailabilitySortOption
) => {
  switch (sortOption) {
    case PremierCollectionAvailabilitySortOption.Recommended:
    default:
      return vrOrderByRecommended;
    case PremierCollectionAvailabilitySortOption.PricingASC:
      return vrOrderByPriceLowToHigh;
    case PremierCollectionAvailabilitySortOption.PricingDESC:
      return vrOrderByPriceHighToLow;
  }
};

const starRatingIntValue: { [key in HotelStarRatingEnum]: number | null } = {
  NotAvailable: null,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
};

const starRatingSortComparer = (l1: Lodging, l2: Lodging) => {
  if (
    l1.lodging.starRating === HotelStarRatingEnum.NotAvailable ||
    l2.lodging.starRating === HotelStarRatingEnum.NotAvailable
  ) {
    if (
      l1.lodging.starRating === HotelStarRatingEnum.NotAvailable &&
      l2.lodging.starRating === HotelStarRatingEnum.NotAvailable
    ) {
      return 0;
    } else if (l1.lodging.starRating === HotelStarRatingEnum.NotAvailable) {
      return -1;
    }

    return 1;
  }

  return (
    (starRatingIntValue[l1.lodging.starRating] as number) -
    (starRatingIntValue[l2.lodging.starRating] as number)
  );
};

export const orderByStarRatingHighToLow = (lodgings: Lodging[]) => {
  return lodgings.sort((l1, l2) => -starRatingSortComparer(l1, l2));
};
