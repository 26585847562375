import React from "react";

export const LOCATION_TITLE = "Location";
export const ROOMS_AND_BED_TITLE = "Rooms & beds";
export const AMENITIES_TITLE = "Popular Amenities";
export const AMENITIES_SUBTITLE =
  "Enjoy your luxury stay with some of these popular amenities.";
export const ADDITIONAL_BENEFITS = (boldFont?: boolean) => {
  return (
    <>
      Plus, enjoy other premium benefits like{" "}
      {boldFont ? (
        <strong>early check-in and late checkout</strong>
      ) : (
        "early check-in and late checkout"
      )}{" "}
      when available.
    </>
  );
};
export const HOUSE_RULES_TITLE = "House rules";

export const getBenefitsList = (
  variant?: "default" | "lifestyle-collection"
) => {
  const isLifestyleCollection = variant === "lifestyle-collection";
  return (
    <ul>
      <li>
        Earn 5X miles on every vacation rental booking from the
        {isLifestyleCollection ? " Lifestyle" : " Premier"} Collection
      </li>
      <li>
        Enjoy a {isLifestyleCollection ? "$50" : "$100"} experience credit
      </li>
    </ul>
  );
};
