import React from "react";
import { isCaponeTenant } from "@capone/common";
import { config } from "../../../../api/config";

export const PC_BENEFITS_TITLE = "Your Premier Collection Benefits:";

export const getBenefitsList = (
  hotelsMultiplier: number,
  isLifestyleCollection?: boolean,
  isVR?: boolean,
  isUserPrimary?: boolean
) => {
  if (isVR) {
    return (
      <ul>
        <li>
          Earn <strong>5X miles</strong> on every vacation rental booking from
          the {isLifestyleCollection ? "Lifestyle" : "Premier"} Collection
        </li>
        <li>
          Enjoy a{" "}
          <strong>
            {isLifestyleCollection ? "$50" : "$100"} experience credit
          </strong>
        </li>
      </ul>
    );
  }
  return isUserPrimary || isCaponeTenant(config.TENANT) ? (
    <ul>
      <li>
        Earn <strong>{hotelsMultiplier}X miles</strong> on any{" "}
        {isLifestyleCollection ? "Lifestyle" : "Premier"} Collection booking
      </li>
      <li>
        Enjoy a{" "}
        <strong>
          {isLifestyleCollection ? "$50" : "$100"} experience credit
        </strong>
      </li>
      {isLifestyleCollection ? (
        <li>
          <strong>Room upgrades</strong> when available
        </li>
      ) : (
        <li>
          Daily <strong>breakfast</strong> for 2 and complimentary{" "}
          <strong>Wifi</strong>
        </li>
      )}
    </ul>
  ) : (
    <ul>
      <li>
        Enjoy a{" "}
        <strong>
          {isLifestyleCollection ? "$50" : "$100"} experience credit
        </strong>
      </li>
      {isLifestyleCollection ? (
        <li>
          <strong>Room upgrades</strong> when available
        </li>
      ) : (
        <li>
          Daily <strong>breakfast</strong> for 2 and complimentary{" "}
          <strong>Wifi</strong>
        </li>
      )}
    </ul>
  );
};

export const PC_ADDITIONAL_BENEFITS = (isVR?: boolean) => {
  if (isVR) {
    return (
      <span>
        Plus, enjoy other premium benefits like{" "}
        <span className="font-bold">early check-in and late checkout</span> when
        available.
      </span>
    );
  }
  return (
    <>
      Plus, enjoy other premium benefits like room upgrades early check-in and
      late checkout when available
    </>
  );
};

export const LC_BENEFITS_TITLE = "Your Lifestyle Collection Benefits:";

export const LC_ADDITIONAL_BENEFITS = (
  <span>
    Plus, enjoy other benefits like <strong>complimentary Wi-Fi</strong> and,
    when available, <strong>early check-in/late checkout</strong>.
  </span>
);
