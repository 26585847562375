import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { getStayType } from "../../../search/reducer";
import {
  setAmenitiesFilter,
  setCancellationFilter,
  setHotelsOnSaleFilter,
  setMaxPriceFilter,
  setPremierCollectionNameFilter,
  setStarRatingsFilter,
  setVacationRentalsAmenities,
  setVacationRentalsRoomCounts,
} from "../../actions/actions";
import {
  getPremierCollectionAvailabilityAmenitiesFilter,
  getPremierCollectionAvailabilityCancellationFilter,
  getPremierCollectionAvailabilityCurrency,
  getPremierCollectionAvailabilityHotelNameFilter,
  getPremierCollectionAvailabilityHotelsOnSaleFilter,
  getPremierCollectionAvailabilityMaxPriceFilter,
  getPremierCollectionAvailabilityMinMaxPriceRange,
  getPremierCollectionAvailabilityStarRatingsFilter,
  getVacationRentalAmenities,
  getVacationRentalRoomCounts,
} from "../../reducer";
import { AvailabilityFilter } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    stayType: getStayType(state),
    currency: getPremierCollectionAvailabilityCurrency(state),
    amenities: getPremierCollectionAvailabilityAmenitiesFilter(state),
    starRatings: getPremierCollectionAvailabilityStarRatingsFilter(state),
    maxPrice: getPremierCollectionAvailabilityMaxPriceFilter(state),
    minMaxPriceRange: getPremierCollectionAvailabilityMinMaxPriceRange(state),
    vacationRentalsRoomCounts: getVacationRentalRoomCounts(state),
    vacationRentalsAmenities: getVacationRentalAmenities(state),
    hotelName: getPremierCollectionAvailabilityHotelNameFilter(state),
    freeCancellation: getPremierCollectionAvailabilityCancellationFilter(state),
    hotelsOnSale: getPremierCollectionAvailabilityHotelsOnSaleFilter(state),
  };
};

const mapDispatchToProps = {
  setAmenitiesFilter,
  setStarRatingsFilter,
  setMaxPriceFilter,
  setVacationRentalsRoomCounts,
  setHotelsOnSaleFilter,
  setCancellationFilter,
  setHotelNameFilter: setPremierCollectionNameFilter,
  setVacationRentalsAmenities,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityFilterConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityFilter = withRouter(
  connector(AvailabilityFilter)
);
