import axios from "axios";
import { addMainGuestApi } from "../paths";

import { config } from "../../config";
import {
  HomesAddMainGuestPostRequest,
  HomesAddMainGuestPostResponse,
} from "redmond";

export const addMainGuest = (
  body: HomesAddMainGuestPostRequest
): Promise<HomesAddMainGuestPostResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(addMainGuestApi, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
