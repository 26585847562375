import React from "react";
import { MobileHotelPhotoModalConnectorProps } from "./container";

import "./styles.scss";
import {
  ActionLink,
  CloseButtonIcon,
  getImagesArray,
  HotelAddress,
  HotelName,
  HotelStarRating,
  MobilePopoverCard,
  PhotoLayout,
} from "halifax";
import clsx from "clsx";
import { MobilePremierCollectionShopRoomTypePickerPanel } from "../MobilePremierCollectionShopRoomTypePickerPanel";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { BaseAddress, StayTypesEnum, LodgingCollectionEnum } from "redmond";

export interface MobileHotelPhotoModalProps
  extends MobileHotelPhotoModalConnectorProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

export const MobileHotelPhotoModal = (props: MobileHotelPhotoModalProps) => {
  const {
    lodging,
    viewedPremierCollectionDetailsProperties,
    openModal,
    setOpenModal,
    stayType,
    listing,
  } = props;
  const [imagesArray, setImagesArray] = React.useState<string[]>([]);

  React.useEffect(() => {
    getImagesArray(
      (stayType === StayTypesEnum.VacationRentals
        ? listing?.listing?.content?.media
        : lodging?.lodging.media) ?? [],
      setImagesArray
    );
  }, [lodging, setImagesArray, stayType, listing]);

  const filteredImagesArray = React.useMemo(
    () =>
      // The filter method is used to remove empty items in imagesArray
      imagesArray.filter((imageTuple) => !!imageTuple),
    [imagesArray]
  );

  return (
    <MobilePopoverCard
      className={clsx("mobile-pc-photo-modal-popup", {
        "mobile-pc-photo-modal-popup-vr":
          stayType === StayTypesEnum.VacationRentals,
      })}
      open={openModal}
      onClose={(event: React.MouseEvent) => {
        event.stopPropagation();
        setOpenModal(false);
      }}
      topRightButton={
        <ActionLink
          className={clsx("mobile-pc-photo-modal-popup-button", "close")}
          content={<CloseButtonIcon />}
          label="Close"
          onClick={() => setOpenModal(false)}
        />
      }
      fullScreen
    >
      <div className="mobile-pc-photo-modal-header">
        <HotelName
          name={
            lodging ? lodging?.lodging?.name : listing?.listing?.content?.name
          }
        />
        {stayType === StayTypesEnum.Hotels ? (
          <HotelAddress address={lodging?.lodging.address} />
        ) : (
          <HotelStarRating
            city={
              lodging
                ? lodging?.lodging?.city
                : (listing?.listing?.content?.location.address as BaseAddress)
                    ?.city
            }
          />
        )}
      </div>
      <div
        id="mobile-photo-gallery"
        className="mobile-pc-photo-modal-container"
      >
        <PhotoLayout
          imgUrls={filteredImagesArray}
          scrollableTarget="mobile-photo-gallery"
        />
      </div>
      {stayType === StayTypesEnum.Hotels && (
        <MobilePremierCollectionShopRoomTypePickerPanel
          onContinueClicked={() => {
            trackEvent({
              eventName: "select_room_from_main_image",
              properties: {
                ...viewedPremierCollectionDetailsProperties,
                is_pc:
                  lodging?.lodgingCollection === LodgingCollectionEnum.Premier,
                is_lc:
                  lodging?.lodgingCollection ===
                  LodgingCollectionEnum.Lifestyle,
              },
            });
          }}
        />
      )}
    </MobilePopoverCard>
  );
};
