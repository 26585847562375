export const INPUT_LABEL = (
  adults: number,
  children: number[],
  petsCount?: number,
  isVacationRentals?: boolean
) => {
  const numTravelers = adults + children.length;
  return numTravelers === 1
    ? `${numTravelers} ${isVacationRentals ? "guest" : "Traveler"}${
        isVacationRentals && petsCount ? `, ${petsCount} pet` : ""
      }`
    : `${numTravelers} ${isVacationRentals ? "guests" : "Travelers"}${
        isVacationRentals && petsCount ? `, ${petsCount} pet` : ""
      }`;
};

export const MODAL_TITLES = {
  modalTitle: "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "",
};

export const PETS_TOOLTIP =
  "If you're traveling with a pet, you must stay in a pet-friendly home. Adding a pet will display only pet-friendly vacation rentals in your search. <br/> <br/> If you'd like to bring more than 1 pet, please discuss with the host after booking. Service animals do not count as pets—you don't need to add them to your guest count, and you can inform your host after booking.";

export const ADDITIONAL_FEE_TEXT = "Additional fee may apply";

export const PETS_MODAL_HEADER = "Pets";
