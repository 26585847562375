export const apiVersionPrefix = "/api/v1";
export const hotelAncillariesApiPrefix = `${apiVersionPrefix}/ancillaries/hotel`;
export const hotelCfarPrefix = `${hotelAncillariesApiPrefix}/cfar`;
export const cfarQuoteForHotelPath = `${hotelCfarPrefix}/quotes/get`;

export const homesPrefix = `${apiVersionPrefix}/homes`;
export const addMainGuestApi: string = `${homesPrefix}/addMainGuest`;

export const autocompleteRegionsPath = `${apiVersionPrefix}/autocomplete/regions`;
export const autocompletePCLCLocationsPath = `${apiVersionPrefix}/autocomplete/lodgings`;
