import { Ancillary, AncillaryOpaqueValue } from "@b2bportal/purchase-api";
import { createSelector } from "@reduxjs/toolkit";
import {
  ProductCfarQuote,
  CfarQuoteResultEnum,
  CfarQuoteEnum,
  HotelCfarQuote,
  HotelCancellationPolicyV2,
  HotelCfarQuoteAdditionalInfo,
  ISearchCfarQuoteByIndex,
  ISearchCancellationPolicyByIndex,
  ISearchAdditionalInfoByIndex,
} from "redmond";
import { DO_NOT_APPLY_OPTION_KEY } from "..";
import {
  AVAILABLE,
  HOTELS_CFAR,
  HOTEL_CFAR_MODEL_V1,
  CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_COPY,
} from "../../../../context/experiments";
import { IStoreState } from "../../../../reducers/types";

export const ancillaryExperimentsSelector = (state: IStoreState) =>
  state.hotelAncillary.experiments;

export const hotelCfarVariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTELS_CFAR]
);

export const hotelCfarModelV1VariantSelector = createSelector(
  ancillaryExperimentsSelector,
  (experiments) => experiments?.[HOTEL_CFAR_MODEL_V1]
);

export const isHotelCfarModelV1RefundableRoomUpdatedCopySelector =
  createSelector(
    hotelCfarModelV1VariantSelector,
    (variant) =>
      variant?.variantName ===
      CFAR_MODEL_ADVANCE_STAR_RATING_23_28_FR_ELIGIBLE_COPY
  );

export const isHotelCfarEnabledSelector = createSelector(
  hotelCfarVariantSelector,
  (hotelCfarVariant) => hotelCfarVariant === AVAILABLE
);

export const isCustomizePageEnabledSelector = createSelector(
  isHotelCfarEnabledSelector,
  (isHotelCfarEnabled) => isHotelCfarEnabled
);

export const lodgingIdWithCfarQuotesSelector = (state: IStoreState) =>
  state.hotelAncillary.lodgingId;

export const roomInfoCfarQuotesSelector = (state: IStoreState) =>
  state.hotelAncillary.roomInfoCfarQuotes;

export const fetchCfarQuotesCallStateSelector = (state: IStoreState) =>
  state.hotelAncillary.fetchCfarQuoteCallState;

export const selectedCfarIdSelector = (state: IStoreState) =>
  state.hotelAncillary.selectedCfarId;

export const hasSelectedRefundableRoomSelector = (state: IStoreState) =>
  state.hotelAncillary.hasSelectedRefundableRoom;

export const hasCfarAttached = createSelector(
  selectedCfarIdSelector,
  (selectedCfarId): boolean =>
    !!selectedCfarId && selectedCfarId.value !== DO_NOT_APPLY_OPTION_KEY
);

export const getAllAncillaries = createSelector(
  selectedCfarIdSelector,
  (selectedCfarId): AncillaryOpaqueValue[] => {
    const ancillaries: AncillaryOpaqueValue[] = [];
    if (selectedCfarId && selectedCfarId.value !== DO_NOT_APPLY_OPTION_KEY) {
      ancillaries.push({
        type: Ancillary.CFAR,
        value: selectedCfarId,
      });
    }

    return ancillaries;
  }
);

// TODO - CFAR Premier: add the selector that returns the CFAR offer by the room id
/*
  note: this selector is an intermediate solution until the room id gets added into the response;
  for example, use the following states from the shop/reducer to work with this selector:
    1) chosenRoomInfoIndex - getHotelShopChosenRoomInfoIndex
    2) chosenProductIndex - getHotelShopChosenProductIndex
*/
export const getHotelProductCfarQuoteByIndexSelector = createSelector(
  roomInfoCfarQuotesSelector,
  (
      roomInfoCfarQuotes
    ): ((props: ISearchCfarQuoteByIndex) => ProductCfarQuote | null) =>
    ({ roomInfoIndex, productIndex }: ISearchCfarQuoteByIndex) => {
      return (
        roomInfoCfarQuotes[roomInfoIndex]?.cfarQuotes[productIndex] ?? null
      );
    }
);

export const getHotelCfarQuoteByIndexSelector = createSelector(
  getHotelProductCfarQuoteByIndexSelector,
  (
      getHotelProductCfarQuoteByIndex
    ): ((props: ISearchCfarQuoteByIndex) => HotelCfarQuote | null) =>
    (props: ISearchCfarQuoteByIndex) => {
      const productCfarQuote = getHotelProductCfarQuoteByIndex(props);

      if (productCfarQuote?.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
        const cfarQuoteResult = productCfarQuote.cfarQuoteResult;

        if (
          cfarQuoteResult.CfarQuoteResult === CfarQuoteResultEnum.HotelCfarQuote
        ) {
          return cfarQuoteResult;
        }
      }

      return null;
    }
);

export const getHotelCancellationPolicyByIndexSelector = createSelector(
  getHotelProductCfarQuoteByIndexSelector,
  (
      getHotelProductCfarQuoteByIndex
    ): ((
      props: ISearchCancellationPolicyByIndex
    ) => HotelCancellationPolicyV2 | null) =>
    (props: ISearchCancellationPolicyByIndex) => {
      const productCfarQuote = getHotelProductCfarQuoteByIndex(props);

      if (productCfarQuote?.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
        return productCfarQuote.cancellationPolicyV2 ?? null;
      }

      return null;
    }
);

export const getHotelAdditionalInfoByIndexSelector = createSelector(
  getHotelProductCfarQuoteByIndexSelector,
  (
      getHotelProductCfarQuoteByIndex
    ): ((
      props: ISearchAdditionalInfoByIndex
    ) => HotelCfarQuoteAdditionalInfo | null) =>
    (props: ISearchAdditionalInfoByIndex) => {
      const productCfarQuote = getHotelProductCfarQuoteByIndex(props);

      if (productCfarQuote?.CfarQuote === CfarQuoteEnum.HotelCfarEligible) {
        return productCfarQuote.additionalInfo;
      }

      return null;
    }
);
