import axios from "axios";
import { autocompletePCLCLocationsPath } from "../paths";

import { config } from "../../config";
import { IResponse } from "redmond";

export const fetchPCLCLocations = (): Promise<IResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(autocompletePCLCLocationsPath, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
