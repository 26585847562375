import React from "react";

import {
  IconDefinition,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import { isEqual } from "lodash-es";
import { RouteComponentProps } from "react-router";

import {
  ActionButton,
  ActionLink,
  B2BSearchAutocomplete,
  CloseButtonIcon,
  Icon,
  IconName,
  MobileAutocompleteOptions,
  MobilePopoverCard,
} from "halifax";
import {
  IIdLodgings,
  IResult,
  LodgingCollectionEnum,
  StayTypesEnum,
} from "redmond";

import {
  PATH_AVAILABILITY,
  PATH_VACATION_RENTALS_AVAILABILITY,
} from "../../../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../../../shop/utils/queryStringHelpers";
import { ViewHotelsNearLocationAutocompleteConnectorProps } from "./container";

export interface IViewHotelsNearLocationAutocompleteProps
  extends ViewHotelsNearLocationAutocompleteConnectorProps,
    RouteComponentProps {
  isLCForNonPremiumCardHoldersEnabled: boolean;
  isVRForPremiumCardHoldersEnabled: boolean;
  getOptionSelected: (a: IResult, b: IResult | null) => boolean;
  label: string;
  className?: string;
  endIcon?: IconDefinition;
  customIcon?: JSX.Element;
  endIconOnClick?: () => void;
  isMobile: boolean;
  clearValue: boolean;
}

const SearchAutocomplete = B2BSearchAutocomplete<IResult>;

export const ViewHotelsNearAutocomplete = (
  props: IViewHotelsNearLocationAutocompleteProps
): JSX.Element => {
  const {
    isLCForNonPremiumCardHoldersEnabled,
    isVRForPremiumCardHoldersEnabled,
    history,
    clearValue,
    currentMapSearchQuery,
    setMapSearchQuery,
    currentHotelsSearchLocation,
    currentVacationRentalsSearchLocaction,
    setHotelsSearchLocation,
    setVacationRentalsSearchLocation,
    fetchInitialHotelsAvailability,
    fetchInitialVacationRentalsAvailability,
    valueCategories,
    availabilitySearchParams,
    loading,
    isMobile,
    className,
  } = props;

  const [modalOpen, setModalOpen] = React.useState(false);

  const [currentLocation, setCurrentLocation] = React.useMemo(() => {
    switch (availabilitySearchParams.type) {
      case StayTypesEnum.Hotels:
        return [currentHotelsSearchLocation, setHotelsSearchLocation];
      case StayTypesEnum.VacationRentals:
        return [
          currentVacationRentalsSearchLocaction,
          setVacationRentalsSearchLocation,
        ];
    }
  }, [
    availabilitySearchParams.type,
    currentHotelsSearchLocation,
    currentVacationRentalsSearchLocaction,
  ]);
  const [localLocation, setLocalLocation] = React.useState(currentLocation);
  const [localQuery, setLocalQuery] = React.useState(
    currentMapSearchQuery || (currentLocation?.label ?? "")
  );

  const doSearch = (value: IResult) => {
    setCurrentLocation(value);
    const location = (value.id as IIdLodgings).lodgingSelection
      .searchTerm as string;
    switch (availabilitySearchParams.type) {
      case StayTypesEnum.Hotels: {
        history.push(
          `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery({
            ...availabilitySearchParams,
            location,
          })}`
        );
        fetchInitialHotelsAvailability(
          history,
          isLCForNonPremiumCardHoldersEnabled
            ? LodgingCollectionEnum.Lifestyle
            : LodgingCollectionEnum.Premier
        );
        break;
      }

      case StayTypesEnum.VacationRentals: {
        history.push(
          `${PATH_VACATION_RENTALS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
            {
              ...availabilitySearchParams,
              location,
            }
          )}`
        );
        fetchInitialVacationRentalsAvailability(history, {
          includePremierCollection: isVRForPremiumCardHoldersEnabled,
        });
        break;
      }
    }
  };

  return (
    <div
      className={className}
      onClick={isMobile ? () => setModalOpen(!modalOpen) : undefined}
    >
      <SearchAutocomplete
        {...props}
        fetchValueCategories={setMapSearchQuery}
        className=""
        disabled={isMobile}
        value={clearValue ? null : currentLocation}
        setValue={(value) => {
          if (value == null) return;
          doSearch(value);
        }}
      />
      {isMobile ? (
        <MobilePopoverCard
          open={modalOpen}
          className="mobile-view-hotels-near-root"
          headerElement={
            <Box className="mobile-view-hotels-near-modal-header-container">
              <Typography className="header-title">
                Enter a destination
              </Typography>
              <ActionLink
                className="modal-close-button"
                onClick={() => setModalOpen(false)}
                content={<CloseButtonIcon className="close-button-icon" />}
                label="Close"
                showTappableArea
              />
            </Box>
          }
        >
          <Box className="mobile-view-hotels-near-modal">
            <Box className="mobile-view-hotels-near-input-container">
              <Box className="mobile-view-hotels-near-input-field">
                <Icon name={IconName.MagnifyingGlass} />
                <TextField
                  className={clsx("mobile-view-hotels-near-auto-complete")}
                  onChange={(e) => {
                    setLocalQuery(e.target.value);
                    setMapSearchQuery(e.target.value);
                  }}
                  value={localQuery}
                  // value={query}
                  label="View hotels in..."
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
                <FontAwesomeIcon
                  className={clsx("icon", {
                    hidden: !localQuery,
                  })}
                  icon={faTimesCircle}
                  onClick={() => {
                    setLocalQuery("");
                    setMapSearchQuery("");
                    setLocalLocation(null);
                  }}
                />
              </Box>
            </Box>

            <MobileAutocompleteOptions
              value={null}
              valueCategories={valueCategories}
              setValue={(value: IResult | null) => {
                setLocalLocation(value);
                setLocalQuery(value?.label ?? "");
                setMapSearchQuery("");
              }}
              loading={loading}
              loadingText="Loading"
            />
            {localLocation &&
            !isEqual(localLocation?.id, currentLocation?.id) ? (
              <ActionButton
                className="mobile-autocomplete-continue-button"
                message={"Continue"}
                onClick={() => {
                  setMapSearchQuery("");
                  setModalOpen(false);
                  doSearch(localLocation);
                }}
              />
            ) : null}
          </Box>
        </MobilePopoverCard>
      ) : null}
    </div>
  );
};
