import { connect, ConnectedProps } from "react-redux";
import {
  getPremierCollectionAvailabilityFromDate,
  getPremierCollectionAvailabilityUntilDate,
} from "../../../availability/reducer";
import { IStoreState } from "../../../../reducers/types";
import { getVacationRentalShopSelectedListing } from "../../../vacation-rental-shop/reducer";
import { SelectedVacationRentalSummary } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    checkIn: getPremierCollectionAvailabilityFromDate(state),
    checkOut: getPremierCollectionAvailabilityUntilDate(state),
    selectedHome: getVacationRentalShopSelectedListing(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SelectedVacationRentalSummaryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedSelectedVacationRentalSummary = connector(
  SelectedVacationRentalSummary
);
