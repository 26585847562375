export const apiVersionPrefix: string = "/api/v0";

export const locationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/premierCollection`;
export const lifestyleLocationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/lifestyleCollection`;
export const hotelAvailabilityApiPrefix: string = `${apiVersionPrefix}/hotels/availability`;
export const hotelShopApiPrefix: string = `${apiVersionPrefix}/hotels/shop`;
export const passengersApiPrefix: string = `${apiVersionPrefix}/passengers`;
export const paymentMethodsApiPrefix: string = `${apiVersionPrefix}/paymentMethods`;
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;
export const hotelBookApiPrefix: string = `${apiVersionPrefix}/payment`;
export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;
export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;
export const customerDetailsApiPath: string = `${apiVersionPrefix}/customer/details`;

export const vacationRentalsLocationApi: string = `${apiVersionPrefix}/autocomplete/homes`;
export const vacatioRentalsApiPrefix: string = `${apiVersionPrefix}/homes`;
export const vacationRentalsAvailabilityApi: string = `${vacatioRentalsApiPrefix}/availability`;
export const vacationRentalShopApiPrefix = `${vacatioRentalsApiPrefix}/shop`;
export const vacationRentalCalendarApiPrefix = `${vacatioRentalsApiPrefix}/listing/getCalendarAvailability`;
export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;
export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;

export const userApiPrefix: string = `${apiVersionPrefix}/user`;
export const travelWalletApiPrefix: string = `${userApiPrefix}/wallet`;

// Corporate travel
export const pendingRequestApiPrefix = `${apiVersionPrefix}/pendingRequests/hotel`;
