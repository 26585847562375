import React from "react";
export const FETCHING_AVAILABILITY_TEXT = "for luxury stays";
export const FETCHING_LC_AVAILABILITY_TEXT = "for your stay";

export const FETCHING_AVAILABILITY_SECONDARY_TEXT = (
  <ul className="pc-benefits">
    <li>
      Earn <strong>10X miles</strong> on Premier Collection bookings
    </li>
    <li>
      Enjoy a <strong>$100 experience credit</strong>
    </li>
  </ul>
);

export const FETCHING_LC_AVAILABILITY_SECONDARY_TEXT = (
  isPremiumCardHolder?: boolean
) => {
  if (isPremiumCardHolder)
    return (
      <>
        Earn <strong>10X miles</strong> on bookings from the Premier & Lifestyle
        Collections.
      </>
    );
  else {
    return (
      <>
        Earn <strong>5X miles</strong> on bookings from the Lifestyle
        Collection.
      </>
    );
  }
};

export const FETCHING_VR_AVAILABILITY_SECONDARY_TEXT = (
  isPremiumCardHolder?: boolean
) => {
  if (isPremiumCardHolder)
    return (
      <>
        Earn <strong>5X miles</strong> on premium vacation rental bookings from
        the Premier & Lifestyle Collections.
      </>
    );
  else {
    return (
      <>
        Earn <strong>5X miles</strong> on every booking from the Lifestyle
        Collection.
      </>
    );
  }
};

export const VIEWING_HOTELS_TEXT = (locationName: string) =>
  `Viewing luxury stays in ${locationName}`;
export const VIEWING_PREMIUM_STAYS_TEXT = (locationName: string) =>
  `Viewing premium stays in ${locationName}`;
export const DATES_TEXT = (fromDate: string, untilDate: string) =>
  `${fromDate} - ${untilDate}`;
