import React from "react";

import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

import { ActionButton, Icon, IconName } from "halifax";

import { PremierCollectionAvailabilitySortOption } from "../../../../reducer";
import {
  SORT_OPTION_PRICING_ASC_LABEL,
  SORT_OPTION_PRICING_DESC_LABEL,
  SORT_OPTION_RECOMMENDED_LABEL,
  SORT_SELECTION_APPLY_BUTTON_LABEL,
  SORT_SELECTION_HEADING_TEXT,
} from "./textConstants";

import "./styles.scss";

export interface ISortSelectionModalProps {
  icon?: IconName;
  selectedSortOption: PremierCollectionAvailabilitySortOption;
  setSortOption: (sortOption: PremierCollectionAvailabilitySortOption) => void;
  onClickApply?: () => void;
  hasValueToApply?: boolean;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
}

export const sortOptions = [
  PremierCollectionAvailabilitySortOption.Recommended,
  PremierCollectionAvailabilitySortOption.PricingASC,
  PremierCollectionAvailabilitySortOption.PricingDESC,
];

export const sortOptionLabel: {
  [key in PremierCollectionAvailabilitySortOption]: string;
} = {
  Recommended: SORT_OPTION_RECOMMENDED_LABEL,
  PricingASC: SORT_OPTION_PRICING_ASC_LABEL,
  PricingDESC: SORT_OPTION_PRICING_DESC_LABEL,
};

export const SortSelection = ({
  icon,
  selectedSortOption,
  setSortOption,
  onClickApply,
  hasValueToApply,
  labelPlacement = "end",
}: ISortSelectionModalProps) => {
  return (
    <div className="premium-stays-sort-selection-root">
      <Typography
        variant="h4"
        className={clsx("filter-heading", "sort-selection-heading")}
      >
        {icon && <Icon name={icon} />} {SORT_SELECTION_HEADING_TEXT}
      </Typography>

      <RadioGroup
        className="sort-selection-radio-group"
        aria-label="sort by"
        name="sort"
        value={selectedSortOption}
        role="radiogroup"
        onChange={(e) =>
          setSortOption(
            e.target.value as PremierCollectionAvailabilitySortOption
          )
        }
      >
        {sortOptions.map((sortOption) => (
          <FormControlLabel
            className={clsx("sort-selection-form-control", {
              "label-placement-start": labelPlacement === "start",
            })}
            role="radio"
            aria-selected={selectedSortOption === sortOption}
            aria-label={`Sort by ${sortOptionLabel[sortOption]} ${
              selectedSortOption === sortOption ? "selected" : ""
            }`}
            classes={{ label: "sort-selection-label" }}
            key={`sort-option-${sortOption}`}
            value={sortOption}
            control={<Radio className="sort-selection-radio-button" />}
            label={sortOptionLabel[sortOption]}
            labelPlacement={labelPlacement}
          />
        ))}
      </RadioGroup>
      {onClickApply && (
        <ActionButton
          className="sort-selection-apply-button"
          defaultStyle="h4r-primary"
          message={SORT_SELECTION_APPLY_BUTTON_LABEL}
          onClick={onClickApply}
          disabled={!hasValueToApply}
        />
      )}
    </div>
  );
};
