import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  schedulePriceQuote,
  setContactInfo,
  scheduleBook,
  fetchApplicableTravelWalletItems,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  setTripPurpose,
} from "../../actions/actions";
import { MobilePremierCollectionBookWorkflow } from "./component";
import {
  getIsBookingValid,
  getPollPriceQuoteCallState,
  getPriceQuote,
  getSelectedPaymentMethodId,
  getSession,
  getUserSelectedTravelerId,
  hasNoUserPassengersSelector,
  getConfirmationDetails,
  getConfirmationPhoneNumber,
  getConfirmationEmail,
  getPriceDifferenceAcknowledged,
  getSchedulePriceQuoteCallState,
  getPriceQuoteErrors,
  getConfirmationDetailsError,
  getOffers,
  getRewardsPaymentAccountReferenceId,
  getIsTravelWalletPaymentOnly,
  getCreditToApply,
  getCredit,
} from "../../reducer";
import {
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopChosenProductIndex,
  getPremierCollectionShopChosenRoomInfo,
  getPremierCollectionShopSelectedAvailability,
  isAddOnOptionAvailableSelector,
  hotelCfarQuoteFromChosenRoomProductSelector,
  getPremierCollectionShopCancellationSummary,
} from "../../../shop/reducer/selectors";
import { CallState } from "redmond";
import { getAllAncillaries, hasCfarAttached } from "../../../ancillary/reducer";
import { fetchTravelWalletCreditHistory } from "../../../travel-wallet/actions/actions";
import { getRewardsAccountWithLargestEarnForBanner } from "../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    priceQuote: getPriceQuote(state),
    priceQuoteInProgress:
      getSchedulePriceQuoteCallState(state) === CallState.InProcess ||
      getPollPriceQuoteCallState(state) === CallState.InProcess,
    priceQuoteErrors: getPriceQuoteErrors(state),
    currentSession: getSession(state),
    paymentMethodId: getSelectedPaymentMethodId(state),
    selectedTravelerId: getUserSelectedTravelerId(state),
    chosenProduct: getPremierCollectionShopChosenProduct(state),
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    roomInfoProduct: getPremierCollectionShopChosenRoomInfo(state),
    chosenProductIndex: getPremierCollectionShopChosenProductIndex(state),
    isBookingValid: getIsBookingValid(state),
    hasNoUserPassengers: hasNoUserPassengersSelector(state),
    reservation: getConfirmationDetails(state),
    confirmationEmail: getConfirmationEmail(state),
    confirmationPhoneNumber: getConfirmationPhoneNumber(state),
    priceDifferenceAcknowledged: getPriceDifferenceAcknowledged(state),
    confirmationDetailsErrors: getConfirmationDetailsError(state),
    offers: getOffers(state),
    rewardsPaymentAccountReferenceId:
      getRewardsPaymentAccountReferenceId(state),
    isTravelWalletPaymentOnly: getIsTravelWalletPaymentOnly(state),
    creditToApply: getCreditToApply(state),
    credit: getCredit(state),
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    ancillaries: getAllAncillaries(state),
    hasCfarAttached: hasCfarAttached(state),
    hotelCfarQuote: hotelCfarQuoteFromChosenRoomProductSelector(state),
    largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
    cancellationSummary: getPremierCollectionShopCancellationSummary(state),
  };
};

const mapDispatchToProps = {
  schedulePriceQuote,
  setContactInfo,
  scheduleBook,
  fetchApplicableTravelWalletItems,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  fetchTravelWalletCreditHistory,
  setTripPurpose,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePremierCollectionBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePremierCollectionBookWorkflow = withRouter(
  connector(MobilePremierCollectionBookWorkflow)
);
