import React from "react";

import { Box } from "@material-ui/core";

import {
  MegaMenuDestination,
  MegaMenuRegion,
  MegaMenuSubregion,
} from "redmond";

import {
  RegionsListView,
  SectionsListView,
  SubregionListView,
} from "./components";
import { MobileMegaMenuConnectorProps } from "./container";

import "./styles.scss";

export interface IMobileMegaMenuProps extends MobileMegaMenuConnectorProps {
  data?: MegaMenuData;
  navigate: (destination: MegaMenuData) => void;
  select: (destination: MegaMenuDestination) => void;
}

export type MegaMenuData =
  | { type: "region"; data: MegaMenuRegion }
  | { type: "subregion"; data: MegaMenuSubregion };

export const MobileMegaMenu = (props: IMobileMegaMenuProps) => {
  const { megaMenuRegions, navigate, select } = props;
  return (
    <Box className="mobile-mega-menu">
      {props.data == null && (
        <RegionsListView
          regions={megaMenuRegions ?? []}
          onClick={(data) => navigate({ type: "region", data })}
        />
      )}
      {props.data != null && props.data?.type === "region" && (
        <SectionsListView
          sections={props.data.data.sections}
          onClick={(data) => navigate({ type: "subregion", data })}
        />
      )}
      {props.data != null && props.data?.type === "subregion" && (
        <SubregionListView
          subregion={props.data.data}
          onClick={(destination) => select(destination)}
        />
      )}
    </Box>
  );
};
