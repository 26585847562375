import React from "react";
import { Cfar } from "redmond";
import { getTotalPriceText } from "halifax";
import { isCaponeTenant } from "@capone/common";
import { config } from "../../../../api/config";

export const BACK_TO_RESULTS = "Back to results";

export const getCfarSecondaryText = (cfarCancellation: Cfar) =>
  `Refund amount: ${getTotalPriceText({
    price: cfarCancellation.refundAmount.fiat,
  })}`;

export const SHOW_ALL_AMENITIES_TEXT = "More Amenities";

export const AMENITIES_TITLE = "Popular Amenities";
export const AMENITIES_SUBTITLE =
  "Enjoy your luxury stay with some of these popular amenities";

export const REVIEWS = "Reviews";

export const CHOOSE_ROOM = "Choose Room";

export const ADDITIONAL_BENEFITS = (
  <>
    Plus, enjoy other premium benefits like{" "}
    <strong>room upgrades, early check-in and late checkout</strong> when
    available.
  </>
);
export const LC_ADDITIONAL_BENEFITS = (
  <>
    Plus, enjoy other benefits like <strong>complimentary Wi-Fi</strong> and,
    when available, <strong>early check-in/late checkout.</strong>
  </>
);

export const getBenefitsList = (
  hotelsMultiplier: number,
  variant?: "default" | "lifestyle-collection",
  isUserPrimary?: boolean
) => {
  const isLifestyleCollection = variant?.includes("lifestyle-collection");
  return isCaponeTenant(config.TENANT) || isUserPrimary ? (
    <ul>
      <li>
        Earn <strong>{hotelsMultiplier}X miles</strong> on any{" "}
        {isLifestyleCollection ? "Lifestyle" : "Premier"} Collection booking
      </li>
      <li>
        Enjoy a{" "}
        <strong>
          {isLifestyleCollection ? "$50" : "$100"} experience credit
        </strong>
      </li>
      {isLifestyleCollection ? (
        <li>
          <strong>Room upgrades</strong> when available
        </li>
      ) : (
        <li>
          Daily <strong>breakfast</strong> for 2 and complimentary{" "}
          <strong>Wifi</strong>
        </li>
      )}
    </ul>
  ) : (
    <ul>
      <li>
        Enjoy a{" "}
        <strong>
          {isLifestyleCollection ? "$50" : "$100"} experience credit
        </strong>
      </li>
      {isLifestyleCollection ? (
        <li>
          <strong>Room upgrades</strong> when available
        </li>
      ) : (
        <li>
          Daily <strong>breakfast</strong> for 2 and complimentary{" "}
          <strong>Wifi</strong>
        </li>
      )}
    </ul>
  );
};
