import { connect, ConnectedProps } from "react-redux";
import { HotelSummaryPanel } from "halifax";
import { getPremierCollectionShopSelectedAvailability } from "../../../shop/reducer/selectors";
import {
  getPremierCollectionAvailabilityFromDate,
  getPremierCollectionAvailabilityUntilDate,
} from "../../../availability/reducer";
import { IStoreState } from "../../../../reducers/types";
import { getVacationRentalShopSelectedListing } from "../../../vacation-rental-shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    checkIn: getPremierCollectionAvailabilityFromDate(state),
    checkOut: getPremierCollectionAvailabilityUntilDate(state),
    selectedHome: getVacationRentalShopSelectedListing(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PremierCollectionBookSummaryPanelConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionBookSummaryPanel =
  connector(HotelSummaryPanel);
