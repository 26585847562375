import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../reducers/types";
import {
  getPremierCollectionAvailabilitySearchLocationResult,
  getPremierCollectionQueryParams,
} from "../../../../reducer";
import { AvailabilityMapPricePin } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  hotelQueryParams: getPremierCollectionQueryParams(state),
  searchLocation: getPremierCollectionAvailabilitySearchLocationResult(state),
});

const connector = connect(mapStateToProps);

export type ConnectedAvailabilityMapPricePinProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityMapPricePin = connector(
  AvailabilityMapPricePin
);
