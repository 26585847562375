import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import { isCustomizePageEnabledSelector } from "../../../ancillary/reducer";
import { fetchTravelWalletDetails } from "../../../travel-wallet/actions/actions";
import { getTravelWalletOffers } from "../../../travel-wallet/reducer";
import {
  getPremierCollectionShopCancellationSummary,
  PremierCollectionShopCallState,
} from "../../reducer";
import {
  getCheapestProduct,
  getPremierCollectionShopCallState,
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopSelectedAvailability,
  hasPremierCollectionShopFailed,
} from "../../reducer/selectors";
import { DesktopShop } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  lodging: getPremierCollectionShopSelectedAvailability(state),
  cheapestProduct: getCheapestProduct(state),
  hotelShopCallInProgress:
    getPremierCollectionShopCallState(state) ===
    PremierCollectionShopCallState.InProcess,
  chosenProduct: getPremierCollectionShopChosenProduct(state),
  hasFailed: hasPremierCollectionShopFailed(state),
  selectedAccountReferenceId:
    getSelectedAccountReferenceIdIfRedemptionEnabled(state),
  travelOffers: getTravelWalletOffers(state),
  isCustomizePageEnabled: isCustomizePageEnabledSelector(state),
  largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
  cancellationSummary: getPremierCollectionShopCancellationSummary(state),
});

export const mapDispatchToProps = {
  fetchTravelWalletDetails: fetchTravelWalletDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type DesktopShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDesktopShop = withRouter(connector(DesktopShop));
