import React, { useRef, useEffect } from "react";
import {
  HotelShopRoomTypePickerRedesign,
  HotelShopRoomTypePickerEnum,
  HotelShopRoomTypePickerVariant,
} from "halifax";
import { RouteComponentProps } from "react-router";
import { DesktopRoomPickerConnectorProps } from "./container";
import clsx from "clsx";
import "./styles.scss";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { SELECT_PC_ROOM } from "redmond";

export interface DesktopRoomPickerProps
  extends DesktopRoomPickerConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: () => void;
  nonRefundablePolicyOverrideText?: string;
  isFintechHotelUpdatedUxEnabled?: boolean;
}

export const DesktopRoomPicker = (props: DesktopRoomPickerProps) => {
  const {
    checkinDate,
    checkoutDate,
    history,
    fetchPremierCollectionShop,
    adultsCount,
    childrenCount,
    roomInfoProductsType,
    handleReadyToRedirect,
    isHotelCfarModelV1RefundableRoomUpdatedCopy,
    isFintechHotelUpdatedUxEnabled = false,
  } = props;
  const isFirstUpdate = useRef<boolean>(true);

  useEffect(() => {
    if (checkinDate && checkoutDate && (adultsCount || childrenCount)) {
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else {
        fetchPremierCollectionShop(history, {
          forceCallHotelAvailability: true,
        });
      }
    }
  }, [checkinDate, checkoutDate, adultsCount, childrenCount]);

  return (
    <HotelShopRoomTypePickerRedesign
      {...props}
      roomInfoProductsType={
        roomInfoProductsType ?? {
          roomInfoProducts: props.roomInfoProducts,
          variant: HotelShopRoomTypePickerEnum.Default,
        }
      }
      onClickContinue={(room_type?: string) => {
        trackEvent({
          eventName: SELECT_PC_ROOM,
          properties: { room_type },
        });
        if (handleReadyToRedirect) {
          handleReadyToRedirect();
        }
      }}
      className={clsx("b2b", "pc-desktop-room-picker")}
      reserveButtonClassName="reserve-cta"
      desktopReserveButtonText={<span>{`Continue for `}</span>}
      isHotelCfarModelV1RefundableRoomUpdatedCopy={
        isHotelCfarModelV1RefundableRoomUpdatedCopy
      }
      variant={
        isFintechHotelUpdatedUxEnabled ? "refundable-room-ux" : "default"
      }
    />
  );
};
