import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { isCustomizePageEnabledSelector } from "./reducer";
import { IStoreState } from "../../reducers/types";
import { PremierCollectionAncillary } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    isCustomizePageEnabled: isCustomizePageEnabledSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PremierCollectionAncillaryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionAncillary = connector(
  withRouter(PremierCollectionAncillary)
);
