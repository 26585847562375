import axios from "axios";
import { config } from "../../../config";
import { IPerson } from "redmond";

import { passengersApiPrefix } from "../../paths";

const END_POINT: string = `${passengersApiPrefix}/update`;

export const updateUserPassenger = (
  body: IUpdateUserPassengerRequest
): Promise<IUpdateUserPassengerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, body, {
        baseURL: config.baseURL,
      });

      const responseBody: IUpdateUserPassengerResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IUpdateUserPassengerRequest {
  person: IPerson;
}

export interface IUpdateUserPassengerResponse {}
