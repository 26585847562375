import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getLocation,
  getPetsCount,
  getStayType,
  getUntilDate,
  getVacationRentalsLocation,
} from "../../../../reducer";
import { MobileCalendarPicker } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    location: getLocation(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    petsCount: getPetsCount(state),
    stayType: getStayType(state),
    searchVacationRentalsLocation: getVacationRentalsLocation(state),
  };
};

const mapDispatchToProps = {
  setFromDate: actions.setFromDate,
  setUntilDate: actions.setUntilDate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCalendarPickerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCalendarPicker = connector(MobileCalendarPicker);
