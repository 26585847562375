import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getNightCount,
  getPetsCount,
  getStayType,
  getUntilDate,
} from "../../../search/reducer";

import { getVacationRentalShopSelectedListing } from "../../reducer/selectors";
import { DesktopListingSummaryBox } from "./component";
import { getViewedPremierCollectionDetailsProperties } from "../../../shop/reducer";
import { showOfferBasedOnSelectedListingSelector } from "../../reducer";
import { fetchVacationRentalShop } from "../../actions/actions";
import { setOpenDatesModal } from "../../../availability/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    viewedPremierCollectionDetailsProperties:
      getViewedPremierCollectionDetailsProperties(state),
    stayType: getStayType(state),
    listing: getVacationRentalShopSelectedListing(state),
    checkinDate: getFromDate(state),
    checkoutDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    petsCount: getPetsCount(state),
    showOfferBasedOnSelectedListing: showOfferBasedOnSelectedListingSelector(state),
  };
};

export const mapDispatchToProps = {
  fetchVacationRentalShop,
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopListingSummaryBoxConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopListingSummaryBox = withRouter(
  connector(DesktopListingSummaryBox)
);
