import { ensureExhaustive, exhaustiveReducer } from "halifax";
import {
  ICategorizedResponse,
  IResult,
  MegaMenuRegion,
  NonEmptyArray,
  StayTypesEnum,
} from "redmond";

import { actions, actionTypes } from "../actions";

export interface IPremierCollectionSearchState {
  megaMenuRegions?: NonEmptyArray<MegaMenuRegion>;
  megaMenuEnabled?: boolean;
  isMegaMenuSelection?: boolean;
  pclcCategories?: ICategorizedResponse[];
  location: IResult | null;
  locationCategories: ICategorizedResponse[];
  locationCategoriesLoading: boolean | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  petsCount: number;
  hasLocationAutocompleteError: boolean;
  locationSearchString: string;
  stayType: StayTypesEnum;
  vacationRentalsLocation: IResult | null;
  vacationRentalsLocationCategories: ICategorizedResponse[];
  vacationRentalsLocationCategoriesLoading: boolean | null;
}

export const DEFAULT_ADULT_COUNT = 2;

const initialState: IPremierCollectionSearchState = {
  location: null,
  pclcCategories: [],
  locationCategories: [],
  locationCategoriesLoading: false,
  fromDate: null,
  untilDate: null,
  adultsCount: DEFAULT_ADULT_COUNT,
  children: [],
  petsCount: 0,
  hasLocationAutocompleteError: false,
  locationSearchString: "",
  vacationRentalsLocation: null,
  vacationRentalsLocationCategories: [],
  vacationRentalsLocationCategoriesLoading: false,
  stayType: StayTypesEnum.Hotels,
};

export const reducer = exhaustiveReducer(
  initialState,
  (state, action: actions.HotelSearchActions) => {
    switch (action.type) {
      case actionTypes.SET_LOCATION:
        const { location } = action;

        return {
          ...state,
          location: location ? { ...location } : null,
          locationCategoriesLoading: false,
          locationSearchString: "",
        };

      case actionTypes.SET_LOCATION_CATEGORIES:
        const { categories } = action;
        return {
          ...state,
          locationCategories: categories,
          locationCategoriesLoading: false,
        };

      case actionTypes.FETCH_LOCATION_CATEGORIES:
        return {
          ...state,
          locationCategories: [],
          locationCategoriesLoading: true,
        };

      case actionTypes.SET_PCLC_LOCATION_CATEGORIES:
        const { pclcCategories } = action;
        return {
          ...state,
          pclcCategories: pclcCategories,
        };

      case actionTypes.FETCH_PCLC_LOCATION_CATEGORIES:
        return { ...state };

      case actionTypes.FETCH_MEGAMENU_REGIONS:
        return { ...state };

      case actionTypes.SET_MEGAMENU_REGIONS:
        return {
          ...state,
          megaMenuRegions: action.regions,
        };

      case actionTypes.FETCH_MEGAMENU_ENABLED:
        return { ...state };

      case actionTypes.SET_MEGAMENU_ENABLED:
        return {
          ...state,
          megaMenuEnabled: action.enabled,
        };

      case actionTypes.FETCH_IS_MEGAMENU_SELECTION:
        return { ...state };

      case actionTypes.SET_IS_MEGAMENU_SELECTION:
        return {
          ...state,
          isMegaMenuSelection: action.isSelection,
        }  

      case actionTypes.SET_FROM_DATE:
        return { ...state, fromDate: action.date };

      case actionTypes.SET_UNTIL_DATE:
        return {
          ...state,
          untilDate: action.date,
        };

      case actionTypes.SET_OCCUPANCY_COUNTS:
        const { adults, children, pets } = action.counts;
        return {
          ...state,
          adultsCount: adults,
          children: children,
          petsCount: pets && !isNaN(pets) ? pets : 0,
        };

      case actionTypes.SET_LOCATION_AUTOCOMPLETE_ERROR: {
        return {
          ...state,
          hasLocationAutocompleteError: action.error,
          locationCategoriesLoading: false,
        };
      }
      case actionTypes.SET_LOCATION_SEARCH_STRING:
        return {
          ...state,
          locationSearchString: action.searchString,
        };
      case actionTypes.SET_STAY_TYPE:
        return {
          ...state,
          stayType: action.stayType,
        };
      case actionTypes.SET_VACATION_RENTALS_LOCATION:
        const { vacationRentalsLocation } = action;

        return {
          ...state,
          vacationRentalsLocation: vacationRentalsLocation
            ? { ...vacationRentalsLocation }
            : null,
          vacationRentalsLocationCategoriesLoading: false,
          locationSearchString: "",
        };

      case actionTypes.SET_VACATION_RENTALS_LOCATION_CATEGORIES:
        const { vacationRentalsCategories } = action;
        return {
          ...state,
          vacationRentalsLocationCategories: vacationRentalsCategories,
          vacationRentalsLocationCategoriesLoading: false,
        };

      case actionTypes.FETCH_VACATION_RENTALS_LOCATION_CATEGORIES:
        return {
          ...state,
          vacationRentalsLocationCategories: [],
          vacationRentalsLocationCategoriesLoading: true,
        };

      default:
        return ensureExhaustive(action);
    }
  }
);

export * from "./selectors";
