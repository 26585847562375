import React from "react";

import { Box, Typography } from "@material-ui/core";
import { RouterProps } from "react-router-dom";

import { ActionButton } from "halifax";

import { PATH_AVAILABILITY, PATH_HOME } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import { TitleSummaryCardConnectorProps } from "./container";
import { BACK_TO_RESULTS, SUBTITLE, TITLE } from "./textConstants";

import "./styles.scss";

export interface ITitleSummaryCardProps
  extends TitleSummaryCardConnectorProps,
    RouterProps {
  isMobile?: boolean;
}

export const TitleSummaryCard = (props: ITitleSummaryCardProps) => {
  const {
    history,
    lodging,
    fromDate,
    untilDate,
    isMobile,
    adultsCount,
    childrenCount,
    petsCount,
  } = props;

  const returnToAvailability = () => {
    if (!lodging) {
      return history.push(PATH_HOME);
    }
    // TODO: This needs to include state to be accurate, we don't have it.
    const search = transformToStringifiedAvailabilityQuery({
      location: lodging.lodging.city,
      fromDate,
      untilDate,
      adultsCount,
      childrenCount,
      petsCount,
    });
    history.push(`${PATH_AVAILABILITY}${search}`);
  };

  return (
    <Box className="title-summary-container">
      {!isMobile && (
        <ActionButton
          className="back-to-results"
          message={BACK_TO_RESULTS}
          onClick={() => {
            returnToAvailability();
          }}
          defaultStyle="h4r-secondary"
        />
      )}
      <Typography variant="h6" className="title">
        {TITLE}
      </Typography>
      <Typography variant="body1" className="subtitle">
        {SUBTITLE}
      </Typography>
    </Box>
  );
};
