import { createSelector } from "@reduxjs/toolkit";
import {
  cardsToShowWithPriorityOnRewardsBanner,
  CustomerAccountRole,
  RewardsAccount,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";

export const getIsFirstLaunch = (state: IStoreState) =>
  state.premierCollectionRewards.isFirstLaunch;

export const getAgentEmail = (state: IStoreState) =>
  state.premierCollectionRewards.agentEmail;

export const getRewardsAccounts = (state: IStoreState) =>
  state.premierCollectionRewards.rewardsAccounts;

export const getIsVentureX = (state: IStoreState) =>
  getRewardsAccounts(state).some((acc) =>
    acc.productDisplayName.includes("Venture X")
  );

export const getUserSelectedAccountReferenceId = (state: IStoreState) =>
  state.premierCollectionRewards.selectedAccountReferenceId;

export const getFetchRewardsAccountsCallState = (state: IStoreState) =>
  state.premierCollectionRewards.fetchRewardsAccountsCallState;

export const getSortedRewardsAccounts = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccount[] => {
    const earnMultiplier = (account: RewardsAccount) =>
      account.earn.hotelsMultiplier ?? -1;
    return rewardsAccounts.sort((prev, current) => {
      return earnMultiplier(current) - earnMultiplier(prev);
    });
  }
);

export const getRewardsAccountWithLargestValue = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccount | undefined => {
    const sortedAccts = [...rewardsAccounts].sort((prev, current) => {
      // sorting [...rewardsAccounts] so it does not mutate the original rewardsAccounts array as that was sorted in getSortedRewardsAccounts by earn
      return (
        current.rewardsCashEquivalent.value - prev.rewardsCashEquivalent.value
      );
    });
    return sortedAccts[0];
  }
);

export const getSortedRewardsAccountWithLargestEarnForBanner = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccount[] => {
    return [...rewardsAccounts].sort((prev, current) => {
      const earnMultiplier = (account: RewardsAccount) =>
        prev.earn.flightsMultiplier && current.earn.flightsMultiplier // should only use flightsMultiplier if prev/current has it or else diff values are compared
          ? account.earn.flightsMultiplier
          : account.earn.hotelsMultiplier ?? -1;
      if (earnMultiplier(current) === earnMultiplier(prev)) {
        return cardsToShowWithPriorityOnRewardsBanner.includes(
          current.productDisplayName.toLowerCase()
        )
          ? 1
          : -1;
      }
      return earnMultiplier(current) - earnMultiplier(prev);
    });
  }
);

export const getRewardsAccountWithLargestEarnForBanner = createSelector(
  getSortedRewardsAccountWithLargestEarnForBanner,
  (sortedRewardsAccount): RewardsAccount => {
    return sortedRewardsAccount[0];
  }
);

// the rewards account selection doesn't have a default state where none of the available options is selected
export const getDefaultSelectedAccountReferenceId = createSelector(
  getSortedRewardsAccounts,
  (rewardsAccounts): string | null => {
    // return the id with largest value
    if (rewardsAccounts.length > 0) {
      return rewardsAccounts[0].accountReferenceId;
    }

    return null;
  }
);

// this is the primary selector used outside the component
// either returns the user selected account id, or the default from above if not present
export const getSelectedAccountReferenceId = createSelector(
  getUserSelectedAccountReferenceId,
  getDefaultSelectedAccountReferenceId,
  (
    userSelectedAccountReferenceId,
    defaultSelectedAccountReferenceId
  ): string | null => {
    return userSelectedAccountReferenceId ?? defaultSelectedAccountReferenceId;
  }
);

export const getSelectedAccount = createSelector(
  getRewardsAccounts,
  getSelectedAccountReferenceId,
  (rewardsAccounts, referenceId): RewardsAccount | undefined => {
    return rewardsAccounts.find(
      (account) => account.accountReferenceId === referenceId
    );
  }
);

export const getSelectedAccountIndex = createSelector(
  getRewardsAccounts,
  getSelectedAccountReferenceId,
  (accounts, id) => {
    return accounts.findIndex((account) => account.accountReferenceId === id);
  }
);

export const getRewardsUserProperties = createSelector(
  getSelectedAccount,
  (
    rewardsAccount
  ): { product: string | undefined; currency: string | undefined } => {
    return {
      product: rewardsAccount?.productDisplayName,
      currency: rewardsAccount?.rewardsBalance.currency,
    };
  }
);

export const getSelectedAccountReferenceIdIfRedemptionEnabled = createSelector(
  getRewardsAccounts,
  getSelectedAccountReferenceId,
  (accounts, selectedAccountId) => {
    const account = accounts.find(
      (account) => account.accountReferenceId === selectedAccountId
    );

    return account?.allowRewardsRedemption ?? true
      ? selectedAccountId || undefined
      : undefined;
  }
);

// corporate
export const getIsInPolicy = (state: IStoreState) => {
  const lodging = state.premierCollectionShop.selectedLodging?.lodging;

  if (lodging && "corporateTravel" in lodging) {
    return lodging.corporateTravel.policyCompliance.isInPolicy;
  }
  return true;
};

export const getAllowRewardsRedemptionInAnyAccount = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): boolean => {
    return rewardsAccounts.some(
      (account) => account.allowRewardsRedemption ?? true
    );
  }
);

export const getIsAutoApprovalEnabled = (state: IStoreState) =>
  state.premierCollectionRewards.isAutoApprovalEnabled;

export const getAllowRewardsWithPolicy = createSelector(
  getAllowRewardsRedemptionInAnyAccount,
  getIsInPolicy,
  getIsAutoApprovalEnabled,
  (allowRewards, isInPolicy, isAutoApprovalEnabled): boolean => {
    if (isCorpTenant(config.TENANT)) {
      return allowRewards && (isInPolicy || isAutoApprovalEnabled);
    }
    return true;
  }
);

export const getUserIsPrimaryCardHolder = createSelector(
  getRewardsAccounts,
  (rewardsAccounts) => {
    return rewardsAccounts.some(
      (account) => account.customerAccountRole === CustomerAccountRole.Primary
    );
  }
);
