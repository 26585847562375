import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { actions as availabilityActions } from "../../../availability/actions";
import { actions } from "../../actions";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getLocation,
  getPetsCount,
  getStayType,
  getUntilDate,
  getVacationRentalsLocation,
} from "../../reducer/index";
import { PremierCollectionSearchControl } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  searchLocation: getLocation(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  adultsCount: getAdultsCount(state),
  childrenCount: getChildrenCount(state),
  stayType: getStayType(state),
  searchVacationRentalsLocation: getVacationRentalsLocation(state),
  petsCount: getPetsCount(state),
});

const mapDispatchToProps = {
  setLocation: actions.setLocation,
  setOpenDatesModal: availabilityActions.setOpenDatesModal,
  setIsMegaMenuSelection: actions.setIsMegaMenuSelection,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PremierCollectionSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionSearchControl = withRouter(
  connector(PremierCollectionSearchControl)
);
