import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PremierCollectionCustomize } from "./component";
import {
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopSelectedAvailability,
  showOfferBasedOnSelectedLodgingSelector,
} from "../../../shop/reducer";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    chosenProduct: getPremierCollectionShopChosenProduct(state),
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    showOfferBasedOnSelectedLodging:
      showOfferBasedOnSelectedLodgingSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PremierCollectionCustomizeConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionCustomize = connector(
  withRouter(PremierCollectionCustomize)
);
