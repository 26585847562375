import { connect, ConnectedProps } from "react-redux";
import {
  updateUserPassenger,
  setUserSelectedPassengerIds,
  deleteUserPassenger,
  fetchUserPassengers,
  fetchCorpUserPassengers,
} from "../../actions/actions";
import {
  getUserPassengerCallState,
  getUserPassengers,
  getUserSelectedTravelerId,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { PremierCollectionBookPassengerSelection } from "./component";
import { getPremierCollectionShopCancellationSummary } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    travelers: getUserPassengers(state),
    selectedTravelerId: getUserSelectedTravelerId(state),
    userPassengerCallState: getUserPassengerCallState(state),
    cancellationSummary: getPremierCollectionShopCancellationSummary(state),
  };
};

const mapDispatchToProps = {
  updateUserPassenger,
  setUserSelectedPassengerIds,
  deleteUserPassenger,
  fetchUserPassengers,
  fetchCorpUserPassengers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PremierCollectionBookPassengerSelectionConnectorProps =
  ConnectedProps<typeof connector>;

export const ConnectedPremierCollectionBookPassengerSelection = connector(
  PremierCollectionBookPassengerSelection
);
