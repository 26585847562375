import React, { useState } from "react";
import { Box, Link, TextField, Typography } from "@material-ui/core";
import {
  IconName,
  Icon,
  ActionLink,
  CloseButtonIcon,
  MobilePopoverCard,
  MobilePremierCollectionAutocompleteOptions,
  InfoIcon,
  ActionButton,
} from "halifax";
import clsx from "clsx";

import * as textConstants from "./textConstants";
import { MobileLocationSearchModalConnectorProps } from "./container";
import { IResult } from "redmond";
import "./styles.scss";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import H from "history";
import { PATH_HOME, PATH_HOTEL } from "../../../../../../utils/paths";

export interface IMobileLocationSearchModalProps
  extends MobileLocationSearchModalConnectorProps {
  onContinue?: () => void;
  history: H.History;
  openLocationModal: boolean;
  setOpenLocationModal: (val: boolean) => void;
  searchOnContinue?: boolean;
  setLocalLocation?: (arg: IResult | null) => void;
  onSelection?: () => void;
  onCloseModal?: () => void;
  onSelectLocation?: (value: any) => void;
  isLifestyleCollection?: boolean;
  includesLifestyleCollection?: boolean;
}
export const MobileLocationSearchModal = (
  props: IMobileLocationSearchModalProps
) => {
  const {
    location,
    loading,
    setLocation,
    setLocationSearchString: setReduxLocationSearchString,
    history,
    searchOnContinue,
    setLocalLocation,
    openLocationModal,
    locationCategories,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    setOpenLocationModal,
    onContinue,
    onSelectLocation,
    isLifestyleCollection = false,
    includesLifestyleCollection = false,
  } = props;

  const [locationSearchString, setLocationSearchString] = useState<string>("");

  React.useEffect(() => {
    location && setLocationSearchString(location?.label);
  }, [location]);

  const onLocationSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    !searchOnContinue && setLocation(null);
    setLocationSearchString(event.target.value);
    if (event.target.value) {
      setReduxLocationSearchString(event.target.value);
    }
  };

  const onLocationSelected = (value: any) => {
    setLocationSearchString(value.label);
    !searchOnContinue && setLocation(value);
    setLocalLocation && setLocalLocation(value);
    !searchOnContinue && handleClose();
    onSelectLocation?.(value);
  };

  const onClearLocationField = () => {
    searchOnContinue
      ? setLocalLocation && setLocalLocation(null)
      : setLocation(null);
    setLocationSearchString("");
    setReduxLocationSearchString("");
  };

  const handleClose = () => {
    if (searchOnContinue) {
      if (location) {
        setLocationSearchString(location?.label);
        setLocalLocation && setLocalLocation(location);
      }
    }
    setOpenLocationModal(false);
  };

  const handleContinue = () => {
    onContinue && onContinue();
    handleClose();
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">{textConstants.HOTELS}</Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };
  return (
    <MobilePopoverCard
      open={openLocationModal}
      className={"mobile-premier-collection-search-location-popup"}
      onClose={handleClose}
      headerElement={<MobileSearchPopoverHeader />}
    >
      <Box className="mobile-hotel-search-location-modal-content">
        <Box className="mobile-hotel-search-location-input-wrapper">
          <Box className="mobile-hotel-search-location-input-field">
            <Icon name={IconName.B2BMapPin} />
            <TextField
              className={clsx("origin-auto-complete", "mobile")}
              onChange={(value) => onLocationSearch(value)}
              value={locationSearchString}
              label={textConstants.LOCATION_SEARCH_PREFIX}
              InputProps={{
                disableUnderline: true,
              }}
              autoFocus
            />
            {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
            <FontAwesomeIcon
              className={clsx("icon", { hidden: !locationSearchString })}
              icon={faTimesCircle}
              onClick={onClearLocationField}
            />
          </Box>
          {hasLocationAutocompleteError && (
            <Box className="autocomplete-error-container">
              <InfoIcon />
              <span className="autocomplete-error-text">
                Error fetching results.{" "}
              </span>
              <span
                onClick={() => {
                  history.push(PATH_HOME);
                  hasLocationAutocompleteError &&
                    setLocationAutocompleteError(false);
                }}
                className="try-again-text"
              >
                Try again.
              </span>
            </Box>
          )}
        </Box>
        <MobilePremierCollectionAutocompleteOptions
          value={null}
          valueCategories={locationCategories}
          setValue={(value: any) => onLocationSelected(value)}
          loading={loading}
          loadingText={textConstants.LOADING_TEXT}
          hideSidePadding
          listHeadingText={
            !locationSearchString
              ? isLifestyleCollection
                ? textConstants.LC_AUTOCOMPLETE_RESULTS_LABEL
                : includesLifestyleCollection
                ? textConstants.PREMIUM_STAYS_AUTOCOMPLETE_RESULTS_LABEL
                : textConstants.AUTOCOMPLETE_RESULTS_LABEL
              : undefined
          }
          noOptionsElement={
            <Box className="no-options-content">
              <Typography className="no-options-text" variant="body1">
                {isLifestyleCollection
                  ? textConstants.NO_LC_OPTIONS_TEXT
                  : includesLifestyleCollection
                  ? textConstants.NO_PREMIUM_STAYS_OPTIONS_TEXT
                  : textConstants.NO_OPTIONS_TEXT}
              </Typography>
              <Box className="no-options-link-content">
                <Typography variant="body2">
                  {isLifestyleCollection
                    ? textConstants.NO_LC_OPTIONS_TO_HOTELS_TEXT
                    : includesLifestyleCollection
                    ? textConstants.NO_PREMIUM_STAYS_OPTIONS_TO_HOTELS_TEXT
                    : textConstants.NO_OPTIONS_TO_HOTELS_TEXT}
                </Typography>
                <Link
                  className="no-options-link"
                  onClick={() => {
                    history.push(PATH_HOTEL);
                  }}
                >
                  <Typography className="no-options-link-text">
                    {textConstants.SEARCH_ALL_HOTELS}
                  </Typography>
                </Link>
              </Box>
            </Box>
          }
        />
        {!!location && onContinue && (
          <ActionButton
            className={clsx("mobile-autocomplete-continue-button", "b2b")}
            message={"Continue"}
            onClick={handleContinue}
          />
        )}
      </Box>
    </MobilePopoverCard>
  );
};
