import axios from "axios";
import { autocompleteRegionsPath } from "../paths";

import { config } from "../../config";
import { MegaMenuRequest, MegaMenuResponse } from "redmond";

export const fetchRegions = (
  body: MegaMenuRequest
): Promise<MegaMenuResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(autocompleteRegionsPath, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
