import React from "react";

export const TITLE_FIRST_HALF = "Luxury stays, elevated.";
export const SUBTITLE =
  "Book with our Premier Collection and enjoy extras at one-of-a-kind properties.";

export const LC_FOR_PREMIUM_CARDHOLDERS_TITLE = "Make your stay extraordinary";
export const LC_FOR_PREMIUM_CARDHOLDERS_SUBTITLE = (
  <>
    Book through the <strong>Premier</strong> or{" "}
    <strong>Lifestyle Collections</strong> and enjoy premium benefits when
    available.
  </>
);

export const TITLE_WITH_EARN = (
  earnRate: string | number,
  productDisplayName: string,
  isVacationRentals?: boolean
) => (
  <span className="font-regular">
    Earn <strong>{earnRate}X miles</strong> on{" "}
    {isVacationRentals ? "vacation rentals" : "hotels"} with{" "}
    {productDisplayName}
  </span>
);

export const VR_TITLE = (cardType: string) => (
  <>
    Earn <strong>5X miles</strong> on vacation rentals with {cardType}
  </>
);
export const VR_SUBTITLE =
  "Book vacation rentals in the US and Caribbean and enjoy premium benefits.";

export const LC_FOR_NON_PREMIUM_CARDHOLDERS_TITLE = "Vibrant stays, elevated";
export const LC_FOR_NON_PREMIUM_CARDHOLDERS_SUBTITLE =
  "Book with our Lifestyle Collection and embrace the local scene.";
