import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchPremierCollectionAvailabilitySaga } from "./fetchPremierCollectionAvailabilitySaga";
import { fetchVacationRentalsAvailabilitySaga } from "./fetchVacationRentalAvailabilitySaga";
import { listPaymentMethodsSaga } from "./listPaymentMethodsSaga";

export function* watchFetchPremierCollectionAvailability() {
  yield takeLatest(
    actionTypes.FETCH_INITIAL_PREMIER_COLLECTION_AVAILABILITY,
    fetchPremierCollectionAvailabilitySaga
  );
  yield takeLatest(
    actionTypes.FETCH_MORE_PREMIER_COLLECTION_AVAILABILITY,
    fetchPremierCollectionAvailabilitySaga
  );
}

export function* watchFetchVacationRentalsAvailability() {
  yield takeLatest(
    actionTypes.FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY,
    fetchVacationRentalsAvailabilitySaga
  );
  yield takeLatest(
    actionTypes.FETCH_MORE_VACATION_RENTALS_AVAILABILITY,
    fetchVacationRentalsAvailabilitySaga
  );
}

export function* watchListPCAvailabilityPaymentMethods() {
  yield takeLatest(actionTypes.LIST_PAYMENT_METHODS, listPaymentMethodsSaga);
}
