import { actions, actionTypes } from "../actions";
import { CreditDetail, Transaction, TravelWalletCredit, TravelWalletOffer } from "redmond";

export interface IPremierCollectionTravelWalletState {
  offers: TravelWalletOffer[];
  credit?: TravelWalletCredit;
  creditBreakdown: CreditDetail[];
  fetchTravelWalletDetailsCallState: CallState;
  creditsHistoryTransactions?: Transaction[];
}

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}

export const initialState: IPremierCollectionTravelWalletState = {
  offers: [],
  credit: undefined,
  creditBreakdown: [],
  fetchTravelWalletDetailsCallState: CallState.NotCalled,
  creditsHistoryTransactions: [],
};

export function reducer(
  state: IPremierCollectionTravelWalletState = initialState,
  action: actions.PremierCollectionTravelWalletActions
): IPremierCollectionTravelWalletState {
  switch (action.type) {
    case actionTypes.FETCH_TRAVEL_WALLET_DETAILS:
      return {
        ...state,
        fetchTravelWalletDetailsCallState: CallState.InProcess,
      };

    case actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        fetchTravelWalletDetailsCallState: CallState.Failed,
      };

    case actionTypes.SET_TRAVEL_WALLET_OFFERS:
      return {
        ...state,
        offers: action.payload,
        fetchTravelWalletDetailsCallState: CallState.Success,
      };

    case actionTypes.SET_TRAVEL_WALLET_CREDIT:
      return {
        ...state,
        credit: action.credit,
        creditBreakdown: action.creditBreakdown,
        fetchTravelWalletDetailsCallState: CallState.Success,
      };

    case actionTypes.SET_TRAVEL_WALLET_CREDIT_HISTORY:
      return {
        ...state,
        creditsHistoryTransactions: action.creditHistory,
      };

    default:
      return state;
  }
}

export * from "./selectors";
