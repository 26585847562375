import { BoundingBox, ICoordinates } from "redmond";

export const convertICoordinatesToGoogleMapCoords = (coordinates: ICoordinates) =>
  new google.maps.LatLng({
    lat: coordinates.lat,
    lng: coordinates.lon,
  });

export const convertGoogleMapCoordsToICoordinates = (
  googleMapCoords: google.maps.LatLng
): ICoordinates => ({
  lat: googleMapCoords.lat(),
  lon: googleMapCoords.lng(),
});

export const convertGoogleMapBoundsToBoundingBox = (
  bounds: google.maps.LatLngBounds
): Omit<BoundingBox, "LocationDescriptor"> => ({
  northEast: convertGoogleMapCoordsToICoordinates(bounds.getNorthEast()),
  southWest: convertGoogleMapCoordsToICoordinates(bounds.getSouthWest()),
});
