import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { StayTypesEnum } from "redmond";

import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../../search/actions";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getLocation,
  getStayType,
  getUntilDate,
  getVacationRentalsLocation,
} from "../../../search/reducer";
import {
  setMaxPriceFilter,
  setPremierCollectionAvailabilitySortOption,
} from "../../actions/actions";
import {
  getPremierCollectionAvailabilityAmenitiesFilter,
  getPremierCollectionAvailabilityCurrency,
  getPremierCollectionAvailabilityLodgings,
  getPremierCollectionAvailabilityMaxPriceFilter,
  getPremierCollectionAvailabilityMinMaxPriceRange,
  getPremierCollectionAvailabilitySortOption,
  getPremierCollectionAvailabilityStarRatingsFilter,
  getVacationRentalAmenities,
  getVacationRentalAvailabilityListings,
  getVacationRentalRoomCounts,
} from "../../reducer";
import { MobileAvailabilitySearchControl } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const stayType = getStayType(state);
  const searchLocation =
    stayType === StayTypesEnum.VacationRentals
      ? getVacationRentalsLocation(state)
      : getLocation(state);
  return {
    searchLocation,
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    maxPrice: getPremierCollectionAvailabilityMaxPriceFilter(state),
    minMaxPriceRange: getPremierCollectionAvailabilityMinMaxPriceRange(state),
    currency: getPremierCollectionAvailabilityCurrency(state),
    sortOption: getPremierCollectionAvailabilitySortOption(state),
    lodgings: getPremierCollectionAvailabilityLodgings(state),
    vrListings: getVacationRentalAvailabilityListings(state),
    stayType,
    starRating: getPremierCollectionAvailabilityStarRatingsFilter(state),
    roomCounts: getVacationRentalRoomCounts(state),
    hotelAmenities: getPremierCollectionAvailabilityAmenitiesFilter(state),
    vacationRentalAmenities: getVacationRentalAmenities(state),
  };
};

const mapDispatchToProps = {
  setOccupancyCounts: actions.setOccupancyCounts,
  setLocation: actions.setLocation,
  setMaxPriceFilter,
  setPremierCollectionAvailabilitySortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileAvailabilitySearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileAvailabilitySearchControl = withRouter(
  connector(MobileAvailabilitySearchControl)
);
