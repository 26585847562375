import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { setPremierCollectionAvailabilitySortOption } from "../../actions/actions";
import { getPremierCollectionAvailabilitySortOption } from "../../reducer";
import { AvailabilitySort } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  sortOption: getPremierCollectionAvailabilitySortOption(state),
});

const mapDispatchToProps = {
  setPremierCollectionAvailabilitySortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilitySortConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilitySort = withRouter(
  connector(AvailabilitySort)
);
