import React from "react";

import { RouteComponentProps } from "react-router-dom";

import {
  ITravelOfferCardContent,
  Icon,
  IconName,
  TravelCreditDetailsCardContent,
  TravelWalletDetailsBanner,
  getTravelCreditCardContent,
  getTravelCreditHistoryModalProps,
  getTravelOfferCardContent,
  isOfferHSPTiered,
  useDeviceTypes,
} from "halifax";
import {
  ENGAGED_CREDIT_CTA,
  ENGAGED_OFFER_CTA,
  SELECTED_TRAVEL_OFFER,
  SelectedTravelOfferProperties,
  TravelWalletOffer,
  VIEWED_CREDIT_BANNER,
  VIEWED_OFFER_BANNER,
  VIEWED_OFFER_FAQ,
  VIEW_CREDIT_HISTORY,
} from "redmond";

import { ClientContext } from "../../../../App";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  CONTROL,
  CREDIT_OFFER_STACKING_V1,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { HSP_OFFER_TITLE_LABEL } from "../TravelWalletDrawer/constants";
import { TravelWalletDetailsBannerConnectorProps } from "./container";
import {
  CREDITS_MODAL_HEADER_TEXT,
  VIEW_DETAILS_CTA_COPY,
  VIEW_OFFER_DETAILS_CTA_COPY,
  getTravelCreditBannerText,
  getTravelOfferBannerText,
} from "./textConstants";

import "./styles.scss";

export interface IPremierCollectionTravelWalletDetailsBannerProps
  extends TravelWalletDetailsBannerConnectorProps,
    RouteComponentProps {
  offer: TravelWalletOffer;
  showButton?: boolean;
  className?: string;
  screen?: SelectedTravelOfferProperties["screen"];
}

export const PremierCollectionTravelWalletDetailsBanner = (
  props: IPremierCollectionTravelWalletDetailsBannerProps
) => {
  const {
    offer,
    showButton,
    className,
    screen,
    credit,
    creditBreakdown,
    creditsTransactionHistory,
    rewardsAccounts,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const [openModal, setOpenModal] = React.useState(false);
  const [bestOffer, setBestOffer] = React.useState(offer);
  const [
    travelWalletDetailsBannerModalContent,
    setTravelWalletDetailsBannerModalContent,
  ] = React.useState<
    ITravelOfferCardContent | TravelCreditDetailsCardContent | null
  >(null);
  const [travelWalletDetailsBannerText, setTravelWalletDetailsBannerText] =
    React.useState("");
  const [isCredit, setIsCredit] = React.useState(false);

  const firstName =
    React.useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = React.useMemo(
    () => creditAndOfferStackingExperimentV1 === AVAILABLE,
    [creditAndOfferStackingExperimentV1]
  );

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = React.useMemo(
    () => travelCreditHistoryExperiment === AVAILABLE,
    [travelCreditHistoryExperiment]
  );

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );
  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  React.useEffect(() => {
    if (offer && bestOffer !== offer) {
      setBestOffer(offer);
    }
    if (credit && isTravelWalletCreditsExperiment) {
      // offer as a prop comes from /availability as the best offer so that amount is the same as the credit amount, then we know the best offer we get back is credit (so we display credit text)
      const bestOfferIsACredit =
        Math.abs(credit.amount.amount) === Math.abs(bestOffer.amount.amount)
          ? true
          : false;
      setIsCredit(bestOfferIsACredit);
      if (bestOfferIsACredit) {
        trackEvent({
          eventName: VIEWED_CREDIT_BANNER,
          properties: {},
        });
      } else {
        trackEvent({
          eventName: VIEWED_OFFER_BANNER,
          properties: {
            location: screen,
          },
          encryptedProperties: bestOffer
            ? [bestOffer.trackingPropertiesV2?.encryptedProperties ?? ""]
            : [],
        });
      }
    } else {
      setIsCredit(false);
    }
  }, [offer, credit]);

  React.useEffect(() => {
    const content =
      isCredit && credit
        ? getTravelCreditCardContent(
            creditBreakdown,
            config.TENANT,
            firstName,
            credit.amount,
            isCreditAndOfferStackingExperimentV1
          )
        : getTravelOfferCardContent(
            bestOffer,
            isCreditAndOfferStackingExperimentV1
          );
    setTravelWalletDetailsBannerModalContent(content);
    const text =
      isCredit && credit
        ? getTravelCreditBannerText(credit)
        : getTravelOfferBannerText(bestOffer, "shop");
    setTravelWalletDetailsBannerText(text || "");
  }, [isCredit]);

  if (!bestOffer && !credit) return null;
  return (
    <>
      {isCredit ? (
        <TravelWalletDetailsBanner
          className={className}
          openModal={openModal}
          setOpenModal={(open) => {
            setOpenModal(open);
            if (open) {
              trackEvent({
                eventName: ENGAGED_CREDIT_CTA,
                properties: {},
              });
            }
          }}
          travelOfferBannerText={travelWalletDetailsBannerText}
          modalHeaderText={CREDITS_MODAL_HEADER_TEXT(firstName)}
          modalCTACopy={VIEW_DETAILS_CTA_COPY}
          showButton={showButton}
          onFirstTravelOfferCardChange={() => {
            trackEvent({
              eventName: VIEWED_OFFER_FAQ,
              properties: {
                screen,
              },
            });
          }}
          creditDetailsCardContent={travelWalletDetailsBannerModalContent}
          modalVariant="credit"
          icon={<Icon name={IconName.PiggyBank} />}
          showCreditHistory={
            isTravelCreditHistoryExperiment &&
            !!creditsTransactionHistory?.length
          }
          travelCreditHistoryProps={
            isTravelCreditHistoryExperiment &&
            !!creditsTransactionHistory?.length
              ? {
                  ...getTravelCreditHistoryModalProps(
                    firstName,
                    creditsTransactionHistory,
                    credit,
                    !matchesMobile ? IconName.PiggyBankCircleOutline : undefined
                  ),
                  rewardsAccounts,
                  credit,
                  isAnnualTravelCreditsExperiment,
                }
              : undefined
          }
          isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
          onViewCreditHistory={() =>
            trackEvent({
              eventName: VIEW_CREDIT_HISTORY,
              properties: {},
            })
          }
        />
      ) : (
        <TravelWalletDetailsBanner
          className={className}
          openModal={openModal}
          setOpenModal={(open) => {
            setOpenModal(open);
            if (open) {
              trackEvent({
                eventName: SELECTED_TRAVEL_OFFER,
                properties: {
                  screen,
                  ...bestOffer.trackingPropertiesV2?.properties,
                },
                encryptedProperties: [
                  bestOffer.trackingPropertiesV2?.encryptedProperties ?? "",
                ],
              });

              trackEvent({
                eventName: ENGAGED_OFFER_CTA,
                properties: {
                  location: screen,
                  entry_type: "banner",
                  funnel: bestOffer.funnels.join(","),
                },
                encryptedProperties: [
                  bestOffer.trackingPropertiesV2?.encryptedProperties ?? "",
                ],
              });
            }
          }}
          travelOfferCardContent={travelWalletDetailsBannerModalContent}
          travelOfferBannerText={travelWalletDetailsBannerText}
          modalCTACopy={VIEW_OFFER_DETAILS_CTA_COPY}
          showButton={showButton}
          onFirstTravelOfferCardChange={() => {
            trackEvent({
              eventName: VIEWED_OFFER_FAQ,
              properties: {
                screen,
              },
            });
          }}
          offerFunnels={offer?.funnels}
          offerLocation={offer?.descriptions[2]}
          isTravelSale={isTravelSaleEnabled}
          imageId={offer?.imageId}
          modalTitleLabelText={
            offer && isOfferHSPTiered(offer.descriptions)
              ? HSP_OFFER_TITLE_LABEL
              : undefined
          }
        />
      )}
    </>
  );
};
