import { put } from "redux-saga/effects";

import { MegaMenuResponse } from "redmond";

import { fetchRegions } from "../../../api/v1/autocomplete/fetchRegions";
import Logger from "../../../utils/logger";
import { actions } from "../actions";

export function* fetchMegaMenuRegionsSaga() {
  try {
    const { regions }: MegaMenuResponse = yield fetchRegions({
      Request: "Luxury",
    });
    yield put(actions.setMegaMenuRegions(regions));
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setLocationCategories([]));
  }
}
