export const LOCATION_SEARCH_PREFIX = "Where are you staying?";
export const LOADING_TEXT = "Loading";
export const WHERE_TO = "Where to?";
export const HOTELS = "Premier Collection";
export const NO_OPTIONS_TEXT = "No premier hotels found.";
export const NO_OPTIONS_TO_HOTELS_TEXT =
  "You may find a hotel in this location by searching outside of our Premier Collection.";
export const SEARCH_ALL_HOTELS = "Search all Hotels";
export const AUTOCOMPLETE_RESULTS_LABEL = "Premier Collection Locations";
export const LC_AUTOCOMPLETE_RESULTS_LABEL = "Lifestyle Collection Locations";
export const NO_LC_OPTIONS_TEXT = "No Lifestyle Collection hotels found.";
export const NO_LC_OPTIONS_TO_HOTELS_TEXT =
  "You may find a hotel in this location by searching outside of our Lifestyle Collection.";
export const PREMIUM_STAYS_AUTOCOMPLETE_RESULTS_LABEL =
  "Premium Stays Locations";
export const NO_PREMIUM_STAYS_OPTIONS_TEXT = "No premium stays found.";
export const NO_PREMIUM_STAYS_OPTIONS_TO_HOTELS_TEXT =
  "You may find a hotel in this location by searching outside of Premier or Lifestyle Collections.";
