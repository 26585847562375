import React from "react";

import { Box, Typography } from "@material-ui/core";

import { MegaMenuRegion } from "redmond";

import { ListView, ListViewItem } from "..";

export interface IRegionsListViewProps {
  regions: MegaMenuRegion[];
  onClick: (region: MegaMenuRegion) => void;
}

export const RegionsListView = ({
  regions,
  onClick,
}: IRegionsListViewProps) => {
  return (
    <ListView
      items={regions.map((region) => makeListItemForRegion(region, onClick))}
      renderSeparators={false}
      itemClassName="region"
    />
  );
};

const makeListItemForRegion = (
  region: MegaMenuRegion,
  onClick: (region: MegaMenuRegion) => void
): ListViewItem => ({
  key: region.label,
  onClick: () => onClick(region),
  node: (
    <>
      <Box>
        <img src={region.imageUrl} />
      </Box>
      <Typography variant="h6">{region.label}</Typography>
    </>
  ),
});
