import axios from "axios";
import { pendingRequestApiPrefix } from "../../../v0/paths";
import { FulfillResponse, Product } from "@b2bportal/purchase-api";
import { config } from "../../../config";
import {
  HotelPriceQuoteScheduleRequest,
  HotelSubmitForApprovalPayload,
} from "redmond";

interface QuoteRequest {
  emailAddress: string;
  request: {
    type: Product;
    value: HotelPriceQuoteScheduleRequest["hotelQuoteRequest"];
  };
}

const submitForApproval = async (
  body: HotelSubmitForApprovalPayload
): Promise<FulfillResponse> => {
  const { hotelQuoteRequest, ...rest } = body;

  const quoteRequest: QuoteRequest = {
    emailAddress: hotelQuoteRequest.hotelQuoteRequest.emailAddress,
    request: {
      type: Product.Hotel,
      value: hotelQuoteRequest.hotelQuoteRequest,
    },
  };

  try {
    const END_POINT = `${pendingRequestApiPrefix}/create`;
    const res = await axios.post(
      END_POINT,
      { ...rest, quoteRequest },
      {
        baseURL: config.baseURL,
      }
    );

    return res.data;
  } catch (e) {
    throw e;
  }
};

export default submitForApproval;
