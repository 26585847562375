import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import schedulePayment from "../../../../api/v0/book/book-flow/schedulePayment";
import {
  ConnectionResultEnum,
  InvalidEnum,
  PaymentSessionToken,
} from "redmond";
import { getSession, getVacationRentalsOpaquePayments } from "../../reducer";
import { IStoreState } from "../../../../reducers/types";

export function* scheduleVacationRentalsBookSaga({
  agentFee,
  isRecommended,
}: actions.IScheduleVacationRentalsBook) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    if (!session) {
      throw Error("Session is undefined");
    }
    const payments = getVacationRentalsOpaquePayments(state);
    if (!payments) {
      throw new Error("Invalid Request.");
    }

    const result: string = yield schedulePayment(session, payments);

    // update the session to the fulfill session token
    yield put(actions.setSession({ value: result } as PaymentSessionToken));
    yield put(
      actions.pollVacationRentalsConfirmationDetails(
        session!,
        agentFee,
        isRecommended
      )
    );
    yield put(actions.setScheduleVacationRentalsBookSuccess());
  } catch (e) {
    yield put(
      actions.setScheduleVacationRentalsBookFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
