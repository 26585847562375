import React from "react";

import { Box, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";

import {
  B2BButton,
  DesktopPopupModal,
  Icon,
  IconName,
  PassengerCountPicker,
  PassengerCountPickerType,
} from "halifax";
import { GuestsSelection, StayTypesEnum } from "redmond";

import { OccupancySelectionConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface IOccupancySelectionProps
  extends OccupancySelectionConnectorProps {
  maxOccupancy?: number;
}
export const OccupancySelection = (props: IOccupancySelectionProps) => {
  const {
    adultsCount,
    children,
    setOccupancyCounts,
    petsCount,
    stayType,
    maxOccupancy,
  } = props;

  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    React.useState(false);

  const label = textConstants.INPUT_LABEL(
    stayType === StayTypesEnum.VacationRentals ? "guests" : "travelers",
    adultsCount,
    children,
    petsCount
  );

  return (
    <Box
      className={clsx("desktop-pc-occupancy-selection", {
        "vacation-rentals": stayType === StayTypesEnum.VacationRentals,
      })}
    >
      <B2BButton
        aria-label={`Guests ${label}`}
        className="num-travelers-input b2b"
        variant="traveler-selector"
        onClick={() => setOpenPassengerCountPicker(true)}
      >
        <Box className="num-traveler-content">
          <Icon
            aria-hidden={true}
            className="icon-start"
            name={IconName.B2BUser}
            ariaLabel=""
          />
          <Box className="text">{label}</Box>
          <Icon
            aria-hidden={true}
            className="icon-end"
            name={IconName.B2BEditPencil}
            ariaLabel=""
          />
        </Box>
      </B2BButton>
      {openPassengerCountPicker && (
        <DesktopPopupModal
          open={openPassengerCountPicker}
          aria-labelledby="passenger-count-picker"
          className={clsx("desktop-pc-occupancy-picker-popup", {
            "vacation-rentals": stayType === StayTypesEnum.VacationRentals,
          })}
          contentClassName="desktop-passenger-count-picker-popup-container"
          onClose={() => setOpenPassengerCountPicker(false)}
          invisibleBackdrop={false}
          headerElement={textConstants.MODAL_TITLES.modalTitle}
        >
          <PassengerCountPicker
            titles={{
              ...textConstants.MODAL_TITLES,
              ...(stayType === StayTypesEnum.VacationRentals
                ? {
                    petsTitle: "Pets",
                    petsSubtitle: (
                      <>
                        <Tooltip
                          placement="bottom"
                          title={
                            <Typography
                              variant="subtitle2"
                              className="tooltip-text"
                              dangerouslySetInnerHTML={{
                                __html: textConstants.PETS_TOOLTIP,
                              }}
                            />
                          }
                          classes={{
                            popper: "pets-info-tooltip-popper",
                            tooltip: "pets-info-tooltip-text",
                          }}
                          enterTouchDelay={50}
                          leaveTouchDelay={2500}
                          tabIndex={0}
                        >
                          <div className="pets-info-tooltip-content">
                            <Typography variant="body2">
                              {textConstants.ADDITIONAL_FEE_TEXT}
                            </Typography>
                            <Icon name={IconName.InfoCircle} />
                          </div>
                        </Tooltip>
                      </>
                    ),
                  }
                : null),
            }}
            setPassengerCounts={(counts: PassengerCountPickerType) => {
              const { adults, children, pets } = counts as GuestsSelection;
              setOccupancyCounts({ adults, children, pets });
            }}
            onClickApply={() => setOpenPassengerCountPicker(false)}
            counts={{
              adults: adultsCount,
              children: children,
              rooms: 1,
              pets: petsCount,
            }}
            unlimited={
              stayType === StayTypesEnum.VacationRentals && !maxOccupancy
            }
            className="b2b"
            maximumTravelersCount={maxOccupancy}
            includeChildrenInMaxCount={!!maxOccupancy}
            maximumChildrenCount={1000}
          />
        </DesktopPopupModal>
      )}
    </Box>
  );
};
