import { Box, Typography, Divider } from "@material-ui/core";
import {
  MobilePopoverCard,
  MobileFloatingButton,
  BackButton,
  ActionLink,
  Icon,
  IconName,
} from "halifax";
import React from "react";
import { IPerson } from "redmond";
import {
  TRAVELER_HEADING,
  PHONE_HEADING,
  EMAIL_HEADING,
  EDIT_TRAVELER,
} from "./textConstants";
import clsx from "clsx";
import "./styles.scss";

interface SelectedPrimaryTravelerInfoProps {
  primaryTraveler: IPerson;
  phoneNumber: string;
  email: string;
  onEditTraveler: () => void;
  header: string;
  onGoBack?: () => void;
  onContinueClick?: () => void;
  mobileHeaderElement?: JSX.Element;
  fullScreenWithBanner?: boolean;
  loading?: boolean;
  bottomContent?: JSX.Element;
  openPrimaryTravelerInfoModal: boolean;
  isMobile?: boolean;
}
export const SelectedPrimaryTravelerInfo = (
  props: SelectedPrimaryTravelerInfoProps
) => {
  const {
    primaryTraveler,
    phoneNumber,
    email,
    header,
    onGoBack,
    onContinueClick,
    mobileHeaderElement,
    fullScreenWithBanner,
    onEditTraveler,
    openPrimaryTravelerInfoModal,
    isMobile,
  } = props;

  const renderContent = () => {
    return (
      <Box
        className={clsx("selected-primary-traveler-info-content-root", {
          mobile: isMobile,
        })}
      >
        <Box className="selected-primary-traveler-info-description">
          <Typography variant="h2" className="title">
            {header}
          </Typography>
        </Box>
        <Divider className="vacation-rental-traveler-info-container-divider" />
        <Box className="vacation-rental-traveler-info-container">
          <Box className="info-fields">
            <Box
              className={clsx(
                "vacation-rental-travel-info-section",
                "vacation-rental-travel-info-text"
              )}
            >
              <Typography
                variant="body2"
                className={clsx("vacation-rental-traveler-info-heading")}
              >
                {TRAVELER_HEADING}
              </Typography>
              <Typography
                variant="body1"
                className={clsx("vacation-rental-traveler-info-value")}
              >
                {`${primaryTraveler.givenName} ${primaryTraveler.surname}`}
              </Typography>
            </Box>
            <Box
              className={clsx(
                "vacation-rental-travel-info-section",
                "vacation-rental-travel-info-text"
              )}
            >
              <Typography
                variant="body2"
                className={clsx("vacation-rental-traveler-info-heading")}
              >
                {PHONE_HEADING}
              </Typography>
              <Typography
                variant="body1"
                className={clsx("vacation-rental-traveler-info-value")}
              >
                {phoneNumber}
              </Typography>
            </Box>
            <Box
              className={clsx(
                "vacation-rental-travel-info-section",
                "vacation-rental-travel-info-text"
              )}
            >
              <Typography
                variant="body2"
                className={clsx("vacation-rental-traveler-info-heading")}
              >
                {EMAIL_HEADING}
              </Typography>
              <Typography
                variant="body1"
                className={clsx("vacation-rental-traveler-info-value")}
              >
                {email}
              </Typography>
            </Box>
          </Box>

          <ActionLink
            className="vacation-rental-travel-info-section vacation-rental-edit-traveler-cta"
            onClick={onEditTraveler}
            content={
              <>
                <Typography className="edit-traveler-cta" variant="body2">
                  {EDIT_TRAVELER}
                </Typography>
                <Icon className="pencil-icon" name={IconName.PencilEdit} />
              </>
            }
          />
        </Box>
      </Box>
    );
  };
  return (
    <MobilePopoverCard
      open={openPrimaryTravelerInfoModal}
      className={"selected-primary-traveler-info-popup b2b"}
      onClose={() => {
        onGoBack && onGoBack();
      }}
      fullScreen
      topLeftButton={
        <BackButton
          className="selected-primary-traveler-info-back-button"
          onClick={() => {
            onGoBack && onGoBack();
          }}
        />
      }
      headerElement={mobileHeaderElement}
      fullScreenWithBanner={fullScreenWithBanner}
    >
      {renderContent()}
      <MobileFloatingButton
        className="mobile-selected-primary-traveler-info-button"
        wrapperClassName={
          "mobile-selected-primary-traveler-info-button-container"
        }
        onClick={() => {
          onContinueClick && onContinueClick();
        }}
      >
        Continue
      </MobileFloatingButton>
    </MobilePopoverCard>
  );
};
