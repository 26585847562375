import React, { useContext, useState } from "react";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router";
import { VRBookingErrorModalConnectorProps } from "./container";
import "./styles.scss";
import {
  CHOOSE_ANOTHER_PROPERTY,
  TRY_AGAIN,
  CONFIRM_AND_BOOK,
  SEARCH_AGAIN,
  ERROR_ICON,
  UNABLED_ICON,
  MODIFY_TRAVELERS,
  CONTINUE,
  EDIT_PAYMENT_INFO,
  PRICE_DECREASE_ICON,
  PRICE_INCREASE_ICON,
} from "../../reducer/selectors/textConstants";
import { goToShop } from "../../../shop/utils/queryStringHelpers";
import { ClientContext } from "../../../../App";
import { AGENT_FEE } from "../index";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  MODAL_ALERT_CHOICE,
  ModalScreens,
  MODAL_ALERT,
  ModalButtonType,
  ModalCategoryType,
} from "redmond";
import queryStringParser from "query-string";
import { goToVRAvailability } from "../../../vacation-rental-shop/utils/queryStringHelpers";

export interface IVRBookingErrorModalProps
  extends VRBookingErrorModalConnectorProps,
    RouteComponentProps {}

export const VRBookingErrorModal = ({
  hasError,
  errorTitles,
  history,
  scheduleBook,
  selectedListing,
  fromDate,
  untilDate,
  adultsCount,
  childrenCount,
  isBookingValid,
  acknowledgePriceDifference,
  resetBookErrors,
  modalType,
  priceQuoteSuccessful,
  petsCount,
}: IVRBookingErrorModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  const { primaryButtonText, secondaryButtonText } = errorTitles;

  const modalEventProperties = {
    type: modalType,
    screen: ModalScreens.VR_CHECKOUT,
    primary_button: primaryButtonText,
    secondary_button: secondaryButtonText,
    category: ModalCategoryType.TROUBLE,
  };

  React.useEffect(() => {
    setIsOpen(hasError);

    if (hasError)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: modalEventProperties,
      });
  }, [hasError]);

  const handleButtonClick = (actionKey: string, isPrimary = false) => {
    setIsOpen(false);
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...modalEventProperties,
        button_choice: isPrimary
          ? ModalButtonType.PRIMARY
          : ModalButtonType.SECONDARY,
      },
    });

    switch (actionKey) {
      case SEARCH_AGAIN:
        resetBookErrors();
        redirectToShop();
        break;
      case TRY_AGAIN:
        acknowledgePriceDifference();
        if (isBookingValid && priceQuoteSuccessful) {
          resetBookErrors();
          redoBook();
        } else {
          redirectToShop();
        }
        break;
      case CHOOSE_ANOTHER_PROPERTY:
        redirectToAvailability();
        break;
      case CONTINUE:
        acknowledgePriceDifference();
        break;
      case CONFIRM_AND_BOOK:
        acknowledgePriceDifference();
        redoBook();
        break;
      case MODIFY_TRAVELERS:
        resetBookErrors();
        scrollToTravelers();
        break;
      case EDIT_PAYMENT_INFO:
        resetBookErrors();
        break;
      default:
        break;
    }
  };

  const redoBook = () => {
    const queryString = queryStringParser.parse(history.location.search);
    scheduleBook({
      agentFee: isAgentPortal ? AGENT_FEE : 0,
      isRecommended: queryString.recommended === "true",
    });
  };

  const redirectToShop = () => {
    goToShop({ history });
  };

  const redirectToAvailability = () => {
    goToVRAvailability({
      history,
      listing: selectedListing,
      fromDate,
      untilDate,
      adultsCount,
      childrenCount,
      petsCount,
    });
  };

  const scrollToTravelers = () => {
    const travelerSectionElement = document.querySelector(
      ".traveler-select-workflow-container"
    );

    const BANNER_HEIGHT =
      document
        .getElementById("b2bportal-banner-container")
        ?.getBoundingClientRect().height ?? 0;
    const HEADER_HEIGHT = matchesMobile
      ? 0
      : document.querySelector(".app-header")?.getBoundingClientRect().height ??
        0;
    const MARGIN = matchesMobile ? 18 : 20;

    const yOffset =
      window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);

    const y =
      (travelerSectionElement?.getBoundingClientRect().top ?? 0) + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  // buttons intentionally saved in reverse order for rendering
  const buttons = [
    {
      buttonText: secondaryButtonText || SEARCH_AGAIN,
      defaultStyle: "h4r-secondary",
      onClick: () => handleButtonClick(secondaryButtonText || SEARCH_AGAIN),
    },
    {
      buttonText: primaryButtonText || CHOOSE_ANOTHER_PROPERTY,
      defaultStyle: "h4r-primary",
      onClick: () =>
        handleButtonClick(primaryButtonText || CHOOSE_ANOTHER_PROPERTY, true),
    },
  ];

  const getIconImage = (type: string) => {
    const iconMap = {
      [ERROR_ICON]: <Icon className="error-icon" name={IconName.ErrorState} />,
      [UNABLED_ICON]: (
        <Icon className="error-icon" name={IconName.UnableToProcess} />
      ),
      [PRICE_DECREASE_ICON]: (
        <Icon className="error-icon" name={IconName.ModalPriceDrop} />
      ),
      [PRICE_INCREASE_ICON]: (
        <Icon className="error-icon" name={IconName.PriceIncrease} />
      ),
    };

    return iconMap[type] || iconMap[ERROR_ICON];
  };

  return (
    <GenericInfoPopup
      agentSubtitle={errorTitles.agentSubtitle}
      agentTitle={errorTitles.agentTitle}
      buttons={buttons}
      className="booking-error-modal"
      image={getIconImage(errorTitles.icon as string)}
      isAgent={isAgentPortal}
      isMobile={matchesMobile}
      open={isOpen}
      subtitle={errorTitles.subtitle}
      title={errorTitles.title}
      // note: mobile popovers in the checkout workflow have z-index: 1300
      zIndex={matchesMobile ? 1301 : 1300}
    />
  );
};
