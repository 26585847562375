import React from "react";

import { Box } from "@material-ui/core";
import clsx from "clsx";

import {
  HotelAvailabilityMapTooltip,
  Icon,
  IconName,
  ensureExhaustive,
} from "halifax";
import {
  ListingCollectionEnum,
  ListingSearchResult,
  Lodging,
  LodgingCollectionEnum,
} from "redmond";

import { ClientContext } from "../../../../../../App";
import { ConnectedAvailabilityMapHotelTooltipProps } from "./container";
import {
  LODGING_PROMOTIONS,
  LODGING_PROMOTIONS_AVAILABLE,
  LODGING_PROMOTIONS_VARIANTS,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../../../context/experiments";

import { config } from "../../../../../../api/config";
import "./styles.scss";

export interface IAvailabilityMapHotelTooltipProps
  extends ConnectedAvailabilityMapHotelTooltipProps {
  lodging: Lodging | ListingSearchResult;
  className?: string;
  onClickHotelDetails: (event: React.MouseEvent) => void;
  onCloseClick: (event: React.MouseEvent) => void;
}

enum CollectionEnum {
  Premier = "Premier",
  Lifestyle = "Lifestyle",
}

const getCollection = (
  collection?: LodgingCollectionEnum | ListingCollectionEnum
): CollectionEnum | undefined => {
  switch (collection) {
    case LodgingCollectionEnum.Premier:
    case ListingCollectionEnum.Premier:
      return CollectionEnum.Premier;
    case LodgingCollectionEnum.Lifestyle:
    case ListingCollectionEnum.Lifestyle:
      return CollectionEnum.Lifestyle;
    case LodgingCollectionEnum.NoCollection:
    case ListingCollectionEnum.NoCollection:
    case undefined:
      return undefined;
    default:
      return ensureExhaustive(collection);
  }
};

const getIcon = (collection?: CollectionEnum): JSX.Element | undefined => {
  if (collection == null) return;
  switch (collection) {
    case CollectionEnum.Premier:
      return <Icon name={IconName.PremierCollectionRibbon} />;
    case CollectionEnum.Lifestyle:
      return <Icon name={IconName.LifestyleCollectionRibbon} />;
  }
};

export const AvailabilityMapHotelTooltip = (
  props: IAvailabilityMapHotelTooltipProps
): JSX.Element => {
  const {
    lodging,
    nightCount,
    accountReferenceId,
    isVentureX,
    onClickHotelDetails,
    onCloseClick,
  } = props;

  const expState = useExperiments();
  const lodgingPromotions = getExperimentVariantCustomVariants(
    expState.experiments,
    LODGING_PROMOTIONS,
    LODGING_PROMOTIONS_VARIANTS
  );
  const isLodgingPromotionsExperiment = React.useMemo(
    () => lodgingPromotions === LODGING_PROMOTIONS_AVAILABLE,
    [lodgingPromotions]
  );

  const collection = getCollection(
    "lodging" in lodging
      ? lodging.lodgingCollection
      : lodging.listing.listingCollection
  );
  const { policies } = React.useContext(ClientContext);
  return (
    <Box
      className={clsx(
        "lodging-details-container",
        {
          "premier-collection": collection === CollectionEnum.Premier,
          "lifestyle-collection": collection === CollectionEnum.Lifestyle,
        },
        props.className
      )}
    >
      {getIcon(collection)}
      <HotelAvailabilityMapTooltip
        lodging={lodging}
        onClickHotelDetails={onClickHotelDetails}
        onCloseClick={onCloseClick}
        policyLimit={policies?.hotels.policies[0].maxPricePerNight}
        tenant={config.TENANT}
        nightCount={nightCount ?? 0}
        rewardsKey={accountReferenceId}
        isLodgingPromotionsExperiment={isLodgingPromotionsExperiment}
        isVentureX={isVentureX}
      />
    </Box>
  );
};
