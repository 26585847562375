export const FETCH_PREMIER_COLLECTION_SHOP =
  "premierCollectionShop/FETCH_PREMIER_COLLECTION_SHOP";
export type FETCH_PREMIER_COLLECTION_SHOP =
  typeof FETCH_PREMIER_COLLECTION_SHOP;

export const SET_PREMIER_COLLECTION_SHOP_RESULTS =
  "premierCollectionShop/SET_PREMIER_COLLECTION_SHOP_RESULTS";
export type SET_PREMIER_COLLECTION_SHOP_RESULTS =
  typeof SET_PREMIER_COLLECTION_SHOP_RESULTS;

export const SET_PREMIER_COLLECTION_SHOP_CALL_STATE_FAILED =
  "premierCollectionShop/SET_PREMIER_COLLECTION_SHOP_CALL_STATE_FAILED";
export type SET_PREMIER_COLLECTION_SHOP_CALL_STATE_FAILED =
  typeof SET_PREMIER_COLLECTION_SHOP_CALL_STATE_FAILED;

export const SELECT_ROOM_TYPE = "premierCollectionShop/SELECT_ROOM_TYPE";
export type SELECT_ROOM_TYPE = typeof SELECT_ROOM_TYPE;

export const ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_FAILURE =
  "premierCollectionShop/ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_FAILURE";
export type ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_FAILURE =
  typeof ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_FAILURE;

export const SELECT_LODGING = "premierCollectionShop/SELECT_LODGING";
export type SELECT_LODGING = typeof SELECT_LODGING;

export const SET_PREMIER_COLLECTION_SHOP_PROGRESS =
  "premierCollectionShop/SET_PREMIER_COLLECTION_SHOP_PROGRESS";
export type SET_PREMIER_COLLECTION_SHOP_PROGRESS =
  typeof SET_PREMIER_COLLECTION_SHOP_PROGRESS;

export const SET_IS_FROM_HOTEL_AVAILABILITY =
  "premierCollectionShop/SET_IS_FROM_HOTEL_AVAILABILITY";
export type SET_IS_FROM_HOTEL_AVAILABILITY =
  typeof SET_IS_FROM_HOTEL_AVAILABILITY;
