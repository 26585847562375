import React from "react";
import { Icon, IconName, NotificationBanner } from "halifax";
import { Box, Typography } from "@material-ui/core";
import {
  getBenefitsList,
  LC_ADDITIONAL_BENEFITS,
  LC_BENEFITS_TITLE,
  PC_ADDITIONAL_BENEFITS,
  PC_BENEFITS_TITLE,
} from "./textConstants";
import clsx from "clsx";
import "./styles.scss";
import { CustomerAccountRole, RewardsAccount } from "redmond";

export interface IPremierCollectionCheckoutBenefitsProps {
  className?: string;
  variant?: "premier-collection" | "lifestyle-collection";
  largestEarnAccount: RewardsAccount;
  isVR?: boolean;
}
export const PremierCollectionCheckoutBenefits = ({
  className,
  variant = "premier-collection",
  largestEarnAccount,
  isVR = false,
}: IPremierCollectionCheckoutBenefitsProps) => {
  return (
    <Box className={clsx("pc-checkout-benefits-content-wrapper", className)}>
      <Box className="pc-benefits-title">
        <Icon name={IconName.StarOutline} />
        <Typography className="pc-benefits-title-text">
          {variant === "premier-collection" && PC_BENEFITS_TITLE}
          {variant === "lifestyle-collection" && LC_BENEFITS_TITLE}
        </Typography>
      </Box>

      <Typography className="pc-benefits-list">
        {getBenefitsList(
          largestEarnAccount.earn.hotelsMultiplier,
          variant === "lifestyle-collection",
          isVR,
          largestEarnAccount.customerAccountRole === CustomerAccountRole.Primary
        )}
      </Typography>
      <NotificationBanner
        icon={<Icon name={IconName.GiftOutline} />}
        content={
          variant === "premier-collection" || isVR
            ? PC_ADDITIONAL_BENEFITS(isVR)
            : LC_ADDITIONAL_BENEFITS
        }
      />
    </Box>
  );
};
