import React from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

import { Icon, IconName } from "halifax";

import { AMENITIES_SELECTION_HEADING_TEXT } from "./textConstants";

import "./styles.scss";

export interface IAmenitiesSelectionProps<Amenity> {
  title?: string;
  icon?: IconName;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  amenities: Amenity[];
  getAmenityLabel: (amenity: Amenity) => string;
  selectedAmenities: Amenity[];
  setSelectedAmenities: (amenities: Amenity[]) => void;
  amenitiesEqual?: (a: Amenity, b: Amenity) => boolean;
}

const MAX_AMENITIES = 8;

export function AmenitiesSelection<Amenity>({
  amenities,
  title = AMENITIES_SELECTION_HEADING_TEXT,
  icon,
  labelPlacement,
  getAmenityLabel,
  selectedAmenities,
  setSelectedAmenities,
  amenitiesEqual = (a, b) => a === b,
}: IAmenitiesSelectionProps<Amenity>) {
  const expandable = amenities.length > MAX_AMENITIES;
  const [expanded, setExpanded] = React.useState(false);

  const visibleAmenities = React.useMemo(
    () =>
      expandable && !expanded ? amenities.slice(0, MAX_AMENITIES) : amenities,
    [expandable, expanded, amenities]
  );

  return (
    <div className="premium-stays-amenities-selection-root">
      <Typography
        variant="h4"
        className={clsx("filter-heading", "amenities-selection-heading")}
      >
        {icon && <Icon name={icon} />} {title}
      </Typography>
      <FormControl className="amenities-selection-list" component="fieldset">
        <FormGroup>
          {visibleAmenities.map((amenity) => (
            <FormControlLabel
              tabIndex={0}
              role="checkbox"
              labelPlacement={labelPlacement}
              aria-selected={selectedAmenities.includes(amenity)}
              className="checkbox-control-label"
              aria-label={`${getAmenityLabel(amenity)} ${
                selectedAmenities.includes(amenity) ? "selected" : ""
              }`}
              control={
                <Checkbox
                  checked={selectedAmenities.includes(amenity)}
                  onChange={(e) => {
                    e.persist();
                    setSelectedAmenities([
                      ...selectedAmenities.filter(
                        (selectedAmenity) =>
                          !amenitiesEqual(selectedAmenity, amenity)
                      ),
                      ...(e.target?.checked ? [amenity] : []),
                    ]);
                  }}
                  name={`${getAmenityLabel(amenity)}-checked`}
                  className="amenities-selection-checkbox checkbox"
                />
              }
              label={getAmenityLabel(amenity)}
            />
          ))}
          {expandable && (
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded
                ? "View less"
                : `View all amenities (${amenities.length})`}
            </Button>
          )}
        </FormGroup>
      </FormControl>
    </div>
  );
}
