import { Box, Divider, Typography } from "@material-ui/core";
import React, { useMemo, useEffect } from "react";
import { RewardsAccountSelection } from "../../../rewards/components";
import {
  ActionLink,
  BackToTopButton,
  HotelAmenities,
  HotelCarousel,
  HotelDescription,
  HotelName,
  HotelOverview,
  Icon,
  IconName,
  MobileHotelShopRoomTypePickerPanelSkeleton,
  PageTabNavigations,
  PremiumStaysInfoAccordian,
  VacationRentalOccupancyInfo,
} from "halifax";
import { useInView } from "react-intersection-observer";
import { MobileShopHeader } from "../../../shop/components";
import { MobileHotelPhotoModal } from "../../../shop/components/MobileHotelPhotoModal";
import { RouteComponentProps } from "react-router";
import { VRMobileShopConnectorProps } from "./container";
import "./styles.scss";
import { RoomsLayoutInfo } from "../RoomsLayoutInfo";
import { VRShopLocationMap } from "../VRShopLocationMap";
import {
  ListingCollectionEnum,
  ObfuscatedAddress,
  StayTypesEnum,
  SelectedTravelOfferScreen,
} from "redmond";
import { ListingTermsInfo } from "../ListingTermsInfo";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import { MobileListingTotalPanel } from "../MobileListingTotalPanel";
import { VRShopErrorModal } from "../VRShopErrorModal";
import { goToVRAvailability } from "../../utils/queryStringHelpers";
import {
  AVAILABLE,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components";

export interface IVRMobileShopProps
  extends VRMobileShopConnectorProps,
    RouteComponentProps {
  isVRForPremiumCardHoldersEnabled: boolean;
  showOfferBasedOnSelectedListing: boolean;
}

export const VRMobileShop = (props: IVRMobileShopProps) => {
  const {
    selectedListing,
    vrShopCallInProgress,
    hasFailed,
    fromDate,
    untilDate,
    childrenCount,
    adultsCount,
    petsCount,
    history,
    isVRForPremiumCardHoldersEnabled,
    showOfferBasedOnSelectedListing,
  } = props;
  const [clickedSectionId, setClickedSectionId] = React.useState<string>();
  const [galleryRef, galleryInView] = useInView({ threshold: 1 });
  const [aboutRef, aboutInView] = useInView({ threshold: 1 });
  const [locationRef, locationInView] = useInView({ threshold: 1 });
  const [roomsRef, roomsInView] = useInView({ threshold: 1 });
  const [amenitiesRef, amenitiesInView] = useInView({ threshold: 1 });
  const [houseRulesRef, houseRulesInView] = useInView({ threshold: 1 });
  const [hostInfoRef, hostInfoInView] = useInView({ threshold: 1 });
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const [showExpandedVRMap, setShowExpandedVRMap] = React.useState(false);
  const [showOfferBanner, setShowOfferBanner] = React.useState(false);

  const expState = useExperiments();

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  useEffect(() => { 
    setShowOfferBanner(
      isTravelWalletOfferExperiment && showOfferBasedOnSelectedListing
    );
  }, [showOfferBasedOnSelectedListing]);

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const selectedTab = useMemo(() => {
    if (
      galleryInView &&
      (clickedSectionId ? clickedSectionId === "#gallery-section" : true)
    ) {
      return 0;
    }
    if (
      aboutInView &&
      (clickedSectionId ? clickedSectionId === "#about-section" : true)
    ) {
      return 1;
    }
    if (
      locationInView &&
      (clickedSectionId ? clickedSectionId === "#location-section" : true)
    ) {
      return 2;
    }
    if (
      roomsInView &&
      (clickedSectionId ? clickedSectionId === "#rooms-section" : true)
    ) {
      return 3;
    }

    if (
      amenitiesInView &&
      (clickedSectionId ? clickedSectionId === "#amenities-section" : true)
    ) {
      return 4;
    }

    if (
      houseRulesInView &&
      (clickedSectionId ? clickedSectionId === "#house-rules-section" : true)
    ) {
      return 5;
    }
    if (
      hostInfoInView &&
      (clickedSectionId ? clickedSectionId === "#host-section" : true)
    ) {
      return 6;
    }

    return undefined;
  }, [
    galleryInView,
    aboutInView,
    locationInView,
    roomsInView,
    amenitiesInView,
    houseRulesInView,
    hostInfoInView,
  ]);
  const scrollToSection = (sectionId: string) => {
    const BANNER_HEIGHT =
      document
        .getElementById("b2bportal-banner-container")
        ?.getBoundingClientRect().height ?? 0;
    const HEADER_HEIGHT =
      document
        .querySelector(".pc-shop-progress-header-container")
        ?.getBoundingClientRect().height ?? 0;
    const MARGIN = 20;
    const yOffset =
      window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);
    const element = document.getElementById(sectionId);

    const y = (element?.getBoundingClientRect().top ?? 0) + yOffset;
    setClickedSectionId(sectionId);
    setTimeout(() => {
      setClickedSectionId(undefined);
    }, 500);
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const tabs = [
    {
      label: "Gallery",
      onClick: () => scrollToSection("gallery-section"),
    },
    { label: "About", onClick: () => scrollToSection("about-section") },
    {
      label: "Location",
      onClick: () => scrollToSection("location-section"),
    },
    {
      label: "Rooms & beds",
      onClick: () => scrollToSection("rooms-section"),
    },
    {
      label: "Amenities",
      onClick: () => scrollToSection("amenities-section"),
    },
    {
      label: "House rules",
      onClick: () => scrollToSection("house-rules-section"),
    },
    ...(selectedListing?.listing.hostInfo?.hostName &&
    selectedListing?.listing.hostInfo?.hostDescription
      ? [
          {
            label: "Host",
            onClick: () => scrollToSection("host-section"),
          },
        ]
      : []),
  ];

  const returnToAvailability = () => {
    goToVRAvailability({
      history,
      listing: selectedListing,
      fromDate,
      untilDate,
      adultsCount,
      childrenCount,
      petsCount,
    });
  };

  const renderSkeleton = () => (
    <Box className={"mobile-vr-shop-skeleton"}>
      <Box>
        <HotelName isSkeleton />
      </Box>
      <HotelCarousel isSkeleton />
      <Box className={"section-wrapper"}>
        <HotelOverview isSkeleton />
        <HotelAmenities isSkeleton />
        <HotelOverview isSkeleton />
      </Box>
      <Box className={"section-wrapper"}>
        <HotelAmenities isSkeleton />
        <HotelAmenities isSkeleton />
        <HotelOverview isSkeleton />
      </Box>
      <Box className={"section-wrapper"}>
        <HotelName isSkeleton />
        <HotelCarousel isSkeleton />
      </Box>
      <MobileHotelShopRoomTypePickerPanelSkeleton />
    </Box>
  );

  const renderShop = () => {
    if (!selectedListing) return null;
    const { listing } = selectedListing;
    const { name, description } = listing.content;
    const formattedDescription = description?.replace(/\n/g, "<br />");
    return (
      <Box
        className={clsx("mobile-vr-shop-container", {
          "lifestyle-collection":
            listing.listingCollection === ListingCollectionEnum.Lifestyle,
        })}
      >
        <MobileShopHeader
          isVRForPremiumCardHoldersEnabled={isVRForPremiumCardHoldersEnabled}
        />

        <Box className="mobile-vr-shop-rewards-account-contents">
          <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
        </Box>

        <MobileHotelPhotoModal
          openModal={openPhotoModal}
          setOpenModal={setOpenPhotoModal}
        />

        <div
          id="gallery-section"
          onClick={() => {
            setOpenPhotoModal(true);
          }}
          ref={galleryRef}
        >
          <HotelCarousel media={selectedListing?.listing.content.media} />
        </div>

        <PageTabNavigations
          navItems={tabs}
          selectedTab={selectedTab}
          className="vr-shop-navigation"
        />

        <Box
          className={clsx("shop-benefits", {
            "lifestyle-collection":
              listing.listingCollection === ListingCollectionEnum.Lifestyle,
          })}
        >
          <Box className="shop-benefits-logo">
            <Icon
              name={
                listing.listingCollection === ListingCollectionEnum.Lifestyle
                  ? IconName.LifestyleCollectionHorizontalIcon
                  : IconName.PremierCollectionHorizontalIcon
              }
            />
          </Box>

          <Typography className="shop-benefits-list">
            {textConstants.getBenefitsList(
              listing?.listingCollection === ListingCollectionEnum.Lifestyle
                ? "lifestyle-collection"
                : "default"
            )}
          </Typography>

          <Box className="additional-benefits">
            <Icon name={IconName.GiftOutline} />
            <Typography className="additional-benefits-text">
              {textConstants.ADDITIONAL_BENEFITS(false)}
            </Typography>
          </Box>
        </Box>
        {showOfferBanner && (
          <TravelWalletDetailsBanner
            offer={selectedListing?.bestOffer!}
            showButton={false}
            className="home-shop-offer"
            screen={SelectedTravelOfferScreen.PREMIUM_STAYS_DETAILS}
            {...props}
          />
        )}
        <div
          id="about-section"
          className={"section-wrapper description-section"}
          ref={aboutRef}
        >
          <Box className="description-wrapper">
            <Typography variant="h2" className="section-title">
              About the
              <span className="regular-font"> {name}</span>
            </Typography>
            <VacationRentalOccupancyInfo
              maxGuestsOccupancy={listing.terms.maxOccupancy}
            />
            <Divider className={"description-divider"} />
            <HotelDescription description={formattedDescription} />
          </Box>
        </div>
        <Divider className="section-divider" />
        <div
          id="location-section"
          className={"section-wrapper location-section"}
          ref={locationRef}
        >
          <Typography variant="h2" className="section-title">
            {textConstants.LOCATION_TITLE}
          </Typography>
          <VRShopLocationMap
            selectedListing={selectedListing}
            setShowExpandedVRMap={setShowExpandedVRMap}
            showExpandedVRMap={showExpandedVRMap}
            isMobile
            renderPreview
          />
          <ActionLink
            className="property-location-link"
            content={
              <Box className="property-location-link-content">
                <Icon name={IconName.B2BMapPin} />
                <Typography className="property-location-text">
                  {`${
                    (
                      selectedListing.listing.content.location
                        .address as ObfuscatedAddress
                    ).city
                  }, ${
                    (
                      selectedListing.listing.content.location
                        .address as ObfuscatedAddress
                    ).state
                  }`}
                </Typography>
              </Box>
            }
            onClick={() => setShowExpandedVRMap(true)}
          />
        </div>
        <Divider className="section-divider" />
        <div
          id="rooms-section"
          className={"section-wrapper rooms-section"}
          ref={roomsRef}
        >
          <Typography variant="h2" className="section-title">
            {textConstants.ROOMS_AND_BED_TITLE}
          </Typography>
          <RoomsLayoutInfo isMobile />
        </div>
        <div
          id="amenities-section"
          className={"section-wrapper amenities-section"}
          ref={amenitiesRef}
        >
          <Box className="left-section">
            <Typography variant="h2" className="amenities-title">
              {textConstants.AMENITIES_TITLE}
            </Typography>
            <Typography variant="body1" className="amenities-subtitle">
              {textConstants.AMENITIES_SUBTITLE}
            </Typography>
            <HotelAmenities
              amenities={selectedListing.listing.content.amenities}
              displayCount={6}
              showAmenitiesText={`View More (${
                selectedListing.listing.content.amenities &&
                selectedListing.listing.content.amenities.length - 6
              })`}
              hideAmenityIconsInTooltip
            />
            <Box className="additional-benefits">
              <Icon name={IconName.GiftOutline} />
              <Typography className="additional-benefits-text">
                {textConstants.ADDITIONAL_BENEFITS(true)}
              </Typography>
            </Box>
          </Box>
        </div>
        <div
          id="house-rules-section"
          className={"section-wrapper house-rules-section"}
          ref={houseRulesRef}
        >
          <Typography variant="h2" className="section-title">
            {textConstants.HOUSE_RULES_TITLE}
          </Typography>
          <ListingTermsInfo selectedListing={selectedListing} isMobile />
        </div>
        {listing.hostInfo?.hostDescription && listing.hostInfo?.hostName && (
          <>
            <Divider className="section-divider" />
            <div
              id="host-section"
              className={"section-wrapper host-section"}
              ref={hostInfoRef}
            >
              <Box className="host-info-title">
                <Typography variant="h2" className="section-title host-name">
                  About your host, <strong>{listing.hostInfo?.hostName}</strong>
                </Typography>
                {listing.hostInfo?.hostImageUrl && (
                  <img
                    className="host-img"
                    src={listing.hostInfo?.hostImageUrl}
                  />
                )}
              </Box>
              <Typography>{listing.hostInfo?.hostDescription}</Typography>
            </div>
          </>
        )}
        <PremiumStaysInfoAccordian
          variant="with-lifestyle-collection"
          includesVacationRentals
          isMobile
          defaultClosed
          stayType={StayTypesEnum.VacationRentals}
        />
        <MobileListingTotalPanel />

        {isGlobalMobileNavExperiment ? (
          <BackToTopButton bottom={115} />
        ) : undefined}
      </Box>
    );
  };
  return (
    <Box
      className={clsx("mobile-vr-shop-wrapper", {
        "global-mobile-nav": isGlobalMobileNavExperiment,
      })}
    >
      {hasFailed && (
        <VRShopErrorModal returnToAvailability={() => returnToAvailability()} />
      )}
      {vrShopCallInProgress ? renderSkeleton() : renderShop()}
    </Box>
  );
};
