import axios from "axios";

import { PaymentSessionToken } from "redmond";
import { FulfillResponseV0, purchaseApi } from "@b2bportal/purchase-api";

const pollFinalized = (req: PaymentSessionToken): Promise<FulfillResponseV0> =>
  new Promise((resolve, reject) => {
    try {
      const res = purchaseApi(axios).apiV0PurchaseFulfillPollPost({
        value: req.value,
      });

      res.then((body) => resolve(body.data)).catch((e) => reject(e));
    } catch (e) {
      reject(e);
    }
  });

export default pollFinalized;
