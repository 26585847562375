import { put, select } from "redux-saga/effects";
import {
  WalletOffersResponse,
  OffersForProductsRequest,
  WalletSummaryResponse,
} from "redmond";
import {
  getOffersRequest,
  getPriceQuotePricing,
  getHomesOffersRequest,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import {
  setBestOfferOverall,
  setFetchApplicableTravelWalletItemsCallStateFailure,
  setFetchApplicableTravelWalletItemsCallStateSuccess,
  setTravelWalletCredit,
  setTravelWalletOffers,
} from "../../actions/actions";
import { fetchTravelWalletOffers } from "../../../../api/v0/travel-wallet/fetchTravelWalletOffers";
import { fetchTravelWalletSummary } from "../../../../api/v0/travel-wallet/fetchTravelWalletSummary";
import { getPremierCollectionShopChosenProduct } from "../../../shop/reducer";
import { actions } from "../../actions";

export function* fetchApplicableTravelWalletItemsSaga({
  isHomesProduct = false,
}: actions.IFetchApplicableTravelWalletItems) {
  try {
    const state: IStoreState = yield select();
    const request: OffersForProductsRequest | null = isHomesProduct
      ? getHomesOffersRequest(state)
      : getOffersRequest(state);

    const estimatePricing = getPremierCollectionShopChosenProduct(state);
    const getQuotePricing = getPriceQuotePricing(state);

    const totalFiatValue =
      getQuotePricing?.payNowTotal.fiat.value ||
      estimatePricing?.total.fiat.value ||
      1;

    if (!request) {
      throw new Error("Must have selected lodging");
    }

    const offersResponse: WalletOffersResponse =
      yield fetchTravelWalletOffers(request);

    const sortedOffers = offersResponse.rankedApplicableOffers.sort(
      (prev, current) => {
        const prevAmount = prev.percentage
          ? Math.floor(
            (prev.percentage * totalFiatValue + Number.EPSILON) * 100
          ) / 100
          : prev.amount.amount * -1;
        const currAmount = current.percentage
          ? Math.floor(
            (current.percentage * totalFiatValue + Number.EPSILON) * 100
          ) / 100
          : current.amount.amount * -1;
        return currAmount - prevAmount;
      }
    );
    yield put(setTravelWalletOffers(sortedOffers));
    yield put(setBestOfferOverall(sortedOffers[0]));

    const summaryResponse: WalletSummaryResponse =
      yield fetchTravelWalletSummary();

    yield put(setTravelWalletCredit(summaryResponse.credit));
    yield put(setFetchApplicableTravelWalletItemsCallStateSuccess());
  } catch (e) {
    yield put(setFetchApplicableTravelWalletItemsCallStateFailure());
  }
}
