import React, { useContext } from "react";
import { GenericBanner, useDeviceTypes } from "halifax";
import { RouterProps } from "react-router-dom";
import {
  B2B_PORTAL_BANNER_CONTAINER_ID,
  CustomerAccountRole,
  StayTypesEnum,
} from "redmond";
import * as constants from "./constants";
import { ClientContext } from "../../../../App";

import { RewardsBannerConnectorProps } from "./container";
import {
  PATH_HOME,
  PATH_AVAILABILITY,
  PATH_SHOP,
  PATH_ANCILLARY_CUSTOMIZE,
  PATH_VACATION_RENTALS,
  PATH_VACATION_RENTALS_AVAILABILITY,
  PATH_VACATION_RENTALS_SHOP,
} from "../../../../utils/paths";
import { Typography } from "@material-ui/core";
import {
  useExperiments,
  getExperimentVariantCustomVariants,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  CONTROL,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
} from "../../../../context/experiments";

export interface IRewardsBannerProps
  extends RewardsBannerConnectorProps,
    RouterProps {}

export const RewardsBanner = (props: IRewardsBannerProps) => {
  const { history, largestValueAccount: account, stayType } = props;
  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  let contentText = undefined;

  const {
    earn,
    lastFour = "",
    productDisplayName: productId = "",
    rewardsBalance,
    customerAccountRole = "",
  } = account || {};
  const { currency, currencyDescription } = rewardsBalance || {};
  let { hotelsMultiplier } = earn || {};

  const earnRate =
    stayType === StayTypesEnum.VacationRentals ? 5 : hotelsMultiplier;

  // TODO remove once earn/credit comes from BE
  const experienceCredit = isLCForNonPremiumCardHoldersEnabled
    ? "$50"
    : isLCForPremiumCardHoldersEnabled
    ? undefined // we don't display experience credit when its LC and PC
    : "$100";

  //if no earn multiplier do not display a banner or if its less than 0
  if (
    !account ||
    !account?.earn?.hotelsMultiplier ||
    (typeof earn === "number" && earn <= 0)
  ) {
    return null;
  }

  // TODO: handle texts properly once the backend is ready
  switch (history.location.pathname) {
    case PATH_HOME.slice(0, -1):
    case PATH_HOME:
    case PATH_VACATION_RENTALS:
      if (!matchesMobile) {
        contentText = (
          <Typography
            variant="subtitle2"
            dangerouslySetInnerHTML={{
              __html: constants.PREMIER_COLLECTION_EARN_BANNER_TEXT({
                firstName,
                earnRate,
                productId,
                lastFour,
                currency: currencyDescription || currency,
                isWithLifestyle: isLCForPremiumCardHoldersEnabled,
                isLifestyleOnly: isLCForNonPremiumCardHoldersEnabled,
                experienceCredit,
                stayType,
                isUserPrimary:
                  customerAccountRole === CustomerAccountRole.Primary,
              }),
            }}
          />
        );
      }
      // hide the banner on mobile search workflow
      else {
        contentText = undefined;
      }
      break;
    case PATH_AVAILABILITY.slice(0, -1):
    case PATH_AVAILABILITY:
    case PATH_SHOP.slice(0, -1):
    case PATH_SHOP:
    case PATH_ANCILLARY_CUSTOMIZE:
    case PATH_VACATION_RENTALS_AVAILABILITY:
    case PATH_VACATION_RENTALS_SHOP.slice(0, -1):
    case PATH_VACATION_RENTALS_SHOP:
      contentText = (
        <Typography
          variant="subtitle2"
          dangerouslySetInnerHTML={{
            __html: constants.PREMIER_COLLECTION_EARN_BANNER_TEXT({
              firstName,
              earnRate,
              productId,
              lastFour,
              currency: currencyDescription || currency,
              isWithLifestyle: isLCForPremiumCardHoldersEnabled,
              isLifestyleOnly: isLCForNonPremiumCardHoldersEnabled,
              experienceCredit,
              stayType,
              isUserPrimary:
                customerAccountRole === CustomerAccountRole.Primary,
            }),
          }}
        />
      );
      break;
  }

  if (!contentText) {
    return null;
  }

  return (
    <GenericBanner
      className="rewards-banner-root"
      content={contentText}
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      isMobile={matchesMobile}
    />
  );
};
