import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { PriceBreakdownDropdown } from "./component";
import {
  getPremierCollectionPricingLineItems,
  getPremierCollectionRewardsAndTotalLineItems,
  getPremierCollectionTripTotalInPrices,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    pricingLineItems: getPremierCollectionPricingLineItems(state),
    rewardsAndTotalLineItems:
      getPremierCollectionRewardsAndTotalLineItems(state),
    tripTotal: getPremierCollectionTripTotalInPrices(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceBreakdownDropdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceBreakdownDropdown = withRouter(
  connector(PriceBreakdownDropdown)
);
