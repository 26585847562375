import React, { useState, useEffect } from "react";
import {
  ActionLink,
  CloseButtonIcon,
  GenericInfoPopup,
  Icon,
  IconName,
  MobileFloatingButton,
  MobilePopoverCard,
  useDeviceTypes,
} from "halifax";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import { PATH_HOME } from "../../../../utils/paths";
import { VRShopErrorModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";
import { VacationRentalShopCallError } from "../../reducer";
import { DesktopCalendarPicker } from "../../../search/components/PremierCollectionSearchControl/components/DesktopCalendarPicker";
import dayjs from "dayjs";
import { Box, Typography } from "@material-ui/core";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../search/components/PremierCollectionSearchControl/components/MonthAndDatePicker";

export interface IAvailabilityErrorModalProps
  extends VRShopErrorModalConnectorProps,
    RouteComponentProps {
  returnToAvailability: () => void;
}

export const VRShopErrorModal = (props: IAvailabilityErrorModalProps) => {
  const {
    hasFailed,
    history,
    fetchVacationRentalShop,
    shopError,
    setOpenDatesModal,
    openDatesModal,
    fromDate,
    untilDate,
    setFromDate,
    setUntilDate,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasChangedDates, setHasChangedDates] = useState(false);
  const [from, setFrom] = useState<Date | null>(fromDate);
  const [until, setUntil] = useState<Date | null>(untilDate);
  const { matchesMobile } = useDeviceTypes();

  const vrUnavailable =
    shopError === VacationRentalShopCallError.NoAvailability;

  useEffect(() => {
    // Ignore unavailable errors since the user can change dates
    // on the cover page.
    if (hasFailed && !vrUnavailable) {
      setIsOpen(true);
    }
  }, [hasFailed, vrUnavailable]);

  useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  useEffect(() => {
    const differentDates = from !== fromDate || until !== untilDate;
    const setBothDates = fromDate && untilDate;

    if (differentDates && setBothDates) {
      setHasChangedDates(true);
    } else {
      setHasChangedDates(false);
    }
  }, [fromDate, untilDate, from, until]);

  const buttons = [
    {
      buttonText: textConstants.GO_BACK_BUTTON,
      buttonWrapperClassName: clsx("vr-shop-error-modal-button", "secondary"),
      onClick: () => {
        setIsOpen(false);
        history.push(PATH_HOME);
      },
    },
    {
      buttonText: textConstants.SEARCH_AGAIN_BUTTON,
      buttonWrapperClassName: clsx("vr-shop-error-modal-button", "primary"),
      onClick: () => {
        setIsOpen(false);
        fetchVacationRentalShop(history);
      },
    },
  ];

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {textConstants.CHOOSE_DATES}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={() => setOpenDatesModal(false)}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };
  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <>
      {matchesMobile && openDatesModal ? (
        <MobilePopoverCard
          open={openDatesModal}
          onClose={() => setOpenDatesModal(false)}
          className="mobile-pc-shop-header-root"
          contentClassName="mobile-shop-header-content-wrapper"
          headerElement={<MobileSearchPopoverHeader />}
        >
          <Box className="mobile-shop-header-content-container">
            <Box className="pc-mobile-calendar-picker-root">
              <MonthAndDatePicker
                viewType={MonthAndDatePickerType.Column}
                from={from}
                until={until}
                setFromDate={setFrom}
                setUntilDate={setUntil}
                className="b2b"
                header={
                  <Box className="premier-collection-mobile-calendar-header">
                    <DatePickerHeader
                      label="Check-in"
                      value={
                        from ? dayjs(from).format("ddd D, MMM") : undefined
                      }
                    />
                    <DatePickerHeader
                      label="Check out"
                      value={
                        until ? dayjs(until).format("ddd D, MMM") : undefined
                      }
                    />
                  </Box>
                }
              />
              {hasChangedDates && (
                <MobileFloatingButton
                  className="mobile-calendar-picker-search-button"
                  disabled={!(!!from && !!until)}
                  onClick={() => {
                    setFromDate(from);
                    setUntilDate(until);
                    setOpenDatesModal(false);
                    fetchVacationRentalShop(history, {
                      forceCallVRAvailability: true,
                    });
                  }}
                  wrapperClassName="b2b"
                >
                  {textConstants.SEARCH_DATES}
                </MobileFloatingButton>
              )}
            </Box>
          </Box>
        </MobilePopoverCard>
      ) : (
        <DesktopCalendarPicker
          {...props}
          open={openDatesModal}
          closePopup={() => {
            setOpenDatesModal(false);
          }}
        />
      )}
      <GenericInfoPopup
        className={clsx("vr-shop-error-modal-root", { mobile: matchesMobile })}
        open={isOpen}
        image={<Icon className="error-icon" name={IconName.UnableToProcess} />}
        title={textConstants.SHOP_ERROR_TITLE}
        subtitle={textConstants.SHOP_ERROR_SUBTITLE}
        buttons={buttons}
        isMobile={matchesMobile}
      />
    </>
  );
};
