import React from "react";

import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import {
  MegaMenuDestination,
  MegaMenuSection,
  MegaMenuSubregion,
} from "redmond";

import { SubregionView } from "../SubregionView";
import { TwoColumnLayout } from "../TwoColumnLayout";

import "./styles.scss";

export interface ISectionViewProps {
  section: MegaMenuSection;
  expandedSubregion?: MegaMenuSubregion;
  setExpanded: (subregion?: MegaMenuSubregion) => void;
  select: (destination: MegaMenuDestination) => void;
}

export const SectionView = ({
  section,
  expandedSubregion,
  setExpanded,
  select,
}: ISectionViewProps) => {
  return (
    <Box className={clsx("section", { titled: section.title != null })}>
      {section.title != null && (
        <Typography className="section-heading" variant="body1">
          {section.title}
        </Typography>
      )}

      <TwoColumnLayout
        className={clsx("section-content")}
        columnClassName="column"
      >
        {section.subregions.map((subregion) => (
          <SubregionView
            subregion={subregion}
            expanded={subregion === expandedSubregion}
            setExpanded={() =>
              setExpanded(
                subregion !== expandedSubregion ? subregion : undefined
              )
            }
            select={select}
          />
        ))}
      </TwoColumnLayout>
    </Box>
  );
};
