import React from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ROOM_COUNT_SELECTION_APPLY_BUTTON_LABEL,
  ROOM_COUNT_SELECTION_HEADING_TEXT,
} from "./textConstants";
import { ActionButton, ActionLink, Icon, IconName } from "halifax";
import {
  VacationRentalsRoomCountOptionValue,
  VacationRentalsRoomCounts,
} from "../../../../reducer";

interface RoomCountOption {
  label: string;
  value: VacationRentalsRoomCountOptionValue | "guests";
  icon: IconName;
}

const ROOM_COUNT_OPTIONS: RoomCountOption[] = [
  {
    label: "Minimum beds",
    value: VacationRentalsRoomCountOptionValue.MIN_BEDS,
    icon: IconName.BedSideFilled,
  },
  {
    label: "Minimum bedrooms",
    value: VacationRentalsRoomCountOptionValue.MIN_BEDROOMS,
    icon: IconName.BedFilled,
  },
  {
    label: "Minimum bathrooms",
    value: VacationRentalsRoomCountOptionValue.MIN_BATHROOMS,
    icon: IconName.BathtubFilled,
  },
];

const ROOM_COUNT_OPTIONS_WITH_GUESTS: RoomCountOption[] = [
  ...ROOM_COUNT_OPTIONS,
  {
    label: "Guests",
    value: VacationRentalsRoomCountOptionValue.MIN_GUESTS,
    icon: IconName.B2BUser,
  },
];

export interface IRoomCountSelectionProps {
  counts: VacationRentalsRoomCounts;
  setCounts: React.Dispatch<React.SetStateAction<VacationRentalsRoomCounts>>;
  includeGuests?: boolean;
  onClickApply?: () => void;
  hasValueToApply?: boolean;
  isMobile?: boolean;
}

export const MAX_COUNT = 100;

export const RoomCountSelection = ({
  counts,
  setCounts,
  includeGuests,
  onClickApply,
  hasValueToApply,
  isMobile = false,
}: IRoomCountSelectionProps) => {
  const options = includeGuests
    ? ROOM_COUNT_OPTIONS_WITH_GUESTS
    : ROOM_COUNT_OPTIONS;

  return (
    <div
      className={clsx("premium-stays-room-count-selection-root", {
        mobile: isMobile,
      })}
    >
      <Typography
        variant="h4"
        className={clsx("filter-heading", "room-count-selection-heading")}
      >
        {ROOM_COUNT_SELECTION_HEADING_TEXT}
      </Typography>
      <div className="room-count-selection-controls-container">
        {options.map((option) => {
          const count = counts[option.value];
          const isMinusDisabled = count <= 0;
          const isPlusDisabled = count >= MAX_COUNT;

          return (
            <div className="count-control">
              <div className="count-control-icon-label">
                <Icon name={option.icon} />
                <Typography className="count-control-label">
                  {option.label}
                </Typography>
              </div>
              <div className="count-control-buttons">
                <ActionLink
                  label={`Decrease ${option.label}`}
                  className={clsx("count-picker-button", "minus")}
                  onClick={() => {
                    if (!isMinusDisabled) {
                      setCounts((prevCounts) => ({
                        ...prevCounts,
                        [option.value]: count - 1,
                      }));
                    }
                  }}
                  content={
                    <Box
                      className={clsx("button-content-wrapper", {
                        disabled: isMinusDisabled,
                      })}
                    >
                      <Icon
                        name={IconName.MinusIcon}
                        className={clsx("button-content minus-icon", {
                          disabled: isMinusDisabled,
                        })}
                      />
                    </Box>
                  }
                  disabled={isMinusDisabled}
                />
                <div className="count-number-container">
                  <Typography
                    className={clsx("count-number", {
                      disabled: isMinusDisabled,
                    })}
                  >
                    {count}
                  </Typography>
                </div>
                <ActionLink
                  label={`Increase ${option.label}`}
                  className={clsx("count-picker-button", "plus")}
                  onClick={() => {
                    if (!isPlusDisabled) {
                      setCounts((prevCounts) => ({
                        ...prevCounts,
                        [option.value]: count + 1,
                      }));
                    }
                  }}
                  disabled={isPlusDisabled}
                  content={
                    <Box
                      className={clsx("button-content-wrapper", {
                        disabled: isPlusDisabled,
                      })}
                    >
                      <Icon
                        name={IconName.PlusIcon}
                        className={clsx("button-content plus-icon", {
                          disabled: isPlusDisabled,
                        })}
                      />
                    </Box>
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
      {onClickApply && (
        <ActionButton
          className="room-count-selection-apply-button"
          defaultStyle="h4r-primary"
          message={ROOM_COUNT_SELECTION_APPLY_BUTTON_LABEL}
          onClick={onClickApply}
          disabled={!hasValueToApply}
        />
      )}
    </div>
  );
};
