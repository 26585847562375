export const TRAVELER_INFO_TITLE = "Step 1: Add Traveler";
export const TRAVELER_INFO_TITLE_UPDATED =
  "Select or add the primary traveler for this reservation.";
export const TRAVELER_INFO_SUBTITLE_MOBILE =
  "Select or add the primary traveler for this hotel reservation.";
export const TRAVELER_INFO_SUBTITLE =
  "Your booking will be reserved under the primary traveler’s name.";
export const FREQUENT_FLYER_TITLE = "Frequent Flyer Details";
export const ADDITIONAL_INFO_TITLE = "Additional Information";
export const ADULT_TITLE = "Adult";
export const CHILD_TITLE = "Child";
export const INFANT_SEAT_TITLE = "Infant";
export const INFANT_LAP_TITLE = "Infant";
export const UPDATE_TEXT = "Updating Passengers";
export const VALIDATE_TEXT = "Validating Passengers";
export const ADD_TRAVELERS_TEXT = "Add Primary Traveler";
export const ADD_TRAVELERS_TEXT_UPDATED = "Select or Add Traveler";
export const ADD_TRAVELER_INFO_TEXT = "Add Traveler Information";
export const TRAVELER_INFO_TEXT = "Add Primary Traveler";
export const ADD_TRAVELER_ERROR_MESSAGE =
  "Add or choose one traveler to continue.";
