import { ensureExhaustive } from "halifax";
import React from "react";
import { LodgingCollectionEnum, StayTypesEnum } from "redmond";

export const FIND_MORE_RESULTS_TITLE_TEXT = "Find more hotels";
export const FIND_MORE_RESULTS_WITH_FILTER_SUBTITLE_TEXT =
  "Adjust your dates or reset filters to see more results.";
export const FIND_MORE_RESULTS_SUBTITLE_TEXT =
  "Adjust your dates to see more results.";
export const ADJUST_DATES_TEXT = "Adjust Dates";
export const RESET_FILTERS_TEXT = "Reset Filters";

export const VIEW_HOTELS_ON_MAP = "View Hotels on Map";
export const VIEW_HOMES_ON_MAP = "View Homes on Map";

export const COUNT_HEADING_TEXT = (
  count: number,
  searchLocation: string,
  isLoading: boolean,
  isWithLifestyleCollection: boolean,
  isOnlyLifestyleCollection: boolean,
  stayType: StayTypesEnum
) => {
  const collectionLabel = isWithLifestyleCollection
    ? "Premier & Lifestyle Collections"
    : (isOnlyLifestyleCollection ? "Lifestyle" : "Premier") + " Collection";
  return (
    <span aria-hidden={true}>
      {isLoading ? (
        "Loading"
      ) : (
        <>
          Showing <strong>({count})</strong>
        </>
      )}{" "}
      {stayType === StayTypesEnum.Hotels ? "hotel" : "vacation rental"}
      {count === 1 ? "" : "s"} from our {collectionLabel} in{" "}
      <strong>{searchLocation}</strong>
      {isLoading ? "..." : ""}
    </span>
  );
};

export const EARN_BENEFITS_TEXT = (
  earnMultiplier: number,
  cardName?: string,
  isLifestyleCollection?: boolean
) => (
  <>
    Earn <strong>{earnMultiplier}X miles</strong> on your{" "}
    {cardName ? `${cardName} ` : ""}card and enjoy a{" "}
    <strong>{isLifestyleCollection ? "$50" : "$100"} experience credit</strong>{" "}
    to use during your stay.
  </>
);
export const ADDITIONAL_BENEFITS_TEXT = (isVacationRental?: boolean) => {
  return (
    <>
      Plus, enjoy other premium benefits like{" "}
      <strong>
        {isVacationRental ? "" : "room upgrades,"} early check-in and late
        checkout
      </strong>{" "}
      when available.
    </>
  );
};

export const getMinNightStayHotelUnavailTitle = (
  nightCount: number | null,
  minNightOfStay?: number
) => {
  if (nightCount && minNightOfStay && nightCount < minNightOfStay) {
    return `Hotel unavailable for ${nightCount}-night stays`;
  } else {
    return undefined;
  }
};

export const getMinNightStayHotelUnavailMessage = (
  nightCount: number | null,
  minNightOfStay?: number
) => {
  if (nightCount && minNightOfStay && nightCount < minNightOfStay) {
    return `Minimum ${minNightOfStay}-night stay required`;
  } else {
    return undefined;
  }
};

export const getEarnTagText = (earnRate: string | number, currency: string) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return `<span class="font-bold">Earn ${earnRate}${multiplier}</span> ${currency} on hotels`;
};

export const getBenefitsSummary = (
  lodgingCollection: LodgingCollectionEnum,
  cardName?: string,
  earnMultiplier?: number
) => {
  switch (lodgingCollection) {
    case LodgingCollectionEnum.Lifestyle:
      return (
        <>
          Earn{" "}
          <strong>{earnMultiplier ?? "5"}X miles</strong>{" "}
          on your {cardName ?? ""} card and enjoy a{" "}
          <strong>$50 experience credit</strong> to use during your stay.
        </>
      );
    case LodgingCollectionEnum.Premier:
      return (
        <>
          Earn <strong>{earnMultiplier ?? "5"}X miles</strong> on your {cardName ?? ""} card and enjoy a{" "}
          <strong>$100 experience credit</strong> to use during your stay, daily
          breakfast for 2, and complimentary WiFi.
        </>
      );
    case LodgingCollectionEnum.NoCollection:
      return <></>;
    default: ensureExhaustive(lodgingCollection)
  }
};

export const getAdditionalBenefitsSummary = () => (
  <>
    Plus, enjoy other premium benefits like{" "}
    <strong>room upgrades, early check-in and late checkout</strong> when
    available
  </>
);
