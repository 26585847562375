import React from "react";

import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import * as H from "history";

import { Icon, IconName } from "halifax";
import {
  IIdLodgings,
  LAUNCHED_MOBILE_CALENDAR,
  LaunchedMobileCalendarProperties,
  StayTypesEnum,
} from "redmond";

import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import {
  PATH_AVAILABILITY,
  PATH_VACATION_RENTALS_AVAILABILITY,
} from "../../../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../../../shop/utils/queryStringHelpers";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../PremierCollectionSearchControl/components/MonthAndDatePicker";
import { HotelSearchButton } from "../../../PremierCollectionSearchControl/components/SearchButton";
import { MobileCalendarPickerConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface IMobileCalendarPickerProps
  extends MobileCalendarPickerConnectorProps {
  focusedMonthIndex?: number;
  onComplete?: () => void;
  onSelectDates?: (from: Date | null, until: Date | null) => void;
  includesVacationRentals?: boolean;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    fromDate,
    untilDate,
    location,
    adultsCount,
    petsCount,
    childrenCount,
    setFromDate,
    setUntilDate,
    focusedMonthIndex,
    onComplete,
    onSelectDates,
    stayType,
    includesVacationRentals,
    searchVacationRentalsLocation,
  } = props;

  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  React.useEffect(() => {
    const properties: LaunchedMobileCalendarProperties = {
      funnel: "premier_collection",
      url: window.location.pathname,
    };
    trackEvent({ eventName: LAUNCHED_MOBILE_CALENDAR, properties });
  }, []);

  const handleClickDone = (history: H.History) => {
    setFromDate(from);
    setUntilDate(until);
    onSelectDates?.(from, until);
    stayType === StayTypesEnum.VacationRentals && includesVacationRentals
      ? history.push(
          `${PATH_VACATION_RENTALS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
            {
              location: (searchVacationRentalsLocation?.id as IIdLodgings)
                .lodgingSelection.searchTerm,
              fromDate: from,
              untilDate: until,
              adultsCount,
              childrenCount,
              petsCount,
            }
          )}`
        )
      : history.push(
          `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery({
            location: (location?.id as IIdLodgings).lodgingSelection.searchTerm,
            fromDate: from,
            untilDate: until,
            adultsCount,
            childrenCount,
          })}`
        );

    onComplete && onComplete();
  };

  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="premier-collection-mobile-calendar-picker-root redesign-v3">
      <MonthAndDatePicker
        className="b2b"
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setFromDate={setFrom}
        setUntilDate={setUntil}
        from={from}
        until={until}
        header={
          <Box className="premier-collection-mobile-calendar-header">
            <DatePickerHeader
              label={textConstants.CHECK_IN}
              value={from ? dayjs(from).format("ddd D, MMM") : undefined}
            />
            <DatePickerHeader
              label={textConstants.CHECK_OUT}
              value={until ? dayjs(until).format("ddd D, MMM") : undefined}
            />
          </Box>
        }
      />
      {from && until && (
        <HotelSearchButton
          className={clsx("mobile-calendar-picker-search-button", "b2b")}
          message={
            stayType === StayTypesEnum.VacationRentals &&
            includesVacationRentals
              ? textConstants.SEARCH_VR_TEXT
              : textConstants.SEARCH_TEXT
          }
          onClick={(history: H.History) => {
            handleClickDone(history);
          }}
          from={from}
          until={until}
        />
      )}
    </Box>
  );
};
