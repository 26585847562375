import { actions } from "../actions";
import {
  CorpLodging,
  CorpRoomInfoProducts,
  Lodging,
  OpaqueValue,
  RoomInfoProducts,
  TrackingProperties,
  CancellationSummary,
} from "redmond";
import {
  FETCH_PREMIER_COLLECTION_SHOP,
  SET_PREMIER_COLLECTION_SHOP_RESULTS,
  SELECT_ROOM_TYPE,
  ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_FAILURE,
  SELECT_LODGING,
  SET_PREMIER_COLLECTION_SHOP_CALL_STATE_FAILED,
  SET_PREMIER_COLLECTION_SHOP_PROGRESS,
  SET_IS_FROM_HOTEL_AVAILABILITY,
} from "../actions/constants";
export interface IPremierCollectionShopState {
  selectedLodging: Lodging | CorpLodging | null;
  shopRequestId: OpaqueValue | null;
  roomInfoProducts: RoomInfoProducts[] | CorpRoomInfoProducts[];
  chosenRoomInfoIndex: number | null;
  chosenProductIndex: number | null;
  premierCollectionShopCallState: PremierCollectionShopCallState;
  premierCollectionShopCallError: PremierCollectionShopCallError | null;
  progress: PremierCollectionShopStep;
  isFromHotelAvailability: boolean;
  hotelShopTrackingPropertiesV2: TrackingProperties | null;
  cancellationSummary: CancellationSummary | null;
}

export enum PremierCollectionShopStep {
  HotelInfo,
  ChooseRoom,
}

export enum PremierCollectionShopCallState {
  NotCalled = "NotCalled",
  InProcess = "InProcess",
  Success = "Success",
  Failed = "Failed",
  FailureAcknowledged = "FailureAcknowledged",
}

export enum PremierCollectionShopCallError {
  NoAvailability = "NoAvailability",
  Unknown = "Unknown",
}

const initialState: IPremierCollectionShopState = {
  selectedLodging: null,
  shopRequestId: null,
  roomInfoProducts: [],
  chosenRoomInfoIndex: null,
  chosenProductIndex: null,
  premierCollectionShopCallState: PremierCollectionShopCallState.NotCalled,
  premierCollectionShopCallError: null,
  progress: PremierCollectionShopStep.HotelInfo,
  isFromHotelAvailability: false,
  hotelShopTrackingPropertiesV2: null,
  cancellationSummary: null,
};

export const reducer = (
  state: IPremierCollectionShopState = initialState,
  action: actions.PremierCollectionShopActions
): IPremierCollectionShopState => {
  switch (action.type) {
    case FETCH_PREMIER_COLLECTION_SHOP:
      return {
        ...state,
        roomInfoProducts: [],
        premierCollectionShopCallState:
          PremierCollectionShopCallState.InProcess,
        premierCollectionShopCallError: null,
      };

    case SET_IS_FROM_HOTEL_AVAILABILITY:
      return {
        ...state,
        isFromHotelAvailability: action.isFromHotelAvailability,
      };

    case SET_PREMIER_COLLECTION_SHOP_RESULTS:
      return {
        ...state,
        chosenRoomInfoIndex: null,
        chosenProductIndex: null,
        premierCollectionShopCallState: action.premierCollectionShopCallState,
        premierCollectionShopCallError: action.premierCollectionShopCallError,
        ...action.payload,
        hotelShopTrackingPropertiesV2:
          action.payload?.trackingPropertiesV2 ??
          state.hotelShopTrackingPropertiesV2,
      };

    case SET_PREMIER_COLLECTION_SHOP_CALL_STATE_FAILED:
      return {
        ...state,
        premierCollectionShopCallState: PremierCollectionShopCallState.Failed,
        premierCollectionShopCallError: action.premierCollectionShopCallError,
      };

    case SELECT_ROOM_TYPE:
      return {
        ...state,
        chosenRoomInfoIndex: action.roomInfoIndex,
        chosenProductIndex: action.productIndex,
      };

    case SELECT_LODGING:
      return {
        ...state,
        selectedLodging: action.lodging,
      };

    case ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_FAILURE:
      return {
        ...state,
        premierCollectionShopCallState:
          PremierCollectionShopCallState.FailureAcknowledged,
      };
    case SET_PREMIER_COLLECTION_SHOP_PROGRESS: {
      return {
        ...state,
        progress: action.progress,
      };
    }

    default:
      return { ...state };
  }
};

export * from "./selectors";
