import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../reducers/types";
import { fetchRewardsAccounts } from "../rewards/actions/actions";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../rewards/reducer";
import { withRouter } from "react-router";
import { getStayType } from "../search/reducer";
import { VacationRentalShop } from "./component";
import { fetchVacationRentalShop } from "./actions/actions";
import {
  getVacationRentalShopCallState,
  getVacationRentalShopSelectedListing,
  getViewedVacationRentalDetailsProperties,
  VacationRentalShopCallState,
} from "./reducer";
import { setStayType } from "../search/actions/actions";
import { listPaymentMethods } from "../availability/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const callState = getVacationRentalShopCallState(state);
  return {
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    rewardsAccounts: getRewardsAccounts(state),
    stayType: getStayType(state),
    selectedListing: getVacationRentalShopSelectedListing(state),
    fetchedVacationRentalDetails:
      callState === VacationRentalShopCallState.Failed ||
      callState === VacationRentalShopCallState.Success,
    viewedVacationRentalDetailsProperties:
      getViewedVacationRentalDetailsProperties(state),
  };
};

const mapDispatchToProps = {
  fetchRewardsAccounts,
  fetchVacationRentalShop,
  setStayType,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VacationRentalShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedVacationRentalShop = withRouter(
  connector(VacationRentalShop)
);
