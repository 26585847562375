import React from "react";

import { Badge, Box } from "@material-ui/core";
import { isEqual } from "lodash-es";
import { RouteComponentProps } from "react-router";

import {
  ActionLink,
  BackButton,
  DatePickerButton,
  Header,
  Icon,
  IconName,
  MobileSearchFieldButton,
} from "halifax";
import { IIdLodgings, IResult, StayTypesEnum } from "redmond";

import {
  AVAILABLE,
  VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";
import {
  PATH_AVAILABILITY,
  PATH_HOME,
  PATH_VACATION_RENTALS_AVAILABILITY,
} from "../../../../utils/paths";
import { MobileCalendarPickerModal } from "../../../search/components/MobilePremierCollectionSearchControl/components/MobileCalendarPickerModal";
import { MobileLocationSearchModal } from "../../../search/components/MobilePremierCollectionSearchControl/components/MobileLocationSearchModal";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import {
  PremierCollectionAvailabilitySortOption,
  initialFilterState,
  initialState,
} from "../../reducer";
import { AllFiltersModal } from "../AvailabilityFilter/components/AllFiltersModal";
import { MobileAvailabilitySearchControlConnectorProps } from "./container";

import "./styles.scss";

export interface IMobileAvailabilitySearchControlProps
  extends RouteComponentProps,
    MobileAvailabilitySearchControlConnectorProps {
  isLifestyleCollection?: boolean;
  includesLifestyleCollection?: boolean;
}

export const MobileAvailabilitySearchControl = (
  props: IMobileAvailabilitySearchControlProps
) => {
  const {
    // Context
    stayType,
    searchLocation,
    history,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,

    // Filters
    sortOption,
    maxPrice,
    starRating,
    roomCounts,
    hotelAmenities,
    vacationRentalAmenities,
    // Results
    lodgings,
    vrListings,
    // Experiments
    isLifestyleCollection = false,
    includesLifestyleCollection = false,
  } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openLocationModal, setOpenLocationModal] = React.useState(false);
  const [openCalendarModal, setOpenCalendarModal] = React.useState(false);
  const [localLocation, setLocalLocation] = React.useState<IResult | null>(
    searchLocation
  );

  const expState = useExperiments();

  const VRForPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForPremiumCardHoldersEnabled = React.useMemo(
    () => VRForPremiumCardHolders === AVAILABLE,
    [VRForPremiumCardHolders]
  );

  const VRForNonPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForNonPremiumCardHoldersEnabled = React.useMemo(
    () => VRForNonPremiumCardHolders === AVAILABLE,
    [VRForNonPremiumCardHolders]
  );

  const availableLodgingCount = React.useMemo(
    () =>
      lodgings?.filter((lodging) => lodging.available !== false).length ?? 0,
    [lodgings]
  );

  const availableVacationRentalsCount = React.useMemo(
    () => vrListings?.length ?? 0,
    [vrListings]
  );

  React.useEffect(() => {
    setLocalLocation(searchLocation);
  }, [searchLocation]);

  const sortOptionApplied =
    sortOption !== PremierCollectionAvailabilitySortOption.Recommended;
  const maxPriceApplied = maxPrice !== initialFilterState.maxPrice;

  const roomCountApplied =
    stayType === StayTypesEnum.VacationRentals &&
    !isEqual(roomCounts, initialState.vacationRentalsRoomCounts);
  const vacationRentalAmenitiesApplied =
    stayType === StayTypesEnum.VacationRentals &&
    !isEqual(vacationRentalAmenities, initialState.vacationRentalsAmenities);
  const hotelAmenitiesApplied =
    stayType === StayTypesEnum.Hotels &&
    !isEqual(hotelAmenities, initialState.amenities);
  const hotelStarRatingApplied =
    stayType === StayTypesEnum.Hotels &&
    !isEqual(starRating, initialState.starRatings);

  const appliedHotelFiltersCount = [
    sortOptionApplied,
    maxPriceApplied,
    hotelAmenitiesApplied,
    hotelStarRatingApplied,
  ].filter((isSet) => isSet).length;

  const appliedVRFiltersCount = [
    sortOptionApplied,
    roomCountApplied,
    vacationRentalAmenitiesApplied,
  ].filter((isSet) => isSet).length;

  const appliedFiltersCount =
    stayType === StayTypesEnum.Hotels
      ? appliedHotelFiltersCount
      : appliedVRFiltersCount;

  const isReadyToSearch = localLocation && fromDate && untilDate && adultsCount;

  const onSearchAgain = () => {
    if (
      localLocation &&
      localLocation.id &&
      (localLocation?.id as IIdLodgings).lodgingSelection.searchTerm
    ) {
      history.push(
        `${
          stayType === StayTypesEnum.VacationRentals
            ? PATH_VACATION_RENTALS_AVAILABILITY
            : PATH_AVAILABILITY
        }${transformToStringifiedAvailabilityQuery({
          location: (localLocation?.id as IIdLodgings).lodgingSelection
            .searchTerm,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
        })}`
      );
    }
  };

  return (
    <>
      <Header
        className="mobile-premier-collection-availability-search-header"
        left={
          <BackButton
            className="mobile-premier-collection-availability-go-back"
            onClick={() => history.push(PATH_HOME)}
          />
        }
        center={
          <Box className="mobile-premier-collection-availability-search-and-date">
            <MobileSearchFieldButton
              value={searchLocation?.label}
              renderPopup={() => (
                <MobileLocationSearchModal
                  openLocationModal={openLocationModal}
                  setOpenLocationModal={setOpenLocationModal}
                  history={history}
                  searchOnContinue
                  onContinue={
                    isReadyToSearch
                      ? () => {
                          onSearchAgain();
                        }
                      : undefined
                  }
                  setLocalLocation={setLocalLocation}
                  isLifestyleCollection={isLifestyleCollection}
                  includesLifestyleCollection={includesLifestyleCollection}
                />
              )}
              onClick={() => setOpenLocationModal(true)}
            />
            <DatePickerButton
              startDate={fromDate}
              endDate={untilDate}
              classes={["hotel-inline-juncture-input"]}
              renderCalendarPopup={() => (
                <MobileCalendarPickerModal
                  openCalendarModal={openCalendarModal}
                  setOpenCalendarModal={setOpenCalendarModal}
                  searchOnContinue
                  includesVacationRentals={
                    isVRForPremiumCardHoldersEnabled ||
                    isVRForNonPremiumCardHoldersEnabled
                  }
                />
              )}
              onClick={() => setOpenCalendarModal(true)}
              dateFormat={"MMM D"}
              variant="minimal"
            />
          </Box>
        }
        right={
          (stayType === StayTypesEnum.Hotels
            ? availableLodgingCount
            : availableVacationRentalsCount) > 1 ? (
            <ActionLink
              className="mobile-premier-collection-availability-edit-filters"
              onClick={() => setOpenModal(true)}
              content={
                <Badge
                  className="filter-count"
                  badgeContent={appliedFiltersCount > 0 ? "" : undefined}
                >
                  <Icon name={IconName.Settings} />
                </Badge>
              }
            />
          ) : (
            <Box className="mobile-premier-collection-availability-edit-filters" />
          )
        }
      />
      <AllFiltersModal isMobile open={openModal} setOpen={setOpenModal} />
    </>
  );
};
