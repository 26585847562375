import dayjs from "dayjs";
import * as H from "history";
import queryStringParser from "query-string";
import { call, put, putResolve, select } from "redux-saga/effects";

import {
  AvailabilityRequest,
  AvailabilityRequestEnum,
  AvailabilityResponse,
  IIdLodgings,
  IResponse,
  IResult,
  ITrackingProperties,
  LC_ENTRY,
  LocationDescriptorEnum,
  LodgingCollectionEnum,
  LodgingSelectionEnum,
  PC_ENTRY,
  PlatformEnum,
  PremierCollectionEntryProperties,
} from "redmond";

import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { fetchPremierCollectionAvailability as fetchAvailability } from "../../../api/v0/availability/fetchPremierCollectionAvailability";
import { fetchLocations } from "../../../api/v0/search/fetchLocations";
import { IStoreState } from "../../../reducers/types";
import Logger from "../../../utils/logger";
import { PATH_AVAILABILITY, PATH_HOME } from "../../../utils/paths";
import { isMobile } from "../../../utils/userAgent";
import { actions as bookActions } from "../../book/actions";
import { actions as searchActions } from "../../search/actions";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getLocation,
  getPremierCollectionEntryProperties,
  getUntilDate,
} from "../../search/reducer";
import { actionTypes, actions } from "../actions";
import { getPremierCollectionAvailabilityNextPageToken } from "../reducer";

export function* fetchPremierCollectionAvailabilitySaga(
  action: actions.IFetchPremierCollectionAvailability
) {
  try {
    let requestBody: AvailabilityRequest;
    const state: IStoreState = yield select();
    yield put(bookActions.redoSearch());

    switch (action.type) {
      case actionTypes.FETCH_INITIAL_PREMIER_COLLECTION_AVAILABILITY: {
        const requestData: {
          fromDate: Date;
          untilDate: Date;
          location: IResult;
          adultsCount: number;
          children: number[];
        } = yield call(
          getPremierCollectionAvailabilityRequestParameters,
          action
        );
        const {
          fromDate,
          untilDate,
          location,
          adultsCount: adults,
          children,
        } = requestData;

        // If we received empty request data, we are redirecting and should not make the request.
        if (JSON.stringify(requestData) === "{}") return;
        if (action.mapBounds != null) {
          yield put(searchActions.setLocation(null));
        }

        requestBody = {
          lodgingSelection:
            action.mapBounds != null
              ? {
                  LodgingSelection: LodgingSelectionEnum.Location,
                  descriptor: {
                    LocationDescriptor: LocationDescriptorEnum.BoundingBox,
                    ...action.mapBounds,
                  },
                }
              : (location?.id as IIdLodgings).lodgingSelection,
          stayDates: {
            from: dayjs(fromDate).format("YYYY-MM-DD"),
            until: dayjs(untilDate).format("YYYY-MM-DD"),
          },
          guests: { adults, children },
          platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
          progressiveConfig: {},
          excludeNonLuxuryLodgings: true,
          lodgingCollection: action.lodgingCollection,
          AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
        };
        break;
      }
      case actionTypes.FETCH_MORE_PREMIER_COLLECTION_AVAILABILITY: {
        const nextPageToken =
          getPremierCollectionAvailabilityNextPageToken(state);

        if (!nextPageToken) return;

        requestBody = {
          moreToken: nextPageToken,
          progressiveConfig: {},
          excludeNonLuxuryLodgings: true,
          lodgingCollection: action.lodgingCollection,
          AvailabilityRequest: AvailabilityRequestEnum.FollowUpSearch,
        };

        break;
      }
    }

    const availabilityResponse: AvailabilityResponse = yield fetchAvailability(
      requestBody
    );

    yield put(
      actions.setPremierCollectionAvailabilityResults({
        payload: availabilityResponse,
      })
    );

    const properties: ITrackingProperties<PremierCollectionEntryProperties> =
      yield select(getPremierCollectionEntryProperties);

    trackEvent({
      eventName:
        action.lodgingCollection === LodgingCollectionEnum.Lifestyle
          ? LC_ENTRY
          : PC_ENTRY,
      properties: {
        ...properties.properties,
        number_of_properties: availabilityResponse.lodgings.length,
      },
      encryptedProperties: properties.encryptedProperties,
    });
  } catch (e) {
    yield put(actions.setPremierCollectionAvailabilityCallStateFailed());
    Logger.debug(e);
  }
}
export interface IPremierCollectionAvailabilityParsedQuery {
  locationName: string;
  fromDate: Date;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
}

const getExistingStateVariables = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  location: getLocation(state),
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
});

const shouldRedirect = ({ fromDate, untilDate, location }: any) =>
  !fromDate || !untilDate || !location;

const parseQueryString = (
  history: H.History
): IPremierCollectionAvailabilityParsedQuery => {
  const queryString = history?.location?.search || "";

  const { locationName, fromDate, untilDate, adultsCount, childrenCount } =
    queryStringParser.parse(queryString);

  return {
    locationName: locationName as string,
    fromDate: dayjs(fromDate as string).toDate(),
    untilDate: dayjs(untilDate as string).toDate(),
    adultsCount: parseInt(adultsCount as string),
    childrenCount: parseInt(childrenCount as string),
  };
};

function* getPremierCollectionAvailabilityRequestParameters(
  fetchPremierCollectionAvailability: actions.IFetchPremierCollectionAvailability
) {
  const state: IStoreState = yield select();
  const history = fetchPremierCollectionAvailability.history;

  let { fromDate, untilDate, location, adultsCount, children } =
    getExistingStateVariables(state);

  const parsedQueryString = parseQueryString(history);

  if (
    !fromDate ||
    !untilDate ||
    !location ||
    !adultsCount ||
    (location?.id as IIdLodgings).lodgingSelection.searchTerm !==
      parsedQueryString.locationName
  ) {
    const { correspondingLocation } = yield fetchLocation(parsedQueryString);

    [fromDate, untilDate, location, adultsCount, children] = [
      parsedQueryString.fromDate,
      parsedQueryString.untilDate,
      correspondingLocation,
      parsedQueryString.adultsCount || 2,
      parsedQueryString.childrenCount > 0
        ? new Array(parsedQueryString.childrenCount).fill(17)
        : [],
    ];

    // If we know we are missing the data we should redirect the user home rather than make a bad request.
    // TODO: this should probably be monitored in the future. If we get a lot of these something is likely wrong.
    if (
      shouldRedirect({
        fromDate,
        untilDate,
        location,
        adultsCount,
      })
    ) {
      if (
        fetchPremierCollectionAvailability.history.location.pathname ===
        PATH_AVAILABILITY
      )
        fetchPremierCollectionAvailability.history.push(PATH_HOME);
      return {};
    }

    yield putResolve(searchActions.setFromDate(fromDate));
    yield putResolve(searchActions.setUntilDate(untilDate));
    yield putResolve(searchActions.setLocation(location));
    yield putResolve(
      searchActions.setOccupancyCounts({
        adults: adultsCount,
        children,
      })
    );
  }

  yield put(actions.setSearchedDates(fromDate!, untilDate!));
  yield put(actions.setSearchedLocationResult(location));
  yield put(
    actions.setSearchedOccupancyCounts({ adults: adultsCount, children })
  );

  return {
    fromDate,
    untilDate,
    location,
    adultsCount,
    children,
  };
}

function* fetchLocation(
  parsedQueryString: IPremierCollectionAvailabilityParsedQuery
) {
  if (!parsedQueryString.locationName)
    return { correspondingLocation: undefined };

  const { categories: locationCategories }: IResponse = yield fetchLocations();
  const correspondingLocation = locationCategories.flatMap((category) =>
    category.results.find((result) =>
      result.label
        .toLowerCase()
        .includes(parsedQueryString.locationName.toLowerCase())
    )
  );

  return { correspondingLocation: correspondingLocation[0] };
}
