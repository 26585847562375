import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getPetsCount,
  getStayType,
  getUntilDate,
} from "../../../search/reducer";
import { fetchTravelWalletDetails } from "../../../travel-wallet/actions/actions";
import { getTravelWalletOffers } from "../../../travel-wallet/reducer";
import {
  PremierCollectionShopCallState,
  getPremierCollectionShopCallState,
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopSelectedAvailability,
} from "../../../shop/reducer";
import { DesktopRewardsHeader } from "./component";
import { getVacationRentalShopSelectedListing } from "../../../vacation-rental-shop/reducer";

const mapStateToProps = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  lodging: getPremierCollectionShopSelectedAvailability(state),
  premierCollectionShopCallInProgress:
    getPremierCollectionShopCallState(state) ===
    PremierCollectionShopCallState.InProcess,
  chosenProduct: getPremierCollectionShopChosenProduct(state),
  adultsCount: getAdultsCount(state),
  childrenCount: getChildrenCount(state),
  travelOffers: getTravelWalletOffers(state),
  selectedListing: getVacationRentalShopSelectedListing(state),
  petsCount: getPetsCount(state),
  stayType: getStayType(state),
});

export const mapDispatchToProps = {
  fetchTravelWalletDetails: fetchTravelWalletDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type DesktopRewardsHeaderConnectorProps = ConnectedProps<typeof connector>;

export interface IDesktopRewardsHeaderProps
  extends DesktopRewardsHeaderConnectorProps,
    RouteComponentProps {
  variant?: "shop" | "customize";
  isVR?: boolean;
}

export const ConnectedDesktopRewardsHeader = withRouter(
  connector(DesktopRewardsHeader)
);
