import React from "react";
import {
  MobilePopoverCard,
  PassengerCountPicker,
  MobileOutlinedTextInput,
  Icon,
  IconName,
  CloseButtonIcon,
  ActionLink,
  PassengerCountPickerType,
  ButtonWrap,
} from "halifax";
import { Box, Typography } from "@material-ui/core";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { MobileOccupancySelectionConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";
import clsx from "clsx";
import { GuestsSelection, StayTypesEnum } from "redmond";
export const OccupancySelection = (
  props: MobileOccupancySelectionConnectorProps
) => {
  const { adultsCount, children, setOccupancyCounts, stayType, petsCount } =
    props;
  const [roomsCount, setRoomsCount] = React.useState(1);
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    React.useState(false);
  const [petsInfoPopoverOpen, setPetsInfoPopoverOpen] = React.useState(false);

  const renderCloseModalButton = () => {
    return (
      <ActionLink
        className="passenger-count-popup-close-button"
        onClick={() => setOpenPassengerCountPicker(false)}
        content={<CloseButtonIcon className="close-button-icon" />}
        label="Close"
        showTappableArea={true}
      />
    );
  };

  return (
    <Box className="mobile-occupancy-selection">
      <MobileOutlinedTextInput
        className="num-traveler-count"
        prefixString={textConstants.INPUT_LABEL(
          adultsCount,
          children,
          petsCount,
          stayType === StayTypesEnum.VacationRentals
        )}
        icon={faUser}
        customEndIcon={<Icon name={IconName.B2BEditPencil} />}
        showCloseIcon={false}
        onClick={() => setOpenPassengerCountPicker(true)}
        value={""}
      />
      <MobilePopoverCard
        open={openPassengerCountPicker}
        className={clsx("mobile-passenger-count-picker-popup", "b2b")}
        contentClassName="mobile-passenger-count-picker-popup-container"
        centered={true}
        onClose={() => setOpenPassengerCountPicker(false)}
        topRightButton={renderCloseModalButton()}
      >
        <PassengerCountPicker
          unlimited={stayType === StayTypesEnum.VacationRentals}
          titles={{
            ...textConstants.MODAL_TITLES,
            ...(stayType === StayTypesEnum.VacationRentals
              ? {
                  petsTitle: "Pets",
                  petsSubtitle: (
                    <>
                      <ButtonWrap onClick={() => setPetsInfoPopoverOpen(true)}>
                        <span className="pets-info-tooltip-content">
                          {textConstants.ADDITIONAL_FEE_TEXT}
                          <Icon name={IconName.InfoCircle} />
                        </span>
                      </ButtonWrap>
                      <MobilePopoverCard
                        className="pets-info-popover"
                        open={petsInfoPopoverOpen}
                        onClose={() => setPetsInfoPopoverOpen(false)}
                        topRightButton={
                          <CloseButtonIcon
                            onClick={() => setPetsInfoPopoverOpen(false)}
                          />
                        }
                      >
                        <div className="pets-info-popover-content">
                          <div className="pets-info-popover-header">
                            <Typography>
                              {textConstants.PETS_MODAL_HEADER}
                            </Typography>
                          </div>
                          <Typography
                            variant="subtitle2"
                            className="pets-info-popover-text"
                            dangerouslySetInnerHTML={{
                              __html: textConstants.PETS_TOOLTIP,
                            }}
                          />
                        </div>
                      </MobilePopoverCard>
                    </>
                  ),
                }
              : {}),
          }}
          counts={{
            adults: adultsCount,
            children: children,
            rooms: roomsCount,
            pets: petsCount,
          }}
          setPassengerCounts={(counts: PassengerCountPickerType) => {
            const { adults, children, rooms, pets } = counts as GuestsSelection;
            setOccupancyCounts({ adults, children, pets });
            setRoomsCount(rooms);
          }}
          onClickApply={() => {
            setOpenPassengerCountPicker(false);
          }}
          className="b2b"
          maximumChildrenCount={1000}
        />
      </MobilePopoverCard>
    </Box>
  );
};
