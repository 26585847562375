import { connect, ConnectedProps } from "react-redux";
import { DesktopCalendarPicker } from "./component";
import { IStoreState } from "../../../../../../reducers/types";
import { getFromDate, getUntilDate } from "../../../../reducer";
import { actions } from "../../../../actions";
import * as availabilityActions from "../../../../../availability/actions/actions";
import { getVacationRentalShopUnavailableDates } from "../../../../../vacation-rental-shop/reducer";

const mapDispatchToProps = {
  setFromDate: actions.setFromDate,
  setUntilDate: actions.setUntilDate,
  setOpenDatesModal: availabilityActions.setOpenDatesModal,
};

const mapStateToProps = (state: IStoreState) => {
  const unavailableDates = getVacationRentalShopUnavailableDates(state);

  return {
    untilDate: getUntilDate(state),
    fromDate: getFromDate(state),
    ...(unavailableDates ? { unavailableDates } : {}),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedDesktopCalendarPicker = ConnectedProps<typeof connector>;

export const ConnectedDesktopCalendarPicker = connector(DesktopCalendarPicker);
