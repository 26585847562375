export const CONTACT_INFO_TITLE = "Step 2: Contact Information";
export const CONTACT_INFO_TITLE_NO_STEP = "Your Contact Information";
export const CONTACT_INFO_SUBTITLE =
  "This information is used to notify you about any updates or changes to your trip.";
export const CONTACT_INFO_SAVE = "Save Contact Information";
export const CONTACT_INFO_HELPER_TEXT =
  "You must add one traveler and enter your contact information to continue.";

export const TRAVELER_INFO_TITLE = "Step 1: Primary Traveler Information";
export const TRAVELER_HEADING = "Traveler";
export const TRAVELER_NAME = (firstName: string, lastName: string) =>
  `${firstName} ${lastName}`;
export const PHONE_HEADING = "Phone";
export const EMAIL_HEADING = "Email";
export const EDIT_TRAVELER = "Edit traveler";
