import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { schedulePriceQuote } from "../../actions/actions";
import { VacationRentalPriceBreakdown } from "./component";
import {
  getVacationRentalsPollPriceQuoteCallState,
  getVacationRentalsPricingLineItems,
  getVacationRentalsRewardsAndTotalLineItems,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    pollVacationRentalPriceQuoteCallState:
      getVacationRentalsPollPriceQuoteCallState(state),
    pricingLineItems: getVacationRentalsPricingLineItems(state),
    rewardsAndTotalLineItems: getVacationRentalsRewardsAndTotalLineItems(state),
  };
};

const mapDispatchToProps = {
  schedulePriceQuote,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VacationRentalPriceBreakdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedVacationRentalPriceBreakdown = withRouter(
  connector(VacationRentalPriceBreakdown)
);
