import React from "react";

import clsx from "clsx";
import { RouteComponentProps } from "react-router";

import { Icon, IconName, ToggleSwitch, VacationRentalNewTag } from "halifax";
import { StayTypesEnum, VR_TOGGLE_SELECTED } from "redmond";

import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  getExperimentVariant,
  LUXURY_VACATION_RENTAL_FUNNEL_LAUNCH,
  useExperiments,
} from "../../../../../../context/experiments";
import {
  PATH_HOME,
  PATH_HOME_SEARCH,
  PATH_VACATION_RENTALS,
  PATH_VACATION_RENTALS_SEARCH,
} from "../../../../../../utils/paths";
import { StayTypeToggleSwitchConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

const VACATION_RENTAL_TOGGLE_SELECTED = "vr-toggle-selected";

export interface IStayTypeToggleSwitchProps
  extends StayTypeToggleSwitchConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

export const StayTypeToggleSwitch = (props: IStayTypeToggleSwitchProps) => {
  const { stayType, setStayType, history, isMobile } = props;

  const expState = useExperiments();

  const vacationFunnelLaunchEnabled =
    getExperimentVariant(
      expState.experiments,
      LUXURY_VACATION_RENTAL_FUNNEL_LAUNCH
    ) === AVAILABLE;

  React.useEffect(() => {
    if (
      vacationFunnelLaunchEnabled &&
      stayType == StayTypesEnum.VacationRentals
    ) {
      sessionStorage.setItem(VACATION_RENTAL_TOGGLE_SELECTED, String(true));
    }
  }, [vacationFunnelLaunchEnabled, stayType]);

  return (
    <ToggleSwitch
      className={clsx("stays-type-toggle-root", { mobile: isMobile })}
      ariaLabel={"Hotels or Vacation Rentals Search"}
      values={[StayTypesEnum.Hotels, StayTypesEnum.VacationRentals]}
      selectedValue={stayType}
      setSelectedValue={(selectedValue: StayTypesEnum) => {
        setStayType(selectedValue);
        if (selectedValue === StayTypesEnum.VacationRentals) {
          trackEvent({
            eventName: VR_TOGGLE_SELECTED,
            properties: {},
          });
          isMobile
            ? history.push(PATH_VACATION_RENTALS_SEARCH)
            : history.push(PATH_VACATION_RENTALS);
        } else {
          isMobile ? history.push(PATH_HOME_SEARCH) : history.push(PATH_HOME);
        }
      }}
      getValueText={(value: StayTypesEnum) => {
        return (
          <>
            <Icon
              name={
                value === StayTypesEnum.Hotels
                  ? IconName.HotelFunnelIcon
                  : IconName.VacationRentalsToggleIcon
              }
            />
            {value === StayTypesEnum.Hotels
              ? textConstants.HOTELS_TOGGLE_TEXT
              : textConstants.VACATION_RENTALS_TOGGLE_TEXT}
            {value === StayTypesEnum.VacationRentals && (
              <VacationRentalNewTag
                enabled={vacationFunnelLaunchEnabled}
                className="toggle-new-tag"
              />
            )}
          </>
        );
      }}
      getAriaValueText={(value: StayTypesEnum) =>
        value === StayTypesEnum.Hotels
          ? textConstants.HOTELS_TOGGLE_TEXT
          : textConstants.VACATION_RENTALS_TOGGLE_TEXT
      }
      overrideStyles={{
        toggleWidth: isMobile ? "50%" : 200,
        togglePadding: 0,
        toggleBorder: 0,
      }}
    />
  );
};
