import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { VRShopErrorModal } from "./component";

import { IStoreState } from "../../../../reducers/types";
import {
  getVacationRentalShopCallError,
  hasVacationRentalShopFailed,
} from "../../reducer";
import { fetchVacationRentalShop } from "../../actions/actions";
import { setOpenDatesModal } from "../../../availability/actions/actions";
import { getOpenDatesModal } from "../../../availability/reducer";
import { getFromDate, getUntilDate } from "../../../search/reducer";
import { setFromDate, setUntilDate } from "../../../search/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    hasFailed: hasVacationRentalShopFailed(state),
    shopError: getVacationRentalShopCallError(state),
    openDatesModal: getOpenDatesModal(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
  };
};

const mapDispatchToProps = {
  fetchVacationRentalShop,
  setOpenDatesModal,
  setFromDate,
  setUntilDate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VRShopErrorModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedVRShopErrorModal = withRouter(
  connector(VRShopErrorModal)
);
