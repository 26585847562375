import axios from "axios";
import { GetCfarQuotesRequest, GetCfarQuotesResponse } from "redmond";
import { config } from "../../config";
import { cfarQuoteForHotelPath } from "../../v1/paths";

export const fetchCfarQuotes = (
  request: GetCfarQuotesRequest
): Promise<GetCfarQuotesResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(cfarQuoteForHotelPath, request, {
        baseURL: config.baseURL,
      });

      resolve(response.data);
    } catch (e) {
      reject(e);
    }
  });
