import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useDeviceTypes } from "halifax";
import { SelectedTravelOfferScreen } from "redmond";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  HotelShopProgressBar,
  MobileShopHeader,
} from "../../../shop/components";
import { DesktopRewardsHeader } from "../../../rewards/components";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components";
import { goToShop } from "../../../shop/utils/queryStringHelpers";
import {
  AddOnHeader,
  AddOnCustomize,
  MobileAddOnCustomize,
  AddOnCustomizeStepAlias,
} from "../index";
import { PremierCollectionCustomizeConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";

export interface IPremierCollectionCustomizeProps
  extends PremierCollectionCustomizeConnectorProps,
    RouteComponentProps {}

export const PremierCollectionCustomize = (
  props: IPremierCollectionCustomizeProps
) => {
  const {
    history,
    chosenProduct,
    selectedLodging,
    showOfferBasedOnSelectedLodging,
  } = props;
  const [currentCustomizeStep, setCurrentCustomizeStep] =
    useState<AddOnCustomizeStepAlias>(AddOnCustomizeStepAlias.AddOn1);
  const { matchesMobile } = useDeviceTypes();

  // note: same hook logic as what is added in hotel/book; it redirects the user to hotel/shop on page refresh.
  useEffect(() => {
    if (!chosenProduct || !selectedLodging) {
      goToShop({ history });
    }
  }, [chosenProduct, selectedLodging]);

  const Content = () => {
    if (matchesMobile) {
      return (
        <>
          <MobileShopHeader
            onClickBackButton={() =>
              setCurrentCustomizeStep((step) => Math.max(step - 1, 0))
            }
            title={constants.CUSTOMIZE_HEADER_TITLE}
            variant="custom"
            isVRForPremiumCardHoldersEnabled={false}
          />
          <MobileAddOnCustomize
            currentStep={currentCustomizeStep}
            setCurrentStep={setCurrentCustomizeStep}
          />
        </>
      );
    } else {
      return (
        <>
          <DesktopRewardsHeader variant="customize" />
          {showOfferBasedOnSelectedLodging &&
            selectedLodging?.bestOfferThisLodging && (
              <TravelWalletDetailsBanner
                className="hotel-customize-travel-wallet-details-banner"
                offer={selectedLodging.bestOfferThisLodging}
                screen={SelectedTravelOfferScreen.PREMIUM_STAYS_CUSTOMIZE}
                showButton
              />
            )}
          <AddOnHeader />
          <Box className="main-section">
            <Box className="progress-bar-wrapper">
              <HotelShopProgressBar useCustomizeStep={true} />
            </Box>
            <AddOnCustomize />
          </Box>
        </>
      );
    }
  };

  return (
    <Box
      className={clsx("premier-collection-shop-customize-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="premier-collection-shop-customize-container">
        <Content />
      </Box>
    </Box>
  );
};
