import * as actionTypes from "./constants";
import * as H from "history";
import {
  CancellationSummary,
  Lodging,
  RoomInfoProducts,
  TrackingProperties,
} from "redmond";
import {
  PremierCollectionShopCallState,
  PremierCollectionShopCallError,
  PremierCollectionShopStep,
} from "../reducer";

export interface IFetchPremierCollectionShop {
  type: actionTypes.FETCH_PREMIER_COLLECTION_SHOP;
  history: H.History;
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallHotelAvailability?: boolean;
    fetchSimilarHotels?: boolean;
    includeRoomsCount?: boolean;
  };
}

export const fetchPremierCollectionShop = (
  history: H.History,
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallHotelAvailability?: boolean;
    fetchSimilarHotels?: boolean;
    includeRoomsCount?: boolean;
  }
): IFetchPremierCollectionShop => ({
  type: actionTypes.FETCH_PREMIER_COLLECTION_SHOP,
  history,
  options,
});

export interface ISetPremierCollectionShopResults {
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_RESULTS;
  premierCollectionShopCallState: PremierCollectionShopCallState;
  premierCollectionShopCallError: PremierCollectionShopCallError | null;
  payload?: ISetPremierCollectionShopResultsArgs;
}

export interface ISetPremierCollectionShopResultsArgs {
  roomInfoProducts: RoomInfoProducts[];
  trackingPropertiesV2?: TrackingProperties;
  cancellationSummary?: CancellationSummary;
}

export const setPremierCollectionShopResults = (args: {
  premierCollectionShopCallState: PremierCollectionShopCallState;
  premierCollectionShopCallError?: PremierCollectionShopCallError;
  payload?: ISetPremierCollectionShopResultsArgs;
}): ISetPremierCollectionShopResults => ({
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_RESULTS,
  ...{ premierCollectionShopCallError: null, ...args },
});

interface IISetPremierCollectionShopCallStateFailedPayload {
  premierCollectionShopCallError: PremierCollectionShopCallError;
}
export interface ISetPremierCollectionShopCallStateFailed
  extends IISetPremierCollectionShopCallStateFailedPayload {
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_CALL_STATE_FAILED;
}

export const setPremierCollectionShopCallStateFailed = ({
  premierCollectionShopCallError,
}: IISetPremierCollectionShopCallStateFailedPayload): ISetPremierCollectionShopCallStateFailed => ({
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_CALL_STATE_FAILED,
  premierCollectionShopCallError,
});

export interface ISelectRoomType {
  type: actionTypes.SELECT_ROOM_TYPE;
  roomInfoIndex: number | null;
  productIndex: number | null;
}

export const selectRoomType = (
  roomInfoIndex: number | null,
  productIndex: number | null
): ISelectRoomType => {
  return {
    type: actionTypes.SELECT_ROOM_TYPE,
    roomInfoIndex,
    productIndex,
  };
};

export interface ISelectLodging {
  type: actionTypes.SELECT_LODGING;
  lodging: Lodging;
}

export const selectLodging = (lodging: Lodging): ISelectLodging => ({
  type: actionTypes.SELECT_LODGING,
  lodging,
});

export interface IAcknowledgePremierCollectionShopFailure {
  type: actionTypes.ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_FAILURE;
}

export const acknowledgePremierCollectionShopFailure =
  (): IAcknowledgePremierCollectionShopFailure => ({
    type: actionTypes.ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_FAILURE,
  });

export interface ISetPremierCollectionShopProgress {
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_PROGRESS;
  progress: PremierCollectionShopStep;
}

export const setPremierCollectionShopProgress = (
  progress: PremierCollectionShopStep
): ISetPremierCollectionShopProgress => ({
  type: actionTypes.SET_PREMIER_COLLECTION_SHOP_PROGRESS,
  progress,
});

export interface ISetIsFromHotelAvailability {
  type: actionTypes.SET_IS_FROM_HOTEL_AVAILABILITY;
  isFromHotelAvailability: boolean;
}

export const setIsFromHotelAvailability = (
  isFromHotelAvailability: boolean
): ISetIsFromHotelAvailability => ({
  type: actionTypes.SET_IS_FROM_HOTEL_AVAILABILITY,
  isFromHotelAvailability,
});

export type PremierCollectionShopActions =
  | IFetchPremierCollectionShop
  | ISetPremierCollectionShopResults
  | ISetPremierCollectionShopCallStateFailed
  | ISelectRoomType
  | ISelectLodging
  | IAcknowledgePremierCollectionShopFailure
  | ISetPremierCollectionShopProgress
  | ISetIsFromHotelAvailability;
