import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getLocation,
  getLocationCategoriesLoading,
  getHasLocationAutocompleteError,
  getLocationCategoriesFilteredBySearchString,
  getStayType,
  getVacationRentalsLocation,
} from "../../../../reducer";
import { MobileLocationSearchModal } from "./component";
import { StayTypesEnum } from "redmond";

const mapDispatchToProps = {
  setLocation: actions.setLocation,
  setLocationAutocompleteError: actions.setLocationAutocompleteError,
  setLocationSearchString: actions.setLocationSearchString,
};
const mapStateToProps = (state: IStoreState) => {
  const stayType = getStayType(state);
  const searchLocation =
    stayType === StayTypesEnum.VacationRentals
      ? getVacationRentalsLocation(state)
      : getLocation(state);
  return {
    location: searchLocation,
    locationCategories: getLocationCategoriesFilteredBySearchString(state),
    loading: !!getLocationCategoriesLoading(state),
    hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileLocationSearchModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileLocationSearchModal = connector(
  MobileLocationSearchModal
);
