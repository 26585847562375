import axios from "axios";
import { config } from "../../config";
import {
  ShopResponse,
  VacationRentalShopRequest,
  VacationRentalCalendarRequest,
  VacationRentalCalendarResponse,
} from "redmond";
import {
  vacationRentalCalendarApiPrefix,
  vacationRentalShopApiPrefix,
} from "../paths";

export const fetchVacationRentalShop = (
  body: VacationRentalShopRequest
): Promise<ShopResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(vacationRentalShopApiPrefix, body, {
        baseURL: config.baseURL,
      });

      const { value } = res.data;

      resolve(value?.listing);
    } catch (e) {
      reject(e);
    }
  });

export const fetchVacationRentalCalendar = (
  body: VacationRentalCalendarRequest
): Promise<VacationRentalCalendarResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(vacationRentalCalendarApiPrefix, body, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });