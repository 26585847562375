// TODO: relocate client specific language; see https://hopper-jira.atlassian.net/wiki/spaces/ENGWEB/pages/1959952414/Discussion+on+practical+ways+to+prevent+adding+Cap+One+specific+language+into+Pawtucket
import { StayTypesEnum, cardsToShowLastFourOnRewardsBanner } from "redmond";
import { isCaponeTenant } from "@capone/common";
import { config } from "../../../../api/config";

export const PREMIER_COLLECTION_EARN_BANNER_TEXT = ({
  firstName,
  earnRate,
  productId,
  lastFour,
  currency,
  experienceCredit,
  isWithLifestyle,
  isLifestyleOnly,
  stayType,
  isUserPrimary,
}: {
  firstName: string;
  earnRate: string | number;
  productId: string;
  lastFour: string;
  currency: string;
  isWithLifestyle: boolean;
  isLifestyleOnly: boolean;
  experienceCredit?: string;
  stayType?: StayTypesEnum;
  isUserPrimary: boolean;
}) => {
  const stayTypeText =
    stayType === StayTypesEnum.VacationRentals ? "vacation rental" : "hotel";

  const lifestyleRewardsBannerIntro =
    isCaponeTenant(config.TENANT) || isUserPrimary
      ? `earn <strong>${earnRate}X ${currency}</strong> and enjoy a`
      : `enjoy a`;

  if (cardsToShowLastFourOnRewardsBanner.includes(productId.toLowerCase())) {
    if (isWithLifestyle || stayType === StayTypesEnum.VacationRentals) {
      return `${firstName}, earn <strong>${earnRate}X ${currency}<strong/> on every <strong>${stayTypeText}</strong> booking from the Premier & Lifestyle Collections with your ${productId} account ending in ${lastFour}.`;
    } else {
      return experienceCredit
        ? `${firstName}, ${lifestyleRewardsBannerIntro} <strong>${experienceCredit} experience credit</strong> on ${
            isLifestyleOnly ? "Lifestyle" : "Premier"
          } Collection bookings with your <strong>${productId}</strong> card ending in ${lastFour}.`
        : `${firstName}, earn <strong>${earnRate}X ${currency}</strong> on hotels and rental cars when you book with your ${productId} account ending in ${lastFour}.`;
    }
  } else {
    if (isWithLifestyle || stayType === StayTypesEnum.VacationRentals) {
      return `${firstName}, earn <strong>${earnRate}X ${currency}</strong> on every <strong>${stayTypeText}</strong> booking from the Premier & Lifestyle Collections with your <strong> ${productId}</strong> card.`;
    } else {
      return experienceCredit
        ? `${firstName}, ${lifestyleRewardsBannerIntro} <strong>${experienceCredit} experience credit</strong> on  ${
            isLifestyleOnly ? "Lifestyle" : "Premier"
          } Collection bookings with your <strong> ${productId}</strong> card.`
        : `${firstName}, earn ${earnRate}X ${currency} on hotels and rental cars when you book with your ${productId} account.`;
    }
  }
};
