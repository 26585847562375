import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import { getNightCount, getStayType } from "../../../search/reducer";

import { getVacationRentalShopSelectedListing } from "../../reducer/selectors";
import { MobileListingTotalPanel } from "./component";
import { getViewedPremierCollectionDetailsProperties } from "../../../shop/reducer";
import { fetchVacationRentalShop } from "../../actions/actions";
import { setOpenDatesModal } from "../../../availability/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    viewedPremierCollectionDetailsProperties:
      getViewedPremierCollectionDetailsProperties(state),
    stayType: getStayType(state),
    listing: getVacationRentalShopSelectedListing(state),
  };
};

export const mapDispatchToProps = {
  fetchVacationRentalShop,
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileListingTotalPanelConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileListingTotalPanel = withRouter(
  connector(MobileListingTotalPanel)
);
