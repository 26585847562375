import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  premierCollectionShopProgressSelector,
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopSelectedAvailability,
  isAddOnOptionAvailableSelector,
  isOptionSelectionCompleteSelector,
} from "../../reducer";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getUntilDate,
} from "../../../search/reducer";
import { setPremierCollectionShopProgress } from "../../actions/actions";
import { HotelShopProgressBar } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    hotelShopStep: premierCollectionShopProgressSelector(state),
    chosenProduct: getPremierCollectionShopChosenProduct(state),
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    isOptionSelectionComplete: isOptionSelectionCompleteSelector(state),
  };
};

const mapDispatchToProps = {
  setPremierCollectionShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelShopProgressBarConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelShopProgressBar = connector(
  withRouter(HotelShopProgressBar)
);
