import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import { getNightCount } from "../../../search/reducer";

import {
  getCheapestProduct,
  getPremierCollectionShopCancellationSummary,
  getPremierCollectionShopRoomInfoProducts,
  getPremierCollectionShopSelectedAvailability,
  showOfferBasedOnSelectedLodgingSelector,
} from "../../reducer/selectors";
import { DesktopPremierCollectionShopChooseRoom } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    roomInfoProducts: getPremierCollectionShopRoomInfoProducts(state),
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    cheapestProduct: getCheapestProduct(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
    showOfferBasedOnSelectedLodging:
      showOfferBasedOnSelectedLodgingSelector(state),
    shopCancelationSummary: getPremierCollectionShopCancellationSummary(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopPremierCollectionShopChooseRoomConnectorProps =
  ConnectedProps<typeof connector>;

export const ConnectedDesktopPremierCollectionShopChooseRoom = withRouter(
  connector(DesktopPremierCollectionShopChooseRoom)
);
