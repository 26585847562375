import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  fetchApplicableTravelWalletItems,
  schedulePriceQuote,
  setPriceQuote,
} from "./actions/actions";

import { IStoreState } from "../../reducers/types";
import { PremierCollectionBook } from "./component";
import {
  getIsBookingValid,
  getPriceQuote,
  getReviewPremierCollectionDetailsCheckoutProperties,
  getUserPassengerCallState,
  getUserPassengers,
  priceQuoteParamsSelector,
} from "./reducer";
import {
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopSelectedAvailability,
} from "../shop/reducer/selectors";
import {
  getVacationRentalShopSelectedListing,
  getViewedVacationRentalDetailsProperties,
} from "../vacation-rental-shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    priceQuoteParams: priceQuoteParamsSelector(state),
    chosenProduct: getPremierCollectionShopChosenProduct(state),
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    isBookingValid: getIsBookingValid(state),
    reviewPremierCollectionDetailsProperties:
      getReviewPremierCollectionDetailsCheckoutProperties(state),
    selectedHome: getVacationRentalShopSelectedListing(state),
    travelers: getUserPassengers(state),
    userPassengerCallState: getUserPassengerCallState(state),
    viewedVacationRentalDetailsProperties:
      getViewedVacationRentalDetailsProperties(state),
    priceQuote: getPriceQuote(state),
  };
};

const mapDispatchToProps = {
  schedulePriceQuote,
  setPriceQuote,
  fetchApplicableTravelWalletItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PremierCollectionBookConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionBook = withRouter(
  connector(PremierCollectionBook)
);
