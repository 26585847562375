import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getAdultsCount,
  getChildren,
  getPetsCount,
  getStayType,
} from "../../../../reducer/selectors";
import { OccupancySelection } from "./component";

const mapDispatchToProps = {
  setOccupancyCounts: actions.setOccupancyCounts,
};
const mapStateToProps = (state: IStoreState) => ({
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  stayType: getStayType(state),
  petsCount: getPetsCount(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type OccupancySelectionConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedOccupancySelection = connector(OccupancySelection);
