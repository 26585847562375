import { Box, Typography } from "@material-ui/core";
import React from "react";
import { RouteComponentProps } from "react-router";
import { RoomsLayoutInfoConnectorProps } from "./container";
import "./styles.scss";
import { Icon, IconName } from "halifax";
import clsx from "clsx";

export interface IRoomsLayoutInfoProps
  extends RoomsLayoutInfoConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

export const RoomsLayoutInfo = (props: IRoomsLayoutInfoProps) => {
  const { selectedListing, isMobile } = props;

  if (!selectedListing) return null;

  const { listing } = selectedListing;
  const { bathrooms, bedrooms } = listing.content.layout;
  return (
    <Box className={clsx("rooms-beds-layout-info", { mobile: isMobile })}>
      {bedrooms.map((bedroom, index) => {
        return (
          <Box className="bedroom room-info">
            <Icon name={IconName.BedOutlineIcon} />
            <Typography className="name">Bedroom {index + 1}</Typography>
            <Typography className="description" variant="body2">
              {bedroom.description}
            </Typography>
          </Box>
        );
      })}
      {bathrooms.map((bathroom, index) => {
        return (
          <Box className="bathroom room-info">
            <Icon name={IconName.ToiletOutlineIcon} />
            <Typography className="-name">Bathroom {index + 1}</Typography>
            <Typography className="description" variant="body2">
              {bathroom.description}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
