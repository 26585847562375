import { IResponse } from "redmond";
import { put } from "redux-saga/effects";
import { fetchLocations } from "../../../api/v0/search/fetchLocations";
import Logger from "../../../utils/logger";
import { actions } from "../actions";

export function* fetchLocationsSaga() {
  try {
    const { categories }: IResponse = yield fetchLocations();
    yield put(
      actions.setLocationCategories(
        categories.map((category) => ({
          ...category,
          results: category.results.sort((a, b) => {
            // sort alphabetically
            if (a.label > b.label) {
              return 1;
            } else if (a.label < b.label) {
              return -1;
            }
            return 0;
          }),
        }))
      )
    );
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setLocationCategories([]));
  }
}
