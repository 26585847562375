export const INPUT_LABEL = (
  labeling: "guests" | "travelers",
  adults: number,
  children: number[],
  pets?: number
) => {
  const numTravelers = adults + children.length;
  const travelersCount = (() => {
    switch (labeling) {
      case "guests":
        return numTravelers === 1
          ? `${numTravelers} Guest`
          : `${numTravelers} Guests`;
      case "travelers":
        return numTravelers === 1
          ? `${numTravelers} Traveler`
          : `${numTravelers} Travelers`;
    }
  })();
  const petsCount = pets
    ? pets === 1
      ? `${pets} Pet`
      : `${pets} Pets`
    : undefined;

  const labels = [travelersCount, petsCount].filter(
    (v): v is string => v != null
  );
  return labels.join(", ");
};

export const MODAL_TITLES = {
  modalTitle: "Edit Guests",
  adultTitle: "Adults",
  adultSubtitle: "Ages 18 or above",
  childrenTitle: "Children",
  chilrenSubtitle: "Under 18",
};

export const PETS_TOOLTIP =
  "If you're traveling with a pet, you must stay in a pet-friendly home. Adding a pet will display only pet-friendly vacation rentals in your search. <br/> <br/> If you'd like to bring more than 1 pet, please discuss with the host after booking. Service animals do not count as pets—you don't need to add them to your guest count, and you can inform your host after booking.";

export const ADDITIONAL_FEE_TEXT = "Additional fee may apply";
