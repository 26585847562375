import React from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@material-ui/core";

import { PriceBreakdownConnectorProps } from "./container";
import {
  LoadingIndicator,
  B2BSpinner,
  HotelPriceBreakdown,
  IHotelPriceLineItem,
} from "halifax";
import { CallState } from "redmond";
import * as constants from "./textConstants";
import "./styles.scss";

export interface IPriceBreakdownProps
  extends PriceBreakdownConnectorProps,
    RouteComponentProps {
  variant?: "book" | "customize";
}

export const PriceBreakdown = ({
  pollPriceQuoteCallState,
  pricingLineItems,
  rewardsAndTotalLineItems,
  variant = "book",
}: IPriceBreakdownProps) => {
  const showLoadingIndicator =
    pollPriceQuoteCallState === CallState.InProcess ||
    pricingLineItems.length === 0;

  const pricingItems = pricingLineItems[0] as IHotelPriceLineItem[];

  const totalItems = (() => {
    const total = pricingLineItems[1] as IHotelPriceLineItem[];

    switch (variant) {
      case "customize":
        // note: only show due-today value on the Customize page as per design
        return total
          ?.filter((item) => item.type === "due-today")
          ?.map((item) => ({
            ...item,
            title: constants.TOTAL,
          }));
      case "book":
      default:
        return total;
    }
  })();

  return (
    <Box className={"premier-collection-price-breakdown"}>
      {showLoadingIndicator ? (
        <LoadingIndicator
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={"Fetching latest price"}
        ></LoadingIndicator>
      ) : (
        <>
          <HotelPriceBreakdown
            className="pricing-line-items-breakdown"
            pricingItems={pricingItems}
            totalItems={totalItems}
            summaryItems={rewardsAndTotalLineItems}
            showSummaryItems={variant === "customize" ? false : undefined}
            showTravelCredit={variant === "customize" ? false : undefined}
          />
        </>
      )}
    </Box>
  );
};
