import { CallState, LodgingId, QuoteId, RoomInfoCfarQuote } from "redmond";
import {
  DefaultVariantType,
  HOTELS_CFAR,
  HOTEL_CFAR_MODEL_V1,
  LDJSONConfig,
} from "../../../context/experiments";
import { actions, actionTypes } from "../actions";

export const DO_NOT_APPLY_OPTION_KEY = "do-not-apply-option";

export interface IHotelAncillaryExperiments {
  [HOTELS_CFAR]: DefaultVariantType;
  [HOTEL_CFAR_MODEL_V1]: LDJSONConfig | null;
}

export interface IHotelAncillaryExperimentState {
  experiments: IHotelAncillaryExperiments | null;
}

export interface IHotelAncillaryState extends IHotelAncillaryExperimentState {
  lodgingId: LodgingId | null;
  roomInfoCfarQuotes: RoomInfoCfarQuote[];
  fetchCfarQuoteCallState: CallState;
  selectedCfarId: QuoteId | null;
  hasSelectedRefundableRoom: boolean;
}

export const initialState: IHotelAncillaryState = {
  experiments: null,
  lodgingId: null,
  roomInfoCfarQuotes: [],
  fetchCfarQuoteCallState: CallState.NotCalled,
  selectedCfarId: null,
  hasSelectedRefundableRoom: false,
};

export const getExperimentState = (
  hotelAncillaryState: IHotelAncillaryState | undefined
): IHotelAncillaryExperimentState => {
  return {
    experiments: hotelAncillaryState?.experiments ?? initialState.experiments,
  };
};

export function reducer(
  state: IHotelAncillaryState = initialState,
  action: actions.HotelAncillaryActions
): IHotelAncillaryState {
  switch (action.type) {
    case actionTypes.SET_ANCILLARY_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
      };
    case actionTypes.FETCH_CFAR_QUOTES:
      return {
        ...state,
        lodgingId: null,
        selectedCfarId: null,
        hasSelectedRefundableRoom: false,
        roomInfoCfarQuotes: [],
        fetchCfarQuoteCallState: CallState.InProcess,
      };
    case actionTypes.SET_FETCH_CFAR_QUOTES_CALL_STATE_FAILED:
      return {
        ...state,
        fetchCfarQuoteCallState: CallState.Failed,
      };
    case actionTypes.SET_CFAR_QUOTES:
      return {
        ...state,
        fetchCfarQuoteCallState: CallState.Success,
        lodgingId: action.response.lodgingId,
        roomInfoCfarQuotes: action.response.roomInfoCfarQuotes,
      };
    case actionTypes.SET_SELECTED_CFAR_ID:
      return {
        ...state,
        selectedCfarId: action.cfarId,
      };
    case actionTypes.SET_HAS_SELECTED_REFUNDABLE_ROOM:
      return {
        ...state,
        hasSelectedRefundableRoom: action.hasSelectedRefundableRoom,
      };
    default:
      return state;
  }
}

export * from "./selectors";
