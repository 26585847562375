import { connect, ConnectedProps } from "react-redux";

import { withRouter } from "react-router";
import {
  getVacationRentalShopCallState,
  getVacationRentalShopSelectedListing,
  VacationRentalShopCallState,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { RoomsLayoutInfo } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  selectedListing: getVacationRentalShopSelectedListing(state),
  vrShopCallInProgress:
    getVacationRentalShopCallState(state) ===
    VacationRentalShopCallState.InProcess,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RoomsLayoutInfoConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRoomsLayoutInfo = withRouter(connector(RoomsLayoutInfo));
