import React from "react";

import { Box, Divider } from "@material-ui/core";
import clsx from "clsx";

import { Icon, IconName } from "halifax";

export interface ListViewItem {
  key: string;
  node: React.ReactNode;
  drillable?: boolean;
  onClick?: () => void;
}

export interface IListViewProps {
  items: ListViewItem[];
  renderSeparators?: boolean;
  itemClassName?: string;
}

export const ListView = ({
  items,
  renderSeparators,
  itemClassName,
}: IListViewProps) => (
  <Box className="list-view">
    {items.map((item, index) => {
      return (
        <React.Fragment key={item.key}>
          {(renderSeparators ?? true) && index > 0 && (
            <Divider style={{ marginLeft: "16px", marginRight: "16px" }} />
          )}
          <Box
            className={clsx("list-item", itemClassName, {
              selectable: item.onClick != null || (item.drillable ?? true),
            })}
            onClick={item.onClick}
          >
            <Box className="list-item-content">{item.node}</Box>
            {(item.drillable ?? true) && item.onClick != null && (
              <Box>
                <Icon name={IconName.RightChevronIcon} />
              </Box>
            )}
          </Box>
        </React.Fragment>
      );
    })}
  </Box>
);
