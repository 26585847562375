import { Cfar } from "redmond";
import { getTotalPriceText } from "halifax";

export const BOOK_BUTTON_TEXT = "Confirm and Book";
export const REQUIRED_DETAILS_TEXT = "Enter all required details";
export const CONTACT_INFO_TITLE = "Contact Information";
export const CONTACT_INFO_SUBTITLE =
  "This information is used to notify you about any updates or changes to your trip.";
export const CONTACT_INFO_SAVE = "Save Contact Information";
export const CONTINUE = "Continue";
export const REVIEW_MY_TRIP_TEXT = "Review my Trip";
export const NEED_TO_KNOW_TITLE = "Need to know";
export const NEED_TO_KNOW_DETAILS =
  "All rooms are guaranteed to accommodate 2 guests. Additional guests are at the discretion of the hotel and may be subject to extra fees. At check-in, the hotel may place a temporary hold on your credit card for incidentals.";
export const PRICE_QUOTE_MESSAGE = "Saving traveler and checking availability";

export const getCfarSecondaryText = (cfarCancellation: Cfar) =>
  `Refund amount: ${getTotalPriceText({
    price: cfarCancellation.refundAmount.fiat,
  })}`;

export const TREES_MODAL_TITLE = "How it works";
export const TREES_MODAL_SUBTITLE =
  "<b>Tree Planting Efforts:</b> Capital One, together with its partners, will donate to GlobalGiving’s fund for EarthLungs’ Reforestation Project enough to plant two (2) trees, $0.25, for every completed flight, hotel booking, car rental, or vacation rental booking made through Capital One Travel.  In the event that a booking is made, but later canceled and refunded to the customer, for any reason, such a payment will not be made. No portion of a customer’s Capital One Travel purchase is tax-deductible. <br>GlobalGiving is a 501(c)(3) nonprofit organization whose mission is to transform aid and philanthropy to accelerate community-led change. To learn more about GlobalGiving, visit <a href='www.globalgiving.org' target='_blank'>www.globalgiving.org</a>; to learn more about GlobalGiving’s nonprofit partner, EarthLungs Reforestation Project, visit <a href='http://www.earthlungsreforestation.org.' target='_blank'>www.earthlungsreforestation.org.";
export const TREES_MODAL_HEADER =
  "When you book, <span class='font-regular'>you plant trees.</span>";

export const TREES_MODAL_CTA_TEXT = "will be planted for this booking! ";
export const TREES_BOLDED_MODAL_CTA_TEXT = "2 trees ";

export const CHECKOUT_PRICE_BREAKDOWN_HEADER = "Checkout Breakdown";

export const EXPERIENCE_CREDIT_FAQ = [
  {
    title: "What's an experience credit?",
    body: `The hotel experience credit can be used toward meals—or experiences like spa treatments—that are charged to your room during your stay.

    Qualifying experiences vary by location, but the credit can be used on at least 1 food and beverage experience. You should contact the hotel directly or ask at check-in to confirm the available options. We recommend making reservations in advance for limited experiences like massages or other spa treatments. See our Capital One Travel Terms & Conditions below for more details.`,
  },
];

export const CFAR_POLICY_TITLE = (percentage?: number) =>
  `${percentage?.toFixed(0) ?? 100}% refundable by Capital One Travel`;
export const CFAR_POLICY_SUBTITLE = (percentage?: number) =>
  `Your room is made refundable by Capital One Travel. Cancel for any reason up until your scheduled check-in time and get a refund for ${
    percentage?.toFixed(0) ?? 100
  }% of your hotel base rate and taxes and fees. Cancellations made after this period are subject to a penalty according to the hotel's policy.`;
