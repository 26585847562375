import React from "react";

import { StayTypesEnum } from "redmond";

export const HOTEL_CTA_TITLE = `Not what you’re looking for?`;
export const HOTEL_CTA_SUBTITLE = (
  locationName: string | undefined,
  isWithLifestyleCollection: boolean,
  isOnlyLifestyleCollection: boolean,
  stayType?: StayTypesEnum
): string => {
  return `${
    stayType === StayTypesEnum.Hotels
      ? `Choose from a variety of other hotels${
          locationName ? ` in ${locationName}` : ""
        } outside of our `
      : "Try searching all hotels, including our "
  }${
    isWithLifestyleCollection
      ? "Premier & Lifestyle "
      : isOnlyLifestyleCollection
      ? "Lifestyle "
      : "Premier "
  } Collection${
    stayType === StayTypesEnum.Hotels ? "." : ", to find more options."
  }`;
};

export const HOTEL_CTA_BUTTON_TEXT = (
  locationName: string,
  stayType: StayTypesEnum
): string =>
  `View ${
    stayType === StayTypesEnum.Hotels ? "other" : ""
  } hotels in ${locationName}`;

export const HOTEL_NO_RESULTS_CTA_TITLE = (
  searchLocation: string | undefined,
  isWithLifestyleCollection: boolean,
  isOnlyLifestyleCollection: boolean,
  stayType: StayTypesEnum
): JSX.Element => (
  <>
    Showing <strong>(0)</strong>{" "}
    {stayType == StayTypesEnum.Hotels ? "hotels" : "vacation rentals"} from our{" "}
    {isWithLifestyleCollection
      ? "Premier & Lifestyle "
      : isOnlyLifestyleCollection
      ? "Lifestyle "
      : "Premier "}
    Collection
    {searchLocation && (
      <>
        {" in"} <strong>{searchLocation}</strong>
      </>
    )}
  </>
);
export const HOTEL_NO_RESULTS_CTA_SUBTITLE = (
  locationName: string | undefined,
  isWithLifestyleCollection: boolean,
  isOnlyLifestyleCollection: boolean
) =>
  `Choose from a variety of other hotels${
    locationName ? ` in ${locationName}` : ""
  } outside of our ${
    isWithLifestyleCollection
      ? "Premier & Lifestyle "
      : isOnlyLifestyleCollection
      ? "Lifestyle "
      : "Premier "
  }Collection.`;
