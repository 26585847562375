import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { CfarDetails } from "./component";
import { IStoreState } from "../../../../../../reducers/types";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer";
import { selectedCfarIdSelector } from "../../../../../ancillary/reducer";
import { setSelectedCfarId } from "../../../../../ancillary/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedAccountReferenceId:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    selectedCfarId: selectedCfarIdSelector(state),
  };
};

const mapDispatchToProps = {
  setSelectedCfarId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CfarDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCfarDetails = connector(withRouter(CfarDetails));
