import { Box, Divider, Typography } from "@material-ui/core";
import React, { useMemo, useCallback } from "react";
import {
  ActionButton,
  ActionLink,
  HotelAmenities,
  HotelCarousel,
  HotelDescription,
  HotelPriceAndRewardsDisplay,
  Icon,
  IconName,
  PageTabNavigations,
  PremierCollectionShopDetailsSkeleton,
  PremiumStaysInfoAccordian,
  VacationRentalOccupancyInfo,
} from "halifax";
import { useInView } from "react-intersection-observer";
import { DesktopHotelPhotoModal } from "../../../shop/components/DesktopHotelPhotoModal";
import { DesktopRewardsHeader } from "../../../rewards/components";
import { RouteComponentProps } from "react-router";
import { VRDesktopShopConnectorProps } from "./container";
import "./styles.scss";
import { RoomsLayoutInfo } from "../RoomsLayoutInfo";
import { VRShopLocationMap } from "../VRShopLocationMap";
import {
  ListingCollectionEnum,
  ObfuscatedAddress,
  StayTypesEnum,
} from "redmond";
import { ListingTermsInfo } from "../ListingTermsInfo";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import { DesktopListingSummaryBox } from "../DesktopListingSummaryBox";
import { goToVacationRentalsCheckout } from "../../../shop/utils/queryStringHelpers";
import { VRShopErrorModal } from "../VRShopErrorModal";
import { goToVRAvailability } from "../../utils/queryStringHelpers";

export interface IVRDesktopShopProps
  extends VRDesktopShopConnectorProps,
    RouteComponentProps {
  isVRForPremiumCardHoldersEnabled: boolean;
}

export const VRDesktopShop = (props: IVRDesktopShopProps) => {
  const {
    selectedListing,
    vrShopCallInProgress,
    isVRForPremiumCardHoldersEnabled,
    nightCount,
    history,
    rewardsKey,
    hasFailed,
    fromDate,
    untilDate,
    childrenCount,
    adultsCount,
    petsCount,
  } = props;
  const [clickedSectionId, setClickedSectionId] = React.useState<string>();
  const [galleryRef, galleryInView] = useInView({ threshold: 0.8 });
  const [aboutRef, aboutInView] = useInView({ threshold: 0.8 });
  const [locationRef, locationInView] = useInView({ threshold: 0.8 });
  const [roomsRef, roomsInView] = useInView({ threshold: 0.8 });
  const [amenitiesRef, amenitiesInView] = useInView({ threshold: 0.8 });
  const [houseRulesRef, houseRulesInView] = useInView({ threshold: 0.8 });
  const [hostInfoRef, hostInfoInView] = useInView({ threshold: 0.8 });
  const [showExpandedVRMap, setShowExpandedVRMap] = React.useState(false);
  const [galleryFullRef, galleryFullInView] = useInView({ threshold: 0.25 });

  // setGalleryRefs used to merge the regs from multiple useInVew (one used for tab selection, one used to displayed fixed pricing/reserve now cta)
  const setGalleryRefs = useCallback(
    (node?: Element | null) => {
      galleryRef(node);
      galleryFullRef(node);
    },
    [galleryRef, galleryFullRef]
  );

  const selectedTab = useMemo(() => {
    if (
      galleryInView &&
      (clickedSectionId ? clickedSectionId === "#gallery-section" : true)
    ) {
      return 0;
    }
    if (
      aboutInView &&
      (clickedSectionId ? clickedSectionId === "#about-section" : true)
    ) {
      return 1;
    }
    if (
      locationInView &&
      (clickedSectionId ? clickedSectionId === "#location-section" : true)
    ) {
      return 2;
    }
    if (
      roomsInView &&
      (clickedSectionId ? clickedSectionId === "#rooms-section" : true)
    ) {
      return 3;
    }

    if (
      amenitiesInView &&
      (clickedSectionId ? clickedSectionId === "#amenities-section" : true)
    ) {
      return 4;
    }

    if (
      houseRulesInView &&
      (clickedSectionId ? clickedSectionId === "#house-rules-section" : true)
    ) {
      return 5;
    }
    if (
      hostInfoInView &&
      (clickedSectionId ? clickedSectionId === "#host-section" : true)
    ) {
      return 6;
    }

    return undefined;
  }, [
    galleryInView,
    aboutInView,
    locationInView,
    roomsInView,
    amenitiesInView,
    houseRulesInView,
    hostInfoInView,
  ]);

  const scrollToSection = (sectionId: string) => {
    const BANNER_HEIGHT =
      document
        .getElementById("b2bportal-banner-container")
        ?.getBoundingClientRect().height ?? 0;
    const HEADER_HEIGHT =
      document.querySelector(".app-header")?.getBoundingClientRect().height ??
      0;
    const MARGIN = 20;
    const yOffset =
      window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);
    const element = document.getElementById(sectionId);

    const y = (element?.getBoundingClientRect().top ?? 0) + yOffset;
    setClickedSectionId(sectionId);
    setTimeout(() => {
      setClickedSectionId(undefined);
    }, 500);

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const tabs = [
    {
      label: "Gallery",
      onClick: () => scrollToSection("gallery-section"),
    },
    { label: "About", onClick: () => scrollToSection("about-section") },
    {
      label: "Location",
      onClick: () => scrollToSection("location-section"),
    },
    {
      label: "Rooms & beds",
      onClick: () => scrollToSection("rooms-section"),
    },
    {
      label: "Amenities",
      onClick: () => scrollToSection("amenities-section"),
    },
    {
      label: "House rules",
      onClick: () => scrollToSection("house-rules-section"),
    },
    ...(selectedListing?.listing.hostInfo?.hostName &&
    selectedListing?.listing.hostInfo?.hostDescription
      ? [
          {
            label: "Host",
            onClick: () => scrollToSection("host-section"),
          },
        ]
      : []),
  ];

  const returnToAvailability = () => {
    goToVRAvailability({
      history,
      listing: selectedListing,
      fromDate,
      untilDate,
      adultsCount,
      childrenCount,
      petsCount,
    });
  };

  const renderSkeleton = () => (
    <Box className="desktop-vr-shop-skeleton">
      <div id="gallery-section">
        <HotelCarousel isSkeleton />
      </div>
      <div
        className={"section-wrapper description-and-date-picker-section"}
        id="about-section"
      >
        <Box className="description-wrapper">
          <PremierCollectionShopDetailsSkeleton />
        </Box>
      </div>
    </Box>
  );

  const renderShop = () => {
    if (!selectedListing) return null;
    const { listing, availability } = selectedListing;
    const { name, description } = listing.content;
    const formattedDescription = description?.replace(/\n/g, "<br />");

    const available = availability?.AvailabilityResult === "Available";

    return (
      <Box
        className={clsx("desktop-vr-shop-container", {
          "lifestyle-collection":
            listing.listingCollection === ListingCollectionEnum.Lifestyle,
        })}
      >
        <div id="gallery-section" ref={setGalleryRefs}>
          <DesktopHotelPhotoModal />
        </div>

        <PageTabNavigations
          navItems={tabs}
          selectedTab={selectedTab}
          className="vr-shop-navigation"
          rightContent={
            !galleryFullInView ? (
              <Box className="fixed-reserve-now-section">
                {available && (
                  <>
                    <HotelPriceAndRewardsDisplay
                      lodgingPrice={selectedListing.availability.rate?.price}
                      nightCount={nightCount ?? 0}
                      rewardsKey={rewardsKey}
                      variant="default"
                    />
                    <ActionButton
                      className="b2b fixed-reserve-now-cta"
                      onClick={() => {
                        goToVacationRentalsCheckout({ history });
                      }}
                      message={
                        <Typography variant="h2" className="button-text">
                          Reserve now
                        </Typography>
                      }
                    />
                  </>
                )}
              </Box>
            ) : undefined
          }
        />

        <div
          id="about-section"
          className={"section-wrapper about-section"}
          ref={aboutRef}
        >
          <Box className="description-wrapper">
            <Typography variant="h2" className="section-title">
              About the
              <span className="regular-font"> {name}</span>
            </Typography>
            <VacationRentalOccupancyInfo
              maxGuestsOccupancy={listing.terms.maxOccupancy}
            />
            <Divider className="description-divider" />
            <HotelDescription description={formattedDescription} />
          </Box>
          <DesktopListingSummaryBox
            isVRForPremiumCardHoldersEnabled={isVRForPremiumCardHoldersEnabled}
          />
        </div>
        <Divider className="section-divider" />
        <div
          id="location-section"
          className={"section-wrapper location-section"}
          ref={locationRef}
        >
          <Typography variant="h2" className="section-title">
            {textConstants.LOCATION_TITLE}
          </Typography>
          <VRShopLocationMap
            selectedListing={selectedListing}
            setShowExpandedVRMap={setShowExpandedVRMap}
            showExpandedVRMap={showExpandedVRMap}
            renderPreview
          />
          <ActionLink
            className="property-location-link"
            content={
              <Box className="property-location-link-content">
                <Icon name={IconName.B2BMapPin} />
                <Typography className="property-location-text">
                  {`${
                    (
                      selectedListing.listing.content.location
                        .address as ObfuscatedAddress
                    ).city
                  }, ${
                    (
                      selectedListing.listing.content.location
                        .address as ObfuscatedAddress
                    ).state
                  }`}
                </Typography>
              </Box>
            }
            onClick={() => setShowExpandedVRMap(true)}
          />
        </div>
        <Divider className="section-divider" />
        <div
          id="rooms-section"
          className={"section-wrapper rooms-section"}
          ref={roomsRef}
        >
          <Typography variant="h2" className="section-title">
            {textConstants.ROOMS_AND_BED_TITLE}
          </Typography>
          <RoomsLayoutInfo />
        </div>
        <div
          id="amenities-section"
          className={"section-wrapper amenities-section"}
          ref={amenitiesRef}
        >
          <Box className="left-section">
            <Typography variant="h2" className="amenities-title">
              {textConstants.AMENITIES_TITLE}
            </Typography>
            <Typography variant="body1" className="amenities-subtitle">
              {textConstants.AMENITIES_SUBTITLE}
            </Typography>
            <HotelAmenities
              amenities={selectedListing.listing.content.amenities}
              displayCount={6}
              showAmenitiesText={`View More (${
                selectedListing.listing.content.amenities &&
                selectedListing.listing.content.amenities.length - 6
              })`}
              hideAmenityIconsInTooltip
            />
          </Box>
          <Box
            className="right-section"
            style={
              !!selectedListing.listing.content?.media?.[1]
                ? {
                    backgroundImage: `url("${selectedListing.listing.content?.media[1].url}")`,
                  }
                : undefined
            }
          ></Box>
        </div>
        <div
          id="house-rules-section"
          className={"section-wrapper house-rules-section"}
          ref={houseRulesRef}
        >
          <Typography variant="h2" className="section-title">
            {textConstants.HOUSE_RULES_TITLE}
          </Typography>
          <ListingTermsInfo selectedListing={selectedListing} />
        </div>

        {listing.hostInfo?.hostDescription && listing.hostInfo?.hostName && (
          <>
            <Divider className="section-divider" />
            <div
              id="host-section"
              className={"section-wrapper host-section"}
              ref={hostInfoRef}
            >
              <Box className="host-info">
                <Typography variant="h2" className="section-title">
                  About your host, <strong>{listing.hostInfo?.hostName}</strong>
                </Typography>
                <Typography>{listing.hostInfo?.hostDescription}</Typography>
              </Box>
              {listing.hostInfo?.hostImageUrl && (
                <img
                  className="host-img"
                  src={listing.hostInfo?.hostImageUrl}
                />
              )}
            </div>
          </>
        )}
        <PremiumStaysInfoAccordian
          variant={
            listing.listingCollection === ListingCollectionEnum.Lifestyle
              ? "only-lifestyle-collection"
              : "with-lifestyle-collection"
          }
          includesVacationRentals
          defaultClosed
          stayType={StayTypesEnum.VacationRentals}
        />
      </Box>
    );
  };
  return (
    <Box className={"desktop-vr-shop-wrapper"}>
      {hasFailed && (
        <VRShopErrorModal returnToAvailability={() => returnToAvailability()} />
      )}
      <DesktopRewardsHeader isVR />
      {vrShopCallInProgress ? renderSkeleton() : renderShop()}
    </Box>
  );
};
