import React from "react";

import { Box, Link, Typography } from "@material-ui/core";
import clsx from "clsx";
import H from "history";
import { RouteComponentProps } from "react-router";

import { BannerSeverity, Icon, IconName, NotificationBanner } from "halifax";
import { IIdLodgings, IResult, StayTypesEnum } from "redmond";

import { usePrevious } from "../../../../hooks/usePrevious";
import {
  PATH_AVAILABILITY,
  PATH_HOTEL,
  PATH_VACATION_RENTALS_AVAILABILITY,
} from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import { MegaMenu } from "../MegaMenu";
import { CalendarPickerButton } from "./components/CalendarPickerButton";
import { LocationAutocomplete } from "./components/LocationAutocomplete";
import { OccupancySelection } from "./components/OccupancySelection";
import { HotelSearchButton } from "./components/SearchButton";
import { StayTypeToggleSwitch } from "./components/StayTypeToggle";
import { PremierCollectionSearchControlConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface IPremierCollectionSearchControlProps
  extends PremierCollectionSearchControlConnectorProps,
    RouteComponentProps {
  className?: string;
  onSearch?: (history: H.History) => void;
  displaySearchOnChange?: boolean;
  showTravelerSelection?: boolean;
  showSearchButton?: boolean;
  includesLifestyleCollection?: boolean;
  isLifestyleCollection?: boolean;
  includesVacationRentals?: boolean;
  overrideLocationName?: string;
  supportsMegaMenu?: boolean;
}

export const PremierCollectionSearchControl = (
  props: IPremierCollectionSearchControlProps
) => {
  const {
    className,
    onSearch,
    displaySearchOnChange = false,
    searchLocation,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    showTravelerSelection = true,
    showSearchButton = true,
    history,
    includesLifestyleCollection = false,
    isLifestyleCollection = false,
    stayType,
    includesVacationRentals,
    searchVacationRentalsLocation,
    petsCount,
    overrideLocationName,
    setLocation,
    setOpenDatesModal,
    setIsMegaMenuSelection,
    supportsMegaMenu,
  } = props;
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const [isSearchFromDatePicker, setIsSearchFromDatePicker] = React.useState<boolean>(true);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    React.useState(false);
  const isFirstUpdate = React.useRef<boolean>(true);
  const prevFrom = usePrevious(fromDate);
  const prevUntil = usePrevious(untilDate);
  const prevLocation = usePrevious(searchLocation);
  const prevVacationRentalsLocation = usePrevious(
    searchVacationRentalsLocation
  );
  const prevAdultCount = usePrevious(adultsCount);
  const prevChildrenCount = usePrevious(childrenCount);
  const prevPetsCount = usePrevious(petsCount);

  const getSelectedOption = (option: IResult | null, value: IResult | null) => {
    const selection = value ? (value.id as IIdLodgings) : null;
    const opt = option ? (option.id as IIdLodgings) : null;
    return (
      !!selection &&
      !!opt &&
      selection.lodgingSelection.placeId === opt.lodgingSelection.placeId
    );
  };

  React.useEffect(() => {
    if (
      (searchLocation || searchVacationRentalsLocation) &&
      fromDate &&
      untilDate
    ) {
      const hasChangedDates = fromDate !== prevFrom && untilDate !== prevUntil;
      const hasChangedLocation =
        prevLocation !== searchLocation ||
        prevVacationRentalsLocation !== searchVacationRentalsLocation;
      const hasChangedOccupancy =
        prevAdultCount !== adultsCount || prevChildrenCount !== childrenCount;

      const hasPetsChange = prevPetsCount !== petsCount;
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else if (
        hasChangedDates ||
        hasChangedLocation ||
        hasChangedOccupancy ||
        hasPetsChange
      ) {
        setHasChanged(true);
      }
    }
  }, [
    searchLocation,
    searchVacationRentalsLocation,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
  ]);

  const isReadyToSearch =
    (!!searchLocation || !!searchVacationRentalsLocation) &&
    !!fromDate &&
    !!adultsCount;

  const handleSearch = (history: H.History) => {
    stayType === StayTypesEnum.VacationRentals
      ? history.push(
          `${PATH_VACATION_RENTALS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
            {
              location: (searchVacationRentalsLocation?.id as IIdLodgings)
                .lodgingSelection.searchTerm,
              fromDate,
              untilDate,
              adultsCount,
              childrenCount,
              petsCount,
            }
          )}`
        )
      : history.push(
          `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery({
            location: (searchLocation?.id as IIdLodgings).lodgingSelection
              .searchTerm,
            fromDate,
            untilDate,
            adultsCount,
            childrenCount,
          })}`
        );
    onSearch && onSearch(history);
    setHasChanged(false);
  };
  const handleSearchClick = (history: H.History) => {
    isReadyToSearch
      ? handleSearch(history)
      : setHasMissingSearchInfoError(true);
  };
  return (
    <Box
      className={clsx("premier-collection-search-control-root", className, {
        "includes-vacation-rentals": includesVacationRentals,
        "vacation-rentals": stayType === StayTypesEnum.VacationRentals,
      })}
    >
      <Box className="stays-type-toggle-row">
        {includesVacationRentals && <StayTypeToggleSwitch />}
      </Box>
      <Box className={"hotel-search-control-row"}>
        <LocationAutocomplete
          className={clsx("destination-auto-complete", "b2b")}
          overrideInputValue={overrideLocationName}
          label={
            includesLifestyleCollection
              ? textConstants.LC_AUTOCOMPLETE_PLACEHOLDER
              : textConstants.LOCATION_AUTOCOMPLETE_PLACEHOLDER
          }
          getOptionSelected={getSelectedOption}
          customIcon={
            <Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden={true} />
          }
          hasMissingSearchInfoError={
            hasMissingSearchInfoError && !searchLocation
          }
          popperClassName="premier-collection-autocomplete-popper"
          noOptionsText={
            <Box className="no-options-content">
              <Typography className="no-options-text" variant="body1">
                {stayType === StayTypesEnum.VacationRentals
                  ? textConstants.NO_VR_OPTIONS_TEXT
                  : isLifestyleCollection
                  ? textConstants.NO_LC_OPTIONS_TEXT
                  : includesLifestyleCollection
                  ? textConstants.NO_PREMIUM_STAYS_OPTIONS_TEXT
                  : textConstants.NO_OPTIONS_TEXT}
              </Typography>
              {stayType === StayTypesEnum.Hotels ? (
                <Box className="no-options-link-content">
                  <Typography variant="body2">
                    {isLifestyleCollection
                      ? textConstants.NO_LC_OPTIONS_TO_HOTELS_TEXT
                      : includesLifestyleCollection
                      ? textConstants.NO_PREMIUM_STAYS_OPTIONS_TO_HOTELS_TEXT
                      : textConstants.NO_OPTIONS_TO_HOTELS_TEXT}
                  </Typography>
                  <Link
                    className="no-options-link"
                    onMouseDown={() => {
                      // To be able to create a clickable link here, onMouseDown is needed vs onClick
                      history.push(PATH_HOTEL);
                    }}
                  >
                    <Typography className="no-options-link-text">
                      {textConstants.SEARCH_ALL_HOTELS}
                    </Typography>
                  </Link>
                </Box>
              ) : undefined}
            </Box>
          }
          isLifestyleCollection={isLifestyleCollection}
          includesLifestyleCollection={includesLifestyleCollection}
          includesVacationRentals={includesVacationRentals}
        />
        <Box className={"date-input"}>
          <CalendarPickerButton
            hasMissingSearchInfoError={
              hasMissingSearchInfoError && !fromDate && !untilDate
            }
            hideSeparator
            onClick={() => {
              setOpenDatesModal(true);
              setIsSearchFromDatePicker(false);
            }}
            onSearchDates={isSearchFromDatePicker ? (history) => handleSearch(history) : undefined}
            isSearchButton={isSearchFromDatePicker}
          />
        </Box>
        {showTravelerSelection && (
          <Box className={"occupancy-selection"}>
            <OccupancySelection />
          </Box>
        )}
        {showSearchButton && (!displaySearchOnChange || hasChanged) && (
          <HotelSearchButton
            className={clsx("hotel-search-control-button", "b2b")}
            message={textConstants.SEARCH}
            onClick={(history) => {
              handleSearchClick(history);
              setIsMegaMenuSelection(false);
            }}
            enabled={true}
          />
        )}
      </Box>
      {hasMissingSearchInfoError && (
        <Box className="missing-info-search-error-container">
          <NotificationBanner
            className={clsx("missing-info-search-error-banner")}
            label={textConstants.MISSING_INFO_SEARCH_ERROR}
            severity={BannerSeverity.ERROR}
            icon={<Icon name={IconName.WarningAlert} />}
          />
        </Box>
      )}
      {supportsMegaMenu && stayType == StayTypesEnum.Hotels && (
        <MegaMenu
          select={(result) => {
            setLocation(result);
            setOpenDatesModal(true);
            setIsSearchFromDatePicker(true);
            setIsMegaMenuSelection(true);
          }}
        />
      )}
    </Box>
  );
};
