import { ConnectedProps, connect } from "react-redux";

import { IStoreState } from "../../../../../../reducers/types";
import {
  getIsVentureX,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../../../rewards/reducer";
import { getSearchedNightCount } from "../../../../reducer";
import { AvailabilityMapHotelTooltip } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  accountReferenceId: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
  isVentureX: getIsVentureX(state),
  nightCount: getSearchedNightCount(state),
});

const connector = connect(mapStateToProps);

export type ConnectedAvailabilityMapHotelTooltipProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityMapHotelTooltip = connector(
  AvailabilityMapHotelTooltip
);
