import React from "react";

import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import { ActionLink, Icon, IconName } from "halifax";
import { MegaMenuDestination, MegaMenuSubregion } from "redmond";

import "./styles.scss";

export interface ISubregionViewProps {
  subregion: MegaMenuSubregion;
  expanded: boolean;
  setExpanded: () => void;
  select: (destination: MegaMenuDestination) => void;
}

export const SubregionView = ({
  subregion,
  expanded,
  setExpanded,
  select,
}: ISubregionViewProps) => {
  const { lodgingSelection } = subregion;
  return (
    <Box className={clsx("subregion", { expanded })}>
      <ActionLink
        // tabIndex={0}
        className="subregion-heading"
        onClick={setExpanded}
        content={
          <>
            <Typography variant="body1">
              {subregion.label} ({subregion.hotelCount})
            </Typography>
            <Box className="chevron">
              <Icon name={IconName.RightChevronIcon} />
            </Box>
          </>
        }
      />

      <Box className="subregion-content">
        {lodgingSelection != null && (
          <ActionLink
            tabIndex={!expanded ? -1 : undefined}
            onClick={() =>
              select({
                label: subregion.label,
                hotelCount: subregion.hotelCount,
                lodgingSelection,
              })
            }
            content={
              <Typography
                className={clsx("destination", "see-all")}
                variant="body1"
              >
                All of {subregion.label}
              </Typography>
            }
          />
        )}
        {subregion.destinations.map((destination) => (
          <ActionLink
            tabIndex={!expanded ? -1 : undefined}
            // tabIndex={expanded ? 0 : undefined}
            onClick={() => select(destination)}
            content={
              <Typography className="destination" variant="body1">
                {destination.label} ({destination.hotelCount})
              </Typography>
            }
          />
        ))}
      </Box>
    </Box>
  );
};
