import React from "react";

import { Typography } from "@material-ui/core";

import { MegaMenuSection, MegaMenuSubregion } from "redmond";

import { ListView, ListViewItem } from "..";

export interface ISectionsListViewProps {
  sections: MegaMenuSection[];
  onClick: (subregion: MegaMenuSubregion) => void;
}

export const SectionsListView = ({
  sections,
  onClick,
}: ISectionsListViewProps) => {
  return (
    <ListView
      items={sections.flatMap((section) =>
        (section.title != null
          ? [makeListItemForSectionTitle(section)]
          : []
        ).concat(
          section.subregions.map((subregion) =>
            makeListItemForSubregion(section, subregion, onClick)
          )
        )
      )}
    />
  );
};

const makeListItemForSectionTitle = (
  section: MegaMenuSection
): ListViewItem => ({
  key: `title-${section.title}`,
  drillable: false,
  node: (
    <Typography variant="body1" style={{ fontWeight: 600 }}>
      {section.title}
    </Typography>
  ),
});

const makeListItemForSubregion = (
  section: MegaMenuSection,
  subregion: MegaMenuSubregion,
  onClick: (subregion: MegaMenuSubregion) => void
): ListViewItem => ({
  key: `subregion-${subregion.label}`,
  drillable: true,
  node: (
    <Typography
      variant="body1"
      style={{ fontWeight: section.title == null ? 600 : "normal" }}
    >
      {subregion.label} ({subregion.hotelCount})
    </Typography>
  ),
  onClick: () => onClick(subregion),
});
