import React from "react";
import {
  RewardsAccountSelection as AccountSelection,
  useDeviceTypes,
} from "halifax";

import { RewardsAccountSelectionConnectorProps } from "./container";

export interface IRewardsAccountSelectionProps
  extends RewardsAccountSelectionConnectorProps {
  className?: string;
  popoverClassName?: string;
}

export const RewardsAccountSelection = (
  props: IRewardsAccountSelectionProps
) => {
  const {
    className,
    rewardsAccounts,
    selectedAccountReferenceId,
    fetchRewardsAccounts,
    setSelectedAccountReferenceId,
    popoverClassName,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  React.useEffect(() => {
    fetchRewardsAccounts();
  }, []);

  return (
    <AccountSelection
      className={className}
      selectedAccountReferenceId={selectedAccountReferenceId}
      setSelectedAccountReferenceId={setSelectedAccountReferenceId}
      rewardsAccounts={rewardsAccounts}
      isMobile={matchesMobile}
      popoverClassName={popoverClassName}
    />
  );
};
