import React from "react";

import { Icon, IconName } from "halifax";

import { Box, Typography } from "@material-ui/core";

import "./styles.scss";

export const MegaMenuHeader = () => (
  <Box className="mega-menu-header">
    <Icon name={IconName.Compass} />
    <Typography className="text" variant="body1">
      Explore all hotel and resort destinations
    </Typography>
    <Icon className="chevron" name={IconName.RightChevronIcon} />
  </Box>
);
