export const SEARCH_AGAIN_BUTTON = "Search again";
export const GO_BACK_BUTTON = "Go back";
export const SHOP_ERROR_TITLE =
  "Uh oh! We were unable to process your shop request.";
export const SHOP_ERROR_SUBTITLE = "Something went wrong.";
export const UNAVAILABLE_DATES_ERROR_TITLE =
  "This vacation rental is unavailable for the dates selected.";
export const CHANGE_DATES_BUTTON = "Change dates";
export const BACK_TO_RESULTS_BUTTON = "Back to results";
export const CHOOSE_DATES = "Choose dates";
export const SEARCH_DATES = "Search dates";
