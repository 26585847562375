import { IResponse } from "redmond";
import { put } from "redux-saga/effects";
import Logger from "../../../utils/logger";
import { actions } from "../actions";
import { fetchPCLCLocations } from "../../../api/v1/autocomplete/fetchPCLCLocations";

export function* fetchPCLCLocationsSaga() {
  try {
    const { categories }: IResponse = yield fetchPCLCLocations();
    yield put(
      actions.setPCLCLocationCategories(
        categories.map((category) => ({
          ...category,
          results: category.results.sort((a, b) => {
            // sort alphabetically
            if (a.label > b.label) {
              return 1;
            } else if (a.label < b.label) {
              return -1;
            }
            return 0;
          }),
        }))
      )
    );
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setPCLCLocationCategories([]));
  }
}
