import React, { useEffect, useRef, useMemo } from "react";
import {
  ActionButton,
  HotelPriceAndRewardsDisplay,
  Icon,
  IconName,
} from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { DesktopListingSummaryBoxConnectorProps } from "./container";
import { CalendarPickerButton } from "../../../search/components/PremierCollectionSearchControl/components/CalendarPickerButton";
import { Box, Typography } from "@material-ui/core";
import "./styles.scss";
import * as textConstants from "./textConstants";
import clsx from "clsx";
import { OccupancySelection } from "../../../search/components/PremierCollectionSearchControl/components/OccupancySelection";
import { ListingCollectionEnum, SelectedTravelOfferScreen } from "redmond";
import { goToVacationRentalsCheckout } from "../../../shop/utils/queryStringHelpers";
import { AvailabilityResultEnum } from "redmond/apis/tysons/vacation-rentals";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";

export interface DesktopListingSummaryBoxProps
  extends DesktopListingSummaryBoxConnectorProps,
  RouteComponentProps {
  isSkeleton?: boolean;
  isVRForPremiumCardHoldersEnabled: boolean;
  showOfferBasedOnSelectedListing: boolean;
  isVRCalendarEnabled?: boolean;
}

export const DesktopListingSummaryBox = (
  props: DesktopListingSummaryBoxProps
) => {
  const {
    nightCount,
    isSkeleton,
    rewardsKey,
    listing,
    checkinDate,
    checkoutDate,
    adultsCount,
    childrenCount,
    fetchVacationRentalShop,
    history,
    petsCount,
    isVRForPremiumCardHoldersEnabled,
    isVRCalendarEnabled,
    setOpenDatesModal,
    showOfferBasedOnSelectedListing,
  } = props;

  const isFirstUpdate = useRef<boolean>(true);
  const expState = useExperiments();
  const [showOfferBanner, setShowOfferBanner] = React.useState(false);

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  useEffect(() => {
    if (
      checkinDate &&
      checkoutDate &&
      (adultsCount || childrenCount || petsCount)
    ) {
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else {
        fetchVacationRentalShop(
          history,
          {
            forceCallVRAvailability: true,
            fetchListingCalendar: isVRCalendarEnabled,
          },
          isVRForPremiumCardHoldersEnabled
        );
      }
    }
  }, [checkinDate, checkoutDate, adultsCount, childrenCount, petsCount]);

  useEffect(() => { 
    setShowOfferBanner(
      isTravelWalletOfferExperiment && showOfferBasedOnSelectedListing
    );
  }, [showOfferBasedOnSelectedListing]);

  const available =
    listing?.availability?.AvailabilityResult ===
    AvailabilityResultEnum.Available;

  return (
    <Box
      className={clsx("listing-reserve-summary-container", {
        "lifestyle-collection":
          listing?.listing.listingCollection ===
          ListingCollectionEnum.Lifestyle,
      })}
    >
      {listing?.listing.listingCollection ===
      ListingCollectionEnum.Lifestyle ? (
        <Icon name={IconName.LifestyleCollectionRibbon} />
      ) : (
        <Icon name={IconName.PremierCollectionRibbon} />
      )}
      <Box className="listing-summary">
        {available ? (
          <HotelPriceAndRewardsDisplay
            lodgingPrice={listing?.availability.rate?.price}
            nightCount={nightCount ?? 0}
            isSkeleton={isSkeleton}
            rewardsKey={rewardsKey}
            variant={"default"}
          />
        ) : (
          <Typography className="unavailable-text">
            {textConstants.DATES_UNAVAILABLE_TEXT}
          </Typography>
        )}
        <CalendarPickerButton
          classes={["room-calendar-button"]}
          hideSeparator
        />
        <OccupancySelection
          maxOccupancy={listing?.listing.terms.maxOccupancy}
        />

        {available ? (
          <ActionButton
            className="b2b"
            onClick={() => {
              goToVacationRentalsCheckout({ history });
            }}
            message={
              <Typography variant="h2" className="button-text">
                {textConstants.RESERVE_CTA_TEXT}
              </Typography>
            }
          />
        ) : (
          <ActionButton
            className="b2b"
            onClick={() => setOpenDatesModal(true)}
            message={
              <Typography variant="h2" className="button-text">
                {textConstants.CHANGE_DATES}
              </Typography>
            }
          />
        )}
      </Box>

      <Box className="collection-shop-benefits">
        <Box className="collection-benefits-title">
          <Icon name={IconName.StarOutline} />
          <Typography className="collection-benefits-title-text">
            {listing?.listing.listingCollection ===
            ListingCollectionEnum.Lifestyle
              ? textConstants.LC_BENEFITS_TITLE
              : textConstants.BENEFITS_TITLE}
          </Typography>
        </Box>

        <Typography className="collection-benefits-list">
          {textConstants.getBenefitsList(
            listing?.listing.listingCollection ===
              ListingCollectionEnum.Lifestyle
              ? "lifestyle-collection"
              : "default"
          )}
        </Typography>
        <Box className="additional-benefits">
          <Icon name={IconName.GiftOutline} />
          <Typography className="additional-benefits-text">
            {textConstants.ADDITIONAL_BENEFITS}
          </Typography>
        </Box>
      </Box>
      {showOfferBanner && 
        (<TravelWalletDetailsBanner
          offer={listing?.bestOffer!}
          showButton={false}
          className="home-shop-offer"
          screen={SelectedTravelOfferScreen.PREMIUM_STAYS_DETAILS}
          {...props}
        />
      )}
    </Box>
  );
};
