import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { schedulePriceQuote } from "../../actions/actions";
import { PriceBreakdown } from "./component";
import {
  getPollPriceQuoteCallState,
  getPremierCollectionPricingLineItems,
  getPremierCollectionRewardsAndTotalLineItems,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    pollPriceQuoteCallState: getPollPriceQuoteCallState(state),
    pricingLineItems: getPremierCollectionPricingLineItems(state),
    rewardsAndTotalLineItems:
      getPremierCollectionRewardsAndTotalLineItems(state),
  };
};

const mapDispatchToProps = {
  schedulePriceQuote,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceBreakdownConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceBreakdown = withRouter(connector(PriceBreakdown));
