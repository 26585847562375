import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { MobileMegaMenu } from "./component";
import { actions } from "../../../../actions";
import { getMegaMenuRegions } from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  megaMenuRegions: getMegaMenuRegions(state),
});

const mapDispatchToProps = {
  setLocation: actions.setLocation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileMegaMenuConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileMegaMenu = connector(MobileMegaMenu);
