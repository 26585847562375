import React from "react";
import { RouteComponentProps } from "react-router";
import { Box, Typography } from "@material-ui/core";

import { VacationRentalPriceBreakdownConnectorProps } from "./container";
import {
  LoadingIndicator,
  B2BSpinner,
  HotelPriceBreakdown,
  IHotelPriceLineItem,
} from "halifax";
import { CallState } from "redmond";
import "./styles.scss";
import { CHECKOUT_BREAKDOWN } from "./textConstants";

export interface IVacationRentalPriceBreakdownProps
  extends VacationRentalPriceBreakdownConnectorProps,
    RouteComponentProps {}

export const VacationRentalPriceBreakdown = ({
  pollVacationRentalPriceQuoteCallState,
  pricingLineItems,
  rewardsAndTotalLineItems,
}: IVacationRentalPriceBreakdownProps) => {
  const showLoadingIndicator =
    pollVacationRentalPriceQuoteCallState === CallState.InProcess ||
    pricingLineItems.length === 0;

  const pricingItems = pricingLineItems[0] as IHotelPriceLineItem[];
  const totalItems = pricingLineItems[1] as IHotelPriceLineItem[];

  return (
    <Box className={"vacation-rentals-price-breakdown"}>
      {showLoadingIndicator ? (
        <LoadingIndicator
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={"Fetching latest price"}
        ></LoadingIndicator>
      ) : (
        <>
          <Typography className="price-breakdown-header" variant="h6">
            {CHECKOUT_BREAKDOWN}
          </Typography>
          <HotelPriceBreakdown
            className="pricing-line-items-breakdown"
            pricingItems={pricingItems}
            totalItems={totalItems}
            summaryItems={rewardsAndTotalLineItems}
            showSummaryItems={undefined}
            showTravelCredit={undefined}
            tooltipClassName="vr-pricing-line-items-tooltip"
          />
        </>
      )}
    </Box>
  );
};
