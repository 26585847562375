import React from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";
import { Lodging, LodgingAddressExact } from "redmond";
import { ActionLink, BackButton, CloseButtonIcon, HotelMapPin } from "halifax";
import { GoogleMap, OverlayView } from "@react-google-maps/api";

import { convertICoordinatesToGoogleMapCoords } from "../../../availability/components/AvailabilityMap/googleMapsHelpers";
import {
  AVAILABLE,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

export interface IPremierCollectionShopExpandedMapProps {
  lodging: Lodging;
  showExpandedPremierCollectionMap: boolean;
  setShowExpandedPremierCollectionMap: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isMobile?: boolean;
}

export const PremierCollectionShopExpandedMap = (
  props: IPremierCollectionShopExpandedMapProps
) => {
  const {
    lodging,
    showExpandedPremierCollectionMap,
    setShowExpandedPremierCollectionMap,
    isMobile,
  } = props;

  const expState = useExperiments();

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const center = convertICoordinatesToGoogleMapCoords(
    lodging.lodging.location.coordinates
  );

  return (
    <Dialog
      open={showExpandedPremierCollectionMap}
      onClose={() => setShowExpandedPremierCollectionMap(false)}
      className={clsx("expanded-pc-map-popover", { mobile: isMobile })}
      /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
      TransitionProps={{ role: "none" } as never}
      PaperProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
      }}
      disableEnforceFocus
    >
      {isMobile ? (
        <BackButton
          className={"b2b"}
          onClick={() => setShowExpandedPremierCollectionMap(false)}
        ></BackButton>
      ) : (
        <ActionLink
          className="expanded-pc-map-close-button"
          onClick={() => setShowExpandedPremierCollectionMap(false)}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea={true}
        />
      )}
      <Box className="expanded-pc-map-content">
        <Box className="expanded-pc-map-title">{lodging.lodging.name}</Box>
        <Typography className="expanded-pc-map-address" variant="body2">
          {(lodging.lodging.address as LodgingAddressExact).line1}
        </Typography>
        <Box className="expanded-pc-map-container">
          <GoogleMap
            id="expanded-pc-map"
            mapContainerStyle={{ height: "100%", width: "100%" }}
            options={{
              clickableIcons: false,
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              gestureHandling: isGlobalMobileNavExperiment
                ? "greedy"
                : undefined,
            }}
            center={center}
            zoom={16}
          >
            <ExpandedPremierCollectionMapContext
              {...props}
            ></ExpandedPremierCollectionMapContext>
          </GoogleMap>
        </Box>
      </Box>
    </Dialog>
  );
};

const ExpandedPremierCollectionMapContext = (
  props: IPremierCollectionShopExpandedMapProps
) => {
  const { lodging } = props;
  const center = convertICoordinatesToGoogleMapCoords(
    lodging.lodging.location.coordinates
  );

  return (
    <>
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={center}
      >
        <HotelMapPin />
      </OverlayView>
    </>
  );
};
