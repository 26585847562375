export const FETCH_LOCATION_CATEGORIES =
  "premierCollectionSearch/FETCH_LOCATION_CATEGORIES";
export type FETCH_LOCATION_CATEGORIES = typeof FETCH_LOCATION_CATEGORIES;

export const FETCH_LIFESTYLE_LOCATION_CATEGORIES =
  "premierCollectionSearch/FETCH_LIFESTYLE_LOCATION_CATEGORIES";
export type FETCH_LIFESTYLE_LOCATION_CATEGORIES =
  typeof FETCH_LIFESTYLE_LOCATION_CATEGORIES;

export const SET_LOCATION_CATEGORIES =
  "premierCollectionSearch/SET_LOCATION_CATEGORIES";
export type SET_LOCATION_CATEGORIES = typeof SET_LOCATION_CATEGORIES;

export const SET_LOCATION = "premierCollectionSearch/SET_LOCATION";
export type SET_LOCATION = typeof SET_LOCATION;

export const FETCH_PCLC_LOCATION_CATEGORIES =
  "premierCollectionSearch/FETCH_PCLC_LOCATION_CATEGORIES";
export type FETCH_PCLC_LOCATION_CATEGORIES =
  typeof FETCH_PCLC_LOCATION_CATEGORIES;

export const SET_PCLC_LOCATION_CATEGORIES =
  "premierCollectionSearch/SET_PCLC_LOCATION_CATEGORIES";
export type SET_PCLC_LOCATION_CATEGORIES = typeof SET_PCLC_LOCATION_CATEGORIES;

export const FETCH_MEGAMENU_REGIONS =
  "premierCollectionSearch/FETCH_MEGAMENU_REGIONS";
export type FETCH_MEGAMENU_REGIONS = typeof FETCH_MEGAMENU_REGIONS;

export const SET_MEGAMENU_REGIONS =
  "premierCollectionSearch/SET_MEGAMENU_REGIONS";
export type SET_MEGAMENU_REGIONS = typeof SET_MEGAMENU_REGIONS;

export const FETCH_MEGAMENU_ENABLED =
  "premierCollectionSearch/FETCH_MEGAMENU_ENABLED";
export type FETCH_MEGAMENU_ENABLED = typeof FETCH_MEGAMENU_ENABLED;

export const SET_MEGAMENU_ENABLED =
  "premierCollectionSearch/SET_MEGAMENU_ENABLED";
export type SET_MEGAMENU_ENABLED = typeof SET_MEGAMENU_ENABLED;

export const FETCH_IS_MEGAMENU_SELECTION =
  "premierCollectionSearch/FETCH_IS_MEGAMENU_SELECTION";
export type FETCH_IS_MEGAMENU_SELECTION = typeof FETCH_IS_MEGAMENU_SELECTION;

export const SET_IS_MEGAMENU_SELECTION =
  "premierCollectionSearch/SET_IS_MEGAMENU_SELECTION";
export type SET_IS_MEGAMENU_SELECTION = typeof SET_IS_MEGAMENU_SELECTION;

export const SET_FROM_DATE = "premierCollectionSearch/SET_FROM_DATE";
export type SET_FROM_DATE = typeof SET_FROM_DATE;

export const SET_UNTIL_DATE = "premierCollectionSearch/SET_UNTIL_DATE";
export type SET_UNTIL_DATE = typeof SET_UNTIL_DATE;

export const SET_OCCUPANCY_COUNTS =
  "premierCollectionSearch/SET_OCCUPANCY_COUNTS";
export type SET_OCCUPANCY_COUNTS = typeof SET_OCCUPANCY_COUNTS;

export const SET_LOCATION_AUTOCOMPLETE_ERROR =
  "premierCollectionSearch/SET_LOCATION_AUTOCOMPLETE_ERROR";
export type SET_LOCATION_AUTOCOMPLETE_ERROR =
  typeof SET_LOCATION_AUTOCOMPLETE_ERROR;

export const SET_LOCATION_SEARCH_STRING =
  "premierCollectionSearch/SET_LOCATION_SEARCH_STRING";
export type SET_LOCATION_SEARCH_STRING = typeof SET_LOCATION_SEARCH_STRING;

export const SET_STAY_TYPE = "premierCollectionSearch/SET_STAY_TYPE";
export type SET_STAY_TYPE = typeof SET_STAY_TYPE;

export const FETCH_VACATION_RENTALS_LOCATION_CATEGORIES =
  "premierCollectionSearch/FETCH_VACATION_RENTALS_LOCATION_CATEGORIES";
export type FETCH_VACATION_RENTALS_LOCATION_CATEGORIES =
  typeof FETCH_VACATION_RENTALS_LOCATION_CATEGORIES;

export const SET_VACATION_RENTALS_LOCATION_CATEGORIES =
  "premierCollectionSearch/SET_VACATION_RENTALS_LOCATION_CATEGORIES";
export type SET_VACATION_RENTALS_LOCATION_CATEGORIES =
  typeof SET_VACATION_RENTALS_LOCATION_CATEGORIES;

export const SET_VACATION_RENTALS_LOCATION =
  "premierCollectionSearch/SET_VACATION_RENTALS_LOCATION";
export type SET_VACATION_RENTALS_LOCATION =
  typeof SET_VACATION_RENTALS_LOCATION;
