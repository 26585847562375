import { connect } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { withRouter } from "react-router";
import { ActionButtonWithRoute } from "halifax";

const mapStateToProps = (state: IStoreState) => {
  const location = state.premierCollectionAvailability.searchLocationResult;
  const fromDate = state.premierCollectionAvailability.searchFromDate;
  const untilDate = state.premierCollectionAvailability.searchUntilDate;

  const hasSetLocation = location != null;
  const hasSetDates = fromDate != null && untilDate != null;

  return {
    disabled: !(hasSetLocation && hasSetDates),
  };
};

export const ConnectedHotelSearchButton = connect(mapStateToProps)(
  withRouter(ActionButtonWithRoute)
);
