import React from "react";

import { Box } from "@material-ui/core";
import clsx from "clsx";
import H from "history";
import { RouteComponentProps } from "react-router";

import {
  BannerSeverity,
  DatePickerButton,
  Icon,
  IconName,
  MobileSearchFieldButton,
  NotificationBanner,
} from "halifax";
import { IIdLodgings } from "redmond";

import {
  AVAILABLE,
  VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";
import { PATH_AVAILABILITY } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import { HotelSearchButton } from "../PremierCollectionSearchControl/components/SearchButton";
import { MobileCalendarPickerModal } from "./components/MobileCalendarPickerModal";
import { MobileLocationSearchModal } from "./components/MobileLocationSearchModal";
import { OccupancySelection } from "./components/OccupancySelection";
import { MobileHotelSearchV2ConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface IMobilePremierCollectionSearchControlProps
  extends MobileHotelSearchV2ConnectorProps,
    RouteComponentProps {
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (from: Date | null, until: Date | null) => void;
}

export const MobilePremierCollectionSearchControl = (
  props: IMobilePremierCollectionSearchControlProps
) => {
  const {
    value,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
    history,
    onSelectLocation,
    onSelectDates,
  } = props;
  const [openLocationModal, setOpenLocationModal] = React.useState(false);
  const [openCalendarModal, setOpenCalendarModal] = React.useState(false);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    React.useState(false);

  const expState = useExperiments();

  const isReadyToSearch = !!value && !!fromDate && !!untilDate && !!adultsCount;

  const VRForPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForPremiumCardHoldersEnabled = React.useMemo(
    () => VRForPremiumCardHolders === AVAILABLE,
    [VRForPremiumCardHolders]
  );

  const VRForNonPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForNonPremiumCardHoldersEnabled = React.useMemo(
    () => VRForNonPremiumCardHolders === AVAILABLE,
    [VRForNonPremiumCardHolders]
  );

  React.useEffect(() => {
    if (isReadyToSearch) {
      setHasMissingSearchInfoError(false);
    }
  }, [value, fromDate, untilDate, adultsCount]);

  const handleSearch = (history: H.History) => {
    history.push(
      `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery({
        location: (value?.id as IIdLodgings).lodgingSelection.searchTerm || [],
        fromDate,
        untilDate,
        adultsCount,
        childrenCount,
        petsCount,
      })}`
    );
  };

  const handleSearchClick = (history: H.History) => {
    isReadyToSearch
      ? handleSearch(history)
      : setHasMissingSearchInfoError(true);
  };
  return (
    <Box className="mobile-premier-collection-search">
      <OccupancySelection />
      <MobileSearchFieldButton
        value={value?.label}
        label={
          value?.label
            ? textConstants.WHERE_TO
            : textConstants.WHERE_ARE_YOU_STAYING
        }
        icon={<Icon name={IconName.B2BMapPin} />}
        renderPopup={() => (
          <MobileLocationSearchModal
            openLocationModal={openLocationModal}
            setOpenLocationModal={setOpenLocationModal}
            history={history}
            onSelectLocation={onSelectLocation}
          />
        )}
        onClick={() => setOpenLocationModal(true)}
        isMissingSearchInfo={hasMissingSearchInfoError && !value}
      />

      {!fromDate || !untilDate ? (
        <MobileSearchFieldButton
          label={textConstants.CHOOSE_DATES}
          icon={<Icon name={IconName.Calendar} />}
          renderPopup={() => (
            <MobileCalendarPickerModal
              openCalendarModal={openCalendarModal}
              setOpenCalendarModal={setOpenCalendarModal}
              handleIncompleteSearch={setHasMissingSearchInfoError}
              onSelectDates={onSelectDates}
              includesVacationRentals={
                isVRForPremiumCardHoldersEnabled ||
                isVRForNonPremiumCardHoldersEnabled
              }
            />
          )}
          onClick={() => setOpenCalendarModal(true)}
          isMissingSearchInfo={
            hasMissingSearchInfoError && (!fromDate || !untilDate)
          }
        />
      ) : (
        <DatePickerButton
          startDate={fromDate}
          endDate={untilDate}
          startLabel={textConstants.CHECK_IN}
          endLabel={textConstants.CHECKOUT}
          classes={["hotel-inline-juncture-input"]}
          renderCalendarPopup={() => (
            <MobileCalendarPickerModal
              openCalendarModal={openCalendarModal}
              setOpenCalendarModal={setOpenCalendarModal}
              handleIncompleteSearch={setHasMissingSearchInfoError}
              onSelectDates={onSelectDates}
              includesVacationRentals={
                isVRForPremiumCardHoldersEnabled ||
                isVRForNonPremiumCardHoldersEnabled
              }
            />
          )}
          onClick={() => setOpenCalendarModal(true)}
          dateFormat={"ddd, MMM D"}
        />
      )}

      {hasMissingSearchInfoError && (
        <Box className="missing-info-search-error-container">
          <NotificationBanner
            className={clsx("missing-info-search-error-banner")}
            label={textConstants.MISSING_INFO_SEARCH_ERROR}
            severity={BannerSeverity.ERROR}
            icon={<Icon name={IconName.WarningAlert} />}
          />
        </Box>
      )}
      <HotelSearchButton
        className={clsx("mobile-hotel-search-control-button", "b2b")}
        message={textConstants.SEARCH_PREMIER_HOTELS}
        onClick={(history) => {
          handleSearchClick(history);
        }}
        enabled
      />
    </Box>
  );
};
