import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import schedulePayment from "../../../../api/v0/book/book-flow/schedulePayment";
import {
  ConnectionResultEnum,
  InvalidEnum,
  PaymentSessionToken,
} from "redmond";
import { getOffers, getOpaquePayments, getSession } from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { Payment } from "@b2bportal/purchase-api";
import { getHSPEarnOfferDescription } from "halifax";
import { authorizeEarnOffer } from "../../../../api/v0/travel-wallet/authorizeEarnOffer";

export function* scheduleBookSaga({
  agentFee,
  isRecommended,
  ancillaries,
}: actions.ISetScheduleBook) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    if (!session) {
      throw Error("Session is undefined");
    }
    const payments = getOpaquePayments(state);
    if (!payments) {
      throw new Error("Invalid Request.");
    }

    const offers = getOffers(state);
    const appliedOfferPayment = payments.find(
      (payment) => payment.type === Payment.TravelWalletOffer
    );

    const appliedOffer = offers?.find(
      (offer) => offer.id === appliedOfferPayment?.value.offerId
    );

    const hspEarnDescription = getHSPEarnOfferDescription(appliedOffer);

    // authorize hsp earn offer before fulfillment
    if (hspEarnDescription && appliedOffer?.id) {
      yield authorizeEarnOffer({
        earnOfferId: appliedOffer.id,
      });
    }

    const corporateOutOfPolicyReason =
      state.premierCollectionBook.approvalRequestReason;

    const result: string = yield schedulePayment(
      session,
      hspEarnDescription
        ? payments.filter(
            (payment) => payment.type !== Payment.TravelWalletOffer // don't include HSP earn offer in payments since it will fail
          )
        : payments,
      ancillaries,
      corporateOutOfPolicyReason
    );

    // update the session to the fulfill session token
    yield put(actions.setSession({ value: result } as PaymentSessionToken));
    yield put(
      actions.pollConfirmationDetails(session!, agentFee, isRecommended)
    );
    yield put(actions.setScheduleBookSuccess());
  } catch (e) {
    yield put(
      actions.setScheduleBookFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
