import { connect, ConnectedProps } from "react-redux";

import { StayTypeToggleSwitch } from "./component";

import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import { setStayType } from "../../../../actions/actions";
import { getStayType } from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  stayType: getStayType(state),
});

const mapDispatchToProps = {
  setStayType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type StayTypeToggleSwitchConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedStayTypeToggleSwitch = withRouter(
  connector(StayTypeToggleSwitch)
);
