import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import {
  getFromDate,
  getNightCount,
  getUntilDate,
} from "../../../search/reducer";
import {
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopChosenProductIndex,
  getPremierCollectionShopChosenRoomInfo,
  getPremierCollectionShopChosenRoomInfoIndex,
  getPremierCollectionShopRoomInfoProducts,
  getPremierCollectionShopSelectedAvailability,
} from "../../reducer/selectors";
import { MobileRoomPicker } from "./component";
import { actions } from "../../actions";
import { withRouter } from "react-router";

const mapStateToProps = (state: IStoreState) => {
  return {
    checkinDate: getFromDate(state),
    checkoutDate: getUntilDate(state),
    roomInfoProducts: getPremierCollectionShopRoomInfoProducts(state),
    chosenRoomInfoIndex: getPremierCollectionShopChosenRoomInfoIndex(state),
    chosenRoomInfo: getPremierCollectionShopChosenRoomInfo(state),
    chosenProductIndex: getPremierCollectionShopChosenProductIndex(state),
    chosenProduct: getPremierCollectionShopChosenProduct(state),
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    nightCount: getNightCount(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
  };
};

export const mapDispatchToProps = {
  selectRoomType: actions.selectRoomType,
  fetchPremierCollectionShop: actions.fetchPremierCollectionShop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileRoomPickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileRoomPicker = withRouter(
  connector(MobileRoomPicker)
);
