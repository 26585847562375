export const LOCATION_AUTOCOMPLETE_LABEL = "Where are you staying?";
export const LOCATION_AUTOCOMPLETE_PLACEHOLDER = "Where are you staying?";
export const LC_AUTOCOMPLETE_PLACEHOLDER = "Where are you going?";
export const AUTOCOMPLETE_RESULTS_GROUP_LABEL = "Premier Collection Locations";
export const SEARCH = "Search";
export const NO_OPTIONS_TEXT = "No premier hotels found.";
export const NO_OPTIONS_TO_HOTELS_TEXT =
  "You may find a hotel in this location by searching outside of our Premier Collection.";
export const SEARCH_ALL_HOTELS = "Search All Hotels";
export const MISSING_INFO_SEARCH_ERROR =
  "To continue, please complete all required fields.";
export const LC_AUTOCOMPLETE_RESULTS_GROUP_LABEL =
  "Lifestyle Collection Locations";
export const NO_LC_OPTIONS_TEXT = "No Lifestyle Collection hotels found.";
export const NO_LC_OPTIONS_TO_HOTELS_TEXT =
  "You may find a hotel in this location by searching outside of our Lifestyle Collection.";
export const PREMIUM_STAYS_AUTOCOMPLETE_RESULTS_GROUP_LABEL =
  "Premium Stays Locations";
export const NO_PREMIUM_STAYS_OPTIONS_TEXT = "No premium stays found.";
export const NO_PREMIUM_STAYS_OPTIONS_TO_HOTELS_TEXT =
  "You may find a hotel in this location by searching outside of Premier or Lifestyle Collections.";
export const VACATION_RENTALS_AUTOCOMPLETE_RESULTS_LABEL =
  "All vacation rentals location";
export const HOTELS_WITH_VR_AUTOCMPLETE_RESULTS_LABEL =
  "All hotel and resort locations";
export const NO_VR_OPTIONS_TEXT = "No vacation rentals found in this location";
