import { ConnectedProps, connect } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import { actions as searchActions } from "../../../../../search/actions";
import * as searchSelectors from "../../../../../search/reducer/selectors";
import { actions } from "../../../../actions";
import {
  getCurrentSearchParams,
  getMapSearchCategoriesFilteredBySearchString,
  getMapSearchCategoriesLoading,
} from "../../../../reducer";
import { ViewHotelsNearAutocomplete } from "./component";

export const mapDispatchToProps = {
  setHotelsSearchLocation: searchActions.setLocation,
  setVacationRentalsSearchLocation: searchActions.setVacationRentalsLocation,
  fetchInitialHotelsAvailability:
    actions.fetchInitialPremierCollectionAvailability,
  fetchInitialVacationRentalsAvailability:
    actions.fetchInitialVacationRentalsAvailability,
  setMapSearchQuery: actions.setMapSearchQuery,
};

export const mapStateToProps = (state: IStoreState) => {
  let loading = getMapSearchCategoriesLoading(state) ?? false;

  return {
    currentMapSearchQuery: state.premierCollectionAvailability.mapSearchQuery,
    currentHotelsSearchLocation: searchSelectors.getLocation(state),
    currentVacationRentalsSearchLocaction:
      searchSelectors.getVacationRentalsLocation(state),
    valueCategories: getMapSearchCategoriesFilteredBySearchString(state),
    availabilitySearchParams: getCurrentSearchParams(state),
    loading,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ConnectedViewHotelsNearLocationAutocomplete = connector(
  withRouter(ViewHotelsNearAutocomplete)
);

export type ViewHotelsNearLocationAutocompleteConnectorProps = ConnectedProps<
  typeof connector
>;
