import { put } from "redux-saga/effects";
import {
  IFetchCfarQuotes,
  setFetchCfarOffersCallStateFailed,
  setCfarQuotes,
} from "../actions/actions";
import Logger from "../../../utils/logger";
import { GetCfarQuotesResponse, GetCfarQuotesSuccess } from "redmond";
import { fetchCfarQuotes } from "../../../api/v1/ancillary/fetchCfarQuotes";
import { GetCfarQuotesResponseEnum } from "redmond/apis/tysons/ancillaries";

export function* fetchCfarQuotesSaga(action: IFetchCfarQuotes) {
  try {
    const { request } = action;

    const response: GetCfarQuotesResponse = yield fetchCfarQuotes(request);

    const successResponse: GetCfarQuotesSuccess =
      response as GetCfarQuotesSuccess;

    if (
      successResponse &&
      successResponse?.GetCfarQuotesResponse ===
        GetCfarQuotesResponseEnum.Success
    ) {
      yield put(setCfarQuotes({ response: successResponse }));
    } else {
      yield put(setFetchCfarOffersCallStateFailed());
    }
  } catch (e) {
    yield put(setFetchCfarOffersCallStateFailed());
    Logger.debug(e);
  }
}
