import React from "react";

import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { RouteComponentProps } from "react-router";

import {
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";
import {
  IIdLodgings,
  LAUNCHED_MOBILE_CALENDAR,
  LaunchedMobileCalendarProperties,
  StayTypesEnum,
} from "redmond";

import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import {
  PATH_AVAILABILITY,
  PATH_VACATION_RENTALS_AVAILABILITY,
} from "../../../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../../../shop/utils/queryStringHelpers";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../PremierCollectionSearchControl/components/MonthAndDatePicker";
import { HotelSearchButton } from "../../../PremierCollectionSearchControl/components/SearchButton";
import { MobileCalendarPickerModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface IMobileCalendarPickerModalProps
  extends MobileCalendarPickerModalConnectorProps,
    RouteComponentProps {
  focusedMonthIndex?: number;
  openCalendarModal: boolean;
  setOpenCalendarModal: (val: boolean) => void;
  handleIncompleteSearch?: (val: boolean) => void;
  searchOnContinue?: boolean;
  onSelectDates?: (from: Date | null, until: Date | null) => void;
  includesVacationRentals?: boolean;
}

export const MobileCalendarPickerModal = (
  props: IMobileCalendarPickerModalProps
) => {
  const {
    fromDate,
    untilDate,
    searchLocation,
    adultsCount,
    childrenCount,
    petsCount,
    setFromDate,
    setUntilDate,
    focusedMonthIndex,
    openCalendarModal,
    setOpenCalendarModal,
    handleIncompleteSearch,
    searchOnContinue = false,
    history,
    onSelectDates,
    includesVacationRentals,
    stayType,
    searchVacationRentalsLocation,
  } = props;

  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  React.useEffect(() => {
    if (window.location.pathname.includes("/search/")) {
      const properties: LaunchedMobileCalendarProperties = {
        funnel: "premier_collection",
        url: window.location.pathname,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_CALENDAR, properties });
    }
  }, []);

  const isReadyToSearch =
    (stayType === StayTypesEnum.VacationRentals && includesVacationRentals
      ? !!searchVacationRentalsLocation
      : !!searchLocation) &&
    !!from &&
    !!until &&
    !!adultsCount;

  const handleContinue = () => {
    setFromDate(from);
    setUntilDate(until);
    setOpenCalendarModal(false);
    onSelectDates?.(from, until);
    isReadyToSearch
      ? (setOpenCalendarModal(false),
        stayType === StayTypesEnum.VacationRentals && includesVacationRentals
          ? history.push(
              `${PATH_VACATION_RENTALS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
                {
                  location: (searchVacationRentalsLocation?.id as IIdLodgings)
                    .lodgingSelection.searchTerm,
                  fromDate: from,
                  untilDate: until,
                  adultsCount,
                  childrenCount: childrenCount,
                  petsCount,
                }
              )}`
            )
          : history.push(
              `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery({
                location: (searchLocation?.id as IIdLodgings).lodgingSelection
                  .searchTerm,
                fromDate: from,
                untilDate: until,
                adultsCount,
                childrenCount: childrenCount,
              })}`
            ))
      : (setOpenCalendarModal(false), handleIncompleteSearch?.(true));
  };

  const handleClose = () => {
    !searchOnContinue && setFromDate(from);
    !searchOnContinue && setUntilDate(until);
    searchOnContinue && setFrom(fromDate);
    searchOnContinue && setUntil(untilDate);
    setOpenCalendarModal(false);
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {textConstants.CHOOSE_DATES}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };
  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <MobilePopoverCard
      open={openCalendarModal}
      className={"mobile-premier-collection-search-calendar-popup"}
      onClose={handleClose}
      headerElement={<MobileSearchPopoverHeader />}
    >
      <Box className="premier-collection-mobile-calendar-picker-root">
        <MonthAndDatePicker
          className="b2b"
          viewType={MonthAndDatePickerType.Column}
          focusedMonthIndex={focusedMonthIndex}
          setFromDate={setFrom}
          setUntilDate={setUntil}
          from={from}
          until={until}
          header={
            <Box className="premier-collection-mobile-calendar-header">
              <DatePickerHeader
                label={textConstants.CHECK_IN}
                value={from ? dayjs(from).format("ddd D, MMM") : undefined}
              />
              <DatePickerHeader
                label={textConstants.CHECK_OUT}
                value={until ? dayjs(until).format("ddd D, MMM") : undefined}
              />
            </Box>
          }
        />
        {from && until && (
          <HotelSearchButton
            className={clsx("mobile-calendar-picker-search-button", "b2b")}
            message={
              stayType === StayTypesEnum.VacationRentals &&
              includesVacationRentals
                ? textConstants.SEARCH_VACATION_RENTALS
                : textConstants.SEARCH_PREMIER_HOTELS
            }
            onClick={handleContinue}
            from={from}
            until={until}
            enabled={from && until ? true : false}
          />
        )}
      </Box>
    </MobilePopoverCard>
  );
};
