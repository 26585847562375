import React from "react";

import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";

import {
  ActionLink,
  BannerSeverity,
  Icon,
  IconName,
  NotificationBanner,
} from "halifax";
import {
  IdEnum,
  IResult,
  MegaMenuDestination,
  MegaMenuRegion,
  MegaMenuSubregion,
  NonEmptyArray,
  MEGA_MENU_ENTRY,
} from "redmond";

import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

import { MegaMenuHeader, SectionView } from "./components";
import { MegaMenuProps } from "./container";

import "./styles.scss";

export interface IMegaMenuProps extends MegaMenuProps, RouteComponentProps {
  select: (result: IResult) => void;
}

export const MegaMenu = (props: IMegaMenuProps): JSX.Element | null => {
  const { regions, select } = props;

  const [active, setActive] = React.useState(false);

  if (regions == null) {
    return null;
  }

  return (
    <Box
      className={clsx("pc-mega-menu", { active })}
      aria-expanded={active}
      aria-label="Explore all hotel and resort destinations"
      role="button"
    >
      <NotificationBanner
        severity={BannerSeverity.INFO}
        onClick={() => 
          {
            setActive(!active)
            trackEvent({
              eventName: MEGA_MENU_ENTRY,
              properties: { is_expanded: active, },
            });
          }
        }
        content={<MegaMenuHeader />}
      />
      <MegaMenuContent
        isOpen={active}
        regions={regions}
        select={(dest) =>
          select({
            label: dest.label,
            id: {
              Id: IdEnum.Lodgings,
              lodgingSelection: dest.lodgingSelection,
            },
          })
        }
      />
    </Box>
  );
};

const MegaMenuContent = ({
  regions,
  isOpen,
  select,
}: {
  isOpen: boolean;
  regions: NonEmptyArray<MegaMenuRegion>;
  select: (destination: MegaMenuDestination) => void;
}) => {
  const [selectedRegion, setSelectedRegion] = React.useState(regions[0]);
  const [expandedSubregion, setExpandedSubregion] = React.useState<
    undefined | MegaMenuSubregion
  >();

  const firstItemRef = React.useRef<HTMLButtonElement | null>(null);
  React.useEffect(() => {
    if (isOpen) {
      firstItemRef.current?.focus();
    }
  }, [firstItemRef.current, isOpen]);

  return (
    <Box className="mega-menu-content">
      <Box className="regions" role="menu">
        {regions.map((region, index) => (
          <ActionLink
            role="menuitem"
            linkRef={index === 0 ? firstItemRef : undefined}
            key={region.label}
            onClick={() => setSelectedRegion(region)}
            content={
              <Box
                className={clsx("region", {
                  selected: region === selectedRegion,
                })}
              >
                <img src={region.imageUrl} alt=""></img>
                <Typography className="text" variant="h3">
                  {region.label}
                </Typography>
                <Box className="chevron">
                  <Icon name={IconName.RightChevronIcon} />
                </Box>
              </Box>
            }
          />
        ))}
      </Box>
      <Box className="sections">
        <Typography className="title" variant="h3">
          {selectedRegion.label}
        </Typography>
        <Box className="content">
          <React.Fragment key={`${selectedRegion.label}`}>
            {selectedRegion.sections.map((section, index) => (
              <SectionView
                key={`${index}`}
                section={section}
                expandedSubregion={expandedSubregion}
                setExpanded={setExpandedSubregion}
                select={select}
              />
            ))}
          </React.Fragment>
        </Box>
      </Box>
    </Box>
  );
};
