import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getLocation,
  getLocationCategoriesLoading,
  getHasLocationAutocompleteError,
  getLocationCategoriesFilteredBySearchString,
  getStayType,
  getVacationRentalsLocation,
} from "../../../../reducer";
import { LocationSearch } from "./component";

const mapDispatchToProps = {
  setLocation: actions.setLocation,
  setLocationSearchString: actions.setLocationSearchString,
  setLocationAutocompleteError: actions.setLocationAutocompleteError,
  setVacationRentalsLocation: actions.setVacationRentalsLocation,
};
const mapStateToProps = (state: IStoreState) => ({
  location: getLocation(state),
  locationCategories: getLocationCategoriesFilteredBySearchString(state),
  loading: !!getLocationCategoriesLoading(state),
  hasLocationAutocompleteError: getHasLocationAutocompleteError(state),
  stayType: getStayType(state),
  vacationRentalsLocation: getVacationRentalsLocation(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LocationSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedLocationSearch = connector(LocationSearch);
