import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { getMegaMenuRegions } from "../../reducer";
import { MegaMenu } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  regions: getMegaMenuRegions(state),
});

const connector = connect(mapStateToProps);

export type MegaMenuProps = ConnectedProps<typeof connector>;

export const ConnectedMegaMenu = withRouter(connector(MegaMenu));
