import axios from "axios";
import { config } from "../../config";
import { IResponse } from "redmond";
import { vacationRentalsLocationApi } from "../paths";

export const fetchVacationRentalsLocations = (): Promise<IResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        vacationRentalsLocationApi,
        { query: { LocationQuery: "Label", l: "" } },
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
