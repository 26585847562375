import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import { getNightCount, getStayType } from "../../../search/reducer";

import {
  getCheapestProduct,
  getPremierCollectionShopRoomInfoProducts,
  getPremierCollectionShopSelectedAvailability,
  getViewedPremierCollectionDetailsProperties,
} from "../../reducer/selectors";
import { DesktopHotelPhotoModal } from "./component";
import { getVacationRentalShopSelectedListing } from "../../../vacation-rental-shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    roomInfoProducts: getPremierCollectionShopRoomInfoProducts(state),
    lodging: getPremierCollectionShopSelectedAvailability(state) || undefined,
    roomProduct: getCheapestProduct(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    viewedPremierCollectionDetailsProperties:
      getViewedPremierCollectionDetailsProperties(state),
    stayType: getStayType(state),
    listing: getVacationRentalShopSelectedListing(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopHotelPhotoModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopHotelPhotoModal = withRouter(
  connector(DesktopHotelPhotoModal)
);
