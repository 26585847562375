import { put, call, select } from "redux-saga/effects";
import {
  ConnectionResultEnum,
  InvalidEnum,
  PaymentSessionToken,
} from "redmond";
import { actions } from "../../actions";
import schedulePriceQuote from "../../../../api/v0/book/book-flow/schedulePriceQuote";
import { IStoreState } from "../../../../reducers/types";
import {
  getSession,
  vacationRentalPriceQuoteParamsSelector,
} from "../../reducer";
import closeSession from "../../../../api/v0/book/session/closeSession";
import * as H from "history";
import { Product } from "@b2bportal/purchase-api";

// Wrapper function that first opens session, and then calls schedule price quote.
export function* scheduleVacationRentalPriceQuoteSaga({
  emailAddress,
  agentFee,
  pollQuoteOnly,
  history,
}: actions.IScheduleVacationRentalPriceQuote) {
  yield call(
    callScheduleVacationRentalPriceQuote,
    emailAddress,
    agentFee,
    pollQuoteOnly,
    history
  );
}

function* callScheduleVacationRentalPriceQuote(
  emailAddress: string,
  agentFee?: number,
  pollQuoteOnly?: boolean,
  history?: H.History
) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    if (session) {
      yield closeSession(session);
      yield put(actions.clearSession());
    }
    const params = vacationRentalPriceQuoteParamsSelector(state);
    if (!params) {
      throw new Error("Invalid Request.");
    }

    const result: String = yield schedulePriceQuote(
      params,
      emailAddress,
      Product.Homes
    );

    yield put(actions.setScheduleVacationRentalPriceQuoteCallStateSuccess());
    yield put(actions.setSession({ value: result } as PaymentSessionToken));
    yield put(
      actions.pollVacationRentalsPriceQuote({
        agentFee,
        pollQuoteOnly,
        history,
      })
    );
  } catch (e) {
    yield put(
      actions.setScheduleVacationRentalPriceQuoteCallStateFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
