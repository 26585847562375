import { takeLatest } from "redux-saga/effects";

import { FETCH_PREMIER_COLLECTION_SHOP } from "../actions/constants";
import fetchPremierCollectionShopSaga from "./fetchPremierCollectionShopSaga";

export function* watchFetchPremierCollectionShopSaga() {
  yield takeLatest(
    FETCH_PREMIER_COLLECTION_SHOP,
    fetchPremierCollectionShopSaga
  );
}
