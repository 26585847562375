export const getPriceAndRewardsCopy = ({
  price,
  rewards,
  separator,
}: {
  price: string;
  rewards?: string;
  separator?: string;
}) =>
  `<strong>${price}</strong>${
    rewards ? ` ${separator ?? "/"} ${rewards}` : ""
  }`;

export const CFAR_SUBTITLE_COPY =
  "Plan your trip worry-free. Add the flexibility to cancel your hotel booking for any reason.";

export const CONTINUE_TO_CHECKOUT_COPY = "Continue to checkout";

export const BACK = "Back";

export const CONTINUE = "Continue";

export const CHECKOUT_BREAKDOWN = "Checkout breakdown";
