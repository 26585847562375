import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import {
  getPremierCollectionShopCallState,
  getPremierCollectionShopSelectedAvailability,
  getViewedPremierCollectionDetailsProperties,
  getViewedPremierCollectionDetailsTrackingProps,
  getPremierCollectionShopShopRequestId,
  isAddOnOptionAvailableSelector,
  getPremierCollectionShopRoomInfoProducts,
  getPremierCollectionShopRoomInfoProductsWithTransformedIndexes,
} from "./reducer/selectors";
import { PremierCollectionShop } from "./component";
import { actions } from "./actions";
import {
  isHotelCfarEnabledSelector,
  hasSelectedRefundableRoomSelector,
} from "../ancillary/reducer";
import {
  fetchCfarQuotes,
  setSelectedCfarId,
  setHasSelectedRefundableRoom,
} from "../ancillary/actions/actions";
import { getRewardsAccounts } from "../rewards/reducer";
import { actions as rewardsActions } from "../rewards/actions";
import {
  fetchTravelWalletCreditHistory,
  fetchTravelWalletDetails,
} from "../travel-wallet/actions/actions";
import { PremierCollectionShopCallState } from "./reducer";
import { setStayType } from "../search/actions/actions";
import { listPaymentMethods } from "../availability/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const callState = getPremierCollectionShopCallState(state);
  return {
    selectedLodging: getPremierCollectionShopSelectedAvailability(state),
    rewardsAccounts: getRewardsAccounts(state),
    trackingProps: getViewedPremierCollectionDetailsTrackingProps(state),
    viewedPremierCollectionDetailsProperties:
      getViewedPremierCollectionDetailsProperties(state),
    fetchedPremierCollectionDetails:
      callState === PremierCollectionShopCallState.Failed ||
      callState === PremierCollectionShopCallState.Success,
    premierCollectionShopShopRequestId:
      getPremierCollectionShopShopRequestId(state),
    isHotelCfarEnabled: isHotelCfarEnabledSelector(state),
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    hasSelectedRefundableRoom: hasSelectedRefundableRoomSelector(state),
    roomInfoProducts: getPremierCollectionShopRoomInfoProducts(state),
    roomInfoProductsWithTransformedIndexes:
      getPremierCollectionShopRoomInfoProductsWithTransformedIndexes(state),
  };
};

export const mapDispatchToProps = {
  fetchPremierCollectionShop: actions.fetchPremierCollectionShop,
  selectLodging: actions.selectLodging,
  selectRoomType: actions.selectRoomType,
  setSelectedAccount: rewardsActions.setSelectedAccountReferenceId,
  fetchTravelWalletDetails,
  fetchCfarQuotes,
  setSelectedCfarId,
  setHasSelectedRefundableRoom,
  fetchTravelWalletCreditHistory,
  setStayType,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PremierCollectionShopConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionShop = withRouter(
  connector(PremierCollectionShop)
);
