import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { StayTypesEnum } from "redmond";

import { IStoreState } from "../../../../reducers/types";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccount,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import { getStayType } from "../../../search/reducer";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";
import {
  fetchMorePremierCollectionAvailability,
  fetchMoreVacationRentalsAvailability,
  setPropertyIdHovered,
  setPropertyIdInFocus,
  setSelectedLodgingIndex,
} from "../../actions/actions";
import {
  getFilteredAndSortedPremierCollectionAvailabilityLodgings,
  getFilteredSortedVacationRentalAvailabilityListings,
  getMapBound,
  getPremierCollectionAvailabilityCallState,
  getPremierCollectionAvailabilityLodgings,
  getPremierCollectionAvailabilityNextPageToken,
  getPremierCollectionAvailabilitySearchLocationResult,
  getSearchedNightCount,
  getVacationRentalAvailabilityListings,
  getVacationRentalsAvailabilityCallState,
  PremierCollectionAvailabilityCallState,
} from "../../reducer";
import { PremierCollectionAvailabilityList } from "./component";

export enum FilterKey {
  MaxPrice = "MaxPrice",
}

const followUpCallStates = [
  PremierCollectionAvailabilityCallState.FollowUpSearchCallInProcess,
  PremierCollectionAvailabilityCallState.FollowUpSearchCallSuccess,
];
const mapStateToProps = (state: IStoreState) => {
  const nextPageToken = getPremierCollectionAvailabilityNextPageToken(state);

  const stayType = getStayType(state);
  const unfilteredLodgings = getPremierCollectionAvailabilityLodgings(state);
  const unfilteredVacationRentalListings =
    getVacationRentalAvailabilityListings(state);

  const lodgings =
    getFilteredAndSortedPremierCollectionAvailabilityLodgings(state);
  const vacationRentalsListings =
    getFilteredSortedVacationRentalAvailabilityListings(state);

  const lodgingAvailabilityCallState =
    getPremierCollectionAvailabilityCallState(state);
  const vacationRentalsAvailabilityCallState =
    getVacationRentalsAvailabilityCallState(state);

  const [results, callState] = (() => {
    switch (stayType) {
      case StayTypesEnum.Hotels:
        return [
          unfilteredLodgings ?? [],
          lodgingAvailabilityCallState,
        ] as const;
      case StayTypesEnum.VacationRentals:
        return [
          unfilteredVacationRentalListings ?? [],
          vacationRentalsAvailabilityCallState,
        ] as const;
    }
  })();

  return {
    lodgings,
    nightCount: getSearchedNightCount(state),
    accountReferenceId: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    mapBounds: getMapBound(state),
    searchLocation: getPremierCollectionAvailabilitySearchLocationResult(state),
    credit: getTravelWalletCredit(state),
    selectedAccount: getSelectedAccount(state),
    callState,
    isFetchingInitialAvailability:
      callState ===
      PremierCollectionAvailabilityCallState.InitialSearchCallInProcess,
    isStillFetchingInFollowUpCalls:
      // Due to limited numbers of LC/PC, the followup first follow up call (first number of lodgings displayed in UI) can be empty array resulting in 0. This is a workaround to show loading state until we get lodgings in initial/followup calls
      (followUpCallStates.includes(callState) || nextPageToken != null) &&
      results.length === 0,
    stayType,
    vacationRentalsListings,
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  };
};

const mapDispatchToProps = {
  setPropertyIdHovered,
  setPropertyIdInFocus,
  fetchMorePremierCollectionAvailability,
  setSelectedLodgingIndex,
  fetchMoreVacationRentalsAvailability,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPremierCollectionAvailabilityList = connector(
  withRouter(PremierCollectionAvailabilityList)
);
