import React from "react";

export const BENEFITS_TITLE = "Your Premier Collection Benefits:";
export const LC_BENEFITS_TITLE = "Your Lifestyle Collection benefits:";

export const getBenefitsList = (
  variant?: "default" | "lifestyle-collection"
) => {
  const isLifestyleCollection = variant === "lifestyle-collection";
  return (
    <ul>
      <li>
        Earn <strong>5X miles</strong> on every vacation rental booking from the
        {isLifestyleCollection ? " Lifestyle" : " Premier"} Collection
      </li>
      <li>
        Enjoy a{" "}
        <strong>
          {isLifestyleCollection ? "$50" : "$100"} experience credit
        </strong>
      </li>
    </ul>
  );
};

export const ADDITIONAL_BENEFITS = (
  <>
    Plus, enjoy other premium benefits like{" "}
    <strong>early check-in and late checkout</strong> when available.
  </>
);

export const RESERVE_CTA_TEXT = "Reserve now";

export const DATES_UNAVAILABLE_TEXT = "These dates are not available";
export const CHANGE_DATES = "Change Dates";
