import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { StayTypesEnum } from "redmond";

import { IStoreState } from "../../reducers/types";
import { getRewardsAccountWithLargestEarnForBanner } from "../rewards/reducer";
import {
  fetchLifestyleLocationCategories,
  fetchLocationCategories,
  fetchVacationRentalsLocationCategories,
  setStayType,
} from "../search/actions/actions";
import {
  getSearchParams,
  getStayType,
  getVacationRentalsLocationCategories,
} from "../search/reducer";
import {
  fetchTravelWalletCreditHistory,
  fetchTravelWalletDetails,
} from "../travel-wallet/actions/actions";
import {
  selectHome,
  setSelectedListingId,
} from "../vacation-rental-shop/actions/actions";
import { actions } from "./actions";
import {
  listPaymentMethods,
  setPropertyIdInFocus,
  setSelectedLodgingIndex,
} from "./actions/actions";
import { PremierCollectionAvailability } from "./component";
import {
  getFilteredAndSortedPremierCollectionAvailabilityLodgings,
  getIsFilteredPremierCollectionAvailabilityLodgingsEmpty,
  getMapBound,
  getPremierCollectionAvailabilityCallState,
  getPremierCollectionAvailabilityFromDate,
  getPremierCollectionAvailabilityNextPageToken,
  getPremierCollectionAvailabilitypropertyIdInFocus,
  getPremierCollectionAvailabilitySearchLocationResult,
  getPremierCollectionAvailabilityTrackingProperties,
  getPremierCollectionAvailabilityUntilDate,
  getPremierCollectionHotelsNumberOfAppliedFilters,
  getPremierCollectionQueryParams,
  getVacationRentalAvailabilityListings,
  getVacationRentalsAvailabilityCallState,
  getVacationRentalsAvailabilityResponse,
  getVacationRentalsNumberOfAppliedFilters,
  getViewedPremierCollectionListProperties,
  getViewedVacationRentalListProperties,
  PremierCollectionAvailabilityCallState,
} from "./reducer";

const mapStateToProps = (state: IStoreState) => {
  const fetchingInitialHotelAvailability =
    getPremierCollectionAvailabilityCallState(state) ===
    PremierCollectionAvailabilityCallState.InitialSearchCallInProcess;
  const fetchingInitialVacationRentalAvailability =
    getVacationRentalsAvailabilityCallState(state) ===
    PremierCollectionAvailabilityCallState.InitialSearchCallInProcess;
  const nextPageToken = getPremierCollectionAvailabilityNextPageToken(state);
  const stayType = getStayType(state);

  const isStillFetchingInHotelFollowUpCalls = // Due to limited numbers of LC/PC, the followup first follow up call (first number of lodgings displayed in UI) can be empty array resulting in 0. This is a workaround to show loading state until we get lodgings in initial/followup calls
    (getPremierCollectionAvailabilityCallState(state) ===
      PremierCollectionAvailabilityCallState.FollowUpSearchCallInProcess ||
      getPremierCollectionAvailabilityCallState(state) ===
        PremierCollectionAvailabilityCallState.FollowUpSearchCallSuccess ||
      !!nextPageToken) && // nextPageToken is to prevent modal closing (a flash), when it goes from initial success -> followup in process
    getFilteredAndSortedPremierCollectionAvailabilityLodgings(state).length ===
      0;

  const isStillFetchingInVacationRentalFollowUpCalls =
    (getVacationRentalsAvailabilityCallState(state) ===
      PremierCollectionAvailabilityCallState.FollowUpSearchCallInProcess ||
      getVacationRentalsAvailabilityCallState(state) ===
        PremierCollectionAvailabilityCallState.FollowUpSearchCallSuccess) &&
    getVacationRentalsAvailabilityResponse(state)?.listings.length === 0;

  const isStillFetchingInFollowUpCalls =
    stayType === StayTypesEnum.Hotels
      ? isStillFetchingInHotelFollowUpCalls
      : isStillFetchingInVacationRentalFollowUpCalls;
  const fetchingInitialAvailability =
    stayType === StayTypesEnum.Hotels
      ? fetchingInitialHotelAvailability
      : fetchingInitialVacationRentalAvailability;

  const lodgings =
    getFilteredAndSortedPremierCollectionAvailabilityLodgings(state);
  const vacationRentalsListings = getVacationRentalAvailabilityListings(state);
  const focusedPropertyId =
    getPremierCollectionAvailabilitypropertyIdInFocus(state);

  const focusedProperty = (() => {
    if (focusedPropertyId == null) return null;
    switch (stayType) {
      case StayTypesEnum.Hotels: {
        const index = lodgings.findIndex(
          (lodging) => lodging.lodging.id === focusedPropertyId
        );
        if (index < 0) {
          return null;
        }
        return { id: focusedPropertyId, index, property: lodgings[index] };
      }

      case StayTypesEnum.VacationRentals: {
        if (vacationRentalsListings == null) {
          return null;
        }
        const index = vacationRentalsListings.findIndex(
          (listing) => listing.listingId.id === focusedPropertyId
        );
        if (index < 0) {
          return null;
        }
        return {
          id: focusedPropertyId,
          index,
          property: vacationRentalsListings[index],
        };
      }
    }
  })();

  return {
    fromDate: getPremierCollectionAvailabilityFromDate(state),
    untilDate: getPremierCollectionAvailabilityUntilDate(state),
    isFetchingInitialAvailability: fetchingInitialAvailability,
    isFilteredPremierCollectionAvailabilityLodgingsEmpty:
      getIsFilteredPremierCollectionAvailabilityLodgingsEmpty(state) &&
      !fetchingInitialAvailability,
    trackingProps: getPremierCollectionAvailabilityTrackingProperties(state),
    viewedPCListProperties: getViewedPremierCollectionListProperties(state),
    lodgings,
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    isStillFetchingInFollowUpCalls,
    stayType,
    isMapSearch: getMapBound(state) != null,
    focusedProperty,
    vacationRentalsListings,
    vacationRentalsLocationCategories:
      getVacationRentalsLocationCategories(state),
    searchParams: getSearchParams(state),
    hotelQueryParams: getPremierCollectionQueryParams(state),
    searchLocation: getPremierCollectionAvailabilitySearchLocationResult(state),
    viewedPremierCollectionListProperties:
      getViewedPremierCollectionListProperties(state),
    viewedVacationRentalListProperties:
      getViewedVacationRentalListProperties(state),
    hotelsFiltersCount: getPremierCollectionHotelsNumberOfAppliedFilters(state),
    vrFiltersCount: getVacationRentalsNumberOfAppliedFilters(state),
  };
};

const mapDispatchToProps = {
  fetchInitialPremierCollectionAvailability:
    actions.fetchInitialPremierCollectionAvailability,
  setMaxPriceFilter: actions.setMaxPriceFilter,
  fetchTravelWalletDetails,
  fetchLocationCategories,
  fetchTravelWalletCreditHistory,
  fetchLifestyleLocationCategories,
  fetchInitialVacationRentalsAvailability:
    actions.fetchInitialVacationRentalsAvailability,
  setStayType,
  fetchVacationRentalsLocationCategories,
  selectHome,
  setSelectedLodgingIndex,
  setSelectedListingId,
  setPropertyIdInFocus,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PremierCollectionAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionAvailability = withRouter(
  connector(PremierCollectionAvailability)
);
