import { delay, put, putResolve, select } from "redux-saga/effects";
import pollPriceQuote from "../../../../api/v0/book/book-flow/pollPriceQuote";
import { IStoreState } from "../../../../reducers/types";
import {
  getPaymentRequestV2,
  getPriceQuotePremierCollectionProperties,
  getPricingEstimateTotal,
  getSession,
} from "../../reducer";
import {
  PRICE_QUOTE_PC,
  HotelPriceQuoteWithAncillaries,
  LodgingCollectionEnum,
  PRICE_QUOTE_LC,
} from "redmond";
import {
  setPollPriceQuoteCallStateFailure,
  setPollPriceQuoteCallStateSuccess,
  setPriceQuote,
} from "../../actions/actions";
import { actions } from "../../actions";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import dayjs from "dayjs";
import {
  ErrorCode,
  PaymentError,
  ProductError,
  Product,
  PurchaseError,
  PurchaseErrorEnum,
  QuoteFailure,
  QuoteResponseEnum,
  QuoteSuccessV0,
  QuoteResponseV0,
} from "@b2bportal/purchase-api";
import queryStringParser from "query-string";
import { getPremierCollectionShopSelectedAvailability } from "../../../shop/reducer";


const toErrorString = (error: PurchaseError): string => {
  switch (error.Error) {
    case PurchaseErrorEnum.ErrorCode:
      return (error as ErrorCode).code;
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      return JSON.stringify(paymentError.value.value);
    case PurchaseErrorEnum.ProductError:
      const productError = error as ProductError;
      return JSON.stringify(productError.value.value);
    default:
      return PurchaseErrorEnum[error.Error];
  }
};

export function* pollPriceQuoteSaga({
  agentFee,
  pollQuoteOnly,
  ancillaries,
  history,
}: actions.IPollPriceQuote) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const pollQuotePremierCollectionProperties =
      getPriceQuotePremierCollectionProperties(state);
    const startTime = dayjs();
    const selectedLodging = getPremierCollectionShopSelectedAvailability(state);

    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [1000];

    let pollFailed = false;
    let index = 0;
    while (!pollFailed) {
      yield delay(delayTimes[index]);
      const priceQuoteCheckedResponse: QuoteResponseV0 = yield pollPriceQuote(
        sessionToken
      );

      switch (priceQuoteCheckedResponse.QuoteResponse) {
        case QuoteResponseEnum.Failure:
          pollFailed = true;
          const failedResponse = priceQuoteCheckedResponse as QuoteFailure;
          if (failedResponse.errors.length > 0) {
            yield putResolve(
              setPollPriceQuoteCallStateFailure(failedResponse.errors)
            );
            trackEvent({
              eventName:
                selectedLodging?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle
                  ? PRICE_QUOTE_LC
                  : PRICE_QUOTE_PC,
              properties: {
                ...pollQuotePremierCollectionProperties.properties,
                failure_reason:
                  failedResponse.errors.length > 0
                    ? failedResponse.errors
                        .map((error) => toErrorString(error))
                        .join(" - ")
                    : "Poll quote checked response returned an error and the given error code is not handleable.",
              },
              encryptedProperties: [
                ...pollQuotePremierCollectionProperties.encryptedProperties,
              ],
            });
            return;
          } else {
            trackEvent({
              eventName:
                selectedLodging?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle
                  ? PRICE_QUOTE_LC
                  : PRICE_QUOTE_PC,
              properties: {
                ...pollQuotePremierCollectionProperties.properties,
                failure_reason:
                  "Poll quote checked response returned an error and the given error code is not handleable.",
              },
              encryptedProperties: [
                ...pollQuotePremierCollectionProperties.encryptedProperties,
              ],
            });
            yield putResolve(setPollPriceQuoteCallStateFailure([]));
            throw new Error(
              "Price quote checked response returned an error and the given error code is not handleable."
            );
          }

        case QuoteResponseEnum.Pending:
          break;
        case QuoteResponseEnum.Success:
          const priceQuoteResponse =
            priceQuoteCheckedResponse as QuoteSuccessV0;
          switch (priceQuoteResponse.quote.type) {
            case Product.Hotel:
            case Product.Homes:
              // BE returns a HotelPriceQuoteWithAncillaries object
              // If hotels cfar is not enabled, the hotelAncillaryQuotes and combinedPricing fields will be empty
              const hotelPriceQuoteWithAncillaries = priceQuoteResponse.quote
                .value as HotelPriceQuoteWithAncillaries;

              yield putResolve(setPollPriceQuoteCallStateSuccess());
              yield putResolve(
                setPriceQuote(
                  hotelPriceQuoteWithAncillaries.hotelQuoteData,
                  hotelPriceQuoteWithAncillaries.combinedPricing
                )
              );

              const estimate = getPricingEstimateTotal(state);

              trackEvent({
                eventName:
                  hotelPriceQuoteWithAncillaries.hotelQuoteData
                    .lodgingCollection === LodgingCollectionEnum.Lifestyle
                    ? PRICE_QUOTE_LC
                    : PRICE_QUOTE_PC,
                properties: {
                  ...pollQuotePremierCollectionProperties.properties,
                  success: true,
                  load_time: dayjs().diff(startTime, "seconds", true),
                },
                encryptedProperties: [
                  ...pollQuotePremierCollectionProperties.encryptedProperties,
                ],
              });
              if (pollQuoteOnly) {
                return;
              }

              if (
                estimate?.fiat.value ===
                hotelPriceQuoteWithAncillaries.hotelQuoteData.pricing.grandTotal
                  .amount
              ) {
                const paymentRequest = getPaymentRequestV2(state);
                if (paymentRequest) {
                  const queryString = history
                    ? queryStringParser.parse(history.location.search)
                    : {};
                  yield put(
                    actions.scheduleBook({
                      agentFee,
                      isRecommended: queryString.recommended === "true",
                      ancillaries: ancillaries,
                    })
                  );
                } else {
                  yield putResolve(setPollPriceQuoteCallStateFailure([]));
                  throw new Error("Payment amount is undefined");
                }
              } else {
                yield put(
                  actions.setSelectedPaymentMethodId({
                    paymentMethodId: "",
                    accountId: undefined,
                  })
                );
                yield put(actions.setSelectedRewardsAccountReferenceId(null));
              }
              return;
            default:
              trackEvent({
                eventName:
                  selectedLodging?.lodgingCollection ===
                  LodgingCollectionEnum.Lifestyle
                    ? PRICE_QUOTE_LC
                    : PRICE_QUOTE_PC,
                properties: {
                  ...pollQuotePremierCollectionProperties.properties,
                  failure_reason: "Poll quote failed",
                },
                encryptedProperties: [
                  ...pollQuotePremierCollectionProperties.encryptedProperties,
                ],
              });
              pollFailed = true;
              yield putResolve(setPollPriceQuoteCallStateFailure([]));
              throw new Error("Price Quote Failed");
          }
      }
      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    const state: IStoreState = yield select();
    const pollQuotePremierCollectionProperties =
      getPriceQuotePremierCollectionProperties(state);
    const selectedLodging = getPremierCollectionShopSelectedAvailability(state);

    trackEvent({
      eventName:
        selectedLodging?.lodgingCollection === LodgingCollectionEnum.Lifestyle
          ? PRICE_QUOTE_LC
          : PRICE_QUOTE_PC,
      properties: {
        ...pollQuotePremierCollectionProperties.properties,
        failure_reason: "Poll quote request failed",
      },
      encryptedProperties: [
        ...pollQuotePremierCollectionProperties.encryptedProperties,
      ],
    });
    yield putResolve(setPollPriceQuoteCallStateFailure([]));
  }
}
