import axios from "axios";
import { config } from "../../config";

import {
  AvailabilityRequest,
  AvailabilityResponse,
  VIEWED_PC_AVAILABILITY_LOADING,
} from "redmond";
import { hotelAvailabilityApiPrefix } from "../paths";
import dayjs from "dayjs";
import { trackEvent } from "../analytics/trackEvent";

export const fetchPremierCollectionAvailability = (
  body: AvailabilityRequest
): Promise<AvailabilityResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const startTime = dayjs();
      const res = await axios.post(hotelAvailabilityApiPrefix, body, {
        baseURL: config.baseURL,
      });

      trackEvent({
        eventName: VIEWED_PC_AVAILABILITY_LOADING,
        properties: {
          runtime: dayjs().diff(startTime, "seconds", true),
        },
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
