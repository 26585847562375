import { createSelector } from "reselect";
import {
  getImageUrlsArray,
  getModifiedLegacyPrices,
  getAddedLegacyPrices,
} from "halifax";
import {
  HotelCfarQuote,
  HotelCancellationPolicyV2,
  PassengerAncillaryPricing,
  AncillaryKindEnum,
  ITrackingProperties,
  ViewedPremierCollectionDetailsProperties,
  RoomInfoProductsWithTransformedIndexes,
  getShopRoomInfoProductsWithTransformedIndexes,
  RoomProduct,
  CorpRoomProduct,
  RoomInfoProducts,
  CorpRoomInfoProducts,
} from "redmond";

import { IStoreState } from "../../../../reducers/types";
import { PremierCollectionShopCallState } from "../index";
import { getSelectedAccount } from "../../../rewards/reducer";
import {
  getIsFromHotelAvailability,
  getViewedPremierCollectionListProperties,
  getSearchedNightCount,
} from "../../../availability/reducer";
import {
  lodgingIdWithCfarQuotesSelector,
  getHotelCfarQuoteByIndexSelector,
  getHotelCancellationPolicyByIndexSelector,
  getHotelAdditionalInfoByIndexSelector,
  isHotelCfarEnabledSelector,
  selectedCfarIdSelector,
  hasSelectedRefundableRoomSelector,
} from "../../../ancillary/reducer/selectors";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";

export const getPremierCollectionShopSelectedAvailability = (
  state: IStoreState
) => state.premierCollectionShop.selectedLodging;

export const getPremierCollectionShopImageUrlsArray = createSelector(
  [getPremierCollectionShopSelectedAvailability],
  (availability) => {
    return getImageUrlsArray(availability?.lodging.media ?? []);
  }
);

export const getPremierCollectionShopSelectedLodgingId = createSelector(
  getPremierCollectionShopSelectedAvailability,
  (selectedLodging) => {
    return selectedLodging?.lodging.id;
  }
);

export const getPremierCollectionShopShopRequestId = createSelector(
  [getPremierCollectionShopSelectedAvailability],
  (availability) => {
    return availability?.price?.opaqueShopRequest;
  }
);

export const getPremierCollectionShopCancellationSummary = (
  state: IStoreState
) => state.premierCollectionShop.cancellationSummary;

export const getPremierCollectionShopRoomInfoProducts = (state: IStoreState) =>
  state.premierCollectionShop.roomInfoProducts;

export const getPremierCollectionShopChosenRoomInfoIndex = (
  state: IStoreState
) => state.premierCollectionShop.chosenRoomInfoIndex;

export const getPremierCollectionShopChosenProductIndex = (
  state: IStoreState
) => state.premierCollectionShop.chosenProductIndex;

export const getPremierCollectionShopChosenRoomInfo = createSelector(
  [
    getPremierCollectionShopRoomInfoProducts,
    getPremierCollectionShopChosenRoomInfoIndex,
  ],
  (
    roomInfoProducts,
    chosenRoomInfoIndex
  ): RoomInfoProducts | CorpRoomInfoProducts | null => {
    if (chosenRoomInfoIndex !== null) {
      return roomInfoProducts[chosenRoomInfoIndex] ?? null;
    }

    return null;
  }
);

export const getPremierCollectionShopChosenProduct = createSelector(
  [
    getPremierCollectionShopRoomInfoProducts,
    getPremierCollectionShopChosenRoomInfoIndex,
    getPremierCollectionShopChosenProductIndex,
  ],
  (
    roomInfoProducts,
    chosenRoomInfoIndex,
    chosenProductIndex
  ): RoomProduct | CorpRoomProduct | null => {
    if (chosenRoomInfoIndex !== null && chosenProductIndex !== null) {
      return (
        roomInfoProducts[chosenRoomInfoIndex]?.products[chosenProductIndex] ??
        null
      );
    }

    return null;
  }
);

export const getPremierCollectionShopCallState = (state: IStoreState) =>
  state.premierCollectionShop.premierCollectionShopCallState;

export const getPremierCollectionShopCallError = (state: IStoreState) =>
  state.premierCollectionShop.premierCollectionShopCallError;

export const getCheapestProduct = createSelector(
  getPremierCollectionShopRoomInfoProducts,
  (roomInfoProducts) => roomInfoProducts[0]?.products[0]
);

export const isMissingProductInfo = createSelector(
  getPremierCollectionShopCallState,
  getPremierCollectionShopRoomInfoProducts,
  (premierCollectionShopCallState, roomInfoProducts) => {
    return (
      premierCollectionShopCallState ===
        PremierCollectionShopCallState.Success && roomInfoProducts.length === 0
    );
  }
);

export const hasPremierCollectionShopFailed = createSelector(
  getPremierCollectionShopCallState,
  isMissingProductInfo,
  (premierCollectionShopCallState, missingProductInfo) => {
    return (
      premierCollectionShopCallState ===
        PremierCollectionShopCallState.Failed || missingProductInfo
    );
  }
);

export const getViewedPremierCollectionDetailsTrackingProps = createSelector(
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopChosenRoomInfo,
  (product, roomInfo): ITrackingProperties | null => {
    return product && roomInfo
      ? {
          properties: {
            ...product?.trackingPropertiesV2?.properties,
            ...roomInfo?.trackingPropertiesV2?.properties,
          },
          encryptedProperties: [
            product?.trackingPropertiesV2?.encryptedProperties ?? "",
            roomInfo?.trackingPropertiesV2?.encryptedProperties ?? "",
          ],
        }
      : null;
  }
);

export const getPCHotelShopTrackingPropertiesV2 = (state: IStoreState) =>
  state.premierCollectionShop.hotelShopTrackingPropertiesV2;

const getHotelShopRoomInfoRoomsImagesCount = createSelector(
  getPremierCollectionShopRoomInfoProducts,
  (
    roomInfoProducts
  ): {
    rooms_with_images_count: number;
    rooms_without_images_count: number;
  } => {
    let rooms_with_images_cnt = 0;
    let rooms_without_images_cnt = 0;
    for (var roomInfoProduct of roomInfoProducts) {
      rooms_with_images_cnt += roomInfoProduct.roomInfo.media.length ? 1 : 0;
      rooms_without_images_cnt += roomInfoProduct.roomInfo.media.length ? 0 : 1;
    }
    return {
      rooms_with_images_count: rooms_with_images_cnt,
      rooms_without_images_count: rooms_without_images_cnt,
    };
  }
);

export const getViewedPremierCollectionDetailsProperties = createSelector(
  getViewedPremierCollectionListProperties,
  getSelectedAccount,
  getPremierCollectionShopSelectedAvailability,
  getViewedPremierCollectionDetailsTrackingProps,
  getPremierCollectionShopRoomInfoProducts,
  getIsFromHotelAvailability,
  getPCHotelShopTrackingPropertiesV2,
  getHotelShopRoomInfoRoomsImagesCount,
  (
    viewedPremierCollectionListProperties,
    account,
    availability,
    trackingProps,
    roomInfoProducts,
    isFromHotelFunnel,
    pcShopTrackingProperties,
    roomInfoRoomsImagesCount
  ): ITrackingProperties<ViewedPremierCollectionDetailsProperties> => {
    return {
      properties: {
        ...viewedPremierCollectionListProperties.properties,
        account_type_selected: account?.productDisplayName || "",
        ...availability?.trackingPropertiesV2?.properties,
        rooms_shown: roomInfoProducts.length,
        ...availability?.bestOfferThisLodging?.trackingPropertiesV2?.properties,
        has_offer: !!availability?.bestOfferThisLodging,
        account_use_type: account?.accountUseType,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        ...trackingProps?.properties,
        funnel: isFromHotelFunnel ? "hotel" : "premium_stays",
        ...pcShopTrackingProperties?.properties,
        rooms_with_images_count:
          roomInfoRoomsImagesCount.rooms_with_images_count,
        rooms_without_images_count:
          roomInfoRoomsImagesCount.rooms_without_images_count,
      },
      encryptedProperties: [
        ...viewedPremierCollectionListProperties.encryptedProperties,
        availability?.trackingPropertiesV2?.encryptedProperties ?? "",
        availability?.bestOfferThisLodging?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
        ...(trackingProps?.encryptedProperties ?? []),
        pcShopTrackingProperties?.encryptedProperties ?? "",
      ],
    };
  }
);

export const premierCollectionShopProgressSelector = (state: IStoreState) =>
  state.premierCollectionShop.progress;

// Travel Wallet
export const showOfferBasedOnSelectedLodgingSelector = createSelector(
  getPremierCollectionShopSelectedAvailability,
  getTravelWalletCredit,
  (selectedLodging, credit): boolean => {
    return (
      !!selectedLodging?.bestOfferThisLodging &&
      ((!!credit &&
        Math.abs(credit.amount.amount) ===
          Math.abs(selectedLodging.bestOfferThisLodging.amount.amount)) ||
        !!selectedLodging.bestOfferThisLodging.availabilityPageBanner)
    );
  }
);

// CFAR
export const hotelCfarQuoteFromChosenRoomProductSelector = createSelector(
  getPremierCollectionShopSelectedLodgingId,
  lodgingIdWithCfarQuotesSelector,
  getPremierCollectionShopChosenRoomInfoIndex,
  getPremierCollectionShopChosenProductIndex,
  getHotelCfarQuoteByIndexSelector,
  (
    hotelShopSelectedLodgingId,
    lodgingIdWithCfarQuotes,
    hotelShopChosenRoomInfoIndex,
    hotelShopChosenProductIndex,
    getHotelCfarQuoteByIndex
  ): HotelCfarQuote | null => {
    if (
      hotelShopChosenRoomInfoIndex === null ||
      hotelShopChosenProductIndex === null ||
      !hotelShopSelectedLodgingId ||
      !lodgingIdWithCfarQuotes ||
      hotelShopSelectedLodgingId !== lodgingIdWithCfarQuotes
    ) {
      return null;
    }

    return getHotelCfarQuoteByIndex({
      roomInfoIndex: hotelShopChosenRoomInfoIndex,
      productIndex: hotelShopChosenProductIndex,
    });
  }
);

export const cancellationPolicyFromChosenRoomProductSelector = createSelector(
  getPremierCollectionShopSelectedLodgingId,
  lodgingIdWithCfarQuotesSelector,
  getPremierCollectionShopChosenRoomInfoIndex,
  getPremierCollectionShopChosenProductIndex,
  getHotelCancellationPolicyByIndexSelector,
  (
    hotelShopSelectedLodgingId,
    lodgingIdWithCfarQuotes,
    hotelShopChosenRoomInfoIndex,
    hotelShopChosenProductIndex,
    getHotelCancellationPolicyByIndex
  ): HotelCancellationPolicyV2 | null => {
    if (
      hotelShopChosenRoomInfoIndex === null ||
      hotelShopChosenProductIndex === null ||
      !hotelShopSelectedLodgingId ||
      !lodgingIdWithCfarQuotes ||
      hotelShopSelectedLodgingId !== lodgingIdWithCfarQuotes
    ) {
      return null;
    }

    return getHotelCancellationPolicyByIndex({
      roomInfoIndex: hotelShopChosenRoomInfoIndex,
      productIndex: hotelShopChosenProductIndex,
    });
  }
);

export const getAddedAncillariesPricing = createSelector(
  selectedCfarIdSelector,
  hotelCfarQuoteFromChosenRoomProductSelector,
  (
    selectedCfarId,
    hotelCfarQuoteFromChosenRoomProduct
  ): PassengerAncillaryPricing[] => {
    const ancillaryPricing: PassengerAncillaryPricing[] = [];
    if (
      !!selectedCfarId &&
      !!hotelCfarQuoteFromChosenRoomProduct &&
      selectedCfarId.value === hotelCfarQuoteFromChosenRoomProduct.id.value
    ) {
      ancillaryPricing.push({
        kind: AncillaryKindEnum.Cfar,
        premium: hotelCfarQuoteFromChosenRoomProduct.premiumPrices,
      });
    }
    return ancillaryPricing;
  }
);

export const isCfarAvailableInChosenRoomProductSelector = createSelector(
  isHotelCfarEnabledSelector,
  getPremierCollectionShopChosenRoomInfoIndex,
  getPremierCollectionShopChosenProductIndex,
  hotelCfarQuoteFromChosenRoomProductSelector,
  hasSelectedRefundableRoomSelector,
  (
    isHotelCfarEnabled,
    hotelShopChosenRoomInfoIndex,
    hotelShopChosenProductIndex,
    hotelCfarQuoteFromChosenRoomProduct,
    hasSelectedRefundableRoom
  ): boolean => {
    return (
      isHotelCfarEnabled &&
      hotelShopChosenRoomInfoIndex !== null &&
      hotelShopChosenProductIndex !== null &&
      hotelCfarQuoteFromChosenRoomProduct !== null &&
      !hasSelectedRefundableRoom
    );
  }
);

export const hasSelectedCfarOptionSelector = createSelector(
  selectedCfarIdSelector,
  isCfarAvailableInChosenRoomProductSelector,
  (selectedCfarId, isCfarAvailable): boolean => {
    return !isCfarAvailable || !!selectedCfarId;
  }
);

export const isCfarOptionSelectionCompleteSelector = createSelector(
  isCfarAvailableInChosenRoomProductSelector,
  hasSelectedCfarOptionSelector,
  isHotelCfarEnabledSelector,
  (isCfarAvailable, hasSelectedCfarOption, isCfarEnabled): boolean => {
    return !isCfarEnabled || !isCfarAvailable || hasSelectedCfarOption;
  }
);

export const isAddOnOptionAvailableSelector = createSelector(
  isCfarAvailableInChosenRoomProductSelector,
  (isCfarAvailable): boolean => {
    return isCfarAvailable;
  }
);

export const isOptionSelectionCompleteSelector = createSelector(
  isCfarOptionSelectionCompleteSelector,
  (isCfarOptionSelectionComplete): boolean => {
    return isCfarOptionSelectionComplete;
  }
);

export const getPremierCollectionShopRoomInfoProductsWithTransformedIndexes =
  createSelector(
    getPremierCollectionShopRoomInfoProducts,
    getHotelCfarQuoteByIndexSelector,
    getHotelCancellationPolicyByIndexSelector,
    getHotelAdditionalInfoByIndexSelector,
    getSearchedNightCount,
    (
      roomInfoProducts,
      getHotelCfarQuoteByIndex,
      getHotelCancellationPolicyByIndex,
      getHotelAdditionalInfoByIndex,
      nightCount
    ): RoomInfoProductsWithTransformedIndexes[] =>
      getShopRoomInfoProductsWithTransformedIndexes({
        roomInfoProducts,
        getHotelCfarQuoteByIndex,
        getHotelCancellationPolicyByIndex,
        getHotelAdditionalInfoByIndex,
        nightCount,
        pricesHelpers: {
          getModifiedLegacyPrices,
          getAddedLegacyPrices,
        },
      })
  );
