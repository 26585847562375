import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";
import { ListingSearchResult } from "redmond";
import "./styles.scss";

export interface IListingTermsInfoProps {
  selectedListing: ListingSearchResult;
  isMobile?: boolean;
}

// TODO: VR - move this to components, so it's not hierarchically in shop modules, since it's also used by checkout
export const ListingTermsInfo = (props: IListingTermsInfoProps) => {
  const { selectedListing } = props;

  const { listing } = selectedListing;

  const getStandardTime = (time: string) => {
    return dayjs("1/1/1 " + time).format("hh:mm A"); // converts military time to standard 12 hrs time
  };

  const cancellationPolicy =
    selectedListing.availability.rate?.cancellationPolicy;

  return (
    <Box className="listing-terms-wrapper">
      <Box className="policy-section check-in-info">
        {/* TODO: VR  - We have a check in checkout time component already in hotels, should probably put that in components and merge with that for consistency */}
        <Typography className="policy-title">Check-in instructions</Typography>
        <Box className="policy-info">
          {selectedListing.listing.terms.checkInTimes?.startTime && (
            <Box className="check-in-time">
              <Typography className="info-title">Check-in time</Typography>
              {selectedListing.listing.terms.checkInTimes?.startTime && (
                <Typography variant="body2">
                  From{" "}
                  {getStandardTime(
                    selectedListing.listing.terms.checkInTimes?.startTime
                  )}{" "}
                  {selectedListing.listing.terms.checkInTimes?.endTime &&
                    `- ${getStandardTime(
                      selectedListing.listing.terms.checkInTimes?.endTime
                    )}`}
                </Typography>
              )}
            </Box>
          )}
          {selectedListing.listing.terms.checkOutTimes?.endTime && (
            <Box className="check-in-time">
              <Typography className="info-title">Checkout time</Typography>
              <Typography variant="body2">
                Until{" "}
                {getStandardTime(
                  selectedListing.listing.terms.checkOutTimes?.endTime
                )}
              </Typography>
            </Box>
          )}
          {listing.terms.checkinInformation && (
            <Typography
              variant="body2"
              className="policy-text"
              dangerouslySetInnerHTML={{
                __html: listing.terms.checkinInformation.replace(
                  /\n/g,
                  "<br/>"
                ),
              }}
            />
          )}
        </Box>
      </Box>

      {listing.terms.petsPolicy && (
        <Box className="policy-section pets-policy">
          <Typography className="policy-title">Pet policy</Typography>
          <Typography variant="body2">
            {selectedListing.listing.terms.petsPolicy}
          </Typography>
        </Box>
      )}
      {listing.terms.importantInformation && (
        <Box className="policy-section important-info">
          <Typography className="policy-title">
            Important Information
          </Typography>
          <Typography
            variant="body2"
            className="policy-text"
            dangerouslySetInnerHTML={{
              __html: listing.terms.importantInformation.replace(
                /\n/g,
                "<br/>"
              ),
            }}
          />
        </Box>
      )}

      {cancellationPolicy && (
        <Box className="policy-section cancellation-info">
          <Typography className="policy-title">
            {cancellationPolicy.policy.primaryText}
          </Typography>
          {"secondaryText" in cancellationPolicy.policy && (
            <Typography variant="body2">
              {cancellationPolicy.policy.secondaryText}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
