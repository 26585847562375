import { connect } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getLocation,
  getFromDate,
  getUntilDate,
  getStayType,
  getVacationRentalsLocation,
} from "../../../../reducer/selectors";
import { withRouter } from "react-router";
import { ActionButtonWithRoute } from "halifax";
import { StayTypesEnum } from "redmond";

const mapStateToProps = (
  state: IStoreState,
  ownProps: { from?: Date; until?: Date; enabled?: boolean }
) => {
  const stayType = getStayType(state);
  const location =
    stayType === StayTypesEnum.VacationRentals
      ? getVacationRentalsLocation(state)
      : getLocation(state);
  const fromDate = getFromDate(state) || ownProps.from;
  const untilDate = getUntilDate(state) || ownProps.until;

  const hasSetLocation = location !== null;
  const areJuncturesSet = !!fromDate && !!untilDate;

  return {
    disabled: ownProps.enabled
      ? !ownProps.enabled
      : !(hasSetLocation && areJuncturesSet),
  };
};

const mapDispatchToProps = {};

export const ConnectedHotelSearchButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActionButtonWithRoute));
