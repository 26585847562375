import { HotelStarRatingEnum } from "redmond";

export const DESKTOP_FILTERS_BUTTON_TEXT = "More filters";
export const MOBILE_FILTERS_BUTTON_TEXT = "Filters";
export const RATING_TEXT = "Rating";
export const PRICE = "Price";
export const PRICE_FILTER_TITLE = "Max Price";
export const PRICE_FILTER_SUBTITLE = "Per Night";

export const AMENITIES_FILTER_LABEL = "Amenities";
export const ROOM_COUNT_FILTER_BUTTON_TEXT = "Rooms & spaces";

const starRatingIntValue: {
  [key in HotelStarRatingEnum]: number | null;
} = {
  NotAvailable: null,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
};

export const PREMIER_COLLECTION_FILTER_LABEL = "Premier Collection";
export const LIFESTYLE_COLLECTION_FILTER_LABEL = "Lifestyle Collection";
export const STAR_RATING_FILTER_LABEL = (rating: HotelStarRatingEnum) =>
  `${starRatingIntValue[rating]} Star`;
