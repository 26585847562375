import React from "react";

import { Box } from "@material-ui/core";
import clsx from "clsx";
import { debounce } from "lodash-es";
import { RouteComponentProps } from "react-router";

import {
  AmenitiesSelection,
  GenericDropdown,
  HotelPriceSelection,
  Icon,
  IconName,
  StarRatingsSelection,
  getCurrencySymbol,
  mapAmenitiesToTopAmenities,
  useDeviceTypes,
} from "halifax";
import { StayTypesEnum } from "redmond";

import { initialFilterState } from "../../reducer";
import { AMENITY_LABELS } from "./components/AmenitiesSelection/textConstants";
import { RoomCountFilter } from "./components/RoomCountFilter";
import { AvailabilityFilterConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface IAvailabilityFilterProps
  extends AvailabilityFilterConnectorProps,
    RouteComponentProps {}

export const AvailabilityFilter = (props: IAvailabilityFilterProps) => {
  const {
    stayType,
    currency,
    vacationRentalsAmenities,
    setVacationRentalsAmenities,
    amenities,
    setAmenitiesFilter,
    starRatings,
    setStarRatingsFilter,
    maxPrice,
    setMaxPriceFilter,
    minMaxPriceRange,
    vacationRentalsRoomCounts,
    setVacationRentalsRoomCounts,
    hotelName,
    setHotelNameFilter,
    hotelsOnSale,
    setHotelsOnSaleFilter,
    freeCancellation,
    setCancellationFilter,
  } = props;
  const { matchesDesktop } = useDeviceTypes();

  // local state values
  const [localMaxPriceFilter, setLocalMaxPriceFilter] =
    React.useState<number>(maxPrice);
  const maxPriceSet = maxPrice !== initialFilterState.maxPrice;

  // outer state handlers
  const setMaxPriceFilterHandler = (maxPrice: number) => {
    setMaxPriceFilter(maxPrice);
  };
  const setMaxPriceFilterDebounced = React.useMemo(
    () => debounce(setMaxPriceFilterHandler, 150),
    []
  );

  React.useEffect(() => {
    setLocalMaxPriceFilter(maxPrice);
  }, [maxPrice]);

  const hasChangedStarRatingsFilter = React.useMemo(
    () => starRatings.length > 0,
    [starRatings.length]
  );
  const ratingLabel = React.useMemo(() => {
    if (starRatings.length === 0) {
      return "";
    } else {
      const moreCount = starRatings.length - 1;
      return `${textConstants.STAR_RATING_FILTER_LABEL(starRatings[0])}${
        moreCount > 0 ? ` +${moreCount}` : ""
      }`;
    }
  }, [starRatings]);

  const hasChangedAmenitiesFilter = React.useMemo(
    () => amenities.length > 0,
    [amenities.length]
  );
  const topAmenities = React.useMemo(
    () => mapAmenitiesToTopAmenities(amenities),
    [amenities]
  );
  const amenitiesLabel = React.useMemo(() => {
    if (topAmenities.length === 0) {
      return "";
    } else {
      const moreCount = topAmenities.length - 1;
      return `${topAmenities[0].label}${moreCount > 0 ? ` +${moreCount}` : ""}`;
    }
  }, [topAmenities]);

  const vrAmenitiesLabel = React.useMemo(() => {
    if (vacationRentalsAmenities.length > 0) {
      const moreCount = vacationRentalsAmenities.length - 1;
      const label = `${AMENITY_LABELS[vacationRentalsAmenities[0]]}${
        moreCount > 0 ? ` +${moreCount}` : ""
      }`;
      console.log(vacationRentalsAmenities, AMENITY_LABELS, label);
      return label;
    }
    return null;
  }, [vacationRentalsAmenities]);

  const renderDropdownButtons = () => {
    switch (stayType) {
      case StayTypesEnum.VacationRentals:
        return (
          <>
            <RoomCountFilter
              roomCounts={vacationRentalsRoomCounts}
              setVacationRentalsRoomCounts={setVacationRentalsRoomCounts}
            />
            {vrAmenitiesLabel && (
              <EnabledFilterTag
                disableFilter={() => setVacationRentalsAmenities([])}
              >
                <strong>
                  {textConstants.AMENITIES_FILTER_LABEL}
                  {": "}
                </strong>
                {vrAmenitiesLabel}
              </EnabledFilterTag>
            )}
          </>
        );
      case StayTypesEnum.Hotels:
        return (
          <>
            <GenericDropdown
              buttonClassName={clsx(
                "hotel-availability-dropdown",
                "star-ratings",
                "b2b-shop-filter",
                "enhanced-hotel-filters",
                {
                  "has-value": hasChangedStarRatingsFilter,
                }
              )}
              popoverClassName={clsx(
                "hotel-availability-star-ratings-dropdown-popover",
                "b2b",
                "enhanced-hotel-filters"
              )}
              ariaLabel={`${textConstants.RATING_TEXT} filter`}
              dropdownLabel={
                <>
                  <strong>
                    {textConstants.RATING_TEXT}
                    {hasChangedStarRatingsFilter ? ": " : ""}
                  </strong>
                  {hasChangedStarRatingsFilter ? ratingLabel : ""}
                </>
              }
              dropdownIcon={
                hasChangedStarRatingsFilter ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setStarRatingsFilter([]);
                    }}
                  >
                    <Icon name={IconName.XCircle} />
                  </div>
                ) : undefined
              }
              dropdownContent={
                <StarRatingsSelection
                  className="desktop-hotel-availability-star-ratings"
                  starRatings={starRatings}
                  setStarRatings={(starRatings) =>
                    setStarRatingsFilter(starRatings)
                  }
                />
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            />
            <GenericDropdown
              buttonClassName={clsx(
                "hotel-availability-dropdown",
                "amenities",
                "b2b-shop-filter",
                "enhanced-hotel-filters",
                {
                  "has-value": hasChangedAmenitiesFilter,
                }
              )}
              popoverClassName={clsx(
                "hotel-availability-amenities-dropdown-popover",
                "b2b",
                "enhanced-hotel-filters"
              )}
              ariaLabel={`${textConstants.AMENITIES_FILTER_LABEL} filter`}
              dropdownLabel={
                <>
                  <strong>
                    {textConstants.AMENITIES_FILTER_LABEL}
                    {hasChangedAmenitiesFilter ? ": " : ""}
                  </strong>
                  {hasChangedAmenitiesFilter ? amenitiesLabel : ""}
                </>
              }
              dropdownIcon={
                hasChangedAmenitiesFilter ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setAmenitiesFilter([]);
                    }}
                  >
                    <Icon name={IconName.XCircle} />
                  </div>
                ) : undefined
              }
              dropdownContent={
                <AmenitiesSelection
                  amenities={amenities}
                  setAmenities={setAmenitiesFilter}
                  useTopAmenities
                />
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            />
            <GenericDropdown
              buttonClassName={clsx(
                "premier-collection-availability-dropdown",
                "rounded",
                "b2b-shop-filter",
                { "has-value": maxPriceSet }
              )}
              popoverClassName={clsx(
                "premier-collection-availability-price-dropdown-popover",
                "b2b"
              )}
              ariaLabel={`${textConstants.PRICE} filter`}
              dropdownLabel={
                <>
                  <strong>
                    {textConstants.PRICE}
                    {maxPriceSet ? ":" : ""}
                  </strong>
                  {maxPriceSet
                    ? ` ${getCurrencySymbol(currency)}${maxPrice}`
                    : ""}
                </>
              }
              dropdownIcon={
                maxPriceSet ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setMaxPriceFilter(initialFilterState.maxPrice);
                    }}
                  >
                    <Icon name={IconName.XCircle} />
                  </div>
                ) : undefined
              }
              dropdownContent={
                <HotelPriceSelection
                  className="desktop-premier-collection-availability-price"
                  title={textConstants.PRICE_FILTER_TITLE}
                  subtitle={textConstants.PRICE_FILTER_SUBTITLE}
                  setHotelMaxPrice={(maxPrice) => {
                    setLocalMaxPriceFilter(maxPrice);

                    setMaxPriceFilterDebounced(maxPrice);
                  }}
                  hotelPriceRange={minMaxPriceRange || { min: 0, max: 0 }}
                  hotelMaxPrice={localMaxPriceFilter}
                  currencySymbol={getCurrencySymbol(currency)}
                  type="max"
                  alwaysShowTooltip
                  showMinLabel
                  showClearButton={
                    localMaxPriceFilter !== initialFilterState.maxPrice
                  }
                  reset={() => setMaxPriceFilter(initialFilterState.maxPrice)}
                />
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            />

            {hotelName && (
              <EnabledFilterTag disableFilter={() => setHotelNameFilter("")}>
                <strong>Hotel:</strong> {hotelName}
              </EnabledFilterTag>
            )}
            {hotelsOnSale && (
              <EnabledFilterTag
                disableFilter={() => setHotelsOnSaleFilter(false)}
              >
                Limited-time promotions
              </EnabledFilterTag>
            )}
            {freeCancellation && (
              <EnabledFilterTag
                disableFilter={() => setCancellationFilter(false)}
              >
                Free cancellation
              </EnabledFilterTag>
            )}
          </>
        );
    }
  };

  return <>{matchesDesktop && renderDropdownButtons()}</>;
};

const EnabledFilterTag = ({
  children,
  disableFilter,
}: {
  children: React.ReactNode;
  disableFilter: () => void;
}): JSX.Element => {
  return (
    <Box className={clsx("hotel-filter-pill")}>
      <Box className="filter-label-wrapper">
        <span className="filter-label-text">{children}</span>
      </Box>
      <button onClick={() => disableFilter()}>
        <Icon name={IconName.XCircle} />
      </button>
    </Box>
  );
};
