import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import {
  getSelectedPaymentMethodId,
  getPaymentMethodRewardsAccountId,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";

import { deletePaymentMethod } from "../../../../api/v0/book/payment-methods/deletePaymentMethod";

export function* deletePaymentMethodSaga({
  paymentId,
}: actions.IDeletePaymentMethod) {
  try {
    const state: IStoreState = yield select();

    yield deletePaymentMethod({ paymentId });
    yield put(actions.deletePaymentMethodCallStateSuccess());

    const selectedPaymentMethodId = getSelectedPaymentMethodId(state);
    const paymentMethodRewardsAccountId =
      getPaymentMethodRewardsAccountId(state);
    if (selectedPaymentMethodId === paymentId) {
      yield put(
        actions.setSelectedPaymentMethodId({
          paymentMethodId: "",
          accountId: paymentMethodRewardsAccountId,
        })
      );
    }

    yield put(actions.listPaymentMethods());
  } catch (e) {
    yield put(actions.deletePaymentMethodCallStateFailed());
  }
}
