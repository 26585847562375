import React from "react";

import { Typography } from "@material-ui/core";

import { MegaMenuDestination, MegaMenuSubregion } from "redmond";

import { ListView, ListViewItem } from "..";

export interface ISubregionListViewProps {
  subregion: MegaMenuSubregion;
  onClick: (destination: MegaMenuDestination) => void;
}

export const SubregionListView = ({
  subregion,
  onClick,
}: ISubregionListViewProps) => {
  const subregionTitle = makeListItemForSubregionTitle(subregion, onClick);
  return (
    <ListView
      items={(subregionTitle == null ? [] : [subregionTitle]).concat(
        subregion.destinations.map((destination) =>
          makeListItemForDestination(destination, onClick)
        )
      )}
    />
  );
};

const makeListItemForSubregionTitle = (
  subregion: MegaMenuSubregion,
  onClick: (destination: MegaMenuDestination) => void
): ListViewItem | null => {
  const lodgingSelection = subregion.lodgingSelection;
  if (lodgingSelection == null) {
    return null;
  }
  return {
    key: `subregion-${subregion.label}`,
    node: (
      <Typography variant="body1" style={{ fontWeight: 600 }}>
        All of {subregion.label}
      </Typography>
    ),
    drillable: false,
    onClick: () => onClick({ ...subregion, lodgingSelection }),
  };
};

const makeListItemForDestination = (
  destination: MegaMenuDestination,
  onClick: (destination: MegaMenuDestination) => void
): ListViewItem => ({
  key: `destination-${destination.label}`,
  node: (
    <Typography variant="body1">
      {destination.label} ({destination.hotelCount})
    </Typography>
  ),
  drillable: false,
  onClick: () => onClick(destination),
});
