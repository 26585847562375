import { FiatPrice, RewardsPrice } from "redmond";
import { getTotalPriceText, getRewardText, twoDecimalFormatter } from "halifax";

export const LOCATION_TEXT = "Tasmania, Australia";
export const CFAR_DETAILS_SUBTITLE =
  "Plan your trip worry-free. Add the flexibility to cancel your hotel booking for any reason.";
export const WARNING_BANNER_COPY =
  "If you don’t add this option, your booking is nonrefundable.";

export const REFUNDABLE_ROOM_TITLE = `Make your hotel <strong>refundable</strong>.`;
export const REFUNDABLE_ROOM_TITLE_SEPARATE_LINES = `Make your hotel<br><strong>refundable</strong>.`;
export const REFUNDABLE_ROOM_SUBTITLE = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined
) =>
  `Plan your trip worry-free. Add the flexibility to cancel your hotel for any reason for a fee of <strong>${getTotalPriceText(
    {
      price: fiat,
      priceFormatter: twoDecimalFormatter,
    }
  )}</strong>${
    reward
      ? ` / ${getRewardText({
          reward,
        })}`
      : ""
  }.`;

export const YES_OPTION_COPY = (
  fiat: FiatPrice,
  reward: RewardsPrice | undefined
) =>
  `Yes, add this option for <strong>${getTotalPriceText({
    price: fiat,
    priceFormatter: twoDecimalFormatter,
  })}</strong>${
    reward
      ? ` / ${getRewardText({
          reward,
        })}`
      : ""
  }.`;
export const NO_OPTION_COPY = `No, thanks. I don’t want to add this option.`;

export const CONTINUE_BUTTON_COPY = "Continue";
export const UPGRADE_AND_CONTINUE_BUTTON_COPY = "Upgrade and continue";
export const READ_TERMS_AND_CONDITIONS_COPY = "Read terms and conditions";
export const VIEW_TERMS_AND_CONDITIONS_COPY = "View Terms and Conditions";

export const CFAR_RADIO_BUTTONS_GROUP =
  "Cancel-for-any-reason attachment radio buttons group";

export const CFAR_NO_OPTION_SELECTED_COPY =
  "You must add or decline this option to continue.";

// note: confirmed with Akhil that this constant will technically never be used (because policyData should technically always be returned).
export const DEFAULT_CASH_COVERAGE_PERCENTAGE = 80;

/*
  note: ESTIMATED_CFAR_CONTENT_HEIGHT is actually an upper bound to the height of CFAR content (with image-background header);
  in the future, it can be removed if we were to find a better solution. DIALOG_MARGIN is the same as the margin in MuiDialog-paperScrollPaper,
  which comes from material-ui.Dialog; MuiDialog-paperScrollPaper has max-height set to calc(100% - 2 * 32px)
*/
export const ESTIMATED_CFAR_CONTENT_HEIGHT = 630;
export const DIALOG_MARGIN = 32;
export const PARTIAL_SCROLL_THRESHOLD = `(max-height: ${
  ESTIMATED_CFAR_CONTENT_HEIGHT + 2 * DIALOG_MARGIN
}px)`;
