import clsx from "clsx";
import { isEqual } from "lodash-es";
import React from "react";

import { GenericDropdown, Icon, IconName } from "halifax";

import { VacationRentalsRoomCounts, initialState } from "../../../../reducer";
import * as textConstants from "../../textConstants";
import { RoomCountSelection } from "../RoomCountSelection";

export interface RoomCountFilterProps {
  roomCounts: VacationRentalsRoomCounts;
  setVacationRentalsRoomCounts: (counts: VacationRentalsRoomCounts) => void;
}

export const RoomCountFilter = (props: RoomCountFilterProps) => {
  const { roomCounts, setVacationRentalsRoomCounts } = props;

  const [closeCountsPopover, setCloseCountsPopover] =
    React.useState<boolean>(false);

  const roomCountsSet = !isEqual(
    roomCounts,
    initialState.vacationRentalsRoomCounts
  );
  const resetRoomCounts = () => {
    setVacationRentalsRoomCounts(initialState.vacationRentalsRoomCounts);
  };
  const handleApplyRoomCounts = () => {
    setVacationRentalsRoomCounts(localCounts);
    setCloseCountsPopover(true);
  };

  const [localCounts, setLocalCounts] =
    React.useState<VacationRentalsRoomCounts>(roomCounts);
  const localRoomCountsSet = !isEqual(roomCounts, localCounts);
  const resetLocalRoomCounts = () => {
    setLocalCounts(roomCounts);
  };

  return (
    <GenericDropdown
      buttonClassName={clsx(
        "premier-collection-availability-dropdown",
        "rounded",
        "b2b-shop-filter",
        { "has-value": roomCountsSet }
      )}
      popoverClassName={clsx(
        "vacation-rentals-availability-room-counts-dropdown-popover",
        "b2b"
      )}
      ariaLabel={`${textConstants.ROOM_COUNT_FILTER_BUTTON_TEXT} filter`}
      dropdownLabel={
        <strong>{textConstants.ROOM_COUNT_FILTER_BUTTON_TEXT}</strong>
      }
      dropdownIcon={
        roomCountsSet ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              resetRoomCounts();
            }}
          >
            <Icon name={IconName.XCircle} />
          </div>
        ) : undefined
      }
      dropdownContent={
        <RoomCountSelection
          counts={localCounts}
          setCounts={setLocalCounts}
          onClickApply={handleApplyRoomCounts}
          hasValueToApply={localRoomCountsSet}
        />
      }
      showClose={false}
      onToggle={(open) => {
        resetLocalRoomCounts();
        setCloseCountsPopover(!open);
      }}
      forceClose={closeCountsPopover}
    />
  );
};
