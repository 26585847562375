import { Box, Dialog, Typography } from "@material-ui/core";
import React from "react";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import { convertICoordinatesToGoogleMapCoords } from "../../../availability/components/AvailabilityMap/googleMapsHelpers";
import {
  ActionLink,
  BackButton,
  CloseButtonIcon,
  Icon,
  IconName,
} from "halifax";
import { ListingSearchResult } from "redmond";
import "./styles.scss";
import clsx from "clsx";
import { ObfuscatedAddress } from "redmond/apis/tysons/vacation-rentals";

export interface IVRShopLocationMapProps {
  selectedListing: ListingSearchResult;
  isMobile?: boolean;
  showExpandedVRMap: boolean;
  setShowExpandedVRMap: (arg: boolean) => void;
  renderPreview?: boolean;
  className?: string;
}

export const VRShopLocationMap = (props: IVRShopLocationMapProps) => {
  const {
    selectedListing,
    isMobile,
    showExpandedVRMap,
    setShowExpandedVRMap,
    renderPreview,
    className,
  } = props;

  const center = convertICoordinatesToGoogleMapCoords(
    selectedListing.listing.content.location.exactCoordinates
  );

  const renderMap = () => {
    return (
      <Box
        className={clsx("vr-shop-map-container", {
          mobile: isMobile,
          className,
        })}
      >
        <GoogleMap
          id="vr-shop-map"
          mapContainerStyle={{ height: "100%", width: "100%" }}
          options={{
            clickableIcons: false,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          center={center}
          zoom={16}
        >
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={center}
          >
            <Box className="location-radius">
              <Box className="radius-circle"></Box>
              <Icon name={IconName.LocationDotPin} />
            </Box>
          </OverlayView>
        </GoogleMap>
      </Box>
    );
  };

  const renderExpandedMap = () => {
    return (
      <Dialog
        open={showExpandedVRMap}
        onClose={() => setShowExpandedVRMap(false)}
        className={clsx("expanded-vr-map-popover", { mobile: isMobile })}
        /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
        TransitionProps={{ role: "none" } as never}
        PaperProps={{
          /* eslint-disable */
          // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
          tabIndex: 0,
          /* eslint-enable */
          className: "vr-map-paper-popover",
        }}
        disableEnforceFocus
      >
        {isMobile ? (
          <BackButton
            className={"b2b"}
            onClick={() => setShowExpandedVRMap(false)}
          ></BackButton>
        ) : (
          <ActionLink
            className="expanded-vr-map-close-button"
            onClick={() => setShowExpandedVRMap(false)}
            content={<CloseButtonIcon className="close-button-icon" />}
            label="Close"
            showTappableArea={true}
          />
        )}
        <Box className="expanded-vr-map-content">
          <Box className="expanded-vr-map-title">
            {selectedListing.listing.content.name}
          </Box>
          <Typography className="expanded-vr-map-address" variant="body2">
            {`${
              (
                selectedListing.listing.content.location
                  .address as ObfuscatedAddress
              ).city
            }, ${
              (
                selectedListing.listing.content.location
                  .address as ObfuscatedAddress
              ).state
            }`}
          </Typography>
          {renderMap()}
        </Box>
      </Dialog>
    );
  };
  return (
    <>
      {renderPreview && renderMap()}
      {showExpandedVRMap && renderExpandedMap()}
    </>
  );
};
