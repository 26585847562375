export enum AddOnCustomizeStep {
  GoBack = "go-back",
  CFAR = "cfar",
  GoToCheckout = "go-to-checkout",
}

export enum AddOnCustomizeStepAlias {
  GoBack,
  AddOn1,
  GoToCheckout,
}
