import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { getOpenDatesModal } from "../../../../../availability/reducer";
import { setOpenDatesModal } from "../../../../../availability/actions/actions";
import { getFromDate, getUntilDate } from "../../../../../search/reducer";
import { CalendarPickerButton } from "./component";

export const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    openDatesModal: getOpenDatesModal(state),
  };
};

const mapDispatchToProps = {
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedCalendarPickerButtonProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCalendarPickerButton = connector(CalendarPickerButton);
