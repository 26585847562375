import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import { RouteComponentProps } from "react-router";

import { PremierCollectionBenefits, useDeviceTypes } from "halifax";
import {
  AUTOCOMPLETE_LOCATION_SELECTED,
  AutocompleteLocationSelectedProperties,
  CALENDAR_DATES_SELECTED,
  CalendarDatesSelectedProperties,
  IResult,
  LAUNCHED_MOBILE_LOCATION_SCREEN,
  LaunchedMobileLocationScreenProperties,
  StayTypesEnum,
} from "redmond";

import { trackEvent } from "../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CONTROL,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  LUXURY_MEGA_MENU_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../context/experiments";
import { PATH_HOME, PATH_VACATION_RENTALS } from "../../utils/paths";
import { MobilePremierCollectionSearchControl } from "./components/MobilePremierCollectionSearchControl";
import { MobilePremierCollectionSearchControlV3 } from "./components/MobilePremierCollectionSearchControlV3";
import { PremierCollectionSearchControl } from "./components/PremierCollectionSearchControl";
import { PremierCollectionSearchConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";
import {
  fetchPCLCLocationCategories,
  setMegaMenuEnabled,
} from "./actions/actions";
import { useDispatch } from "react-redux";

export interface IPremierCollectionSearchProps
  extends PremierCollectionSearchConnectorProps,
    RouteComponentProps {}

export const PremierCollectionSearch = (
  props: IPremierCollectionSearchProps
) => {
  const {
    fetchMegaMenuRegions,
    fetchRewardsAccounts,
    fetchLocationCategories,
    largestValueAccount,
    rewardsAccounts,
    fetchLifestyleLocationCategories,
    stayType,
    fetchVacationRentalsLocationCategories,
    vacationRentalsLocationCategories,
    setStayType,
    history,
    listPaymentMethods,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();
  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const VRForPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForPremiumCardHoldersEnabled = React.useMemo(
    () => VRForPremiumCardHolders === AVAILABLE,
    [VRForPremiumCardHolders]
  );

  const VRForNonPremiumCardHolders = getExperimentVariant(
    expState.experiments,
    VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT
  );
  const isVRForNonPremiumCardHoldersEnabled = React.useMemo(
    () => VRForNonPremiumCardHolders === AVAILABLE,
    [VRForNonPremiumCardHolders]
  );

  const LuxuryMegaMenuExperiment = getExperimentVariant(
    expState.experiments,
    LUXURY_MEGA_MENU_EXPERIMENT
  );
  const enableMegaMenu = React.useMemo(
    () => LuxuryMegaMenuExperiment === AVAILABLE,
    [LuxuryMegaMenuExperiment]
  );

  React.useEffect(() => {
    fetchRewardsAccounts();
    listPaymentMethods();
    isLCForNonPremiumCardHoldersEnabled
      ? fetchLifestyleLocationCategories()
      : fetchLocationCategories();
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  React.useEffect(() => {
    if (expState.experiments.length > 0) {
      if (
        history?.location.pathname === PATH_VACATION_RENTALS &&
        !isVRForPremiumCardHoldersEnabled
      ) {
        history.push(PATH_HOME);
      }

      if (
        history?.location.pathname === PATH_VACATION_RENTALS &&
        isVRForPremiumCardHoldersEnabled
      ) {
        setStayType(StayTypesEnum.VacationRentals);
      } else {
        setStayType(StayTypesEnum.Hotels);
      }
    }
  }, [expState, history]);

  React.useEffect(() => {
    if (
      vacationRentalsLocationCategories.length === 0 &&
      stayType === StayTypesEnum.VacationRentals &&
      (isVRForPremiumCardHoldersEnabled || isVRForNonPremiumCardHoldersEnabled)
    ) {
      fetchVacationRentalsLocationCategories();
    }
  }, [stayType]);

  React.useEffect(() => {
    if (matchesMobile) {
      const properties: LaunchedMobileLocationScreenProperties = {
        funnel: "premier_collection",
        url: window.location.pathname,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_LOCATION_SCREEN, properties });
    }
  }, [matchesMobile]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (enableMegaMenu) {
      fetchMegaMenuRegions();
      dispatch(fetchPCLCLocationCategories());
      dispatch(setMegaMenuEnabled(true));
    }
  }, [enableMegaMenu]);

  const handleSelectLocation = React.useCallback((value: IResult | null) => {
    if (value?.label) {
      const properties: AutocompleteLocationSelectedProperties = {
        funnel: "premier_collection",
        url: window.location.pathname,
        autocomplete_value: value.label,
      };
      trackEvent({ eventName: AUTOCOMPLETE_LOCATION_SELECTED, properties });
    }
  }, []);

  const handleSelectDates = React.useCallback(
    (fromDate: Date | null, untilDate: Date | null) => {
      if (fromDate && untilDate) {
        const properties: CalendarDatesSelectedProperties = {
          funnel: "premier_collection",
          url: window.location.pathname,
          start_date: dayjs(fromDate).format("YYYY-MM-DD"),
          end_date: dayjs(untilDate).format("YYYY-MM-DD"),
        };
        trackEvent({ eventName: CALENDAR_DATES_SELECTED, properties });
      }
    },
    []
  );
  return (
    <>
      {matchesMobile ? (
        <Box
          className={clsx("mobile-premier-collection-search-root", {
            "redesign-v3":
              mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3,
          })}
        >
          <Box className="premier-collection-search-container-mobile">
            {mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2 ? (
              <>
                <MobilePremierCollectionSearchControl
                  onSelectLocation={handleSelectLocation}
                  onSelectDates={handleSelectDates}
                />
                <Box className="img-container"></Box>
              </>
            ) : (
              <MobilePremierCollectionSearchControlV3
                supportsMegaMenu={enableMegaMenu}
                onSelectLocation={handleSelectLocation}
                onSelectDates={handleSelectDates}
                isLifestyleCollection={isLCForNonPremiumCardHoldersEnabled}
                includesLifestyleCollection={isLCForPremiumCardHoldersEnabled}
                includesVacationRentals={
                  isVRForPremiumCardHoldersEnabled ||
                  isVRForNonPremiumCardHoldersEnabled
                }
              />
            )}
          </Box>
          {mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2 && (
            <PremierCollectionBenefits
              isMobile
              largestValueAccount={largestValueAccount}
              rewardsAccounts={rewardsAccounts}
            />
          )}
        </Box>
      ) : (
        <>
          <Box
            className={clsx("premier-collection-search-root", {
              "premium-stays": isLCForPremiumCardHoldersEnabled,
              "lifestyle-collection": isLCForNonPremiumCardHoldersEnabled,
              "with-vacation-rentals": isVRForPremiumCardHoldersEnabled,
              "premium-vacation-rentals":
                stayType === StayTypesEnum.VacationRentals &&
                isVRForPremiumCardHoldersEnabled,
              "non-premium-vacation-rentals":
                stayType === StayTypesEnum.VacationRentals &&
                isVRForNonPremiumCardHoldersEnabled,
            })}
          >
            <Box className="premier-collection-search-top-section">
              <Box className="premier-collection-search-container-desktop">
                <Typography variant="h2" className="search-title">
                  {((isVRForPremiumCardHoldersEnabled ||
                    isVRForNonPremiumCardHoldersEnabled) &&
                    largestValueAccount &&
                    textConstants.TITLE_WITH_EARN(
                      stayType === StayTypesEnum.VacationRentals
                        ? 5
                        : largestValueAccount?.earn.hotelsMultiplier,
                      largestValueAccount?.productDisplayName,
                      stayType === StayTypesEnum.VacationRentals
                    )) ||
                    (isLCForNonPremiumCardHoldersEnabled &&
                      textConstants.LC_FOR_NON_PREMIUM_CARDHOLDERS_TITLE) ||
                    (isLCForPremiumCardHoldersEnabled &&
                      textConstants.LC_FOR_PREMIUM_CARDHOLDERS_TITLE) ||
                    textConstants.TITLE_FIRST_HALF}
                </Typography>
                <Typography variant="h2" className="search-subtitle">
                  {(isVRForNonPremiumCardHoldersEnabled &&
                    stayType == StayTypesEnum.VacationRentals &&
                    textConstants.VR_SUBTITLE) ||
                    (isLCForNonPremiumCardHoldersEnabled &&
                      textConstants.LC_FOR_NON_PREMIUM_CARDHOLDERS_SUBTITLE) ||
                    (isLCForPremiumCardHoldersEnabled &&
                      textConstants.LC_FOR_PREMIUM_CARDHOLDERS_SUBTITLE) ||
                    textConstants.SUBTITLE}
                </Typography>

                <PremierCollectionSearchControl
                  supportsMegaMenu={enableMegaMenu}
                  includesLifestyleCollection={
                    isLCForPremiumCardHoldersEnabled ||
                    isLCForNonPremiumCardHoldersEnabled
                  }
                  isLifestyleCollection={isLCForNonPremiumCardHoldersEnabled}
                  includesVacationRentals={
                    isVRForPremiumCardHoldersEnabled ||
                    isVRForNonPremiumCardHoldersEnabled
                  }
                />
              </Box>
            </Box>
            <PremierCollectionBenefits
              largestValueAccount={largestValueAccount}
              variant={(() => {
                if (isLCForPremiumCardHoldersEnabled)
                  return "with-lifestyle-collection";
                if (isLCForNonPremiumCardHoldersEnabled)
                  return "only-lifestyle-collection";
                return "default";
              })()}
              rewardsAccounts={rewardsAccounts}
              includesVacationRentals={
                isVRForPremiumCardHoldersEnabled ||
                isVRForNonPremiumCardHoldersEnabled
              }
              stayType={stayType}
            />
          </Box>
        </>
      )}
    </>
  );
};
