import { connect, ConnectedProps } from "react-redux";

import { withRouter } from "react-router";
import {
  getVacationRentalShopCallState,
  getVacationRentalShopSelectedListing,
  hasVacationRentalShopFailed,
  VacationRentalShopCallState,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { VRDesktopShop } from "./component";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getNightCount,
  getPetsCount,
  getUntilDate,
} from "../../../search/reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => ({
  selectedListing: getVacationRentalShopSelectedListing(state),
  vrShopCallInProgress:
    getVacationRentalShopCallState(state) ===
    VacationRentalShopCallState.InProcess,
  nightCount: getNightCount(state),
  rewardsKey:
    getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
  hasFailed: hasVacationRentalShopFailed(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  adultsCount: getAdultsCount(state),
  childrenCount: getChildrenCount(state),
  petsCount: getPetsCount(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VRDesktopShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedVRDesktopShop = withRouter(connector(VRDesktopShop));
