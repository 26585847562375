import React from "react";

import { Box, Link, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import ReactList from "react-list";
import { RouteComponentProps } from "react-router";

import {
  ActionButton,
  HotelInformationalBanner,
  Icon,
  IconName,
  PremierCollectionAvailabilityCard,
  PremierCollectionBenefitsModal,
  getIsFreeBreakfast,
  textConstants as halifaxTextConstants,
} from "halifax";
import {
  ENGAGED_OFFER_CTA,
  ListingCollectionEnum,
  ListingSearchResult,
  Lodging,
  LodgingCollectionEnum,
  SelectedTravelOfferScreen,
  StayTypesEnum,
  VRAvailabilityResultEnum,
} from "redmond";

import {
  AVAILABLE,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";
import { PremierCollectionAvailabilityCallState } from "../../reducer";
import { AvailabilityMap } from "../AvailabilityMap";
import { PremierCollectionAvailabilitySearchControl } from "../AvailabilitySearchControl";
import { AvailabilitySort } from "../AvailabilitySort";
import { PremierCollectionHotelFunnelCTA } from "../HotelFunnelCTA";
import { AvailabilityListConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import { config } from "../../../../api/config";
import "./styles.scss";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IAvailabilityListProps
  extends AvailabilityListConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  isVRForPremiumCardHoldersEnabled: boolean;
  isLCForPremiumCardHoldersEnabled: boolean;
  isLCForNonPremiumCardHoldersEnabled: boolean;
  openExpandedMap?: () => void;
  navigateToProperty: (
    property: Lodging | ListingSearchResult,
    index: number
  ) => void;
  fetchInitial: () => void;
}

export const PremierCollectionAvailabilityList = (
  props: IAvailabilityListProps
) => {
  const {
    // Context
    isMobile,
    stayType,
    credit,
    nightCount,
    history,
    searchLocation,
    mapBounds,
    // Property selection
    setPropertyIdInFocus,
    setPropertyIdHovered,
    setSelectedLodgingIndex,
    // Outer state
    navigateToProperty,
    openExpandedMap,
    // Accounts
    accountReferenceId,
    selectedAccount,
    largestValueAccount,
    // Availability Management
    vacationRentalsListings,
    lodgings,
    callState,
    isFetchingInitialAvailability,
    isStillFetchingInFollowUpCalls,
    fetchInitial,
    fetchMorePremierCollectionAvailability,
    fetchMoreVacationRentalsAvailability,
    // Experiments
    isVRForPremiumCardHoldersEnabled,
    isLCForPremiumCardHoldersEnabled,
    isLCForNonPremiumCardHoldersEnabled,
  } = props;
  const [openBenefitsModal, setOpenBenefitsModal] = React.useState(false);
  const [benefitsModalVariant, setBenefitsModalVariant] = React.useState<
    | "premier-collection"
    | "lifestyle-collection-premium"
    | "lifestyle-collection-non-premium"
  >();
  const expState = useExperiments();

  const listRef = React.useRef<ReactList | null>(null);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const headerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (headerRef.current && !isFetchingInitialAvailability) {
      headerRef.current.focus();
    }
  }, [isFetchingInitialAvailability]);

  const locationName = React.useMemo(
    () => (mapBounds != null ? "Map area" : searchLocation?.label ?? ""),
    [mapBounds == null, searchLocation?.label]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  React.useEffect(() => {
    if (
      ![
        PremierCollectionAvailabilityCallState.FollowUpSearchCallSuccess,
        PremierCollectionAvailabilityCallState.InitialSearchCallSuccess,
      ].includes(callState)
    ) {
      return;
    }
    switch (stayType) {
      case StayTypesEnum.Hotels: {
        fetchMorePremierCollectionAvailability(
          history,
          isLCForNonPremiumCardHoldersEnabled
            ? LodgingCollectionEnum.Lifestyle
            : LodgingCollectionEnum.Premier
        );
        break;
      }
      case StayTypesEnum.VacationRentals: {
        fetchMoreVacationRentalsAvailability(history);
        break;
      }
    }
  }, [
    history,
    callState,
    fetchMorePremierCollectionAvailability,
    fetchMoreVacationRentalsAvailability,
  ]);

  const isLoading =
    isFetchingInitialAvailability || isStillFetchingInFollowUpCalls;

  const isAvail = (curr: Lodging) => curr.available ?? true;

  const availableSort = (a: Lodging, b: Lodging) => {
    if (isAvail(a) && isAvail(b)) {
      return 0;
    } else if (isAvail(b)) {
      return 1;
    } else if (isAvail(a)) {
      return -1;
    } else {
      return 0;
    }
  };

  // sort lodgings/VR listings
  const sortedProperties = React.useMemo(() => {
    if (stayType === StayTypesEnum.VacationRentals) {
      return vacationRentalsListings?.length ? vacationRentalsListings : [];
    } else {
      return lodgings.sort(availableSort);
    }
  }, [lodgings, stayType, vacationRentalsListings]);

  const numberOfResults =
    stayType === StayTypesEnum.VacationRentals
      ? vacationRentalsListings?.length ?? 0
      : lodgings.length;

  const renderItem = (
    property: ListingSearchResult | Lodging,
    index: number
  ) => {
    const linkId = `availability-row-${index}`;
    if ("lodging" in property) {
      const { lodging } = property;
      const freeBreakfast =
        property.lodgingCollection === LodgingCollectionEnum.Lifestyle &&
        getIsFreeBreakfast(lodging.amenities, true);
      return (
        <Link
          id={linkId}
          onMouseEnter={() => setPropertyIdHovered(lodging.id)}
          onMouseLeave={() => setPropertyIdHovered(null)}
          onFocus={(event) => {
            if (event.target.id === linkId) {
              setPropertyIdInFocus(lodging.id);
            }
          }}
          className={clsx("availability-row-result", {
            unavailable: !property.available,
            "free-breakfast": freeBreakfast,
          })}
          component="a"
          key={lodging.id}
          onClick={() => {
            if (property.available) {
              setSelectedLodgingIndex(index);
              navigateToProperty(property, index);
            }
          }}
        >
          {!isMobile && freeBreakfast ? (
            <HotelInformationalBanner
              content={
                <Tooltip
                  title={
                    <Typography className="tooltip-text">
                      {halifaxTextConstants.FREE_BREAKFAST_TOOLTIP}
                    </Typography>
                  }
                  classes={{
                    popper: "free-breakfast-tooltip-popper",
                    tooltip: "free-breakfast-tooltip-text",
                  }}
                >
                  <Box className="free-breakfast-banner-content">
                    <Typography className="free-breakfast-content-text">
                      Free breakfast for cardholders
                    </Typography>
                    <Icon name={IconName.InfoCircle} />
                  </Box>
                </Tooltip>
              }
              isMobile={false}
              isMap={false}
              iconName={IconName.FreeBreakfast}
            />
          ) : null}
          <PremierCollectionAvailabilityCard
            hotelAvailabilityInfo={{
              ...property,
            }}
            isLifestyleCollection={
              (isLCForPremiumCardHoldersEnabled ||
                isLCForNonPremiumCardHoldersEnabled) &&
              property.lodgingCollection === LodgingCollectionEnum.Lifestyle
            }
            isMobile={isMobile}
            showHotelDetailsCTA={false}
            hotelUnavailableTitleText={textConstants.getMinNightStayHotelUnavailTitle(
              nightCount,
              property.minNightOfStay
            )}
            hotelUnavailableMessage={textConstants.getMinNightStayHotelUnavailMessage(
              nightCount,
              property.minNightOfStay
            )}
            showRibbon={
              isLCForPremiumCardHoldersEnabled ||
              (!isMobile && isLCForNonPremiumCardHoldersEnabled)
            }
            showOffer={
              isTravelWalletOfferExperiment &&
              property.bestOfferThisLodging?.amount.amount !==
                credit?.amount.amount
            }
            nightCount={nightCount}
            rewardsKey={accountReferenceId}
            available={property.available}
            tenant={config.TENANT}
            className={clsx({ "free-breakfast": freeBreakfast })}
            earnTagContent={
              largestValueAccount != null ? (
                <>
                  <Icon name={IconName.StarIcon} />
                  <Typography
                    className="earn-tag-text"
                    dangerouslySetInnerHTML={{
                      __html: textConstants.getEarnTagText(
                        largestValueAccount.earn?.hotelsMultiplier,
                        largestValueAccount.rewardsBalance
                          .currencyDescription ??
                          largestValueAccount.rewardsBalance.currency
                      ),
                    }}
                  />
                </>
              ) : undefined
            }
            earnTagClassName="b2b"
            onBenefitsTooltipClick={() => {
              setBenefitsModalVariant(
                (() => {
                  if (
                    property.lodgingCollection ===
                    LodgingCollectionEnum.Lifestyle
                  ) {
                    if (isLCForPremiumCardHoldersEnabled)
                      return "lifestyle-collection-premium";
                    return "lifestyle-collection-non-premium";
                  }
                  return "premier-collection";
                })()
              );
              setOpenBenefitsModal(true);
            }}
            tooltipContent={
              <>
                <Box className="benefits-summary">
                  <Icon name={IconName.StarOutline} />
                  <Typography className="benefits-summary-text">
                    {textConstants.getBenefitsSummary(
                      property.lodgingCollection ??
                        LodgingCollectionEnum.NoCollection,
                      selectedAccount?.productDisplayName,
                      largestValueAccount.earn?.hotelsMultiplier
                    )}
                  </Typography>
                </Box>
                <Box className="additional-benefits">
                  <Icon name={IconName.GiftOutline} />
                  <Typography className="additional-benefits-summary-text">
                    {textConstants.getAdditionalBenefitsSummary()}
                  </Typography>{" "}
                </Box>
              </>
            }
            onOfferTooltipSetOpenModal={(open) => {
              if (open) {
                trackEvent({
                  eventName: ENGAGED_OFFER_CTA,
                  properties: {
                    location: SelectedTravelOfferScreen.PREMIUM_STAYS_SHOP,
                    entry_type: "tooltip",
                    funnel: property.bestOfferThisLodging?.funnels.join(","),
                    offer_name:
                      property.bestOfferThisLodging?.trackingPropertiesV2
                        ?.properties?.offer_name,
                  },
                  encryptedProperties: [
                    property.bestOfferThisLodging?.trackingPropertiesV2
                      ?.encryptedProperties ?? "",
                  ],
                });
              }
            }}
          />
        </Link>
      );
    } else {
      const { listingId } = property;
      return (
        <Link
          id={linkId}
          className="availability-row-result"
          component="a"
          key={listingId.toString()}
          onMouseEnter={() => setPropertyIdHovered(listingId.id)}
          onMouseLeave={() => setPropertyIdHovered(null)}
          onFocus={(event) => {
            if (event.target.id === linkId) {
              setPropertyIdInFocus(listingId.id);
            }
          }}
          onClick={() => navigateToProperty(property, index)}
        >
          <PremierCollectionAvailabilityCard
            home={property}
            premierCollectionEarnBenefits={
              selectedAccount?.earn.hotelsMultiplier
                ? {
                    text: textConstants.EARN_BENEFITS_TEXT(
                      5, // benefit is for vacation rentals so hardcoded to 5
                      selectedAccount?.productDisplayName,
                      property.listing.listingCollection ===
                        ListingCollectionEnum.Lifestyle
                    ),
                  }
                : undefined
            }
            premierCollectionAdditionalBenefits={{
              text: textConstants.ADDITIONAL_BENEFITS_TEXT(true),
            }}
            // isSkeleton={isLoading}
            isMobile={isMobile}
            showHotelDetailsCTA={
              property.availability.AvailabilityResult ===
              VRAvailabilityResultEnum.Available
            }
            showRibbon={
              isLCForPremiumCardHoldersEnabled ||
              (!isMobile && isLCForNonPremiumCardHoldersEnabled)
            }
            showOffer={
              isTravelWalletOfferExperiment &&
              property.bestOffer?.amount.amount !==
                credit?.amount.amount
            }
            ctaText="View home details"
            isLifestyleCollection={
              property.listing.listingCollection ===
              ListingCollectionEnum.Lifestyle
            }
            nightCount={nightCount}
            rewardsKey={accountReferenceId}
            available={
              property.availability.AvailabilityResult ===
              VRAvailabilityResultEnum.Available
            }
            hotelUnavailableTitleText={"Home Unavailable"}
            onOfferTooltipSetOpenModal={(open) => {
              if (open) {
                trackEvent({
                  eventName: ENGAGED_OFFER_CTA,
                  properties: {
                    location: SelectedTravelOfferScreen.PREMIUM_STAYS_SHOP,
                    entry_type: "tooltip",
                    funnel: property.bestOffer?.funnels.join(","),
                    offer_name:
                      property.bestOffer?.trackingPropertiesV2
                        ?.properties?.offer_name,
                  },
                  encryptedProperties: [
                    property.bestOffer?.trackingPropertiesV2
                      ?.encryptedProperties ?? "",
                  ],
                });
              }
            }}
          />
        </Link>
      );
    }
  };

  const renderFooter = () => {
    if (isLoading) {
      return (
        <PremierCollectionAvailabilityCard
          className="premier-collection-availability-card-skeleton"
          isSkeleton
          isMobile={isMobile}
        />
      );
    } else if (
      !isFetchingInitialAvailability &&
      !isStillFetchingInFollowUpCalls &&
      numberOfResults === 0
    ) {
      return (
        <PremierCollectionHotelFunnelCTA
          variant="no-results"
          locationName={locationName}
          isLCForNonPremiumCardHoldersEnabled={
            isLCForNonPremiumCardHoldersEnabled
          }
          isLCForPremiumCardHoldersEnabled={isLCForPremiumCardHoldersEnabled}
          stayType={stayType}
        />
      );
    } else {
      return (
        <PremierCollectionHotelFunnelCTA
          variant="banner"
          locationName={locationName}
          isLCForNonPremiumCardHoldersEnabled={
            isLCForNonPremiumCardHoldersEnabled
          }
          isLCForPremiumCardHoldersEnabled={isLCForPremiumCardHoldersEnabled}
          stayType={stayType}
        />
      );
    }
  };

  return (
    <Box
      className={clsx("pc-availability-list-root", {
        mobile: isMobile,
        "only-lifestyle-collection": isLCForNonPremiumCardHoldersEnabled,
      })}
    >
      {!isMobile ? (
        <Box className="search-control">
          <PremierCollectionAvailabilitySearchControl
            doSearch={fetchInitial}
            isLifestyleCollection={isLCForNonPremiumCardHoldersEnabled}
            isLCForNonPremiumCardHoldersEnabled={
              isLCForNonPremiumCardHoldersEnabled
            }
            isLCForPremiumCardHoldersEnabled={isLCForPremiumCardHoldersEnabled}
          />
        </Box>
      ) : undefined}
      <PremierCollectionBenefitsModal
        openBenefitsModal={openBenefitsModal}
        handleClosePCBenefitsModal={() => {
          setOpenBenefitsModal(false);
        }}
        isMobile={isMobile}
        variant={benefitsModalVariant}
        useBenefitsSummary
        largestValueAccount={largestValueAccount}
      />
      <Box className="availability-list-container">
        {isFetchingInitialAvailability || numberOfResults > 0 ? (
          <Box className="availability-list-header">
            <Typography
              className="pc-count-heading"
              ref={headerRef}
            >
              {textConstants.COUNT_HEADING_TEXT(
                numberOfResults,
                locationName,
                isLoading,
                isLCForPremiumCardHoldersEnabled,
                isLCForNonPremiumCardHoldersEnabled,
                stayType
              )}
            </Typography>
            {!isMobile && numberOfResults > 1 && <AvailabilitySort />}
          </Box>
        ) : undefined}

        <div ref={divRef} className="availability-list">
          {isMobile && !isFetchingInitialAvailability && numberOfResults > 0 ? (
            <div className="availability-map-preview">
              <AvailabilityMap
                isPreview
                isLCForNonPremiumCardHoldersEnabled={
                  isLCForNonPremiumCardHoldersEnabled
                }
                isVRForPremiumCardHoldersEnabled={
                  isVRForPremiumCardHoldersEnabled
                }
                navigateToProperty={() => undefined}
              />
              <div
                className="availability-map-button-container"
                onClick={openExpandedMap}
              >
                <ActionButton
                  fill="blue"
                  className={"show-map-button b2b"}
                  onClick={() => undefined}
                  message={
                    <Typography className="toggle-map-button-text">
                      {stayType === StayTypesEnum.VacationRentals
                        ? textConstants.VIEW_HOMES_ON_MAP
                        : textConstants.VIEW_HOTELS_ON_MAP}
                    </Typography>
                  }
                />
              </div>
            </div>
          ) : undefined}
          <ReactList
            ref={listRef}
            itemRenderer={(index) => {
              const property = sortedProperties[index];
              const id =
                "listingId" in property
                  ? property.listingId.id
                  : property.lodging.id;
              return (
                <div key={id} className="availability-list-item">
                  {renderItem(property, index)}
                </div>
              );
            }}
            length={sortedProperties.length}
          />
          {renderFooter()}
        </div>
      </Box>
    </Box>
  );
};
