import React from "react";

import { Box } from "@material-ui/core";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";

import { GenericDropdown, Icon, IconName } from "halifax";

import {
  PremierCollectionAvailabilitySortOption,
  initialState,
} from "../../reducer";
import { SortSelection } from "../AvailabilityFilter/components/SortSelection";
import { AvailabilitySortConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface IAvailabilitySortProps
  extends AvailabilitySortConnectorProps,
    RouteComponentProps {}

const sortOptionLabel: {
  [key in PremierCollectionAvailabilitySortOption]: string;
} = {
  Recommended: textConstants.RECOMMENDED_TEXT,
  PricingASC: textConstants.PRICE_ASC,
  PricingDESC: textConstants.PRICE_DESC,
};

export const AvailabilitySort = (props: IAvailabilitySortProps) => {
  const { sortOption, setPremierCollectionAvailabilitySortOption } = props;

  const [closeSortPopover, setCloseSortPopover] =
    React.useState<boolean>(false);

  const [localSortOption, setLocalSortOption] =
    React.useState<PremierCollectionAvailabilitySortOption>(sortOption);

  const resetSortOption = () => {
    setPremierCollectionAvailabilitySortOption(initialState.sortOption);
  };
  const resetLocalSortOption = () => {
    setLocalSortOption(sortOption);
  };
  const handleApplySortOption = () => {
    setPremierCollectionAvailabilitySortOption(localSortOption);
    setCloseSortPopover(true);
  };

  const sortOptionSet = sortOption !== initialState.sortOption;
  const hasSortOptionToApply = sortOption !== localSortOption;

  return (
    <Box className={clsx("premier-collection-availability-sort-root")}>
      <Box className="availability-sort-container" tabIndex={0}>
        <GenericDropdown
          buttonClassName={clsx(
            "premier-collection-availability-sort-button",
            "b2b-shop-filter",
            { "has-value": sortOptionSet }
          )}
          popoverClassName={clsx(
            "vacation-rentals-availability-sort-dropdown-popover",
            "b2b"
          )}
          ariaLabel={textConstants.VACATION_RENTALS_SORT_BUTTON_LABEL}
          dropdownLabel={
            <>
              <strong>Sort:</strong> {sortOptionLabel[sortOption]}
            </>
          }
          dropdownIcon={
            sortOptionSet ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  resetSortOption();
                }}
              >
                <Icon name={IconName.XCircle} />
              </div>
            ) : undefined
          }
          dropdownContent={
            <SortSelection
              selectedSortOption={localSortOption}
              setSortOption={setLocalSortOption}
              onClickApply={handleApplySortOption}
              hasValueToApply={hasSortOptionToApply}
              labelPlacement="start"
            />
          }
          showClose={false}
          onToggle={(open) => {
            if (open) {
              resetLocalSortOption();
            }
            setCloseSortPopover(!open);
          }}
          forceClose={closeSortPopover}
        />
      </Box>
    </Box>
  );
};
