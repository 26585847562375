import React, { useContext, useMemo, useState } from "react";
import {
  GenericBanner,
  useDeviceTypes,
  PriceMatchGuarantee,
  MobilePopoverCard,
  CloseButtonIcon,
  ActionLink,
  ButtonWrap,
} from "halifax";
import { B2B_PORTAL_BANNER_CONTAINER_ID } from "redmond";
import { ClientContext } from "../../../../App";
import * as textConstants from "./textConstants";
import { PATH_BEST_PRICE_GUARANTEE } from "../../../../utils/paths";
import { Box, Typography, Dialog } from "@material-ui/core";
import { BEST_PRICE_GUARANTEE_BODY } from "redmond";
import "./styles.scss";
import {
  useExperiments,
  getExperimentVariant,
  AVAILABLE,
  PRICE_MATCH_CREDITS_EXPERIMENT,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";

export const PriceMatchBanner = () => {
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();
  const [priceMatchModalOpen, setPriceMatchModalOpen] = useState(false);

  const isPriceMatchCredits = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        PRICE_MATCH_CREDITS_EXPERIMENT
      ) === AVAILABLE,
    [expState.experiments]
  );
  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  const renderCloseModalButton = () => {
    return (
      <ActionLink
        className="price-drop-protection-close-button"
        onClick={() => setPriceMatchModalOpen(false)}
        content={<CloseButtonIcon className="close-button-icon" />}
        label="Close"
        showTappableArea={true}
      />
    );
  };
  return (
    <>
      <GenericBanner
        tabIndex={0}
        className="price-match-banner-root"
        content={
          <Typography variant="subtitle2">
            Hi, {firstName}. If you find a better price elsewhere,{" "}
            <ButtonWrap>
              <span
                className="price-match-modal-text"
                onClick={() => setPriceMatchModalOpen(true)}
              >
                we'll match it.
              </span>
            </ButtonWrap>
          </Typography>
        }
        containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
        isMobile={matchesMobile}
      />
      {!matchesMobile && (
        <Dialog
          open={priceMatchModalOpen}
          className="price-match-popup"
          /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
          TransitionProps={{ role: "none" } as never}
          PaperProps={{
            /* eslint-disable */
            // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
            tabIndex: 0,
            /* eslint-enable */
          }}
          onClose={() => setPriceMatchModalOpen(false)}
        >
          <Box className="price-match-wrapper">
            <PriceMatchGuarantee
              className="price-match-modal"
              title={textConstants.PRICE_MATCH_TITLE}
              onClick={() =>
                window.open(`${PATH_BEST_PRICE_GUARANTEE} `, "_blank")
              }
              onClose={() => setPriceMatchModalOpen(false)}
              subtitle={
                isPriceMatchCredits
                  ? textConstants.PRICE_MATCH_CREDITS_SUBTITLE
                  : textConstants.PRICE_MATCH_SUBTITLE
              }
              header={textConstants.PRICE_MATCH_HEADER}
              ctaText={textConstants.PRICE_MATCH_CTA_TEXT}
              disclaimerText={textConstants.PRICE_MATCH_DISCLAIMER_TEXT(
                config.TENANT
              )}
            />
          </Box>
        </Dialog>
      )}
      {matchesMobile && (
        <MobilePopoverCard
          topRightButton={renderCloseModalButton()}
          open={priceMatchModalOpen}
          className="mobile-price-match-popup"
          contentClassName="mobile-price-match-content-wrapper"
          onClose={() => setPriceMatchModalOpen(false)}
        >
          <PriceMatchGuarantee
            className="price-match-modal"
            title={textConstants.PRICE_MATCH_TITLE}
            subtitle={
              isPriceMatchCredits
                ? textConstants.PRICE_MATCH_CREDITS_SUBTITLE
                : textConstants.PRICE_MATCH_SUBTITLE
            }
            header={textConstants.PRICE_MATCH_HEADER}
            ctaText={textConstants.PRICE_MATCH_CTA_TEXT}
            disclaimerText={textConstants.PRICE_MATCH_DISCLAIMER_TEXT(
              config.TENANT
            )}
            mobileTermsConditionCopy={BEST_PRICE_GUARANTEE_BODY}
            isMobile={true}
          />
        </MobilePopoverCard>
      )}
    </>
  );
};
