import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { VacationRentalsPriceBreakdownDropdown } from "./component";
import {
  getVacationRentalsPricingLineItems,
  getVacationRentalsRewardsAndTotalLineItems,
  getVacationRentalsTripTotalInPrices,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    pricingLineItems: getVacationRentalsPricingLineItems(state),
    rewardsAndTotalLineItems: getVacationRentalsRewardsAndTotalLineItems(state),
    tripTotal: getVacationRentalsTripTotalInPrices(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VacationRentalsPriceBreakdownDropdownConnectorProps =
  ConnectedProps<typeof connector>;

export const ConnectedVacationRentalsPriceBreakdownDropdown = withRouter(
  connector(VacationRentalsPriceBreakdownDropdown)
);
