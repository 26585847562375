import axios, { AxiosResponse } from "axios";
import { config } from "../../config";
import { travelWalletApiPrefix } from "../paths";

const END_POINT: string = `${travelWalletApiPrefix}/offers/earn/authorize`;

export interface AuthorizeEarnOfferRequest {
  earnOfferId: string;
}

export type AuthorizeEarnOfferSuccessResponse = {
  successfullyAuthorizedOn: string;
};

export type AuthorizeEarnOfferErrorResponse = { error: string };

export type AuthorizeEarnOfferResponse =
  | AuthorizeEarnOfferSuccessResponse
  | AuthorizeEarnOfferErrorResponse;

export const authorizeEarnOffer = (
  req: AuthorizeEarnOfferRequest
): Promise<AuthorizeEarnOfferSuccessResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<AuthorizeEarnOfferResponse> = await axios.put(
        END_POINT,
        req,
        {
          baseURL: config.baseURL,
        }
      );

      if ("error" in res.data) {
        reject(
          `Failed to authorize HSP earn offer ${req.earnOfferId}: ${res.data.error}`
        );
      } else if (res.status !== 200) {
        reject(
          `Failed to authorize HSP earn offer ${req.earnOfferId}: HTTP status ${res.status}`
        );
      } else {
        resolve(res.data);
      }
    } catch (e) {
      reject(e);
    }
  });
