import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  getCreditBreakdown,
  getTravelCreditsHistoryTransaction,
  getTravelWalletCredit,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { HotelTravelCreditBalanceCard } from "./component";
import { getRewardsAccounts } from "../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => ({
  credit: getTravelWalletCredit(state),
  creditBreakdown: getCreditBreakdown(state),
  creditsTransactionHistory: getTravelCreditsHistoryTransaction(state),
  rewardsAccounts: getRewardsAccounts(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelTravelCreditBalanceCardConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelTravelCreditBalanceCard = connector(
  withRouter(HotelTravelCreditBalanceCard)
);
