import { connect, ConnectedProps } from "react-redux";

import { PremierCollectionBookMobileButton } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getUserSelectedTravelerId } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedTravelerId: getUserSelectedTravelerId(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PremierCollectionBookMobileButtonConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPremierCollectionBookMobileButton = connector(
  PremierCollectionBookMobileButton
);
