import { connect, ConnectedProps } from "react-redux";

import { withRouter } from "react-router";
import {
  getVacationRentalShopCallState,
  getVacationRentalShopSelectedListing,
  hasVacationRentalShopFailed,
  VacationRentalShopCallState,
  showOfferBasedOnSelectedListingSelector,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { VRMobileShop } from "./component";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getPetsCount,
  getUntilDate,
} from "../../../search/reducer";
import { getOpenDatesModal } from "../../../availability/reducer";

const mapStateToProps = (state: IStoreState) => ({
  selectedListing: getVacationRentalShopSelectedListing(state),
  vrShopCallInProgress:
    getVacationRentalShopCallState(state) ===
    VacationRentalShopCallState.InProcess,
  hasFailed: hasVacationRentalShopFailed(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  adultsCount: getAdultsCount(state),
  childrenCount: getChildrenCount(state),
  petsCount: getPetsCount(state),
  openDatesModal: getOpenDatesModal(state),
  showOfferBasedOnSelectedListing: showOfferBasedOnSelectedListingSelector(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VRMobileShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedVRMobileShop = withRouter(connector(VRMobileShop));
