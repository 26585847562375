import { put, call, select } from "redux-saga/effects";
import {
  ConnectionResultEnum,
  InvalidEnum,
  PaymentSessionToken,
} from "redmond";
import { actions } from "../../actions";
import schedulePriceQuote from "../../../../api/v0/book/book-flow/schedulePriceQuote";
import { IStoreState } from "../../../../reducers/types";
import { getSession, priceQuoteParamsSelector } from "../../reducer";
import closeSession from "../../../../api/v0/book/session/closeSession";
import * as H from "history";
import { AncillaryOpaqueValue } from "@b2bportal/purchase-api";

// Wrapper function that first opens session, and then calls schedule price quote.
export function* schedulePriceQuoteSaga({
  agentFee,
  pollQuoteOnly,
  ancillaries,
  history,
}: actions.ISchedulePriceQuote) {
  yield call(
    callSchedulePriceQuote,
    agentFee,
    pollQuoteOnly,
    ancillaries,
    history
  );
}

function* callSchedulePriceQuote(
  agentFee?: number,
  pollQuoteOnly?: boolean,
  ancillaries?: AncillaryOpaqueValue[],
  history?: H.History
) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    if (session) {
      yield closeSession(session);
      yield put(actions.clearSession());
    }
    const params = priceQuoteParamsSelector(state);
    if (!params) {
      throw new Error("Invalid Request.");
    }

    if ("hotelQuoteRequest" in params) {
      yield put(actions.setPriceQuoteRequest(params));
    }

    const result: String = yield schedulePriceQuote(
      params,
      params.hotelQuoteRequest.emailAddress
    );

    yield put(actions.setSchedulePriceQuoteCallStateSuccess());
    yield put(actions.setSession({ value: result } as PaymentSessionToken));
    yield put(
      actions.pollPriceQuote({ agentFee, pollQuoteOnly, ancillaries, history })
    );
  } catch (e) {
    yield put(
      actions.setSchedulePriceQuoteCallStateFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
