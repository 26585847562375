import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  RewardsAccount,
  ConvertRewardsToUsdSuccess,
  RewardsAccountMinimumRequirementState,
  LodgingCollectionEnum,
  ListingCollectionEnum,
} from "redmond";
import {
  RewardsCheckoutWorkflowRow,
  RewardsCheckoutWorkflow,
  SplitPay,
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import { RewardsSelectionConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { convertRewardsAmountToUsd } from "../../../../api/v0/rewards/convertRewardsAmountToUsd";
import "./styles.scss";
import { shouldDisableCard } from "../PaymentCard/component";
import {
  ALL_CARDS_IN_PHC,
  AVAILABLE,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

const DO_NOT_APPLY_REWARDS_KEY = "do-not-apply-rewards";

export interface RewardsSelectionProps
  extends RewardsSelectionConnectorProps,
    RouteComponentProps {
  disabled?: boolean;
  rewardsTitleId: string;
  rewardsSubtitleId: string;
}
export const RewardsSelection = (props: RewardsSelectionProps) => {
  const {
    rewardsAccounts,
    selectedRewardsPaymentAccountId,
    rewardsPaymentInFiatCurrency,
    rewardsPaymentInRewardsCurrency,
    isCreditCardPaymentRequired,
    rewardsAccountMinimumRequirementState,
    setSelectedRewardsAccountId,
    setRewardsPaymentAmount,
    fetchProductToEarn,
    tripTotal,
    setRewardsConversionFailed,
    totalCreditCardPaymentRequired,
    setPaymentMethod,
    setEarnValue,
    disabled,
    rewardsTitleId,
    rewardsSubtitleId,
    offerToApply,
    creditToApply,
    selectedLodging,
    selectedHome,
  } = props;

  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();
  const canUseAllCardsExperiment = getExperimentVariant(
    expState.experiments,
    ALL_CARDS_IN_PHC
  );

  const canUseAllCards = useMemo(
    () => canUseAllCardsExperiment === AVAILABLE,
    [canUseAllCardsExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  React.useEffect(() => {
    if (!totalCreditCardPaymentRequired) {
      setPaymentMethod({ paymentMethod: undefined });
      setEarnValue(0);
    } else {
      // only fetch product to earn if credit card payment is required
      fetchProductToEarn();
    }
  }, [totalCreditCardPaymentRequired, fetchProductToEarn]);

  React.useEffect(() => {
    const everyAccountIneligible = rewardsAccounts.every(
      (account) => !(account.allowRewardsRedemption ?? true)
    );

    if (everyAccountIneligible) {
      handleSwitchAccount(null);
      setSelectedRewardsAccountId(DO_NOT_APPLY_REWARDS_KEY);
    }
  }, [tripTotal, rewardsAccounts]);

  React.useEffect(() => {
    if (
      rewardsAccountMinimumRequirementState ===
      RewardsAccountMinimumRequirementState.ALL_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT
    ) {
      setTimeout(() => {
        handleSwitchAccount(null);
        setSelectedRewardsAccountId(DO_NOT_APPLY_REWARDS_KEY);
      });
    }
  }, [rewardsAccountMinimumRequirementState]);

  const handleSetCustomRewardsAmount = async (
    customRewardsValue: number,
    account: RewardsAccount
  ) => {
    try {
      const fiatPrice = await convertRewardsAmountToUsd({
        rewardsAmount: customRewardsValue,
        accountReferenceId: account.accountReferenceId,
      });

      const convertedPrice = (fiatPrice as ConvertRewardsToUsdSuccess).value;

      if (!convertedPrice) {
        throw new Error("Could not fetch split pay conversion.");
      }

      setRewardsPaymentAmount(
        account.accountReferenceId,
        {
          value: customRewardsValue,
          currency: account.rewardsBalance.currency,
        },
        {
          value: convertedPrice,
          currencyCode: account.rewardsCashEquivalent.currencyCode,
          currencySymbol: account.rewardsCashEquivalent.currencySymbol,
        },
        tripTotal
      );
    } catch (err) {
      // TODO: Figure out error handling with product, for now, set the rewards to the full account balance if we cannot convert.
      setRewardsConversionFailed();
      setRewardsPaymentAmount(
        account.accountReferenceId,
        account.rewardsBalance,
        account.rewardsCashEquivalent,
        tripTotal
      );
    }
  };

  const handleSwitchAccount = async (account: RewardsAccount | null) => {
    if (!account) {
      setRewardsPaymentAmount(DO_NOT_APPLY_REWARDS_KEY, null, null, tripTotal);
      return;
    }

    const { rewardsBalance, rewardsCashEquivalent, accountReferenceId } =
      account;

    // If the account is not the same as the existing selected account.
    if (accountReferenceId !== selectedRewardsPaymentAccountId) {
      setRewardsPaymentAmount(
        account.accountReferenceId,
        rewardsBalance,
        rewardsCashEquivalent,
        tripTotal
      );
    }
  };

  const renderRewardAccountRow = ({
    account,
    isSelectedAccount,
    isExpanded,
    isDisabled,
  }: {
    account?: RewardsAccount;
    isSelectedAccount: boolean;
    isExpanded?: boolean;
    isDisabled: boolean;
  }) => {
    const minRewardsAmountNotMatched =
      account && account.redemptionMin?.value > account.rewardsBalance.value;
    const emptyRewards = account && account.rewardsBalance?.value <= 0;

    return (
      <RewardsCheckoutWorkflowRow
        account={account}
        titles={{
          rewardsApplyText: textConstants.SPLIT_PAY_REWARDS_APPLY_TEXT,
          doNotApplyRewardsText: textConstants.SPLIT_PAY_DO_NOT_APPLY_TEXT,
        }}
        doNotApplyRewardsKey={DO_NOT_APPLY_REWARDS_KEY}
        setSelectedAccountReferenceId={setSelectedRewardsAccountId}
        onSwitchAccount={handleSwitchAccount}
        isSelectedAccount={isSelectedAccount}
        isMobile={matchesMobile}
        aria-expanded={isExpanded}
        disabled={
          isDisabled || disabled || minRewardsAmountNotMatched || emptyRewards
        }
      />
    );
  };

  const renderSplitPay = (account: RewardsAccount) => (
    <SplitPay
      account={account}
      splitPayCoversTotal={!isCreditCardPaymentRequired}
      customAmount={rewardsPaymentInRewardsCurrency?.value}
      setCustomAmount={async (value) =>
        handleSetCustomRewardsAmount(value, account)
      }
      rewardsPaymentInFiatCurrency={rewardsPaymentInFiatCurrency}
      titles={{
        applyText: textConstants.SPLIT_PAY_APPLY_TEXT,
        editButtonText: textConstants.SPLIT_PAY_EDIT_TEXT,
        saveButtonText: textConstants.SPLIT_PAY_SAVE_TEXT,
        cancelButtonText: textConstants.SPLIT_PAY_CANCEL_TEXT,
        customAmountTooHighError:
          textConstants.SPLIT_PAY_CUSTOM_AMOUNT_TOO_HIGH,
        customAmountGenericError:
          textConstants.SPLIT_PAY_CUSTOM_AMOUNT_GENERIC_ERROR,
        saveNotification: textConstants.SPLIT_PAY_SAVE_NOTIFICATION,
        coversTotalCostNotification: isCreditAndOfferStackingExperimentV1
          ? textConstants.getSplitPayCoverageNotification(
              !!offerToApply,
              !!creditToApply
            )
          : textConstants.SPLIT_PAY_COVERS_TOTAL_COST_NOTIFICATION,
      }}
      isMobile={matchesMobile}
      disabled={disabled}
    />
  );

  const renderNotification = (): JSX.Element | null => {
    const getNotficationLabel = () => {
      switch (rewardsAccountMinimumRequirementState) {
        case RewardsAccountMinimumRequirementState.ALL_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT:
          return textConstants.ALL_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT;
        case RewardsAccountMinimumRequirementState.ONE_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT:
          return textConstants.ONE_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT;
        default:
          return "";
      }
    };
    const label = getNotficationLabel();

    if (!label) {
      return null;
    }
    return (
      <NotificationBanner
        className={clsx("rewards-checkout-notification", {
          mobile: matchesMobile,
        })}
        label={label}
        severity={BannerSeverity.WARNING}
        icon={<Icon name={IconName.WarningAlert} />}
      />
    );
  };

  return (
    <>
      {!disabled && renderNotification()}
      <RewardsCheckoutWorkflow
        rewardsAccounts={rewardsAccounts}
        renderRewardAccountRow={renderRewardAccountRow}
        renderSplitPay={renderSplitPay}
        selectedAccountReferenceId={selectedRewardsPaymentAccountId ?? null}
        doNotApplyRewardsKey={DO_NOT_APPLY_REWARDS_KEY}
        isMobile={matchesMobile}
        disabled={disabled}
        shouldDisableAccount={shouldDisableCard(
          canUseAllCards,
          selectedHome
            ? selectedHome?.listing.listingCollection ===
                ListingCollectionEnum.Lifestyle
            : selectedLodging?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle
        )}
        {...{ rewardsTitleId, rewardsSubtitleId }}
      />
    </>
  );
};
