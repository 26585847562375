import axios from "axios";

import { PaymentSessionToken } from "redmond";
import { purchaseApi, QuoteResponseV0 } from "@b2bportal/purchase-api";

const pollPriceQuote = (req: PaymentSessionToken): Promise<QuoteResponseV0> =>
  new Promise((resolve, reject) => {
    try {
      const res = purchaseApi(axios).apiV0PurchaseQuotePollPost({
        value: req.value,
      });

      res.then((body) => resolve(body.data)).catch((e) => reject(e));
    } catch (e) {
      reject(e);
    }
  });

export default pollPriceQuote;
