import React from "react";

import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import queryStringParser from "query-string";
import { useHistory } from "react-router-dom";

import { ActionButton, useDeviceTypes } from "halifax";
import { StayTypesEnum } from "redmond";

import { HOTELS_PATH_AVAILABILITY } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import {
  HOTEL_CTA_BUTTON_TEXT,
  HOTEL_CTA_SUBTITLE,
  HOTEL_CTA_TITLE,
  HOTEL_NO_RESULTS_CTA_SUBTITLE,
  HOTEL_NO_RESULTS_CTA_TITLE,
} from "./textConstants";

import "./styles.scss";

interface PremierCollectionHotelFunnelCTAProps {
  locationName?: string;
  variant: "banner" | "no-results";
  isLCForPremiumCardHoldersEnabled?: boolean;
  isLCForNonPremiumCardHoldersEnabled?: boolean;
  stayType: StayTypesEnum;
}

export const PremierCollectionHotelFunnelCTA = ({
  locationName,
  variant = "banner",
  isLCForPremiumCardHoldersEnabled = false,
  isLCForNonPremiumCardHoldersEnabled = false,
  stayType,
}: PremierCollectionHotelFunnelCTAProps) => {
  const { matchesMobile } = useDeviceTypes();
  const history = useHistory();

  const parsedQueryStringPrimitive = React.useMemo(
    () => queryStringParser.parse(history.location.search),
    [history.location.search]
  );

  const searchLocationName = React.useMemo(
    () => parsedQueryStringPrimitive.locationName as string,
    [parsedQueryStringPrimitive]
  );

  const searchCity = React.useMemo(
    () => searchLocationName.split(",")[0],
    [searchLocationName]
  );

  const onSearchHotelsClick = React.useCallback(() => {
    const search = transformToStringifiedAvailabilityQuery({
      location: encodeURIComponent(searchLocationName),
      fromDate: new Date(parsedQueryStringPrimitive.fromDate as string),
      untilDate: new Date(parsedQueryStringPrimitive.untilDate as string),
      adultsCount: parseInt(
        parsedQueryStringPrimitive.adultsCount as string,
        10
      ),
      childrenCount: parseInt(
        parsedQueryStringPrimitive.childrenCount as string,
        10
      ),
    });

    if (matchesMobile) history.push(`${HOTELS_PATH_AVAILABILITY}${search}`);
    else window.open(`${HOTELS_PATH_AVAILABILITY}${search}`, "_blank");
  }, [matchesMobile, parsedQueryStringPrimitive, searchLocationName]);

  return (
    <Box
      className={clsx({
        mobile: matchesMobile,
        banner: variant === "banner",
        "no-results": variant === "no-results",
        "premier-collection-hotel-funnel-cta":
          !isLCForNonPremiumCardHoldersEnabled,
        "with-lifestyle-collection": isLCForPremiumCardHoldersEnabled,
        "lifestyle-collection-hotel-funnel-cta":
          isLCForNonPremiumCardHoldersEnabled,
      })}
    >
      <Box className="cta-text">
        <Typography className="cta-text-title" variant="h3">
          {variant === "banner"
            ? HOTEL_CTA_TITLE
            : HOTEL_NO_RESULTS_CTA_TITLE(
                locationName,
                isLCForPremiumCardHoldersEnabled,
                isLCForNonPremiumCardHoldersEnabled,
                stayType
              )}
        </Typography>
        <Typography className="cta-text-subtitle" variant="subtitle1">
          {variant === "banner"
            ? HOTEL_CTA_SUBTITLE(
                searchCity,
                isLCForPremiumCardHoldersEnabled,
                isLCForNonPremiumCardHoldersEnabled,
                stayType
              )
            : HOTEL_NO_RESULTS_CTA_SUBTITLE(
                searchCity,
                isLCForPremiumCardHoldersEnabled,
                isLCForNonPremiumCardHoldersEnabled
              )}
        </Typography>
        <ActionButton
          className="cta-text-button"
          message={HOTEL_CTA_BUTTON_TEXT(searchCity, stayType)}
          onClick={onSearchHotelsClick}
        />
      </Box>
    </Box>
  );
};
