import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import { actions } from "../../actions";
import {
  getUntilDate,
  getFromDate,
  getNightCount,
} from "../../../search/reducer/selectors";
import {
  getPremierCollectionShopRoomInfoProducts,
  getPremierCollectionShopCallState,
  getPremierCollectionShopSelectedAvailability,
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopChosenRoomInfoIndex,
  getPremierCollectionShopChosenProductIndex,
  getPremierCollectionShopChosenRoomInfo,
  premierCollectionShopProgressSelector,
} from "../../reducer/selectors";
import { MobilePremierCollectionShopRoomTypePickerPanel } from "./component";
import { PremierCollectionShopCallState } from "../../reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  const checkinDate = getFromDate(state);
  const checkoutDate = getUntilDate(state);
  const chosenRoomInfoIndex =
    getPremierCollectionShopChosenRoomInfoIndex(state);
  const chosenProductIndex = getPremierCollectionShopChosenProductIndex(state);
  const chosenRoomInfo = getPremierCollectionShopChosenRoomInfo(state);
  const chosenProduct = getPremierCollectionShopChosenProduct(state);
  const selectedLodging = getPremierCollectionShopSelectedAvailability(state);
  const nightCount = getNightCount(state);
  const rewardsKey =
    getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined;
  const roomInfoProducts = getPremierCollectionShopRoomInfoProducts(state);
  const isPremierCollectionShopLoading =
    getPremierCollectionShopCallState(state) ===
    PremierCollectionShopCallState.InProcess;
  const premierCollectionShopStep =
    premierCollectionShopProgressSelector(state);

  return {
    checkinDate,
    checkoutDate,
    chosenRoomInfoIndex,
    chosenProductIndex,
    chosenRoomInfo,
    chosenProduct,
    selectedLodging,
    nightCount,
    rewardsKey,
    roomInfoProducts,
    isPremierCollectionShopLoading,
    premierCollectionShopStep,
  };
};

const mapDispatchToProps = {
  selectRoomType: actions.selectRoomType,
  fetchPremierCollectionShop: actions.fetchPremierCollectionShop,
  setPremierCollectionShopProgress: actions.setPremierCollectionShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePremierCollectionShopRoomTypePickerPanelConnectorProps =
  ConnectedProps<typeof connector>;

export const ConnectedMobilePremierCollectionShopRoomTypePickerPanel =
  withRouter(connector(MobilePremierCollectionShopRoomTypePickerPanel));
