import {
  CreditCard,
  Invalid,
  IPerson,
  PassengerError,
  Payment,
  PaymentVerifyResultEnum,
  PersonId,
  PriceQuote,
  Reservation,
  FiatPrice,
  RewardsPrice,
  CallState,
  PaymentSessionToken,
  PaymentError,
  Prices,
  SELECT_REWARDS,
  SELECT_PAYMENT,
  TravelWalletOffer,
  TravelWalletCredit,
  HotelPricing,
  HomesPriceQuote,
  Person,
  HotelPriceQuoteScheduleRequestV2,
  HotelSubmitForApprovalPayload,
  EarnResponse,
} from "redmond";

import * as actionTypes from "./constants";
import * as H from "history";
import { IUpdateUserPassengerRequest } from "../../../api/v0/book/user-passengers/updateUserPassenger";
import { IDeleteUserPassengerRequest } from "../../../api/v0/book/user-passengers/deleteUserPassenger";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { AncillaryOpaqueValue, PurchaseError } from "@b2bportal/purchase-api";
import { HomesReservation } from "redmond/apis/tysons/vacation-rentals";

export const fetchUserPassengers = (): IFetchUserPassengers => ({
  type: actionTypes.FETCH_USER_PASSENGERS,
});

export interface IFetchUserPassengers {
  type: actionTypes.FETCH_USER_PASSENGERS;
}

export const updateUserPassenger = (
  payload: IUpdateUserPassengerArgs,
  addDriversLicense?: boolean
): IUpdateUserPassenger => ({
  type: actionTypes.UPDATE_USER_PASSENGER,
  payload,
  addDriversLicense,
});

export interface IUpdateUserPassenger {
  type: actionTypes.UPDATE_USER_PASSENGER;
  payload: IUpdateUserPassengerArgs;
  addDriversLicense?: boolean;
}

export interface IUpdateUserPassengerArgs extends IUpdateUserPassengerRequest {}

export const deleteUserPassenger = (
  payload: IDeleteUserPassengerArgs
): IDeleteUserPassenger => ({
  type: actionTypes.DELETE_USER_PASSENGER,
  payload,
});

export interface IDeleteUserPassenger {
  type: actionTypes.DELETE_USER_PASSENGER;
  payload: IDeleteUserPassengerArgs;
}

export interface IDeleteUserPassengerArgs extends IDeleteUserPassengerRequest {}

export const setUserPassengers = (args: {
  userPassengerCallState: CallState;
  userPassengers: IPerson[];
}): ISetUserPassengers => ({
  type: actionTypes.SET_USER_PASSENGERS,
  ...args,
});

export interface ISetUserPassengers {
  type: actionTypes.SET_USER_PASSENGERS;
  userPassengerCallState: CallState;
  userPassengers: IPerson[];
}

export const setUserSelectedPassengerIds = (args: {
  userSelectedPassengerIds: PersonId[];
}): ISetUserSelectedPassengerIds => ({
  type: actionTypes.SET_USER_SELECTED_PASSENGER_IDS,
  ...args,
});

export interface ISetUserSelectedPassengerIds {
  type: actionTypes.SET_USER_SELECTED_PASSENGER_IDS;
  userSelectedPassengerIds: PersonId[];
}

export const setUserPassengersCallStateFailed =
  (): ISetUserPassengersCallStateFailed => ({
    type: actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED,
  });

export interface ISetUserPassengersCallStateFailed {
  type: actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED;
}

export const acknowledgeUserPassengerFailure =
  (): IAcknowledgeUpdateUserPassengerFailure => ({
    type: actionTypes.ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE,
  });

export interface IAcknowledgeUpdateUserPassengerFailure {
  type: actionTypes.ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE;
}

export const acknowledgePriceDifference = (): IAcknowledgePriceDifference => ({
  type: actionTypes.ACKNOWLEDGE_PRICE_DIFFERENCE,
});

export interface IAcknowledgePriceDifference {
  type: actionTypes.ACKNOWLEDGE_PRICE_DIFFERENCE;
}

export interface ISetContactInfo {
  type: actionTypes.SET_CONTACT_INFO;
  email: string;
  phoneNumber: string;
}

export const setContactInfo = (
  email: string,
  phoneNumber: string
): ISetContactInfo => ({
  type: actionTypes.SET_CONTACT_INFO,
  email,
  phoneNumber,
});

export const fetchPaymentMethod = (paymentId: string): IFetchPaymentMethod => ({
  type: actionTypes.FETCH_PAYMENT_METHOD,
  paymentId,
});

export interface IFetchPaymentMethod {
  type: actionTypes.FETCH_PAYMENT_METHOD;
  paymentId: string;
}

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const verifyPaymentMethod = (
  payload: IVerifyPaymentMethodArgs,
  accountId: string,
  isVCN: boolean
): IVerifyPaymentMethod => ({
  type: actionTypes.VERIFY_PAYMENT_METHOD,
  payload,
  accountId,
  isVCN,
});

export interface IVerifyPaymentMethod {
  type: actionTypes.VERIFY_PAYMENT_METHOD;
  payload: IVerifyPaymentMethodArgs;
  accountId: string;
  isVCN: boolean;
}

export interface IVerifyPaymentMethodArgs {
  token: string;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const setPaymentMethod = (args: {
  paymentMethod?: CreditCard;
}): ISetPaymentMethod => ({
  type: actionTypes.SET_PAYMENT_METHOD,
  ...args,
});

export interface ISetPaymentMethod {
  type: actionTypes.SET_PAYMENT_METHOD;
  paymentMethod?: CreditCard;
}

export const resetPaymentCardSelectedAccounts =
  (): IResetPaymentCardSelectedAccounts => {
    return {
      type: actionTypes.RESET_PAYMENT_CARD_SELECTED_ACCOUNTS,
    };
  };

export interface IResetPaymentCardSelectedAccounts {
  type: actionTypes.RESET_PAYMENT_CARD_SELECTED_ACCOUNTS;
}

export const setSelectedPaymentMethodId = (args: {
  paymentMethodId: string;
  accountId?: string;
}): ISetSelectedPaymentMethodId => {
  trackEvent({
    eventName: SELECT_PAYMENT,
    properties: {},
  });
  return {
    type: actionTypes.SET_SELECTED_PAYMENT_METHOD_ID,
    ...args,
  };
};

export interface ISetSelectedPaymentMethodId {
  type: actionTypes.SET_SELECTED_PAYMENT_METHOD_ID;
  paymentMethodId: string;
  accountId?: string;
}

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const deletePaymentMethod = (args: {
  paymentId: string;
}): IDeletePaymentMethod => ({
  type: actionTypes.DELETE_PAYMENT_METHOD,
  ...args,
});

export interface IDeletePaymentMethod {
  type: actionTypes.DELETE_PAYMENT_METHOD;
  paymentId: string;
}

export const deletePaymentMethodCallStateFailed =
  (): IDeletePaymentMethodCallStateFailed => ({
    type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED,
  });

export interface IDeletePaymentMethodCallStateFailed {
  type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED;
}

export const deletePaymentMethodCallStateSuccess =
  (): IDeletePaymentMethodCallStateSuccess => ({
    type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS,
  });

export interface IDeletePaymentMethodCallStateSuccess {
  type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS;
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

export const setPaymentMethodCallStateFailed =
  (): ISetPaymentMethodCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED;
}

export const verifyPaymentMethodCallStateFailed = (
  result: PaymentVerifyResultEnum
): IVerifyPaymentMethodCallStateFailed => ({
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED,
  result,
});

export interface IVerifyPaymentMethodCallStateFailed {
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED;
  result: PaymentVerifyResultEnum;
}

export const verifyPaymentMethodCallStateSuccess =
  (): IVerifyPaymentMethodCallStateSuccess => ({
    type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS,
  });

export interface IVerifyPaymentMethodCallStateSuccess {
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS;
}

export const setSession = (token: PaymentSessionToken): ISetSession => ({
  type: actionTypes.SET_SESSION,
  token,
});

export interface ISetSession {
  type: actionTypes.SET_SESSION;
  token: PaymentSessionToken;
}

export const clearSession = (): IClearSession => ({
  type: actionTypes.CLEAR_SESSION,
});

export interface IClearSession {
  type: actionTypes.CLEAR_SESSION;
}

export const schedulePriceQuote = ({
  history,
  agentFee,
  pollQuoteOnly,
  ancillaries,
}: {
  history: H.History;
  agentFee?: number;
  pollQuoteOnly?: boolean;
  ancillaries?: AncillaryOpaqueValue[];
}): ISchedulePriceQuote => ({
  type: actionTypes.SCHEDULE_PRICE_QUOTE,
  history,
  agentFee,
  pollQuoteOnly,
  ancillaries,
});

export interface ISchedulePriceQuote {
  type: actionTypes.SCHEDULE_PRICE_QUOTE;
  history: H.History;
  agentFee?: number;
  pollQuoteOnly?: boolean;
  ancillaries?: AncillaryOpaqueValue[];
}

export const setSchedulePriceQuoteCallStateSuccess =
  (): ISetSchedulePriceQuoteCallStateSuccess => ({
    type: actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS,
  });

export interface ISetSchedulePriceQuoteCallStateSuccess {
  type: actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS;
}

export const setSchedulePriceQuoteCallStateFailed = (
  invalid: Invalid
): ISetSchedulePriceQuoteCallStateFailure => ({
  type: actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED,
  invalid,
});

export interface ISetSchedulePriceQuoteCallStateFailure {
  type: actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED;
  invalid: Invalid;
}

export const pollPriceQuote = ({
  agentFee,
  pollQuoteOnly,
  ancillaries,
  history,
}: {
  agentFee?: number;
  pollQuoteOnly?: boolean;
  ancillaries?: AncillaryOpaqueValue[];
  history?: H.History;
}): IPollPriceQuote => ({
  type: actionTypes.POLL_PRICE_QUOTE,
  agentFee,
  pollQuoteOnly,
  ancillaries,
  history,
});

export interface IPollPriceQuote {
  type: actionTypes.POLL_PRICE_QUOTE;
  agentFee?: number;
  pollQuoteOnly?: boolean;
  ancillaries?: AncillaryOpaqueValue[];
  history?: H.History;
}

export const setPollPriceQuoteCallStateSuccess =
  (): IPollPriceQuoteCallStateSuccess => ({
    type: actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS,
  });

export interface IPollPriceQuoteCallStateSuccess {
  type: actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS;
}

export const setPollPriceQuoteCallStateFailure = (
  errors: PurchaseError[]
): ISetPollPriceQuoteCallStateFailure => ({
  type: actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED,
  errors,
});

export interface ISetPollPriceQuoteCallStateFailure {
  type: actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED;
  errors: PurchaseError[];
}

export const setPriceQuote = (
  priceQuote: PriceQuote | null,
  pricingWithAncillaries: HotelPricing | null
): ISetPriceQuote => ({
  type: actionTypes.SET_PRICE_QUOTE,
  priceQuote,
  pricingWithAncillaries,
});

export interface ISetPriceQuote {
  type: actionTypes.SET_PRICE_QUOTE;
  priceQuote: PriceQuote | null;
  pricingWithAncillaries: HotelPricing | null;
}

export const pollVacationRentalsPriceQuote = ({
  agentFee,
  pollQuoteOnly,
  history,
}: {
  agentFee?: number;
  pollQuoteOnly?: boolean;
  history?: H.History;
}): IPollVacationRentalsPriceQuote => ({
  type: actionTypes.POLL_VACATION_RENTALS_PRICE_QUOTE,
  agentFee,
  pollQuoteOnly,
  history,
});

export interface IPollVacationRentalsPriceQuote {
  type: actionTypes.POLL_VACATION_RENTALS_PRICE_QUOTE;
  agentFee?: number;
  pollQuoteOnly?: boolean;
  history?: H.History;
}

export const setPollVacationRentalsPriceQuoteCallStateSuccess =
  (): IPollVacationRentalsPriceQuoteCallStateSuccess => ({
    type: actionTypes.SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_SUCCESS,
  });

export interface IPollVacationRentalsPriceQuoteCallStateSuccess {
  type: actionTypes.SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_SUCCESS;
}

export const setPollVacationRentalsPriceQuoteCallStateFailure = (
  errors: PurchaseError[]
): ISetPollVacationRentalsPriceQuoteCallStateFailure => ({
  type: actionTypes.SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_FAILED,
  errors,
});

export interface ISetPollVacationRentalsPriceQuoteCallStateFailure {
  type: actionTypes.SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_FAILED;
  errors: PurchaseError[];
}

export const setVacationRentalsPriceQuote = (
  priceQuote: HomesPriceQuote | null
): ISetVacationRentalsPriceQuote => ({
  type: actionTypes.SET_VACATION_RENTALS_PRICE_QUOTE,
  priceQuote,
});

export interface ISetVacationRentalsPriceQuote {
  type: actionTypes.SET_VACATION_RENTALS_PRICE_QUOTE;
  priceQuote: HomesPriceQuote | null;
}

export const pollPassengerValidation = (): IPollPassengerValidation => ({
  type: actionTypes.POLL_PASSENGER_VALIDATION,
});

export interface IPollPassengerValidation {
  type: actionTypes.POLL_PASSENGER_VALIDATION;
}

export const setPollPassengerValidationCallStatePending =
  (): IPollPassengerValidationCallStatePending => ({
    type: actionTypes.POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING,
  });

export interface IPollPassengerValidationCallStatePending {
  type: actionTypes.POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING;
}

export const setPollPassengerValidationCallStateSuccess =
  (): IPollPassengerValidationCallStateSuccess => ({
    type: actionTypes.POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS,
  });

export interface IPollPassengerValidationCallStateSuccess {
  type: actionTypes.POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS;
}

export const setPollPassengerValidationCallStateFailure = (
  errors: PaymentError[]
): IPollPassengerValidationCallStateFailure => ({
  type: actionTypes.POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED,
  errors,
});

export interface IPollPassengerValidationCallStateFailure {
  type: actionTypes.POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED;
  errors: PaymentError[];
}

export const setPassengersValid = (): ISetPassengersValid => ({
  type: actionTypes.SET_PASSENGERS_VALID,
});

export interface ISetPassengersValid {
  type: actionTypes.SET_PASSENGERS_VALID;
}

export const setPassengersInvalid = (
  errors: PassengerError[]
): ISetPassengersInvalid => ({
  type: actionTypes.SET_PASSENGERS_INVALID,
  errors,
});

export interface ISetPassengersInvalid {
  type: actionTypes.SET_PASSENGERS_INVALID;
  errors: PassengerError[];
}

export const scheduleBook = ({
  agentFee,
  isRecommended,
  ancillaries,
  approvalRequestReason,
}: {
  agentFee?: number;
  isRecommended?: boolean;
  ancillaries?: AncillaryOpaqueValue[];
  approvalRequestReason?: string;
}): ISetScheduleBook => ({
  type: actionTypes.SCHEDULE_BOOK,
  agentFee,
  isRecommended,
  ancillaries,
  approvalRequestReason,
});

export interface ISetScheduleBook {
  type: actionTypes.SCHEDULE_BOOK;
  agentFee?: number;
  isRecommended?: boolean;
  ancillaries?: AncillaryOpaqueValue[];
  approvalRequestReason?: string;
}

export const setScheduleBookSuccess = (): ISetScheduleBookSuccess => ({
  type: actionTypes.SET_SCHEDULE_BOOK_SUCCESS,
});

export interface ISetScheduleBookSuccess {
  type: actionTypes.SET_SCHEDULE_BOOK_SUCCESS;
}

export const setScheduleBookFailed = (
  invalid: Invalid
): ISetScheduleBookFailed => ({
  type: actionTypes.SET_SCHEDULE_BOOK_FAILED,
  invalid,
});

export interface ISetScheduleBookFailed {
  type: actionTypes.SET_SCHEDULE_BOOK_FAILED;
  invalid: Invalid;
}

export const pollConfirmationDetails = (
  payload: PaymentSessionToken,
  agentFee?: number,
  isRecommended?: boolean
): IPollConfirmationDetails => ({
  type: actionTypes.POLL_CONFIRMATION_DETAILS,
  payload,
  agentFee,
  isRecommended,
});

export interface IPollConfirmationDetails {
  type: actionTypes.POLL_CONFIRMATION_DETAILS;
  payload: PaymentSessionToken;
  agentFee?: number;
  isRecommended?: boolean;
}

export const setPollConfirmationDetailsCallStatePending =
  (): IPollConfirmationDetailsCallStatePending => ({
    type: actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING,
  });

export interface IPollConfirmationDetailsCallStatePending {
  type: actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING;
}

export const setPollConfirmationDetailsCallStateSuccess =
  (): IPollConfirmationDetailsCallStateSuccess => ({
    type: actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS,
  });

export interface IPollConfirmationDetailsCallStateSuccess {
  type: actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS;
}

export const setPollConfirmationDetailsCallStateFailure = (
  errors: PurchaseError[]
): IPollConfirmationDetailsCallStateFailure => ({
  type: actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED,
  errors,
});

export interface IPollConfirmationDetailsCallStateFailure {
  type: actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED;
  errors: PurchaseError[];
}

export const setConfirmationDetails = (
  confirmationDetails: Reservation
): ISetConfirmationDetails => ({
  type: actionTypes.SET_CONFIRMATION_DETAILS,
  confirmationDetails,
});

export interface ISetConfirmationDetails {
  type: actionTypes.SET_CONFIRMATION_DETAILS;
  confirmationDetails: Reservation;
}

export const redoSearch = (): IRedoSearch => ({
  type: actionTypes.REDO_SEARCH,
});

export interface IRedoSearch {
  type: actionTypes.REDO_SEARCH;
}

export interface IPopulatePremierCollectionBookQueryParams {
  type: actionTypes.POPULATE_PREMIER_COLLECTION_BOOK_QUERY_PARAMS;
  history: H.History;
}

export const populatePremierCollectionBookQueryParams = (
  history: H.History
): IPopulatePremierCollectionBookQueryParams => ({
  type: actionTypes.POPULATE_PREMIER_COLLECTION_BOOK_QUERY_PARAMS,
  history,
});

export interface ISetPremierCollectionBookQueryParams {
  type: actionTypes.SET_PREMIER_COLLECTION_BOOK_QUERY_PARAMS;
  history: H.History;
}

export const setPremierCollectionBookQueryParams = (
  history: H.History
): ISetPremierCollectionBookQueryParams => ({
  type: actionTypes.SET_PREMIER_COLLECTION_BOOK_QUERY_PARAMS,
  history,
});

export interface ISetSelectedRewardsAccountReferenceId {
  type: actionTypes.SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID;
  rewardsAccountReferenceId: string | null;
}

export const setSelectedRewardsAccountReferenceId = (
  rewardsAccountReferenceId: string | null
): ISetSelectedRewardsAccountReferenceId => {
  trackEvent({
    eventName: SELECT_REWARDS,
    properties: {},
  });
  return {
    type: actionTypes.SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID,
    rewardsAccountReferenceId,
  };
};

export interface ISetSelectedRewardsPaymentTotal {
  type: actionTypes.SET_SELECTED_REWARDS_PAYMENT_TOTAL;
  accountReferenceId: string;
  rewardsPaymentTotal: RewardsPrice | null;
  rewardsPaymentInFiatCurrency: FiatPrice | null;
  tripTotal: Prices | null;
}

export const setSelectedRewardsPaymentTotal = (
  accountReferenceId: string,
  rewardsPaymentTotal: RewardsPrice | null,
  rewardsPaymentInFiatCurrency: FiatPrice | null,
  tripTotal: Prices | null
): ISetSelectedRewardsPaymentTotal => ({
  type: actionTypes.SET_SELECTED_REWARDS_PAYMENT_TOTAL,
  accountReferenceId,
  rewardsPaymentTotal,
  rewardsPaymentInFiatCurrency,
  tripTotal,
});

export interface IFetchProductToEarn {
  type: actionTypes.FETCH_PRODUCT_TO_EARN;
}

export const fetchProductToEarn = (): IFetchProductToEarn => ({
  type: actionTypes.FETCH_PRODUCT_TO_EARN,
});

export interface ISetProductEarnValue {
  type: actionTypes.SET_PRODUCT_EARN_VALUE;
  productEarnValue: number;
}

export const setEarnValue = (
  productEarnValue: number
): ISetProductEarnValue => ({
  type: actionTypes.SET_PRODUCT_EARN_VALUE,
  productEarnValue,
});

export interface IFetchAllEarnForProduct {
  type: actionTypes.FETCH_ALL_EARN_FOR_PRODUCT;
}

export const fetchAllEarnForProduct = (): IFetchAllEarnForProduct => ({
  type: actionTypes.FETCH_ALL_EARN_FOR_PRODUCT,
});

export interface ISetAllEarnValues {
  type: actionTypes.SET_ALL_EARN_VALUES;
  earnValuesByRewardAcctId: { [key: string]: EarnResponse };
}

export const setAllEarnValues = (earnValuesByRewardAcctId: {
  [key: string]: EarnResponse;
}): ISetAllEarnValues => ({
  type: actionTypes.SET_ALL_EARN_VALUES,
  earnValuesByRewardAcctId,
});

export interface ISetRewardsConversionFailed {
  type: actionTypes.REWARDS_CONVERSION_FAILED;
}

export const setRewardsConversionFailed = (): ISetRewardsConversionFailed => ({
  type: actionTypes.REWARDS_CONVERSION_FAILED,
});

export interface IResetBookErrors {
  type: actionTypes.RESET_BOOK_ERRORS;
}

export const resetBookErrors = (): IResetBookErrors => ({
  type: actionTypes.RESET_BOOK_ERRORS,
});

export interface ISetOfferToApply {
  type: actionTypes.SET_OFFER_TO_APPLY;
  offer?: TravelWalletOffer;
}

export const setOfferToApply = (offer?: TravelWalletOffer) => ({
  type: actionTypes.SET_OFFER_TO_APPLY,
  offer,
});

export interface IFetchApplicableTravelWalletItems {
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS;
  isHomesProduct?: boolean; // Used to determine which selector to use to form request
}
export const fetchApplicableTravelWalletItems = (
  isHomesProduct?: boolean
): IFetchApplicableTravelWalletItems => ({
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS,
  isHomesProduct,
});

export interface IFetchApplicableTravelWalletItemsCallStateSuccess {
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS;
}
export const setFetchApplicableTravelWalletItemsCallStateSuccess =
  (): IFetchApplicableTravelWalletItemsCallStateSuccess => ({
    type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS,
  });
export interface IFetchApplicableTravelWalletItemsCallStateFailure {
  type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE;
}
export const setFetchApplicableTravelWalletItemsCallStateFailure =
  (): IFetchApplicableTravelWalletItemsCallStateFailure => ({
    type: actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE,
  });

export interface ISetTravelWalletOffers {
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS;
  offers?: TravelWalletOffer[];
}

export const setTravelWalletOffers = (
  offers?: TravelWalletOffer[]
): ISetTravelWalletOffers => ({
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS,
  offers,
});

export interface ISetBestOfferOverall {
  type: actionTypes.SET_BEST_OFFER_OVERALL;
  offer: TravelWalletOffer;
}

export const setBestOfferOverall = (
  offer: TravelWalletOffer
): ISetBestOfferOverall => ({
  type: actionTypes.SET_BEST_OFFER_OVERALL,
  offer,
});

export interface ISetTravelWalletCredit {
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT;
  credit?: TravelWalletCredit;
}

export const setTravelWalletCredit = (
  credit?: TravelWalletCredit
): ISetTravelWalletCredit => ({
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT,
  credit,
});

export interface ISetCreditToApply {
  type: actionTypes.SET_CREDIT_TO_APPLY;
  credit?: TravelWalletCredit;
}

export const setCreditToApply = (
  credit?: TravelWalletCredit
): ISetCreditToApply => ({
  type: actionTypes.SET_CREDIT_TO_APPLY,
  credit,
});

export const scheduleVacationRentalPriceQuote = ({
  history,
  emailAddress,
  agentFee,
  pollQuoteOnly,
}: {
  history: H.History;
  emailAddress: string;
  agentFee?: number;
  pollQuoteOnly?: boolean;
}): IScheduleVacationRentalPriceQuote => ({
  type: actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE,
  history,
  emailAddress,
  agentFee,
  pollQuoteOnly,
});

export interface IScheduleVacationRentalPriceQuote {
  type: actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE;
  history: H.History;
  emailAddress: string;
  agentFee?: number;
  pollQuoteOnly?: boolean;
}

export const setScheduleVacationRentalPriceQuoteCallStateSuccess =
  (): ISetScheduleVacationRentalPriceQuoteCallStateSuccess => ({
    type: actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_SUCCESS,
  });

export interface ISetScheduleVacationRentalPriceQuoteCallStateSuccess {
  type: actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_SUCCESS;
}

export const setScheduleVacationRentalPriceQuoteCallStateFailed = (
  invalid: Invalid
): ISetScheduleVacationRentalPriceQuoteCallStateFailure => ({
  type: actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_FAILED,
  invalid,
});

export interface ISetScheduleVacationRentalPriceQuoteCallStateFailure {
  type: actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_FAILED;
  invalid: Invalid;
}

export const addMainGuest = ({
  emailAddress,
  mainGuest,
}: {
  emailAddress: string;
  mainGuest: Person | null;
}): IAddMainGuest => ({
  type: actionTypes.ADD_MAIN_GUEST,
  emailAddress,
  mainGuest,
});

export interface IAddMainGuest {
  type: actionTypes.ADD_MAIN_GUEST;
  emailAddress: string;
  mainGuest: Person | null;
}

export const addMainGuestCallStateSuccess =
  ({}: {}): IAddMainGuestCallStateSuccess => ({
    type: actionTypes.ADD_MAIN_GUEST_SUCCESS,
  });

export interface IAddMainGuestCallStateSuccess {
  type: actionTypes.ADD_MAIN_GUEST_SUCCESS;
}

export const addMainGuestCallStateFailed =
  ({}: {}): IAddMainGuestCallStateFailed => ({
    type: actionTypes.ADD_MAIN_GUEST_FAILED,
  });

export interface IAddMainGuestCallStateFailed {
  type: actionTypes.ADD_MAIN_GUEST_FAILED;
}

export const scheduleVacationRentalsBook = ({
  agentFee,
  isRecommended,
}: {
  agentFee?: number;
  isRecommended?: boolean;
}): IScheduleVacationRentalsBook => ({
  type: actionTypes.SCHEDULE_VACATION_RENTALS_BOOK,
  agentFee,
  isRecommended,
});

export interface IScheduleVacationRentalsBook {
  type: actionTypes.SCHEDULE_VACATION_RENTALS_BOOK;
  agentFee?: number;
  isRecommended?: boolean;
}

export const setScheduleVacationRentalsBookSuccess =
  (): ISetScheduleVacationRentalsBookSuccess => ({
    type: actionTypes.SET_SCHEDULE_VACATION_RENTALS_BOOK_SUCCESS,
  });

export interface ISetScheduleVacationRentalsBookSuccess {
  type: actionTypes.SET_SCHEDULE_VACATION_RENTALS_BOOK_SUCCESS;
}

export const setScheduleVacationRentalsBookFailed = (
  invalid: Invalid
): ISetScheduleVacationRentalsBookFailed => ({
  type: actionTypes.SET_SCHEDULE_VACATION_RENTALS_BOOK_FAILED,
  invalid,
});

export interface ISetScheduleVacationRentalsBookFailed {
  type: actionTypes.SET_SCHEDULE_VACATION_RENTALS_BOOK_FAILED;
  invalid: Invalid;
}

export const pollVacationRentalsConfirmationDetails = (
  payload: PaymentSessionToken,
  agentFee?: number,
  isRecommended?: boolean
): IPollVacationRentalsConfirmationDetails => ({
  type: actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS,
  payload,
  agentFee,
  isRecommended,
});

export interface IPollVacationRentalsConfirmationDetails {
  type: actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS;
  payload: PaymentSessionToken;
  agentFee?: number;
  isRecommended?: boolean;
}

export const setPollVacationRentalsConfirmationDetailsCallStatePending =
  (): IPollVacationRentalsConfirmationDetailsCallStatePending => ({
    type: actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_PENDING,
  });

export interface IPollVacationRentalsConfirmationDetailsCallStatePending {
  type: actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_PENDING;
}

export const setPollVacationRentalsConfirmationDetailsCallStateSuccess =
  (): IPollVacationRentalsConfirmationDetailsCallStateSuccess => ({
    type: actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS,
  });

export interface IPollVacationRentalsConfirmationDetailsCallStateSuccess {
  type: actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS;
}

export const setPollVacationRentalsConfirmationDetailsCallStateFailure = (
  errors: PurchaseError[]
): IPollVacationRentalsConfirmationDetailsCallStateFailure => ({
  type: actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_FAILED,
  errors,
});

export interface IPollVacationRentalsConfirmationDetailsCallStateFailure {
  type: actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_FAILED;
  errors: PurchaseError[];
}

export const setVacationRentalsConfirmationDetails = (
  confirmationDetails: HomesReservation
): ISetVacationRentalsConfirmationDetails => ({
  type: actionTypes.SET_VACATION_RENTALS_CONFIRMATION_DETAILS,
  confirmationDetails,
});

export interface ISetVacationRentalsConfirmationDetails {
  type: actionTypes.SET_VACATION_RENTALS_CONFIRMATION_DETAILS;
  confirmationDetails: HomesReservation;
}

// Corporate travel
export const setPriceQuoteRequest = (
  request: HotelPriceQuoteScheduleRequestV2
): ISetPriceQuoteRequest => ({
  type: actionTypes.SET_PRICE_QUOTE_REQUEST,
  payload: request,
});

export interface ISetPriceQuoteRequest {
  type: actionTypes.SET_PRICE_QUOTE_REQUEST;
  payload: HotelPriceQuoteScheduleRequestV2;
}

export interface ISetSubmitForApproval {
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL;
  payload: HotelSubmitForApprovalPayload;
}

export const setSubmitForApproval = (
  payload: HotelSubmitForApprovalPayload
): ISetSubmitForApproval => ({
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL,
  payload,
});

export interface ISetSubmitForApprovalSuccess {
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL_SUCCESS;
}

export const setSubmitForApprovalSuccess =
  (): ISetSubmitForApprovalSuccess => ({
    type: actionTypes.SET_SUBMIT_FOR_APPROVAL_SUCCESS,
  });

export interface ISetSubmitForApprovalFailure {
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL_FAILURE;
  invalid: Invalid;
}

export const setSubmitForApprovalFailure = (
  invalid: Invalid
): ISetSubmitForApprovalFailure => ({
  type: actionTypes.SET_SUBMIT_FOR_APPROVAL_FAILURE,
  invalid,
});

export const fetchCorpUserPassengers = (): IFetchCorpUserPassengers => ({
  type: actionTypes.FETCH_CORP_USER_PASSENGERS,
});

export interface IFetchCorpUserPassengers {
  type: actionTypes.FETCH_CORP_USER_PASSENGERS;
}

export interface ISetTripPurpose {
  type: actionTypes.SET_TRIP_PURPOSE;
  tripPurpose: string;
}

export const setTripPurpose = (tripPurpose: string): ISetTripPurpose => ({
  type: actionTypes.SET_TRIP_PURPOSE,
  tripPurpose,
});

export type PremierCollectionBookActions =
  | IFetchUserPassengers
  | IUpdateUserPassenger
  | IDeleteUserPassenger
  | ISetUserPassengers
  | ISetUserSelectedPassengerIds
  | ISetUserPassengersCallStateFailed
  | IAcknowledgeUpdateUserPassengerFailure
  | ISetContactInfo
  | IFetchPaymentMethod
  | IListPaymentMethods
  | IDeletePaymentMethod
  | ISetPaymentMethod
  | ISetPaymentMethods
  | ISetSelectedPaymentMethodId
  | IVerifyPaymentMethod
  | IVerifyPaymentMethodCallStateSuccess
  | IVerifyPaymentMethodCallStateFailed
  | IDeletePaymentMethodCallStateSuccess
  | IDeletePaymentMethodCallStateFailed
  | ISetPaymentMethodsCallStateFailed
  | ISetPaymentMethodCallStateFailed
  | ISetSession
  | IClearSession
  | ISchedulePriceQuote
  | ISetSchedulePriceQuoteCallStateSuccess
  | ISetSchedulePriceQuoteCallStateFailure
  | IPollPriceQuote
  | IPollPriceQuoteCallStateSuccess
  | ISetPollPriceQuoteCallStateFailure
  | ISetPriceQuote
  | IPollPassengerValidation
  | IPollPassengerValidationCallStatePending
  | IPollPassengerValidationCallStateSuccess
  | IPollPassengerValidationCallStateFailure
  | ISetPassengersValid
  | ISetPassengersInvalid
  | ISetScheduleBook
  | ISetScheduleBookFailed
  | ISetScheduleBookSuccess
  | IPollConfirmationDetails
  | IPollConfirmationDetailsCallStatePending
  | IPollConfirmationDetailsCallStateSuccess
  | IPollConfirmationDetailsCallStateFailure
  | ISetConfirmationDetails
  | IRedoSearch
  | IPopulatePremierCollectionBookQueryParams
  | ISetPremierCollectionBookQueryParams
  | ISetSelectedRewardsAccountReferenceId
  | ISetSelectedRewardsPaymentTotal
  | ISetProductEarnValue
  | IAcknowledgePriceDifference
  | ISetRewardsConversionFailed
  | IResetBookErrors
  | ISetOfferToApply
  | IFetchApplicableTravelWalletItems
  | IFetchApplicableTravelWalletItemsCallStateFailure
  | IFetchApplicableTravelWalletItemsCallStateSuccess
  | ISetTravelWalletOffers
  | ISetBestOfferOverall
  | IResetPaymentCardSelectedAccounts
  | ISetTravelWalletCredit
  | ISetCreditToApply
  | IScheduleVacationRentalPriceQuote
  | ISetScheduleVacationRentalPriceQuoteCallStateSuccess
  | ISetScheduleVacationRentalPriceQuoteCallStateFailure
  | IPollVacationRentalsPriceQuote
  | IPollVacationRentalsPriceQuoteCallStateSuccess
  | ISetPollVacationRentalsPriceQuoteCallStateFailure
  | ISetVacationRentalsPriceQuote
  | IScheduleVacationRentalsBook
  | ISetScheduleVacationRentalsBookSuccess
  | ISetScheduleVacationRentalsBookFailed
  | IPollVacationRentalsConfirmationDetails
  | IPollVacationRentalsConfirmationDetailsCallStatePending
  | IPollVacationRentalsConfirmationDetailsCallStateSuccess
  | IPollVacationRentalsConfirmationDetailsCallStateFailure
  | ISetVacationRentalsConfirmationDetails
  | IAddMainGuest
  | IAddMainGuestCallStateSuccess
  | IAddMainGuestCallStateFailed
  | ISetPriceQuoteRequest
  | ISetSubmitForApproval
  | ISetSubmitForApprovalSuccess
  | ISetSubmitForApprovalFailure
  | IFetchCorpUserPassengers
  | ISetTripPurpose
  | ISetAllEarnValues
  | IFetchAllEarnForProduct;
