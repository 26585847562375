import dayjs from "dayjs";
import * as H from "history";
import queryStringParser from "query-string";
import { call, put, putResolve, select } from "redux-saga/effects";

import {
  IIdLodgings,
  IResponse,
  IResult,
  ITrackingProperties,
  VIEWED_VR_LIST,
  VR_ENTRY,
  VacationRentalEntryProperties,
  VacationRentalsAvailabilityRequest,
  VacationRentalsAvailabilityRequestEnum,
  VacationRentalsAvailabilityResponse,
  ViewedVacationRentalListProperties,
} from "redmond";
import {
  AvailabilityModeEnum,
  SelectionEnum,
} from "redmond/apis/tysons/vacation-rentals";

import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { fetchVacationRentalsAvailability as fetchAvailability } from "../../../api/v0/availability/fetchVacationRentalsAvailability";
import { fetchVacationRentalsLocations } from "../../../api/v0/search/fetchVacationRentalsLocations";
import { IStoreState } from "../../../reducers/types";
import Logger from "../../../utils/logger";
import {
  PATH_HOME,
  PATH_VACATION_RENTALS_AVAILABILITY,
} from "../../../utils/paths";
import { actions as bookActions } from "../../book/actions";
import { actions as searchActions } from "../../search/actions";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getPetsCount,
  getUntilDate,
  getVacationRentalEntryProperties,
  getVacationRentalsLocation,
} from "../../search/reducer";
import { actionTypes, actions } from "../actions";
import {
  getVacationRentalsAvailabilityNextPageToken,
  getViewedVacationRentalListProperties,
} from "../reducer";

export function* fetchVacationRentalsAvailabilitySaga(
  action: actions.IFetchVacationRentalsAvailability
) {
  try {
    let requestBody: VacationRentalsAvailabilityRequest;
    const state: IStoreState = yield select();
    yield put(bookActions.redoSearch());

    let isInitialRequest: boolean;
    const requestData: {
      fromDate: Date;
      untilDate: Date;
      location: IResult;
      adultsCount: number;
      children: number[];
      petsCount: number;
    } = yield call(getVacationRentalsAvailabilityRequestParameters, action);

    if (JSON.stringify(requestData) === "{}") return;

    const { fromDate, untilDate, location, adultsCount, children, petsCount } =
      requestData;

    switch (action.type) {
      case actionTypes.FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY: {
        requestBody = {
          listingSelection: {
            selection: {
              Selection: SelectionEnum.PlaceId,
              placeId: (location?.id as IIdLodgings).lodgingSelection.placeId, // [VR TODO]: Update to use correct location when autocommplete is done
            },
          },
          stayDetails: {
            dateRange: {
              from: dayjs(fromDate).format("YYYY-MM-DD"),
              until: dayjs(untilDate).format("YYYY-MM-DD"),
            },
            guestDetails: {
              adults: adultsCount,
              children: children.length,
              infants: 0, // infants are supported in VR models but not in the UI
              petsIncluded: petsCount ? petsCount > 0 : false,
            },
          },
          distributionChannels: [
            {
              channelId: "cap1_lux_lifestyle",
            },
            ...(action.includePremierCollection
              ? [
                  {
                    channelId: "cap1_lux_premier",
                  },
                ]
              : []),
          ],
          availabilityMode: AvailabilityModeEnum.AVAILABLE_FIRST,
          AvailabilityRequest:
            VacationRentalsAvailabilityRequestEnum.InitialSearch,
        };
        isInitialRequest = true;
        break;
      }
      case actionTypes.FETCH_MORE_VACATION_RENTALS_AVAILABILITY: {
        const nextPageToken =
          getVacationRentalsAvailabilityNextPageToken(state);

        if (!nextPageToken) return;

        requestBody = {
          stayDetails: {
            dateRange: {
              from: dayjs(fromDate).format("YYYY-MM-DD"),
              until: dayjs(untilDate).format("YYYY-MM-DD"),
            },
            guestDetails: {
              adults: adultsCount,
              children: children.length,
              infants: 0, // infants are supported in VR models but not in the UI
              petsIncluded: petsCount ? petsCount > 0 : false,
            },
          },
          nextPageToken: nextPageToken,
          AvailabilityRequest:
            VacationRentalsAvailabilityRequestEnum.FollowupSearch,
        };
        isInitialRequest = false;
        break;
      }
    }
    const availabilityResponse: VacationRentalsAvailabilityResponse =
      yield fetchAvailability(requestBody);

    yield put(
      actions.setVacationRentalsAvailabilityResults({
        payload: availabilityResponse,
        isInitialRequest,
      })
    );
    const properties: ITrackingProperties<VacationRentalEntryProperties> =
      yield select(getVacationRentalEntryProperties);
    const viewedVRListProperties: ITrackingProperties<ViewedVacationRentalListProperties> =
      yield select(getViewedVacationRentalListProperties);
    if (
      action.type === actionTypes.FETCH_INITIAL_VACATION_RENTALS_AVAILABILITY
    ) {
      trackEvent({
        eventName: VR_ENTRY,
        properties: {
          ...properties.properties,
        },
        encryptedProperties: properties.encryptedProperties,
      });
      trackEvent({
        eventName: VIEWED_VR_LIST,
        ...viewedVRListProperties,
      });
    }
  } catch (e) {
    yield put(actions.setVacationRentalsAvailabilityCallStateFailed());
    Logger.debug(e);
  }
}

export interface IVacationRentalsAvailabilityParsedQuery {
  locationName: string;
  fromDate: Date;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
  petsCount: number;
}

const getExistingStateVariables = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  location: getVacationRentalsLocation(state),
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  petsCount: getPetsCount(state),
});

const shouldRedirect = ({ fromDate, untilDate, location }: any) =>
  !fromDate || !untilDate || !location;

const parseQueryString = (
  history: H.History
): IVacationRentalsAvailabilityParsedQuery => {
  const queryString = history?.location?.search || "";

  const {
    locationName,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
  } = queryStringParser.parse(queryString);

  return {
    locationName: locationName as string,
    fromDate: dayjs(fromDate as string).toDate(),
    untilDate: dayjs(untilDate as string).toDate(),
    adultsCount: parseInt(adultsCount as string),
    childrenCount: parseInt(childrenCount as string),
    petsCount: parseInt(petsCount as string),
  };
};

function* getVacationRentalsAvailabilityRequestParameters(
  fetchVacationRentalsAvailability: actions.IFetchVacationRentalsAvailability
) {
  const state: IStoreState = yield select();
  const history = fetchVacationRentalsAvailability.history;

  let { fromDate, untilDate, location, adultsCount, children, petsCount } =
    getExistingStateVariables(state);

  const parsedQueryString = parseQueryString(history);

  if (
    !fromDate ||
    !untilDate ||
    !location ||
    !adultsCount ||
    (location?.id as IIdLodgings).lodgingSelection.searchTerm !==
      parsedQueryString.locationName
  ) {
    const { correspondingLocation } = yield fetchLocation(parsedQueryString);

    [fromDate, untilDate, location, adultsCount, children, petsCount] = [
      parsedQueryString.fromDate,
      parsedQueryString.untilDate,
      correspondingLocation,
      parsedQueryString.adultsCount || 2,
      parsedQueryString.childrenCount > 0
        ? new Array(parsedQueryString.childrenCount).fill(17)
        : [],
      parsedQueryString.petsCount,
    ];

    // If we know we are missing the data we should redirect the user home rather than make a bad request.
    // TODO: this should probably be monitored in the future. If we get a lot of these something is likely wrong.
    if (
      shouldRedirect({
        fromDate,
        untilDate,
        location,
        adultsCount,
        petsCount,
      })
    ) {
      if (
        fetchVacationRentalsAvailability.history.location.pathname ===
        PATH_VACATION_RENTALS_AVAILABILITY
      )
        fetchVacationRentalsAvailability.history.push(PATH_HOME);
      return {};
    }

    yield putResolve(searchActions.setFromDate(fromDate));
    yield putResolve(searchActions.setUntilDate(untilDate));

    yield putResolve(searchActions.setVacationRentalsLocation(location));

    yield putResolve(
      searchActions.setOccupancyCounts({
        adults: adultsCount,
        children,
        pets: petsCount,
      })
    );
  }
  yield put(actions.setSearchedDates(fromDate!, untilDate!));
  yield put(actions.setSearchedLocationResult(location));
  yield put(
    actions.setSearchedOccupancyCounts({
      adults: adultsCount,
      children,
      pets: petsCount,
    })
  );

  return {
    fromDate,
    untilDate,
    location,
    adultsCount,
    children,
    petsCount,
  };
}

function* fetchLocation(
  parsedQueryString: IVacationRentalsAvailabilityParsedQuery
) {
  if (!parsedQueryString.locationName)
    return { correspondingLocation: undefined };

  const { categories: locationCategories }: IResponse =
    yield fetchVacationRentalsLocations();
  const correspondingLocation = locationCategories.flatMap((category) =>
    category.results.find((result) =>
      result.label
        .toLowerCase()
        .includes(parsedQueryString.locationName.toLowerCase())
    )
  );

  return { correspondingLocation: correspondingLocation[0] };
}
