import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  setContactInfo,
  scheduleBook,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setPriceQuote,
  fetchApplicableTravelWalletItems,
  scheduleVacationRentalPriceQuote,
} from "../../actions/actions";

import { IStoreState } from "../../../../reducers/types";
import { VacationRentalBookTravellerSection } from "./component";
import {
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTraveler,
  isTravelerStepComplete,
} from "../../reducer";
import { fetchTravelWalletCreditHistory } from "../../../travel-wallet/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    email: getConfirmationEmail(state),
    phoneNumber: getConfirmationPhoneNumber(state),
    userSelectedTraveler: getUserSelectedTraveler(state),
    isTravelerStepComplete: isTravelerStepComplete(state),
  };
};

const mapDispatchToProps = {
  setContactInfo,
  scheduleBook,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setPriceQuote,
  fetchApplicableTravelWalletItems,
  fetchTravelWalletCreditHistory,
  scheduleVacationRentalPriceQuote,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VacationRentalBookTravellerSectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedVacationRentalBookTravellerSection = withRouter(
  connector(VacationRentalBookTravellerSection)
);
