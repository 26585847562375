import React, { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@material-ui/core";
import {
  goToShop,
  goToCheckout,
} from "../../../../shop/utils/queryStringHelpers";
import { CfarDetails } from "../index";
import { MobileAddOnCustomizeConnectorProps } from "./container";
import { AddOnCustomizeStep, AddOnCustomizeStepAlias } from "./types";

export interface IMobileAddOnCustomizeProps
  extends MobileAddOnCustomizeConnectorProps,
    RouteComponentProps {
  // note: this callback function is used to overwrite the default (final) on-continue behaviour
  goToNextPage?: () => void;
  goBack?: () => void;
  currentStep: AddOnCustomizeStepAlias;
  setCurrentStep: React.Dispatch<React.SetStateAction<AddOnCustomizeStepAlias>>;
}

export const MobileAddOnCustomize = (props: IMobileAddOnCustomizeProps) => {
  const {
    history,
    cfarQuote,
    cancellationPolicy,
    isCfarAvailable,
    goToNextPage = () => {
      goToCheckout({ history });
    },
    goBack = () => {
      goToShop({ history });
    },
    currentStep,
    setCurrentStep,
  } = props;
  const prevStep = useRef<AddOnCustomizeStepAlias>(currentStep);

  const onContinue = () => {
    setCurrentStep((step) => step + 1);
  };

  useEffect(() => {
    return () => {
      setCurrentStep(AddOnCustomizeStepAlias.AddOn1);
    };
  }, []);

  useEffect(() => {
    const processStep = (skipCondition: boolean) => {
      /*
        note: when a step isn't enabled (e.g. cfar experiment is turned off or the cfar offer is not available),
        that step needs to be skipped.
      */
      if (skipCondition) {
        const stepAfter = currentStep + 1;
        const stepBefore = currentStep - 1;

        // note: take into account the direction of the navigation, i.e. if from the step after, then skip to before, and vice versa.
        if (prevStep.current === stepAfter) {
          setCurrentStep(stepBefore);
        } else {
          setCurrentStep(stepAfter);
        }
      } else {
        prevStep.current = currentStep;
      }
    };

    switch (addOnCustomizeSteps[currentStep]) {
      case AddOnCustomizeStep.GoBack:
        prevStep.current = currentStep;
        goBack();
        break;
      case AddOnCustomizeStep.GoToCheckout:
        prevStep.current = currentStep;
        goToNextPage();
        break;
      case AddOnCustomizeStep.CFAR:
        processStep(!isCfarAvailable);
        break;
    }
  }, [currentStep, isCfarAvailable, goBack, goToNextPage, setCurrentStep]);

  return (
    <Box className="mobile-hotel-add-on-customize-root">
      <Box className="mobile-hotel-add-on-customize-container">
        {addOnCustomizeSteps[currentStep] === AddOnCustomizeStep.CFAR &&
          isCfarAvailable &&
          cfarQuote !== null && (
            <CfarDetails
              currentHotelCfarQuote={cfarQuote}
              currentCancellationPolicy={cancellationPolicy}
              onContinue={onContinue}
              cardContentProps={{
                disablePartialScroll: true,
              }}
            />
          )}
      </Box>
    </Box>
  );
};

const addOnCustomizeSteps = {
  [AddOnCustomizeStepAlias.GoBack]: AddOnCustomizeStep.GoBack,
  [AddOnCustomizeStepAlias.AddOn1]: AddOnCustomizeStep.CFAR,
  [AddOnCustomizeStepAlias.GoToCheckout]: AddOnCustomizeStep.GoToCheckout,
};
