import React, { useState, useEffect } from "react";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import type { BookingSubmitApprovalErrorModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface IBookingSubmitApprovalModalProps
  extends BookingSubmitApprovalErrorModalConnectorProps {}

export const BookingSubmitApprovalErrorModal = ({
  isOpen = false,
}: IBookingSubmitApprovalModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen);
  const { matchesMobile } = useDeviceTypes();

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  return (
    <GenericInfoPopup
      className="booking-submit-approval-error-modal"
      isMobile={matchesMobile}
      open={isModalOpen}
      image={<Icon className="error-icon" name={IconName.UnableToProcess} />}
      subtitle={textConstants.subtitle}
      title={textConstants.title}
      buttons={[
        {
          buttonText: "Try Again",
          onClick: () => setIsModalOpen(false),
        },
      ]}
    />
  );
};
