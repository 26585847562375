import React from "react";

import { Box } from "@material-ui/core";

export interface ITwoColumnLayoutProps {
  children: React.ReactElement[];
  className?: string;
  columnClassName?: string;
}

export const TwoColumnLayout = ({
  children,
  className,
  columnClassName,
}: ITwoColumnLayoutProps) => {
  const childrenCount = children.length;
  const firstColumnCount = React.useMemo(
    () => Math.ceil(childrenCount / 2),
    [childrenCount]
  );
  return (
    <Box
      style={{ display: "flex", flexDirection: "row", columnGap: "16px" }}
      className={className}
    >
      <Box className={columnClassName} style={{ width: "50%" }}>
        {children.slice(0, firstColumnCount)}
      </Box>
      <Box className={columnClassName} style={{ width: "50%" }}>
        {children.slice(firstColumnCount)}
      </Box>
    </Box>
  );
};
