import { Cfar } from "redmond";
import { getTotalPriceText } from "halifax";
import { isCaponeTenant } from "@capone/common";
import { config } from "../../../../api/config";

export const BACK_TO_RESULTS = "Back to results";

export const getCfarSecondaryText = (cfarCancellation: Cfar) =>
  `Refund amount: ${getTotalPriceText({
    price: cfarCancellation.refundAmount.fiat,
  })}`;

export const AMENITIES_TITLE = "Popular Amenities";
export const getAmenitiesSubtitle = (isLifestyleCollection?: boolean) => {
  return `Enjoy your ${
    isLifestyleCollection ? "" : "luxury"
  } stay with some of these popular amenities`;
};

export const REVIEWS = "Reviews";

export const CHOOSE_ROOM = "Choose Room";

export const PC_EARN_BANNER_TEXT = `<strong>Earn 10X miles</strong> on Premier Collection purchases,
and enjoy a <strong>$100 experience credit</strong> to spend at qualifying dining, spa or
hotel activities.`;

export const getLCEarnBannerText = (
  hotelsMultiplier: number,
  isUserPrimary?: boolean
) => {
  return isCaponeTenant(config.TENANT) || isUserPrimary
    ? `<strong>Earn ${hotelsMultiplier}X miles</strong> on Lifestyle Collection purchases,
  and enjoy a <strong>$50 experience credit</strong> to use toward things like signature hotel restaurants and room service.`
    : `<strong>Enjoy a <strong>$50 experience credit</strong> to use toward things like signature hotel restaurants and room service.`;
};
