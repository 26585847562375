import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getLocation,
  getPetsCount,
  getUntilDate,
} from "../../reducer";
import { MobilePremierCollectionSearchControl } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  value: getLocation(state),
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  adultsCount: getAdultsCount(state),
  childrenCount: getChildrenCount(state),
  petsCount: getPetsCount(state),
});

const connector = connect(mapStateToProps);

export type MobileHotelSearchV2ConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePremierCollectionSearchControl = withRouter(
  connector(MobilePremierCollectionSearchControl)
);
