import axios from "axios";

import {
  HotelPriceQuoteScheduleRequestV2,
  CreateHomeProductRequest,
} from "redmond";
import { purchaseApi, Product } from "@b2bportal/purchase-api";

const schedulePriceQuote = (
  req: HotelPriceQuoteScheduleRequestV2 | CreateHomeProductRequest,
  email: string,
  productType: Product = Product.Hotel
): Promise<String> =>
  new Promise((resolve, reject) => {
    try {
      const request = {
        type: productType,
        value: req as object,
      };

      const res = purchaseApi(axios).apiV0PurchaseQuoteSchedulePost({
        emailAddress: email,
        request: request,
      });

      res.then((body) => resolve(body.data.value)).catch((e) => reject(e));
    } catch (e) {
      reject(e);
    }
  });

export default schedulePriceQuote;
